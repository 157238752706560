/**
 * Created by Yoon Yong (Andy) Shin on 23/12/2016
 * As part of Nosework
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & Yoon Yong (Andy) Shin - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Yoon Yong (Andy) Shin <andy.shin@applicat.co.kr>, 23/12/2016
 *
 */

// Angular
import {Directive, Input, ElementRef, Renderer, OnInit, SimpleChange, OnChanges} from "@angular/core";

@Directive({
  selector: '[sanitize-style]'
})
export class SanitizeStyle implements OnInit, OnChanges {
  public element;

  @Input('sanitize-style') style: any;

  constructor(private renderer: Renderer,
              private elementRef: ElementRef) {
    this.element = elementRef;
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {
    if (this.style)
      this.updateStyle();
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (changes['style']) {
      this.updateStyle();
    }
  }

  /*****************************
   *        util functions
   *****************************/

  updateStyle() {
    for (var key in this.style) {
      this.renderer.setElementStyle(this.element.nativeElement, key, this.style[key]);
    }
  }

}