/**
 * Created by PHILIP on 11/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 11/07/2017
 *
 */


declare var wcs;
declare var wcs_do;
// Angular
import {Component, OnInit} from "@angular/core";
import {MdDialog, MdDialogConfig, MdDialogRef} from "@angular/material";

// Project Sources
import {getTemplate} from "../../../shared/templates";
import {AppService} from "../../../shared/services/app.service";
import {AuthService} from "../../../shared/services/auth.service";
import {DialogService} from "../../../shared/components/dialog-message/dialog-message.service";
import {DaumMap} from "../../../shared/components/daum-map/daum-map";

@Component({
  selector: 'setting-account',
  template: getTemplate('pages/store/setting-account/setting-account')
})
export class SettingAccount implements OnInit{

  appEventDisposor;

  config: MdDialogConfig = new MdDialogConfig();
  dialogDaumMapRef: MdDialogRef<DaumMap>;

  user;

  currentPassword;
  password;
  passwordConfirm;

  deliveryInfo;
  address;

  constructor(public appService: AppService,
              private mdDialog: MdDialog,
              private authService: AuthService,
              private dialogService: DialogService){}

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit(){

    if (!wcs_add) var wcs_add = {};
    wcs_add["wa"] = "s_52e6d95fb9a0";
    if (!_nasa) var _nasa = {};
    wcs.inflow();
    wcs_do(_nasa);

    if (this.appEventDisposor) this.appEventDisposor.unsubscribe();
    this.appEventDisposor = this.appService.appEvent.subscribe(this.appEventHandler.bind(this));

    this.user = {
      identifier: '',
      nickname: ''
    };

    this.currentPassword = '';
    this.password = '';
    this.passwordConfirm = '';

    this.deliveryInfo = {
      addressInfo: {
        zipCode: '',
        address: '',
        detailAddress: ''
      },
      phone: null,
      name: ''
    };
    this.address = '';

    this.initLoadSequence();
  }

  ngOnDestroy() {
    if (this.appEventDisposor)
      this.appEventDisposor.unsubscribe();
  }

  /*****************************
   *        util functions
   *****************************/

  initLoadSequence(){
    this.authService.getMyUserInfo({})
      .subscribe(
        (userWrapper) => {
          if(userWrapper['user']){
            this.appService.user = userWrapper['user'];

            this.user['nickname'] = userWrapper['user'].nickname;
            this.user['identifier'] = userWrapper['user'].identifier;
            if(userWrapper['user'].deliveryInfo){
              this.deliveryInfo = userWrapper['user'].deliveryInfo;

              if(this.deliveryInfo.zipCode)
                this.address = '(' + this.deliveryInfo['addressInfo'].zipCode + ') ';
              this.address += this.deliveryInfo['addressInfo'].address;
            }
          } else if(!userWrapper['user']) {
            this.dialogService.message("알림", "사용자 정보를 찾을 수 없습니다. 다시 로그인 해주세요.");
            this.appService.sendEvent('logout');
          }
        },
        (err) => {
          let subTitle = '';

          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '사용자 정보를 찾을 수 없습니다. 다시 로그인 해주세요.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      )
  }

  appEventHandler(event) {
    switch (event.name) {
      case 'reload':
        this.initLoadSequence();
        break;
    }
  }

  isValid(type){
    switch(type){
      case 'nickname':
        if(!this.user['nickname'] || this.user['nickname'] == '') return false;
        else if((this.user['nickname'].length < 2 || this.user['nickname'] > 8)) return false;
        else return true;
      case 'password':
        let regExpPassword = /^.*(?=^.{8,12}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[*~`!^\-_+@\#$%&\=\(\)]).*$/;

        if (!this.currentPassword || this.currentPassword == '' || !regExpPassword.test(this.currentPassword))return false;
        else if(!this.password || this.password == '' || !regExpPassword.test(this.password)) return false;
        else if(!this.passwordConfirm || this.passwordConfirm == '' || this.password != this.passwordConfirm) return false;
        else return true;
      case 'address':
        let regExpPhone = /^[0-9]*$/;
        if(!this.deliveryInfo['addressInfo'].address || this.deliveryInfo['addressInfo'].address == '') return false;
        else if(!this.deliveryInfo['phone'] || this.deliveryInfo['phone'] == '' || !regExpPhone.test(this.deliveryInfo['phone'])) return false;
        else if(!this.deliveryInfo['name'] || this.deliveryInfo['name'] == '') return false;
        else return true;
    }
  }

  openDaumMapForm(){
    this.dialogService.resizeModal(this.config);
    this.config.disableClose = true;

    this.dialogDaumMapRef = this.mdDialog.open(DaumMap, this.config);

    this.dialogDaumMapRef.afterClosed()
      .subscribe((result) => {
        if(result && result.place){
          this.address = '';

          if(result.place.zipcode){
            this.address = '(' + result.place.zipcode + ') ';
            this.deliveryInfo['addressInfo'].zipCode = result.place.zipcode;
          } else {
            this.deliveryInfo['addressInfo'].zipCode = '확인불가';
          }
          this.address += result.place.address;
          this.deliveryInfo['addressInfo'].address = result.place.address;
        }
      });
  }

  /*****************************
   *       helper functions
   *****************************/

  changeNickname(){
    if(this.isValid('nickname')){
      this.dialogService.loadingSubject.next('spinner');

      let user = this.appService.user;
      user['nickname'] = this.user['nickname'];

      this.authService.updateMyInfo(user)
        .finally(() => {
          this.dialogService.loadingSubject.next('hide');
        })
        .subscribe(
          (userWrapper) => {
            this.appService.user = userWrapper['user'];
            this.user['nickname'] = userWrapper['user'].nickname;

            this.dialogService.message("알림", "닉네임이 변경되었습니다.");
          },
          (err) => {
            let subTitle = '';
            switch (err.status) {
              case 409:
                subTitle = '이미 사용중인 닉네임입니다.';
                break;
              case 500:
                subTitle = '서버에러';
                break;
              default:
                subTitle = '잘못된 요청입니다.';
                break;
            }
            this.dialogService.message("알림", subTitle);
          }
        )
    } else {
      this.dialogService.message("알림", "닉네임을 확인해주세요.");
    }
  }

  changePassword(){
    if(this.isValid('password')) {
      this.dialogService.loadingSubject.next('spinner');

      this.authService.changePassword(this.currentPassword, this.password)
        .finally(() => {
          this.dialogService.loadingSubject.next('hide');
        })
        .subscribe(
          () => {
            this.currentPassword = '';
            this.password = '';
            this.passwordConfirm = '';

            this.dialogService.message("알림", "새로운 비밀번호가 설정되었습니다.");
          },
          (err) => {
            let subTitle = '';
            switch (err.status) {
              case 400:
                subTitle = '입력한 정보를 확인해주세요.';
                break;
              case 422:
                subTitle = '올바른 패스워드가 아닙니다.';
                break;
              case 500:
                subTitle = '서버에러';
                break;
              default:
                subTitle = '잘못된 요청입니다.';
                break;
            }
            this.dialogService.message("알림", subTitle);
          }
        );
    } else {
      this.dialogService.message("알림", "패스워드를 확인해주세요.");
    }
  }

  changeAddress(){
    if(this.isValid('address')) {
      this.dialogService.loadingSubject.next('spinner');

      let user = this.appService.user;
      user['deliveryInfo'] = this.deliveryInfo;

      this.authService.updateMyInfo(user)
        .finally(() => {
          this.dialogService.loadingSubject.next('hide');
        })
        .subscribe(
          (userWrapper) => {
            this.appService.user = userWrapper['user'];
            this.user['deliveryInfo'] = userWrapper['user'].deliveryInfo;

            this.dialogService.message("알림", "배송지 정보가 변경되었습니다.");
          },
          (err) => {
            let subTitle = '';
            switch (err.status) {
              case 500:
                subTitle = '서버에러';
                break;
              default:
                subTitle = '잘못된 요청입니다.';
                break;
            }
            this.dialogService.message("알림", subTitle);
          }
        )
    } else {
      this.dialogService.message("알림", "배송지정보를 확인해주세요.");
    }
  }

}