/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */

// Browser Lib
declare var _;

// Angular
import {Component, OnInit,ViewChild,ElementRef,Renderer } from "@angular/core";
import {MdDialogRef, MdDialog, MdDialogConfig} from "@angular/material";

// Project Sources
import {getTemplate} from "../../shared/templates";
import {AppService} from "../../shared/services/app.service";
import {AuthService} from "../../shared/services/auth.service";
import {DialogService} from "../../shared/components/dialog-message/dialog-message.service";
import {Register} from "./register/register";
import {FindPassword} from "./find-password/find-password";
import {PreOrderHistory} from "./pre-order-history/pre-order-history";
import {PopUpDialog} from "../popup-dialog/popup-dialog";
import {PopUpDialogService} from "../../shared/services/popup-dialog.service";
import { constructDependencies } from "@angular/core/src/di/reflective_provider";
import {FocusModule} from 'angular2-focus';

@Component({
  selector: 'login',
  template: getTemplate('pages/login/login')
})
export class Login implements OnInit {

  config: MdDialogConfig = new MdDialogConfig();
  dialogFindPasswordRef: MdDialogRef<FindPassword>;
  dialogRegisterRef: MdDialogRef<Register>;
  dialogNonMemberPreOrderHistoryRef: MdDialogRef<PreOrderHistory>;

  loginInfo;
  validationText;

  dialogPopUpDialogRef: MdDialogRef<PopUpDialog>;
  constructor(public dialogRef: MdDialogRef<Login>,
              private mdDialog: MdDialog,
              private appService: AppService,
              private authService: AuthService,
              private popupDialogService:PopUpDialogService,
              private dialogService: DialogService,
              private renderer: Renderer) {}

  /*****************************
   *         life cycle
   *****************************/
  //@ViewChildren('input') vc;
  
  ngOnInit() {
    this.loginInfo = {
      identifier: '',
      password: ''
    };
    this.validationText = '';
  }
  /*****************************
   *        util functions
   *****************************/

  isValidLogin() {
    let regExpMail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
    let regExpPassword = /^.*(?=^.{8,12}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[*~`!^\-_+@\#$%&\=\(\)]).*$/;

    if (!this.loginInfo['identifier'] || this.loginInfo['identifier'] == '' || this.loginInfo['identifier'].length > 32 || !regExpMail.test(this.loginInfo['identifier'])){
      this.validationText = '이메일을 확인해주세요.';
      return false;
    } else if (!this.loginInfo['password'] || this.loginInfo['password ']== '') {
      this.validationText = '비밀번호를 확인해주세요.';
      return false;
    } else if(!regExpPassword.test(this.loginInfo['password'])){
      this.validationText = '영문, 숫자, 특수문자 포함 8자 ~ 12자 이내로 입력해주세요.';
      return false;
    } else {
      return true;
    }
  }

  openFindPasswordForm() {
    this.dialogService.resizeModal(this.config);
    this.config.disableClose = true;

    this.dialogFindPasswordRef = this.mdDialog.open(FindPassword, this.config);

    this.dialogFindPasswordRef.afterClosed()
      .subscribe((result) => {});
  }

  openRegisterForm() {
    this.config.disableClose = true;
    this.config.width = '100%';
    this.config.height = '100%';

    this.dialogRegisterRef = this.mdDialog.open(Register, this.config);

    this.dialogRegisterRef.afterClosed()
      .subscribe((result) => {});

    this.dialogRef.close();
  }

  openNonMemberOrderHistoryForm() {
    this.dialogService.resizeModal(this.config);
    this.config.disableClose = true;

    this.dialogNonMemberPreOrderHistoryRef = this.mdDialog.open(PreOrderHistory, this.config);

    this.dialogNonMemberPreOrderHistoryRef.afterClosed()
      .subscribe((result) => {});

    this.dialogRef.close();
  }

  dismiss() {
    this.dialogRef.close();
  }

  /*****************************
   *       helper functions
   *****************************/

  login() {
    if(this.isValidLogin()){
      this.dialogService.loadingSubject.next('spinner');

      this.authService.login(this.loginInfo['identifier'], this.loginInfo['password'])
        .finally(() => {
          this.dialogService.loadingSubject.next('hide');
        })
        .subscribe(
          (userWrapper) => {
            if(userWrapper['user']){
              this.appService.user = userWrapper['user'];
              this.appService.token = userWrapper['token'];
              this.appService.sendEvent('reload');
              this.dialogRef.close();
            } else if(!userWrapper['user']){
              this.dialogService.message("알림", "등록되지 않은 사용자입니다.");
            }
          },
          (err) => {
            let subTitle = '';
            switch (err.status) {
              case 400:
                subTitle = "아이디 또는 비밀번호를 확인해주세요.";
                break;
              case 401:
                subTitle = '등록되지 않은 사용자입니다.';
                if(err._body){
                  let message = JSON.parse(err._body).message;
                  if(message == 'WrongPassword')
                    subTitle = '비밀번호를 확인해주세요.';
                  else if(message == 'UnActivateUser')
                    subTitle = '비활성화된 계정입니다.';
                }
                break;
              case 500:
                subTitle = '서버에러';
                break;
              default:
                subTitle = '잘못된 요청입니다.';
                break;
            }
            this.dialogService.message("알림", subTitle);
          });
    } else {
      this.dialogService.message("알림", this.validationText);
    }
  }
}