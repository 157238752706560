/**
 * Created by Yoon Yong (Andy) Shin on 23/12/2016
 * As part of Nosework
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & Yoon Yong (Andy) Shin - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Yoon Yong (Andy) Shin <andy.shin@applicat.co.kr>, 23/12/2016
 *
 */

// Angular
import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, FormControl, Validator } from '@angular/forms';

// /^
// (?=.*\d)                //should contain at least one digit
// (?=.*[a-z])             //should contain at least one lower case
// (?=.*[A-Z])             //should contain at least one upper case
// [a-zA-Z0-9]{8,}         //should contain at least 8 from the mentioned characters
// $/
let PSD_REGEXP: RegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{9,}$/;

@Directive({
  selector: '[invalidPassword][formControlName],[invalidPassword][ngModel],[invalidPassword][formControl]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => PSDValidator), multi: true}
  ]
})
export class PSDValidator implements Validator {

  constructor() {
  }

  validate(c: FormControl) {
    return PSDValidator.isValidFormat(c);
  }

  static isValidFormat(c: FormControl) {
    return PSD_REGEXP.test(c.value) ? null : {
      invalidPassword: true
    };
  }
}