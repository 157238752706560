/**
 * Created by PHILIP on 27/06/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 27/06/2017
 *
 */

// Angular
import {ModuleWithProviders} from "@angular/core";
import {RouterModule} from "@angular/router";

// Project Sources
import {Home} from "../pages/home/home";
import {Brand} from "../pages/brand/brand";
import {ProductConneck} from "../pages/product/product-conneck/product-conneck";
import {ConneckOverview} from "../pages/product/product-conneck/conneck-overview/conneck-overview";
import {ConneckStory} from "../pages/product/product-conneck/conneck-story/conneck-story";
import {ConneckFunctionDesign} from "../pages/product/product-conneck/conneck-function-design/conneck-function-design";
import {ProductNoseBall} from "../pages/product/product-noseBall/product-noseBall";
import {NoseBallOverview} from "../pages/product/product-noseBall/noseBall-overview/noseBall-overview";
import {NoseBallStory} from "../pages/product/product-noseBall/noseBall-story/noseBall-story";
import {NoseBallFunctionDesign} from "../pages/product/product-noseBall/noseBall-function-design/noseBall-function-design";
import {ProductNoseWorKit} from "../pages/product/product-noseWorKit/product-noseWorKit";
import {NoseWorKitOverview} from "../pages/product/product-noseWorKit/noseWorKit-overview/noseWorKit-overview";
import {NoseWorKitStory} from "../pages/product/product-noseWorKit/noseWorKit-story/noseWorKit-story";
import {NoseWorKitFunctionDesign} from "../pages/product/product-noseWorKit/noseWorKit-function-design/noseWorKit-function-design";
import {ProductSmartNoseWork} from "../pages/product/product-smartNoseWork/product-smartNoseWork";
import {SmartNoseWorkOverview} from "../pages/product/product-smartNoseWork/smartNoseWork-overview/smartNoseWork-overview";
import {SmartNoseWorkStory} from "../pages/product/product-smartNoseWork/smartNoseWork-story/smartNoseWork-story";
import {SmartNoseWorkFunction} from "../pages/product/product-smartNoseWork/smartNoseWork-function/smartNoseWork-function";
import {SmartNoseWorkDesign} from "../pages/product/product-smartNoseWork/smartNoseWork-design/smartNoseWork-design";
import {Service} from "../pages/service/service";
import {Supporters} from "../pages/supporters/supporters";
import {Store} from "../pages/store/store";
import {StoreHome} from "../pages/store/store-home/store-home";
import {ProductDetail} from "../pages/store/product-detail/product-detail";
import {ProductDetailIndex} from "../pages/store/product-detail/product-detail-index";
import {CartList} from "../pages/store/cart-list/cart-list";
import {Payment} from "../pages/store/payment/payment";
import {SuccessPayment} from "../pages/store/payment/success-payment/success-payment";
import {InvoiceList} from "../pages/store/invoice-list/invoice-list";
import {InvoiceDetail} from "../pages/store/invoice-list/invoice-detail/invoice-detail";
import {SettingAccount} from "../pages/store/setting-account/setting-account";
import {ManageProduct} from "../pages/store/manage-product/manage-product";



export const routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    label: 'Home',
    path: 'home',
    component: Home
  },
  {
    label: 'Brand',
    path: 'brand',
    component: Brand
  },
  {
    label: 'Product-Conneck',
    path: 'product-conneck',
    component: ProductConneck,
    children: [
      {
        path: '',
        redirectTo: 'conneck-overview',
        pathMatch: 'full'
      },
      {
        label: 'Conneck-Overview',
        path: 'conneck-overview',
        component: ConneckOverview
      },
      {
        label: 'Conneck-Story',
        path: 'conneck-story',
        component: ConneckStory
      },
      {
        label: 'Conneck-Function-Design',
        path: 'conneck-function-design',
        component: ConneckFunctionDesign
      }
    ]
  },
  {
    label: 'Product-NoseBall',
    path: 'product-noseBall',
    component: ProductNoseBall,
    children: [
      {
        path: '',
        redirectTo: 'noseBall-overview',
        pathMatch: 'full'
      },
      {
        label: 'NoseBall-Overview',
        path: 'noseBall-overview',
        component: NoseBallOverview
      },
      {
        label: 'NoseBall-Story',
        path: 'noseBall-story',
        component: NoseBallStory
      },
      {
        label: 'NoseBall-Function-Design',
        path: 'noseBall-function-design',
        component: NoseBallFunctionDesign
      }
    ]
  },
  {
    label: 'Product-NoseWorKit',
    path: 'product-noseWorKit',
    component: ProductNoseWorKit,
    children: [
      {
        path: '',
        redirectTo: 'noseWorKit-overview',
        pathMatch: 'full'
      },
      {
        label: 'NoseWorKit-Overview',
        path: 'noseWorKit-overview',
        component: NoseWorKitOverview
      },
      {
        label: 'NoseWorKit-Story',
        path: 'noseWorKit-story',
        component: NoseWorKitStory
      },
      {
        label: 'NoseWorKit-Function-Design',
        path: 'noseWorKit-function-design',
        component: NoseWorKitFunctionDesign
      }
    ]
  },
  {
    label: 'Product-SmartNoseWork',
    path: 'product-smartNoseWork',
    component: ProductSmartNoseWork,
    children: [
      {
        path: '',
        redirectTo: 'smartNoseWork-overview',
        pathMatch: 'full'
      },
      {
        label: 'SmartNoseWork-Overview',
        path: 'smartNoseWork-overview',
        component: SmartNoseWorkOverview
      },
      {
        label: 'SmartNoseWork-Story',
        path: 'smartNoseWork-story',
        component: SmartNoseWorkStory
      },
      {
        label: 'SmartNoseWork-Function',
        path: 'smartNoseWork-function',
        component: SmartNoseWorkFunction
      },
      {
        label: 'SmartNoseWork-Design',
        path: 'smartNoseWork-design',
        component: SmartNoseWorkDesign
      }
    ]
  },
  {
    label: 'Service',
    path: 'service',
    component: Service
  },
  {
    label: 'Supporters',
    path: 'supporters',
    component: Supporters
  },
  {
    label: 'Store',
    path: 'store',
    component: Store,
    children: [
      {
        path: '',
        redirectTo: 'store-home',
        pathMatch: 'full'
      },
      {
        label: 'Store-Home',
        path: 'store-home',
        component: StoreHome
      },
      {
        label: 'Store-Home',
        path: 'store-home/:type',
        component: StoreHome
      },
      {
        label: 'Product-Detail',
        path: 'product-detail/:name',
        component: ProductDetail
      },
      {
        label: 'Product-Detail',
        path: 'product-detail',
        component: ProductDetailIndex
      },
      {
        label: 'Cart-List',
        path: 'cart-list',
        component: CartList
      },
      {
        label: 'Payment',
        path: 'payment',
        component: Payment
      },
      {
        label: 'SuccessPayment',
        path: 'success-payment/:invoiceId',
        component: SuccessPayment
      },
      {
        label: 'Invoice-List',
        path: 'invoice-list',
        component: InvoiceList
      },
      {
        label: 'Invoice-Detail',
        path: 'invoice-detail/:invoiceId',
        component: InvoiceDetail
      },
      {
        label: 'Manage-Product',
        path: 'manage-product',
        component: ManageProduct
      },
      {
        label: 'Setting-Account',
        path: 'setting-account',
        component: SettingAccount
      }
    ]
  },
  {
    component: Home,
    path: "**"
  }

];


export const routing: ModuleWithProviders = RouterModule.forRoot(routes);