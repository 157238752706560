/**
 * Created by Yoon Yong (Andy) Shin on 10/04/2017
 * As part of Nosework
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & Yoon Yong (Andy) Shin - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Yoon Yong (Andy) Shin <andy.shin@applicat.co.kr>, 10/04/2017
 *
 */

// Export Lib
declare var _;

// Browser Lib
import {Observable} from 'rxjs/Observable';

// Angular
import {Injectable} from '@angular/core';
import {CanDeactivate, Router} from '@angular/router';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | boolean;
}

@Injectable()
export class RouterGuardService implements CanDeactivate<CanComponentDeactivate> {
  constructor(private router: Router) {

    let root = null;
    _.forEach(this.router.config, (state) => {
      if (state.redirectTo)
        root = state.redirectTo;
    });

    let firstPage = window.location.pathname + window.location.search;

    if (root != window.location.pathname) {
      history.replaceState(null, null, root);
      history.pushState(null, null, firstPage);
    }
  }

  canDeactivate(component: CanComponentDeactivate) {
    if (component.canDeactivate) {
      let result = component.canDeactivate();

      if (!result) this.router.navigate([this.router.url]);

      return result;
    }
    return true;
  }
}
