/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */

declare var wcs;
declare var wcs_do;

// Angular
import {Component, OnInit} from "@angular/core";
import {MdDialog, MdDialogConfig, MdDialogRef} from "@angular/material";

// Project Sources
import {getTemplate} from "../../../shared/templates";
import {AppService} from "../../../shared/services/app.service";
import {SerialService} from "../../../shared/services/serial.service";
import {DialogService} from "../../../shared/components/dialog-message/dialog-message.service";
import {RegisterGenuine} from "./register-genuine/register-genuine";

@Component({
  selector: 'manage-product',
  template: getTemplate('pages/store/manage-product/manage-product')
})
export class ManageProduct implements OnInit{

  appEventDisposor;

  config: MdDialogConfig = new MdDialogConfig();
  dialogRegisterGenuine: MdDialogRef<RegisterGenuine>;

  registeredSerials;

  constructor(private mdDialog: MdDialog,
              private appService: AppService,
              private serialService: SerialService,
              private dialogService: DialogService){}

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit(){

    if (!wcs_add) var wcs_add = {};
    wcs_add["wa"] = "s_52e6d95fb9a0";
    if (!_nasa) var _nasa = {};
    wcs.inflow();
    wcs_do(_nasa);

    if (this.appEventDisposor) this.appEventDisposor.unsubscribe();
    this.appEventDisposor = this.appService.appEvent.subscribe(this.appEventHandler.bind(this));

    this.registeredSerials = [];

    this.initLoadSequence();
  }

  ngOnDestroy() {
    if (this.appEventDisposor)
      this.appEventDisposor.unsubscribe();
  }

  /*****************************
   *        util functions
   *****************************/

  initLoadSequence(){
    this.serialService.find({
      query: {
        registered: this.appService.user['_id']
      },
      sort: {lastUpdatedAt: -1}
    })
      .subscribe(
        (serialWrapper) => {
          this.registeredSerials = serialWrapper['serials'];
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      )
  }

  appEventHandler(event) {
    switch (event.name) {
      case 'reload':
        this.initLoadSequence();
        break;
    }
  }

  openRegisterGenuine(){
    this.dialogService.resizeModal(this.config);
    this.config.disableClose = true;

    this.dialogRegisterGenuine = this.mdDialog.open(RegisterGenuine, this.config);

    this.dialogRegisterGenuine.afterClosed()
      .subscribe((result) => {});
  }

  /*****************************
   *       helper functions
   *****************************/

  cancelRegistered(index){

    let serial = this.registeredSerials[index];

    this.serialService.cancelRegistered({
      serialNumber: serial['serialNumber'],
      productName: serial['productName'],
      registered: serial['registered']
    })
      .subscribe(
        (serialWrapper) => {
          this.dialogService.message("알림", "정상적으로 삭제되었습니다.");
          this.initLoadSequence();
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 400:
              subTitle = '잘못된 요청입니다.';
              break;
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      )
  }

}