/**
 * Created by PHILIP on 11/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 11/07/2017
 *
 */

// Angular

declare var _;

import {Component, HostListener, OnInit, Renderer, ViewChild} from "@angular/core";
import {MdDialogRef} from "@angular/material";

// Project Sources
import {getTemplate} from "../../../../shared/templates";
import {throttleable} from "../../../../shared/services/util";
import {ProductService} from "../../../../shared/services/product.service";
import {DialogService} from "../../../../shared/components/dialog-message/dialog-message.service";
import { AppService } from "../../../../shared/services/app.service";

@Component({
  selector: 'product-detail-dialog',
  template: getTemplate('pages/store/product-detail/product-detail-dialog/product-detail-dialog')
})
export class ProductDetailDialog implements OnInit {

  @ViewChild("textarea") textarea;

  supportInfo;

  contentSize;
  
  fileData;
  fileTypeName;
  
  productDialog_ID;
  constructor(private renderer: Renderer,
              private dialogRef: MdDialogRef<ProductDetailDialog>,
              private appService:AppService,
              private productService: ProductService,
              private dialogService: DialogService){}

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit(){
    this.recalcSize();
    this.fileData = {};
    this.fileTypeName = '';
    var ID = parseInt(this.productDialog_ID);
    this.productService.findOnePopupDialog(
        {
          query: {
            _id: ID 
          }
        }
      )
        .finally(() => {
          this.dialogService.loadingSubject.next('hide');
        })
        .subscribe(
          (productdialogData) => {
            if (productdialogData['file']) {
              this.fileData = productdialogData['file'];
              this.checkImageAndVideo(productdialogData['file'].fileType);
              //
            } else {
              this.dialogService.message("알림", "찾을 수 없는 상품입니다.");
              this.appService.goToMenu('/store');
            }
          },
          (err) => {
            let subTitle = '';
            switch (err.status) {
              case 500:
                subTitle = '서버에러';  
                break;
              default:
                subTitle = '잘못된 요청입니다.';
                break;
            }
            this.dialogService.message("알림", subTitle);
            this.appService.goToMenu('/store');
          }
        )
  }
  /*****************************
   *        util functions
   *****************************/

  checkImageAndVideo(fileType){
      this.fileTypeName = '';
      for(var i = 0 ;i < fileType.length;i++){
          if(fileType[i] == "/"){
              break;
          } else {
            this.fileTypeName += fileType[i];
          }
      }
  }
  @HostListener('window:resize')
  @throttleable(5)
  recalcSize() {
    this.contentSize = this.dialogService.calcPopupDialogSize();
  }

  dismiss(){
    this.dialogRef.close();
  }
  /*****************************
   *       helper functions
   *****************************/
  closePopup() {
    this.dialogService.loadingSubject.next('spinner');
  }

}