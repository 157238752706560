/**
 * Created by PHILIP on 11/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 11/07/2017
 *
 */

// Export Lib
declare var _;

// Browser Lib
import {Observable} from "rxjs";
import "rxjs/add/operator/toPromise";

// Angular
import {Injectable} from "@angular/core";
import {URLSearchParams} from "@angular/http";

// Angular Third Party Lib
import {InterceptableHttp} from "../../lib/ng-http-interceptor";

// Project Sources
import {config} from "../../app/app.config";

@Injectable()
export class TransactionService {
  private serverUrl = config.serverUrl + '/transaction';

  // products = [];


  get products(): any {
    let products = localStorage.getItem('nosework_products');


    if(!products) return [];

    try {
      products = JSON.parse(products);
      return products;
    } catch (e) {
      return null;
    }
  };

  set products(_products) {
    if (_products)
      localStorage.setItem('nosework_products', JSON.stringify(_products));
    else
      localStorage.removeItem('nosework_products');
  };

  IMP;
  NAVER;

  constructor(private  http: InterceptableHttp) {
  }

  pendingPayment(data): Observable<any> {
    let url = this.serverUrl + '/pendingPayment';

    return this.http
      .post(url, data);
  }

  failPayment(paymentInfo): Observable<any> {
    let url = this.serverUrl + '/failPayment';

    return this.http
      .put(url, {paymentInfo: paymentInfo});
  }

  confirmPayment(merchant_uid, amount): Observable<any> {
    let url = this.serverUrl + '/confirmPayment';

    let params: URLSearchParams = new URLSearchParams();
    params.set('merchant_uid', merchant_uid);
    params.set('amount', amount);

    return this.http
      .get(url, {search: params});
  }

  myInvoices(queryParams: any): Observable<any> {
    let url = this.serverUrl + '/myInvoices';

    let params: URLSearchParams = new URLSearchParams();
    _.forEach(queryParams, (value, key) => {
      params.set(key, JSON.stringify(value));
    });
    
    return this.http
      .get(url, {search: params});
  }

  myInvoice(queryParams): Observable<any> {
    let url = this.serverUrl + '/myInvoice';

    let params: URLSearchParams = new URLSearchParams();
    _.forEach(queryParams, (value, key) => {
      params.set(key, JSON.stringify(value));
    });

    return this.http
      .get(url, {search: params});
  }

  cancelPaymentForUser(paymentId): Observable<any> {
    let url = this.serverUrl + '/cancelPaymentForUser';
    return this.http
      .put(url, {paymentId: paymentId});
  }

  requestNaverPay(data): Observable<any> {
    let url = this.serverUrl + '/requestNaverPay';

    console.log(url);
    console.log(data);

    return this.http
      .post(url, data);
  }

  requestNaverPayWish(data): Observable<any> {
    let url = this.serverUrl + '/requestNaverPayWish';

    console.log(url);
    console.log(data);

    return this.http
      .post(url, data);
  }
  

}