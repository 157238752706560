/**
 * Created by PHILIP on 11/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 11/07/2017
 *
 */

// Export Lib
declare var _;
declare var window;

// Angular
import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {MdDialog, MdDialogConfig, MdDialogRef} from "@angular/material";

// Angular Third Party Lib
import {HttpInterceptorService, getHttpOptionsAndIdx, getHttpHeadersOrInit} from "../lib/ng-http-interceptor";
import {TranslateService} from "ng2-translate";

// Project Sources
import {getTemplate} from "../shared/templates";
import {AppService} from "../shared/services/app.service";
import {AuthService} from "../shared/services/auth.service";
import {TransactionService} from "../shared/services/transaction.service";
import {DialogService} from "../shared/components/dialog-message/dialog-message.service";
import {Login} from "../pages/login/login";

/*
 * App Component
 * Top Level Component
 */
@Component({
    selector: 'app-root',
    template: getTemplate('app/app.component')
})
export class AppComponent implements OnInit {

    appEventDisposor;
    showFlag;//mangus
    dialogLoginRef: MdDialogRef<Login>;
    config: MdDialogConfig = new MdDialogConfig();

    constructor(public router: Router,
                public appService: AppService,
                private httpInterceptor: HttpInterceptorService,
                private mdDialog: MdDialog,
                private translate: TranslateService,
                private authService: AuthService,
                private transactionService: TransactionService,
                private dialogService: DialogService) {
    }

    /*****************************
     *         life cycle
     *****************************/

    ngOnInit() {

        if (this.appEventDisposor) this.appEventDisposor.unsubscribe();
        this.appEventDisposor = this.appService.appEvent.subscribe(this.appEventHandler.bind(this));

        if(!this.appService.cartList) this.appService.cartList = [];
        this.init();
    }

    ngOnDestroy() {
        if (this.appEventDisposor)
            this.appEventDisposor.unsubscribe();
    }

    /*****************************
     *        util functions
     *****************************/

    appEventHandler(event) {
        switch (event.name) {
            case 'logout':
                this.appService.user = null;
                this.appService.token = null;
                let loginDialog = _.find(this.mdDialog._openDialogs, (dialog) => {
                    return (dialog.componentInstance instanceof Login);
                });
                this.appService.goToMenu('/store/store-home/');
                if (!loginDialog)
                    this.openLoginForm();
                break;
            default:
                break;
        }
    }

    init(){
        this.appService.showFlag = false;
        this.translateConfig();
        this.setCurrentMenuPosition();
        this.initialiseInterceptor();
        this.transactionService.IMP = window.IMP;
        // this.transactionService.NAVER = window.NAVER;
        this.transactionService.IMP.init('imp46208861');
        // this.transactionService.NAVER.init('FE7A3593-C4FD-401C-A1CC-4310B876A33E');
        // this.transactionService.NAVER.init('FE7A3593-C4FD-401C-A1CC-4310B876A33E'); // for debug (2018.04.07)
    }

    translateConfig(local?){
        this.appService.currentLocal = local || _.find(this.appService.locals, {local: 'en'});

        this.translate.setDefaultLang(this.appService.currentLocal['local']);
        this.translate.use(this.appService.currentLocal['local']);
    }

    setCurrentMenuPosition(){
        this.router.events.subscribe((data) =>{
            let url = data['url'];
            if(url.indexOf('/brand') == 0)
                this.appService.currentMenuPosition = 'BRAND';
            else if(url.indexOf('/product') == 0)
                this.appService.currentMenuPosition = 'PRODUCT';
            else if(url.indexOf('/service') == 0)
                this.appService.currentMenuPosition = 'SERVICE';
            else if(url.indexOf('/store') == 0)
                this.appService.currentMenuPosition = 'STORE';
            else
                this.appService.currentMenuPosition = 'HOME';
        });
    }

    openLoginForm() {
        this.config.disableClose = true;
        this.config.width = '100%';
        this.config.height = '100%';

        this.dialogLoginRef = this.mdDialog.open(Login, this.config);

        this.dialogLoginRef.afterClosed()
            .subscribe((result) => {});
    }
    isClassVisible: boolean = false;
    logout(){
        this.authService.logout()
            .subscribe(
                () => {
                    this.appService.sendEvent('logout');
                },
                (err) => {
                    this.dialogService.message("알림", '잘못된 요청입니다.');
                }
            )
    }

    /*****************************
     *       helper functions
     *****************************/

    initialiseInterceptor() {
        this.httpInterceptor.request().addInterceptor((req, method) => {

            let requestOption = getHttpOptionsAndIdx(req, method);
            requestOption.options.withCredentials = true;
            req[requestOption.idx] = requestOption.options;

            // Setting header?
            let requestHeaders = getHttpHeadersOrInit(req, method);
            if (this.appService.user && this.appService.token){
                requestHeaders.set('Authorization', 'Bearer ' + this.appService.token);
            }

            return req;
        });

        this.httpInterceptor.response().addInterceptor((res, method) => {
            return res
                .map((res) => {
                    if (res._body)
                        return res.json();
                    return res;
                })
                .catch((err) => {
                    switch(err.status){
                        case 401 :
                        case 403 :
                            this.appService.sendEvent('logout');
                            throw err;
                        default :
                            throw err;
                    }
                });
        });
    }

}