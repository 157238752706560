import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({name: 'youtube_video_id'})
export class YoutubeVideoIdPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: string): any {
    if (!value) return value;
    
    let video_id = value.substring(value.indexOf('/watch?v=') + 9);
    let pos = video_id.indexOf('&');
    if (pos >= 0) {
      video_id = video_id.substring(0, pos);
    }
    
    return video_id;
  }
}