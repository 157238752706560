/**
 * Created by Yoon Yong (Andy) Shin on 18/04/2017
 * As part of Nosework
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & Yoon Yong (Andy) Shin - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Yoon Yong (Andy) Shin <andy.shin@applicat.co.kr>, 18/04/2017
 *
 */

// Angular
import {AfterViewInit, Component, EventEmitter, forwardRef, HostListener, Input, OnInit, Output, ViewChild} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

const noop = () => {};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NumberInput),
  multi: true
};

@Component({
  selector: 'number-input',
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  template: `
    <input #number1 type="number"
           (keydown)="onKeyPress($event)"
           (keyup)="setToMaxChar($event)"
           (change)="setToMaxChar($event)"
           (blur)="setToMaxChar($event)"
           (focus)="checkAllowed($event)"/>
  `
})
export class NumberInput implements OnInit, AfterViewInit, ControlValueAccessor {

  private specialKeys: Array<string> = ['Tab', 'End', 'Home'];

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  @Input() maxLength: number;

  @Output() blur: EventEmitter<any> = new EventEmitter();

  @ViewChild("number1") numberInput;

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {}


  ngAfterViewInit() {}


  checkAllowed(event) {}

  /*****************************
   *        util functions
   *****************************/

  isValidInput() {
    if (!this.numberInput.nativeElement.value)return false;

    return true;
  }

  setToMaxChar(event) {
    if (event.target.value.length > this.maxLength)
      event.target.value = event.target.value.substring(0, this.maxLength);

    if (this.isValidInput())
      this.onChangeCallback(this.numberInput.nativeElement.value);
    else
      this.onChangeCallback("");
  }


  onKeyPress(event) {
    if ([46, 16, 9, 27, 13, 110, 91, 190].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode == 65 && event.ctrlKey === true) ||
      // Allow: Ctrl+C
      (event.keyCode == 67 && event.ctrlKey === true) ||
      // Allow: Ctrl+V
      (event.keyCode == 86 && event.ctrlKey === true) ||
      // Allow: Ctrl+X
      (event.keyCode == 88 && event.ctrlKey === true) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39) ||

      // specialKeys
      this.specialKeys.indexOf(event.key) !== -1) {
      // let it happen, don't do anything
      return;
    }
  }

  @HostListener('click', ['$event'])
  onClick(event) {
    // if (!this.phoneNumber2.nativeElement.value || this.phoneNumber2.nativeElement.value.length < 4)
    //   this.phoneNumber2.nativeElement.focus();
    // else
    //   this.phoneNumber3.nativeElement.focus();
  }

  //From ControlValueAccessor interface
  writeValue(value: any) {
    if (!value)return;

    value = value.toString();

    if (value) {
      this.numberInput.nativeElement.value = value.substring(0, this.maxLength);
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

}