/**
 * Created by PHILIP on 27/06/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 27/06/2017
 *
 */

declare var wcs;
declare var wcs_do;

// Angular
import {Component, OnInit} from "@angular/core";
// Project Sources
import {getTemplate} from "../../../../shared/templates";

@Component({
  selector: 'conneck-function-design',
  template: getTemplate('pages/product/product-conneck/conneck-function-design/conneck-function-design')
})
export class ConneckFunctionDesign implements OnInit {

  constructor() {
  }

  ngOnInit() {
    if (!wcs_add) var wcs_add = {};
    wcs_add["wa"] = "s_52e6d95fb9a0";
    if (!_nasa) var _nasa = {};
    wcs.inflow();
    wcs_do(_nasa);
  }
}