/**
 * Created by PHILIP on 11/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 11/07/2017
 *
 */

// Export Lib
declare var _;

// Browser Lib
import {Observable} from "rxjs";
import "rxjs/add/operator/toPromise";
import 'rxjs/Rx';

// Angular
import {Injectable} from "@angular/core";
import {RequestOptions, URLSearchParams, Headers} from "@angular/http";

// Angular Third Party Lib
import {InterceptableHttp} from "../../lib/ng-http-interceptor";

// Project Sources
import {config} from "../../app/app.config";

@Injectable()
export class AuthService {
  private serverUrl = config.serverUrl;

  constructor(private  http: InterceptableHttp) {}

  checkIdentifier(identifier: string): Observable<any> {
    let url = this.serverUrl + '/checkIdentifier';
    let params: URLSearchParams = new URLSearchParams();
    params.set("identifier", identifier);

    return this.http
      .get(url, _.assign({search: params}));
  }

  checkNickname(nickname: string): Observable<any>{
    let url = this.serverUrl + '/checkNickname';
    let params: URLSearchParams = new URLSearchParams();
    params.set("nickname", nickname);

    return this.http
      .get(url, _.assign({search: params}));
  }

  register(data): Observable<any> {
    let url = this.serverUrl + '/register';

    return this.http
      .post(url, data);
  }

  forgotPasswordStart(identifier: string): Observable<any> {
    let url = this.serverUrl + '/forgotPasswordStart';

    return this.http
      .post(url, {identifier: identifier});
  }

  forgotPasswordCheck(identifier: string, code: string): Observable<any> {
    let url = this.serverUrl + '/forgotPasswordCheck';

    return this.http
      .post(url, {identifier: identifier, code: code});
  }

  forgotPasswordComplete(newPassword: string): Observable<any> {
    let url = this.serverUrl + '/forgotPasswordComplete';

    return this.http
      .put(url, {newPassword: newPassword});
  }

  login(identifier: string, password: string): Observable<any> {
    let url = this.serverUrl + '/login';

    return this.http
      .post(url, {identifier: identifier, password: password});
  }

  logout(): Observable<any> {
    let url = this.serverUrl + '/logout';

    return this.http.get(url);
  }

  support(enquiry, file?): Observable<any> {
    const formData = new FormData();
    _.forEach(enquiry, (val, Key) => {
      formData.append(Key, val);
    });

    if (file)
      formData.append('file', file);

    const headers = new Headers({});
    let options = new RequestOptions({headers});
    let url = this.serverUrl + '/support';

    return this.http.post(url, formData, options);
  }

  getMyUserInfo(queryParams: any): Observable<any> {
    let url = this.serverUrl + '/me';

    let params: URLSearchParams = new URLSearchParams();

    _.forEach(queryParams, (value, key) => {
      params.set(key, JSON.stringify(value));
    });

    return this.http
      .get(url, {search: params});
  }

  updateMyInfo(user): Observable<any> {
    let url = this.serverUrl + '/me';

    return this.http
      .put(url, user);
  }

  changePassword(oldPassword: string, newPassword: string): Observable<any> {
    let url = this.serverUrl + '/changePassword';

    return this.http
      .put(url, {oldPassword: oldPassword, newPassword: newPassword});
  }

}