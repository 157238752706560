var templates = { 
'app/app.component' : `<!--
/**
 * Created by PHILIP on 11/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 11/07/2017
 *
 */
-->

<span defaultOverlayTarget></span>

<div id="app"
     #app>

  <!-- app-header-container -->
  <div class="app-header-container">

    <!-- app-header-panel -->
    <div class="app-header-panel">

      <!-- header-menu-panel -->
      <div class="header-menu-panel">
        
        <button class="ex-hamburger btn-toggleNav mat-button" (click)="isClassVisible = !isClassVisible;">
          <span class="mat-button-wrapper">
            <img src="assets/img/collapse_menu.png">
          </span>
        </button>

        <!-- header-logo -->
        <img class="header-logo "
             src="assets/img/header_symbol.png"
             (click)="appService.goToMenu('/home')"/>
        <!-- // header-logo -->

        <!-- menu-panel -->
        <div class="menu-panel">
          <div class="menu-item-panel">
            <div class="menu-item"
                 (click)="appService.goToMenu('/brand')"
                 [ngClass]="{active: appService.currentMenuPosition == 'BRAND'}">
              <p> {{ 'menu.brand.name' | translate }} </p>
            </div>
            <div class="menu-item product"
                 [ngClass]="{active: appService.currentMenuPosition == 'PRODUCT'}">
              <p (click)="appService.goToMenu('/product-smartNoseWork/smartNoseWork-overview')">
                {{ 'menu.product.name' | translate }}
              </p>
              <div class="product-panel">
                <div class="product-item smart"
                     [ngClass]="{active: appService.isActiveUrl('/product-smartNoseWork/smartNoseWork-overview')}"
                     (click)="appService.goToMenu('/product-smartNoseWork/smartNoseWork-overview')">
                  <span>{{ 'menu.product.child.smartNosework.name' | translate }}</span>
                </div>
                <div class="product-item noseworkit"
                     [ngClass]="{active: appService.isActiveUrl('/product-noseWorKit/noseWork-overview')}"
                     (click)="appService.goToMenu('/product-noseWorKit/noseWorKit-overview')">
                  <span>{{ 'menu.product.child.noseworKit.name' | translate }}</span>
                </div>
                <div class="product-item noseball"
                     [ngClass]="{active: appService.isActiveUrl('/product-noseBall/noseBall-overview')}"
                     (click)="appService.goToMenu('/product-noseBall/noseBall-overview')">
                  <span>{{ 'menu.product.child.noseBall.name' | translate }}</span>
                </div>
                <div class="product-item conneck"
                     [ngClass]="{active: appService.isActiveUrl('/product-conneck/conneck-overview')}"
                     (click)="appService.goToMenu('/product-conneck/conneck-overview')">
                  <span>{{ 'menu.product.child.conneck.name' | translate }}</span>
                </div>
              </div>
            </div>
            <div class="menu-item"
                 [ngClass]="{active: appService.currentMenuPosition == 'SERVICE'}"
                 (click)="appService.goToMenu('/service')">
              <p>{{ 'menu.service.name' | translate }}</p>
            </div>
            <div class="menu-item"
                 (click)="appService.openWeb('https://nosework-shop.com/board/free/list.html?board_no=2')">
              <p>{{ 'menu.blog.name' | translate }}</p>
            </div>
            <!-- <div class="menu-item"
                 [ngClass]="{active: appService.currentMenuPosition == 'SUPPORTERS'}"
                 (click)="appService.goToMenu('/supporters')">
              <p>{{ 'menu.supporters.name' | translate }}</p>
            </div> -->
             <div class="menu-item store"
             (click)="appService.openWeb('https://nosework-shop.com/')">
              <p>{{ 'menu.store.name' | translate }}</p>
            </div>
            <!-- <div class="menu-item store"
              [ngClass]="{active: appService.currentMenuPosition == 'STORE'}">
              <p (click)="appService.goToMenu('/store/store-home/', 'all')">
                {{ 'menu.store.name' | translate }}
              </p>
              <div class="store-panel">
                <div class="store-item dog"
                     [ngClass]="{active: appService.isActiveUrl('/store/store-home/dog')}"
                     (click)="appService.goToMenu('/store/store-home/', 'dog')">
                  <span>{{ 'menu.store.child.dog.name' | translate }}</span>
                </div>
                <div class="store-item cat"
                     [ngClass]="{active: appService.isActiveUrl('/store/store-home/cat')}"
                     (click)="appService.goToMenu('/store/store-home/', 'cat')">
                  <span>{{ 'menu.store.child.cat.name' | translate }}</span>
                </div>                
              </div>
            </div> -->
          </div>
        </div>
        <!-- // menu-panel -->

      </div>
      <!-- // header-menu-panel -->

      <!-- header-setting-panel -->
      <div class="header-setting-panel">

        <!-- account-panel -->
        <div class="account-panel"
             *ngIf="appService.currentMenuPosition == 'STORE'">

          <!-- login-panel -->
          <div class="login-panel"
               *ngIf="!appService.user || !appService.token">

            <!-- btn-login -->
            <div class="btn-login"
                 (click)="openLoginForm()">
              <span>LOGIN</span>
            </div>
            <!-- // btn-login -->

            <!-- cart-panel -->
            <div class="cart-panel"
                 (click)="appService.goToMenu('/store/cart-list')">
              <i class="material-icons">shopping_cart</i>
              <span>{{ appService.cartList.length }}</span>
            </div>
            <!-- // cart-panel -->

          </div>
          <!-- //login-panel -->

          <!-- user-setting-panel -->
          <div class="user-setting-panel"
               *ngIf="appService.user && appService.token">

            <!-- select-setting-panel -->
            <div class="select-setting-panel">

              <!-- nickname-panel -->
              <div class="nickname-panel"
                   md-button
                   [mdMenuTriggerFor]="settingList">
                <div>
                  <p>안녕하세요</p>
                  <p class="nickname">
                    <span>{{ appService.user.nickname }}</span>님
                  </p>
                </div>
                <i class="material-icons">keyboard_arrow_down</i>
              </div>
              <!-- // nick-name-panel -->

              <!-- setting-list-panel -->
              <md-menu class="setting-list-panel"
                       #settingList
                       y-position="below">
                <div>
                  <p>
                    <span>{{ appService.user.nickname }}</span>님
                  </p>
                  <div>
                    <p (click)="appService.goToMenu('/store/setting-account')">계정 설정</p>
                    <p (click)="appService.goToMenu('/store/invoice-list')">주문 내역</p>
                    <p (click)="appService.goToMenu('/store/manage-product')">제품 관리</p>
                    <p (click)="logout()">로그아웃</p>
                  </div>
                </div>
              </md-menu>
              <!-- // setting-list-panel -->

            </div>
            <!-- // select-setting-panel -->

            <!-- cart-panel -->
            <div class="cart-panel"
                 (click)="appService.goToMenu('/store/cart-list')">
              <i class="material-icons">shopping_cart</i>
              <span>{{ appService.cartList.length }}</span>
            </div>
            <!-- // cart-panel -->

          </div>
          <!-- // user-setting-panel -->

        </div>
        <!-- // account-panel -->

        <!-- select-language-panel -->
        <div class="select-language-panel"
             *ngIf="appService.currentMenuPosition != 'STORE'">

          <!-- selected-language -->
          <div class="selected-language"
               md-button
               [mdMenuTriggerFor]="languageList">
            <span>{{ appService.currentLocal.name }}</span>
            <img src="assets/img/header_dropdown_arrow.png">
          </div>
          <!-- //selected-language -->

          <!-- language-list-panel -->
          <md-menu class="language-list-panel"
                   #languageList
                   y-position="below">
            <p *ngFor="let local of appService.locals"
               (click)="translateConfig(local)">{{local.name }}</p>
            {{ local }}
          </md-menu>
          <!-- // language-list-panel -->

        </div>
        <!-- // select-language-panel -->

      </div>
      <!-- // header-setting-panel -->
      <ul class="ex-on-small-menu" [class.ex-open-menu]="isClassVisible" (click)="isClassVisible = !isClassVisible;">
        <li>
          <div class="menu-item"
               (click)="appService.goToMenu('/brand')"
               [ngClass]="{active: appService.currentMenuPosition == 'BRAND'}">
            {{ 'menu.brand.name' | translate }}
          </div>
        </li>
        <li>
          <div class="menu-item product"
               [ngClass]="{active: appService.currentMenuPosition == 'PRODUCT'}">
            <p (click)="appService.goToMenu('/product-smartNoseWork/smartNoseWork-overview')">
              {{ 'menu.product.name' | translate }}
            </p>
            <div class="product-panel" (click)="isClassVisible = !isClassVisible;">
              <div class="product-item smart "
                   [ngClass]="{active: appService.isActiveUrl('/product-smartNoseWork/smartNoseWork-overview')}"
                   (click)="appService.goToMenu('/product-smartNoseWork/smartNoseWork-overview')">
                <span (click)="isClassVisible = !isClassVisible;">{{ 'menu.product.child.smartNosework.name' | translate }}</span>
              </div>
              <div class="product-item noseworkit"
                   [ngClass]="{active: appService.isActiveUrl('/product-noseWorKit/noseWork-overview')}"
                   (click)="appService.goToMenu('/product-noseWorKit/noseWorKit-overview')">
                <span (click)="isClassVisible = !isClassVisible;">{{ 'menu.product.child.noseworKit.name' | translate }}</span>
              </div>
              <div class="product-item noseball"
                   [ngClass]="{active: appService.isActiveUrl('/product-noseBall/noseBall-overview')}"
                   (click)="appService.goToMenu('/product-noseBall/noseBall-overview')">
                <span (click)="isClassVisible = !isClassVisible;">{{ 'menu.product.child.noseBall.name' | translate }}</span>
              </div>
              <div class="product-item conneck"
                   [ngClass]="{active: appService.isActiveUrl('/product-conneck/conneck-overview')}"
                   (click)="appService.goToMenu('/product-conneck/conneck-overview')">
                <span (click)="isClassVisible = !isClassVisible;">{{ 'menu.product.child.conneck.name' | translate }}</span>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="menu-item"
               [ngClass]="{active: appService.currentMenuPosition == 'SERVICE'}"
               (click)="appService.goToMenu('/service')">
            {{ 'menu.service.name' | translate }}
          </div>
        </li>
        <li>
          <div class="menu-item"
          (click)="appService.openWeb('https://nosework-shop.com/board/free/list.html?board_no=2')">
            {{ 'menu.blog.name' | translate }}
          </div>
        </li>
        <!--
          <li>
          <div class="menu-item store"
            [ngClass]="{active: appService.currentMenuPosition == 'STORE'}">
            <p (click)="appService.goToMenu('/store/store-home/all')">
              {{ 'menu.store.name' | translate }}
            </p>
            <div class="store-panel" (click)="isClassVisible = !isClassVisible;">
              <div class="store-item dog "
                  [ngClass]="{active: appService.isActiveUrl('/store/store-home/dog')}"
                  (click)="appService.goToMenu('/store/store-home/', 'dog')">
                <span (click)="isClassVisible = !isClassVisible;">{{ 'menu.store.child.dog.name' | translate }}</span>
              </div>
              <div class="store-item cat"
                  [ngClass]="{active: appService.isActiveUrl('/store/store-home/cat')}"
                  (click)="appService.goToMenu('/store/store-home/', 'cat')">
                <span (click)="isClassVisible = !isClassVisible;">{{ 'menu.store.child.cat.name' | translate }}</span>
              </div>
            </div>
          </div>
        </li>
      -->
      <!-- supporters item -->
        <!-- <li>
          <div class="menu-item"
               (click)="appService.goToMenu('/supporters')"
               [ngClass]="{active: appService.currentMenuPosition == 'SUPPORTERS'}">
            {{ 'menu.supporters.name' | translate }}
          </div>
        </li> -->
        <li>
          <div class="menu-item"
               (click)="appService.openWeb('https://nosework-shop.com')">
            {{ 'menu.store.name' | translate }}
          </div>
        </li>
      </ul>
    </div>
    <!-- // app-header-panel -->

  </div>
  <!-- // app-header -->

  <!-- app-content -->
  <div class="app-container">
    <router-outlet></router-outlet>
  </div>
  <!-- // app-content -->

  <!-- app-footer -->
  <div class="app-footer-container">
    <div class="app-footer-panel">
      <p class="footer-copyright">Copyright NOSEWORK Inc. All rights reserved</p>
    </div>
  </div>
  <!-- app-footer -->

</div>

<!--loading-->
<loading></loading>`,
'public/index' : `<!--
/**
 * Created by PHILIP on 27/06/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 27/06/2017
 *
 */
-->

<!DOCTYPE html>
<html lang="en">
<head>
  <title>노즈워크</title>

  <meta charset="utf-8">
  <meta http-equiv="pragma" content="no-cache"/>
  <meta http-equiv="cache-control" content="max-age=0"/>
  <meta http-equiv="cache-control" content="no-cache"/>
  <meta http-equiv="expires" content="-1"/>
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1,user-scalable=0">
  <meta name="description" content="NOSEWORK">
  <meta name="naver-site-verification" content="5430a55fdb86d45511984426d118528653d31173"/>
  <meta name="google-site-verification" content="R2U9xQq5vg2B3Wash-XWzHrGnTi02DSgnjsQ9KSf8kI" />
  <meta name="description" content="NOSEWORK는 모든 반려동물이 행복한 세상을 희망합니다. 유럽수의사, 훈련사와 함께 만드는 Premium 용품을 지금 바로 만나보세요.">
  <meta property="og:type" content="website">
  <meta property="og:title" content="NOSEWORK">
  <meta property="og:description" content="NOSEWORK는 모든 반려동물이 행복한 세상을 희망합니다. 유럽수의사, 훈련사와 함께 만드는 Premium 용품을 지금 바로 만나보세요.">
  <meta property="og:url" content="http://localhost:3333">

  <!-- base url -->
  <base href="/">
  <link rel="icon" type="image/x-icon" href="assets/img/favicon.ico">
  <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
  <link rel="stylesheet" href="build/main.css">

  <script type="text/javascript">
    function getIEVersion(){
      var agent = navigator.userAgent;
      var reg = /MSIE\s?(\d+)(?:\.(\d+))?/i;
      var matches = agent.match(reg);
      if (matches != null) {
        return { major: matches[1], minor: matches[2] };
      }
      return { major: "-1", minor: "-1" };
    }

    var ie_version =  getIEVersion();
    if(ie_version.major == 11){
      setTimeout(function(){
        document.getElementById('app-root').classList.add('ie11');
        document.getElementById('ie11-support').classList.add('ie11-show');
      }, 0);
    };
  </script>

  <script type="text/javascript" src="https://wcs.naver.net/wcslog.js"></script>
  <script async src="https://www.googletagmanager.com/gtag/js?id=UA-102874015-3"></script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'UA-102874015-3');
  </script>

   <!-- 네이버 공동유입스크립트 -->
  <script type="text/javascript" src="http://wcs.naver.net/wcslog.js"></script>
  <script type="text/javascript">
      if (!wcs_add) var wcs_add = {};
      wcs_add["wa"] = "s_52e6d95fb9a0";
      wcs.inflow("nosework-official.com");
      wcs_do();
  </script>

</head>
<body>

<!--[if lte IE 9]>
<div>Internet Explorer 10이하 버전은 지원하지 않습니다.</div>
<![endif]-->

<div id="ie11-support" style="display:none">Internet Explorer 11이하 버전은 지원하지 않습니다.</div>

<!--[if (!IE)|(gt IE 9)]><!-->
<app-root>
  <div style="position: absolute; width:100%; height: 100%;">
    <img style="position: absolute; top:50%; left:50%; margin-left:-90px; margin-top:-125px; width: 180px;"
         src="assets/img/nosework_symbol.png">
  </div>
</app-root>
<!--<![endif]-->

<!-- 주소 찾기 api -->
<script type="text/javascript"
        src="https://apis.daum.net/maps/maps3.js?apikey=1d77329135df78c95c219758f5fdddfb&libraries=services"></script>
<!-- iamport 결제 api-->
<script type="text/javascript"
        src="https://service.iamport.kr/js/iamport.payment-1.1.5.js"></script>
<!-- 네이버페이 버튼 생성-->
<!--<script type="text/javascript" src="https://test-pay.naver.com/customer/js/naverPayButton.js" charset="UTF-8"></script>
<script type="text/javascript" src="https://test-pay.naver.com/customer/js/mobile/naverPayButton.js" charset="UTF-8"></script>-->
<script type="text/javascript" src="https://test-pay.naver.com/customer/js/naverPayButton.js" charset="UTF-8"></script>
<script type="text/javascript" src="https://test-pay.naver.com/customer/js/mobile/naverPayButton.js" charset="UTF-8"></script>

<script src="build/global.js"></script>
<script src="build/vendor.js"></script>
<script src="build/main.js"></script>

</body>
</html>
`,
'pages/brand/brand' : `<!--
/**
 * Created by PHILIP on 27/06/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 27/06/2017
 *
 */
-->

<div class="brand-container">

  <!-- main-panel -->
  <div class="main-panel">

    <!-- desc-panel -->
    <div class="desc-panel"
         [innerHtml]="'brand.description' | translate"></div>
    <!-- // desc-panel -->

  </div>
  <!-- // main-panel -->

  <!-- philosophy-panel -->
  <div class="philosophy-panel">

    <!-- desc-panel -->
    <div class="desc-panel">
      <h2>{{ 'brand.philosophy.name' | translate }}</h2>
      <p>{{ 'brand.philosophy.description' | translate}}</p>
    </div>
    <!-- // desc-panel -->

  </div>
  <!-- // philosophy-panel -->

  <!-- design-panel -->
  <div class="design-panel">

    <!-- desc-panel -->
    <div class="desc-panel">
      <h2>{{ 'brand.design.name' | translate }}</h2>
      <div [innerHtml]="'brand.design.description' | translate"></div>
    </div>
    <!-- // desc-panel -->

    <img src="assets/img/brand_img_design.png">
  </div>
  <!-- // design-panel -->

  <!-- technology-panel -->
  <div class="technology-panel">

    <!-- desc-panel -->
    <div class="desc-panel">
      <h2>{{ 'brand.technology.name' | translate }}</h2>
      <div [innerHtml]="'brand.technology.description' | translate"></div>
    </div>
    <!-- // desc-panel -->

  </div>
  <!-- // technology-panel -->

  <!-- identity-panel -->
  <div class="identity-panel">

    <!-- desc-panel -->
    <div class="desc-panel">
      <h2>{{ 'brand.brandIdentity.name' | translate }}</h2>
      <div [innerHtml]="'brand.brandIdentity.description' | translate"></div>
    </div>
    <!-- // desc-panel -->

    <!-- wideLogo-panel -->
    <div class="wideLogo-panel">
      <p>WIDE TYPE</p>
      <img src="assets/img/brand_img_wideLogo.png">
    </div>
    <!-- // wideLogo-panel -->

    <!-- logo-panel -->
    <div class="logo-panel">
      <p>LOGO TYPE</p>
      <img src="assets/img/brand_img_logotype2.png">
    </div>
    <!-- // logo-panel -->

  </div>
  <!-- // identity-panel -->

  <!-- map-panel -->
  <div class="map-panel">

    <!-- map -->
    <!-- <div class="map">
      <sebm-google-map [latitude]="'brand.addressInfo.headOffice.geoJSON.latitude' | translate"
                       [longitude]="-121.961475"
                       [zoom]="15">
        <sebm-google-map-marker [latitude]="'brand.addressInfo.headOffice.geoJSON.latitude' | translate"
                                [longitude]="-121.961475"></sebm-google-map-marker>
      </sebm-google-map>
    </div> -->
    <div class="map">
      <sebm-google-map [latitude]="'brand.addressInfo.mapOffice.geoJSON.latitude' | translate"
                       [longitude]="'brand.addressInfo.mapOffice.geoJSON.longitude' | translate"
                       [zoom]="15">
      <sebm-google-map-marker [latitude]="'brand.addressInfo.mapOffice.geoJSON.latitude' | translate"
                                [longitude]="'brand.addressInfo.mapOffice.geoJSON.longitude' | translate"></sebm-google-map-marker>
      </sebm-google-map>
    </div>
    <!-- // map -->

    <!-- contact-panel -->
    <div class="contact-panel">

      <h2>NOSEWORK</h2>

      <!-- detail-panel -->
      <div class="detail-panel">

        <!-- address-panel -->
        <div class="address-panel">
          <div>
            <h2>Address({{ 'brand.addressInfo.headOffice.name' | translate }})</h2>
            <p>{{ 'brand.addressInfo.headOffice.address' | translate }}</p>
          </div>
          <div>
            <h2>Address({{ 'brand.addressInfo.branchOffice.name' | translate }})</h2>
            <p>{{ 'brand.addressInfo.branchOffice.address' | translate }}</p>
          </div>
        </div>
        <!-- // address-panel -->

        <!-- call-panel -->
        <div class="call-panel">
          <h2>Call</h2>
          <p>{{ 'brand.phone' | translate }}</p>
        </div>
        <!-- // call-panel -->

      </div>
      <!-- // detail-panel -->

    </div>
    <!-- // contact-panel -->

  </div>
  <!-- // map-panel -->

</div>`,
'pages/home/home' : `<!--
/**
 * Created by PHILIP on 27/06/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 27/06/2017
 *
 */
-->

<div class="home-container">

  <!-- banner-panel -->
  <div class="banner-panel">
    <swiper-container #bannerSlider
                      [options]="swiperOptions">
      <swiper-slide *ngFor="let banner of model.banners">
        <preload-image [imageSource]="banner.photo.secure_url" class="home-preload-image"
                       [ratio]="0.8" *ngIf="(banner.fileTypes == 'image')" (click)="appService.openWeb(banner.url)"></preload-image>
        <div class="video-panel" *ngIf="banner.fileTypes == 'video'">
          <youtube-player
            [videoId]="banner.url | youtube_video_id" [width]="700" [height]="300"
            (ready)="savePlayer($event)"
            (change)="onStateChange($event)"></youtube-player>
        </div>
      </swiper-slide>
    </swiper-container>
  </div>
  <!-- // banner-panel -->

  <!-- content-panel -->
  <div class="content-panel">

    <!-- product-panel -->
    <div class="product-panel">

      <div>
        <h2>{{ 'home.product.smartNosework.name' | translate }}</h2>
        <p>{{ 'home.product.smartNosework.description' | translate }}</p>
        <span (click)="appService.goToMenu('/product-smartNoseWork/smartNoseWork-overview')">{{ 'buttons.viewProduct' | translate }} ></span>
      </div>

      <div>
        <h2>{{ 'home.product.noseBall.name' | translate }}</h2>
        <p>{{ 'home.product.noseBall.description' | translate }}</p>
        <span (click)="appService.goToMenu('/product-noseBall/noseBall-overview')">{{ 'buttons.viewProduct' | translate }} ></span>
      </div>

    </div>
    <!-- // product-panel -->

    <!-- story-panel -->
    <div class="story-panel">
      <img src="assets/img/main_img_brand.png">

      <!-- title-panel -->
      <div class="title-panel">
        <p>OurBrand</p>
        <p>NOSEWORK STORIES</p>
      </div>
      <!-- // title-panel -->

      <!-- desc -->
      <p class="desc">{{ 'home.brand.description' | translate }}</p>
      <!-- // desc -->

      <!-- btn-more -->
      <div class="btn-more"
           (click)="appService.goToMenu('/brand')">
        {{ 'buttons.readMore' | translate }}
      </div>
      <!-- // btn-more -->

    </div>
    <!-- //story-panel -->

    <!-- service-panel -->
    <div class="service-panel">

      <!-- customer-panel -->
      <div class="customer-panel">

        <!-- desc-panel -->
        <div class="desc-panel">
          <h2>Customer Center</h2>
          <p>{{ 'service.customerCenter.description' | translate }}</p>
        </div>
        <!-- // desc-panel -->

        <!-- phone -->
        <div class="phone">{{ 'service.customerCenter.phone' | translate }}</div>
        <!-- // phone -->

        <!-- hours-panel -->
        <div class="hours-panel">
          <div>
            <span class="name">{{ 'service.customerCenter.openingHours.weekdays.name' | translate }}</span>
            <span class="divider">┃</span>
            {{ 'service.customerCenter.openingHours.weekdays.time' | translate }}
          </div>
          <div>
            <span class="name">{{ 'service.customerCenter.openingHours.launch.name' | translate }}</span>
            <span class="divider">┃</span>
            {{ 'service.customerCenter.openingHours.launch.time' | translate }}
          </div>
        </div>
        <!-- // hours-panel -->

      </div>
      <!-- // customer-panel -->

      <!-- support-panel -->
      <div class="support-panel">

        <!-- desc-panel -->
        <div class="desc-panel">
          <h2>Support</h2>
          <p>{{ 'service.support.description' | translate }}</p>
        </div>
        <!-- // desc-panel -->

        <!-- btn-support -->
        <div class="btn-support"
             (click)="openSupportForm()">
          {{ 'buttons.getSupport' | translate }}
        </div>
        <!-- // btn-support -->

      </div>
      <!-- // support-panel -->

    </div>
    <!-- // service-panel -->

  </div>
  <!-- // content-panel -->

</div>`,
'pages/login/login' : `<!--
/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */
-->

<div class="login-container">
  <!-- btn-close -->
  <div class="btn-close"
       (click)="dismiss()">
    <i class="material-icons">close</i>
  </div>
  <!-- // btn-close -->

  <!-- form-panel -->
  <div class="form-panel">

    <!-- label-panel -->
    <div class="label-panel">
      <h2>로그인</h2>
    </div>
    <!-- // label-panel -->

    <!-- content-panel -->
    <div class="content-panel">
      <!-- login-panel -->
      <div class="login-panel">
        <form>
          <div class="input-panel">
              <!-- <input type="email"
              autofocus
              name="email"
              placeholder="이메일 주소"
              [(ngModel)]="loginInfo.identifier"
              maxlength="256"> -->
            <md-input-container>
              <input mdInput
                    autofocus
                    type="email"
                    name="email"
                    placeholder="이메일 주소"
                    maxlength="256"
                    [(ngModel)]="loginInfo.identifier"/>
            </md-input-container>
          </div>

          <div class="input-panel">
              <!-- <input type="password"
              name="password"
              placeholder="비밀번호"
              [(ngModel)]="loginInfo.password"
              minlength="8"> -->
            <md-input-container>
              <input mdInput

                    type="password"
                    name="password"
                    placeholder="비밀번호"
                    [(ngModel)]="loginInfo.password"
                    
                  />
            </md-input-container>
          </div>

          <div class="btn-panel">
            <button type="submit"
                    (click)="login()"
                    [disabled]="!isValidLogin()">
              <span>로그인</span>
            </button>
            <div class="btn-findPassword"
                 (click)="openFindPasswordForm()">
              <span>비밀번호 찾기</span>
            </div>
            <div class="btn-findPassword"
                 (click)="openNonMemberOrderHistoryForm()">
              <span>비회원 주문내역 확인</span>
            </div>            
          </div>

        </form>

      </div>
      <!-- // login-panel -->

      <!-- divider -->
      <div class="divider"></div>
      <!-- // divider -->

      <!-- register-panel -->
      <div class="register-panel">
        <p>아직 계정이 없으신가요?</p>
        <div class="btn-register"
             (click)="openRegisterForm()">
          <span>회원가입</span>
        </div>
      </div>
      <!-- // register-panel -->

    </div>
    <!-- // content-panel -->

  </div>
  <!-- // form-panel -->

</div>
`,
'pages/popup-dialog/popup-dialog' : `<!--
/**
 * Created by PHILIP on 11/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 11/07/2017
 *
 */
-->

<div class="popup-dialog-container">

  <!-- btn-close-->
  <div class="btn-close"
       (click)="dismiss()"></div>
  <!-- // btn-close-->

  <!-- form-panel -->
  <div class="form-panel">

    <!-- title-panel -->
    <div class="title-panel">
      <h2 class="form-title">{{popupDialogInfo.title}}</h2>
      <!-- <p>{{ 'service.support.emailForm.summary' | translate }}</p> -->
    </div>
    <!-- // title-panel -->

    <!-- form-panel -->
    <div class="form-panel">
      <form>
          <div class="imageView" (click)="appService.openWeb(popupDialogInfo.url)"  *ngIf = "popupDialogInfo.photo.length > 0">
              <preload-image [imageSource]="popupDialogInfo.photo[0].secure_url"
              [ratio]="1" style="display: contents;cursor: pointer;" *ngIf = "popupDialogInfo.url.length > 0">
              </preload-image>
              <preload-image [imageSource]="popupDialogInfo.photo[0].secure_url"
              [ratio]="1" style="display: contents;" *ngIf = "popupDialogInfo.url.length == 0">
              </preload-image>
        </div>  
        <div class="input-panel">
            <p style="word-break: break-all;">
                {{popupDialogInfo.text}}
            </p>
        </div>
        <!-- btn-panel -->
        <div class="dialogChecked">
                <md-checkbox [(ngModel)]="popupDialogInfo.checked"
                [ngModelOptions]="{standalone: true}"
                (change)="clickedEnd()">
                <span>{{ 'popups.closeToday' | translate }}</span>
            </md-checkbox>
        </div>
      </form>
    </div>
    <!-- // form-panel -->

  </div>
  <!-- // form-panel -->

</div>`,
'pages/service/service' : `<!--
/**
 * Created by PHILIP on 27/06/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 27/06/2017
 *
 */
-->

<div class="service-container">

  <!-- header-panel -->
  <div class="header-panel">
    <div>
      <h2>CUSTOMER SERVICE</h2>
    </div>
  </div>
  <!-- // header-panel -->

  <!-- pdf-panel -->
  <div class="pdf-panel">

    <!-- product-panel -->
    <div class="product-panel">
      <div class="product-item smart"
          [ngClass]="{active: selectedProduct == 'SMARTNOSEWORK'}"
          (click)="selectedProduct = 'SMARTNOSEWORK'">
        <span>{{ 'menu.product.child.smartNosework.name' | translate}}</span>
      </div>
      <div class="product-item noseworkit"
          [ngClass]="{active: selectedProduct == 'NOSEWORKIT'}"
          (click)="selectedProduct = 'NOSEWORKIT'">
        <span>{{ 'menu.product.child.noseworKit.name' | translate}}</span>
      </div>
      <div class="product-item noseball"
          [ngClass]="{active: selectedProduct == 'NOSEBALL'}"
          (click)="selectedProduct = 'NOSEBALL'">
        <span>{{ 'menu.product.child.noseBall.name' | translate}}</span>
      </div>
      <div class="product-item conneck"
          [ngClass]="{active: selectedProduct == 'CONNECK'}"
          (click)="selectedProduct = 'CONNECK'">
        <span>{{ 'menu.product.child.conneck.name' | translate}}</span>
      </div>
    </div>
    <!-- // product-panel -->

    <!-- btn-pdf -->
    <div class="btn-pdf"
         (click)="downloadPDF()">
      <span>PDF DOWNLOAD</span>
    </div>
    <!-- // btn-pdf -->

  </div>
  <!-- // pdf-panel -->

  <!-- faq-panel -->
  <div class="faq-panel"
       *ngIf="faqs.length > 0">

    <!-- question-panel -->
    <div class="question-panel">
      <div *ngFor="let faq of faqs"
           [ngClass]="{active: selectedFAQ._id == faq._id}"
           (click)="selectedFAQ = faq">
        <h2>{{appService.currentLocal.local == 'kr' ? faq.questionKr : faq.questionEn}}</h2>
      </div>
    </div>
    <!-- // question-panel -->

    <!-- answer-panel -->
    <div class="answer-panel">
      <div>
        <h2>{{appService.currentLocal.local == 'kr' ? selectedFAQ.questionKr : selectedFAQ.questionEn}}</h2>
        <p>{{appService.currentLocal.local == 'kr' ? selectedFAQ.answerKr : selectedFAQ.answerEn}}</p>
      </div>
    </div>
    <!-- // answer-panel -->

  </div>
  <!-- // faq-panel -->

  <!-- info-panel -->
  <div class="info-panel">

    <!-- service-panel -->
    <div class="service-panel">

      <div class="customer-panel">
        <div class="desc-panel">
          <h2>Customer Center</h2>
          <p>{{ 'service.customerCenter.description' | translate }}</p>
        </div>
        <div class="phone">{{ 'service.customerCenter.phone' | translate }}</div>
        <div class="hours-panel">
          <div>
            <span class="name">{{ 'service.customerCenter.openingHours.weekdays.name' | translate }}</span>
            <span class="divider">┃</span>
            {{ 'service.customerCenter.openingHours.weekdays.time' | translate }}
          </div>
          <div>
            <span class="name">{{ 'service.customerCenter.openingHours.launch.name' | translate }}</span>
            <span class="divider">┃</span>
            {{ 'service.customerCenter.openingHours.launch.time' | translate }}
          </div>
        </div>
      </div>

      <div class="support-panel">
        <div class="desc-panel">
          <h2>Support</h2>
          <p>{{ 'service.support.description' | translate }}</p>
        </div>
        <div class="btn-support"
             (click)="openSupportForm()">
          {{ 'buttons.getSupport' | translate }}
        </div>
      </div>

    </div>
    <!-- // service-panel -->

    <!-- map-panel -->
    <div class="map-panel">

      <div class="map">
        <sebm-google-map [latitude]="'service.afterServiceCenter.addressInfo.branchOffice.geoJSON.latitude' | translate"
                         [longitude]="'service.afterServiceCenter.addressInfo.branchOffice.geoJSON.longitude' | translate"
                         [zoom]="15">
          <sebm-google-map-marker [latitude]="'service.afterServiceCenter.addressInfo.branchOffice.geoJSON.latitude' | translate"
                                  [longitude]="'service.afterServiceCenter.addressInfo.branchOffice.geoJSON.longitude' | translate"></sebm-google-map-marker>
        </sebm-google-map>
      </div>

      <div class="contact-panel">

        <h2>After Service Center</h2>

        <div class="detail-panel">
          <div class="address-panel">
            <div>
              <h2>Address({{ 'service.afterServiceCenter.addressInfo.headOffice.name' | translate }})</h2>
              <p>{{ 'service.afterServiceCenter.addressInfo.headOffice.address' | translate }}</p>
            </div>
            <div>
              <h2>Address({{ 'service.afterServiceCenter.addressInfo.branchOffice.name' | translate }})</h2>
              <p>{{ 'service.afterServiceCenter.addressInfo.branchOffice.address' | translate }}</p>
            </div>
          </div>

          <div class="call-panel">
            <h2>Call</h2>
            <p>{{ 'service.afterServiceCenter.phone' | translate }}</p>
          </div>
        </div>

      </div>

    </div>
    <!-- // map-panel -->

  </div>

</div>`,
'pages/support-form/support-form' : `<!--
/**
 * Created by PHILIP on 11/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 11/07/2017
 *
 */
-->

<div class="support-form-container"
     [perfect-scrollbar]="{ suppressScrollX: false, suppressScrollY: false }"
     [style.width]="contentSize.width"
     [style.height]="contentSize.height">

  <!-- btn-close-->
  <div class="btn-close"
       (click)="dismiss()"></div>
  <!-- // btn-close-->

  <!-- form-panel -->
  <div class="form-panel">

    <!-- title-panel -->
    <div class="title-panel">
      <h2 class="form-title">{{ 'service.support.emailForm.title' | translate }}</h2>
      <p>{{ 'service.support.emailForm.summary' | translate }}</p>
    </div>
    <!-- // title-panel -->

    <!-- form-panel -->
    <div class="form-panel">
      <form>

        <!-- input-panel -->
        <div class="input-panel">
          <md-input-container>
            <input type="email"
                   name="email"
                   [placeholder]="'service.support.emailForm.address' | translate"
                   mdInput
                   [(ngModel)]="supportInfo.email">
          </md-input-container>
        </div>
        <!-- // input-panel -->

        <!-- textarea-panel -->
        <div class="textarea-panel"
             #textarea>
          <label>{{ 'service.support.emailForm.contents' | translate }}</label>
          <textarea name="content"
                    (input)="inputChange()"
                    [(ngModel)]="supportInfo.content"></textarea>
        </div>
        <!-- // textarea-panel -->

        <!-- btn-panel -->
        <div class="btn-panel">
          <button class="btn-send"
                  type="submit"
                  [disabled]="!isValid()"
                  (click)="send()">
            <span>{{ 'buttons.send' | translate }}</span>
          </button>
        </div>
        <!-- // btn-panel -->

      </form>
    </div>
    <!-- // form-panel -->

  </div>
  <!-- // form-panel -->

</div>`,
'pages/store/store' : `<!--
/**
 * Created by PHILIP on 27/06/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 27/06/2017
 *
 */
-->
 <!-- 네이버 공동유입스크립트 -->
<script type="text/javascript" src="http://wcs.naver.net/wcslog.js"></script>
<script type="text/javascript">
    if (!wcs_add) var wcs_add = {};
    wcs_add["wa"] = "s_52e6d95fb9a0";
    wcs.inflow("nosework-official.com");
    wcs_do();
</script>

<div class="store-container">

  <router-outlet></router-outlet>

  <!-- store-footer-panel -->
  <div class="store-footer-panel">

    <div>

      <div>

        <!-- inquiry-panel -->
        <div class="inquiry-panel">

          <!-- title-panel -->
          <div class="title-panel">
            <h2>고객문의</h2>
            <div class="divider"></div>
          </div>
          <!-- // title-panel -->

          <!-- info-panel -->
          <div class="info-panel">
            <p class="phone">1600-2190</p>
            <div class="hours-panel">
              <p>
                평일<span>오전 09:30 ~ 오후 05:30</span>
              </p>
              <p>
                점심<span>오후 12:00 ~ 오후 01:00</span>
              </p>
              <p>
                휴무<span>주말 및 공휴일 휴무</span>
              </p>
            </div>
          </div>
          <!-- // info-panel -->

        </div>
        <!-- // inquiry-panel -->

        <!-- bank-panel -->
        <div class="bank-panel">

          <!-- title-panel -->
          <div class="title-panel">
            <h2>무통장 입금 안내</h2>
            <div class="divider"></div>
          </div>
          <!-- // title-panel -->

          <!-- info-panel -->
          <div class="info-panel">
            <p>
              입금은행<span>신한은행</span>
            </p>
            <p>
              계좌번호<span>140-011-856410</span>
            </p>
            <p>
              예금주<span>노즈워크</span>
            </p>
            <p>
              입금확인시간<span>오전9:00 ~ 오후6:00</span>
            </p>
          </div>
          <!-- // info-panel -->

        </div>
        <!-- // bank-panel -->

        <!-- company-panel -->
        <div class="company-panel">

          <!-- title-panel -->
          <div class="title-panel">
            <h2>회사정보</h2>
            <div class="divider"></div>
          </div>
          <!-- // title-panel -->

          <!-- info-panel -->
          <div class="info-panel">
            <div>
              <p>
                회사명<span>(주)노즈워크</span>
              </p>
              <p>
                대표이사<span>장현덕</span>
              </p>
              <p>
                대표전화<span>1600-2190</span>
              </p>
            </div>
            <p>
              본사<span>4241 Topsail Ct, Soquel, CA 95073</span>
            </p>
            <p>
              지사<span>충청남도 천안시 서북구 직산읍 직산로 136, 616호</span>
            </p>
            <p>
              사업자 등록번호<span>209-81-64804</span> &emsp; <A  onmouseover="this.style.color='#ff0000';" onmouseout="this.style.color='#666666';" style="font:#666666;cursor:pointer;" onclick="window.open('http://www.ftc.go.kr/bizCommPop.do?wrkr_no=2098164804', 'NOSEWORKBizInfo', 'height=750px, width=700px')">사업자 정보 확인</A>
            </p>
            <p>
              통신판매업 신고<span>제2017-충남천안-1004호</span>
            </p>
            <p>
              이메일<span>admin@nosework-official.com</span>
            </p>
          </div>
          <!-- // info-panel -->

        </div>
        <!-- // company-panel -->
      </div>

    </div>

  </div>
  <!-- // store-footer-panel -->

</div>`,
'pages/supporters/supporters' : `<!--
/**
 * Created by HARACE on 27/03/2019
 * As part of NoseWork
 *
 * Copyright (C) NOSEWORK (www.nosework-official.com) & HARACE - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by HARACE <harace.yh.jeon@gmail.com>, 27/03/2019
 *
 */
-->
<!-- 네이버 공동유입스크립트 -->
<script type="text/javascript" src="http://wcs.naver.net/wcslog.js"></script>
<script type="text/javascript">
    if (!wcs_add) var wcs_add = {};
    wcs_add["wa"] = "s_52e6d95fb9a0";
    wcs.inflow("nosework-official.com");
    wcs_do();
</script>


<div class="pos vis section">
    <div class="vis-2 pos-2 size cont">
        <div class="vis-2 pos-2 size colwrapper">
            <div class="vis-2 pos-2 size-2 cont-2">
                <div class="vis-2 pos-3 size-3 cont-3">
                    <picture class="img-2">
                        <img title="Wadiz_Banner" alt="NOSEWORK X Wadiz" class="js img"
                            src="assets/img/brand_img_logotype2.png">
                    </picture>
                </div>
                <div class="vis-3 pos-4 size-4 cont-4">
                    <p class="para"><span class="font">[TEXT Box1]</span></p>
                    <p class="para"><span class="font"> 노즈워크 서포터즈는 반려동물의 행복을 위해 수익금 중 일부와 기부를 진행하고 있습니다.</span></p>
                    <p class="para"><span class="font"> </span></p>
                    <p class="para"><span class="font">﻿ </span></p>
                    <p class="para-2"><span class="font-2"> 1시간에 버려지는 반려동물 약 11마리</span></p>
                    <p class="para"><span class="font-3">﻿ </span></p>
                    <p class="para"><span class="font-4"> 반려동물 인구 1000만 시대에 접어들었지만, 2017년 한해 동안 유기된 아이들은 100,715마리라고
                            합니다.(통계청)</span></p>
                    <p class="para"><span class="font-4"> 지금도 무책임한 사람들 때문에 고통받는 아이들은 점점 늘어나고 있습니다.</span></p>
                    <p class="para"><span class="font">﻿ </span></p>
                    <p class="para"><span class="font">﻿ </span></p>
                </div>
                <div class="vis-3 pos-5 size-5 cont-5">
                    <p class="para-2"><span class="font-2">2019.01.20</span></p>
                    <p class="para-2"><span class="font-2">~ 2019.02.15</span></p>
                    <p class="para-2"><span class="font-2">﻿ </span></p>
                    <p class="para-2"><span class="font-2">노즈워크</span></p>
                    <p class="para-2"><span class="font-2">X</span></p>
                    <p class="para-2"><span class="font-2">와디즈</span></p>
                </div>
                <div class="vis-2 pos-6 size-6 cont-6">
                    <iframe frameborder="0" allowfullscreen mozallowfullscreen webkitallowfullscreen
                        src="https://www.youtube.com/embed/M3Gv9YvGVRA?rel=0" class="video">
                    </iframe>
                </div>
            </div>
            <div class="vis-3 pos-7 size-7 cont-7">
                <h2 class="para-2"><span class="font-5">응원해주셔서 감사합니다.</span></h2>
            </div>
            <div class="vis-2 pos-8 size-8 cont">
                <div class="vis-2 pos-9 size-9 cont-8">
                    <picture class="img-4">
                        <source srcset=" 1x,  2x">
                        <img src="" alt="" class="js-2 img-3">
                    </picture>
                </div>
                <div class="vis-3 pos-10 size-10 cont-9">
                    <p class="para"><span class="font">[TEXT Box2]</span></p>
                    <p class="para"><span class="font">﻿ </span></p>
                    <p class="para"><span class="font-6"> 와디즈 프로젝트</span><span class="font">는 단순 제품 판매가 목적이 아닌, 유기동물과의
                            공생을 위한 펀딩이었습니다. 단순 제품 판매가 아닌 반려동물을 사랑하는 모든 분들과 함께 동행을 그리고자 하였고, 이에 많은 분들께서 도움을 주셨습니다.</span>
                    </p>
                    <p class="para"><span class="font">﻿ </span></p>
                    <p class="para"><span class="font"> 동행해주신 여러분들께 진심의 감사 인사를 전합니다. </span></p>
                    <p class="para"><span class="font"> </span></p>
                    <p class="para-2"><span class="font-7">감사합니다.</span></p>
                    <p class="para"><span class="font">﻿ </span></p>
                    <p class="para"><span class="font">﻿ </span></p>
                    <p class="para"><span class="font"> </span><span class="font-8">정세향     노윤정    아라m    최선미    이형주   
                            김진범    조혜영        박혜민        국중필        오지현        김민정</span></p>
                    <p class="para"><span class="font-8"> 오지현        김민정    김진령    조미선    남혜숙    손혜민    김난희        정다영 
                                  김초롱        손혜림        박은아</span></p>
                    <p class="para"><span class="font-8"> 박채연        이강주    서예서    노윤선    김지혜</span></p>
                    <p class="para"><span class="font">﻿ </span></p>
                    <p class="para"><span class="font">﻿ </span></p>
                    <p class="para"><span class="font">﻿ </span></p>
                    <p class="para"><span class="font"> </span></p>
                    <p class="para"><span class="font"> </span></p>
                    <p class="para"><span class="font">﻿ </span></p>
                    <p class="para"><span class="font">﻿ </span></p>
                    <p class="para"><span class="font">﻿ </span></p>
                    <p class="para"><span class="font">﻿ </span></p>
                    <p class="para"><span class="font">﻿ </span></p>
                    <p class="para"><span class="font">﻿ </span></p>
                    <p class="para"><span class="font">﻿ </span></p>
                    <p class="para"><span class="font">﻿ </span></p>
                    <p class="para"><span class="font">﻿ </span></p>
                </div>
                <div class="vis-2 pos-11 size-11 cont-10">
                    <picture class="img-6">
                        <source srcset="images/pasted-image-640.jpg 1x, images/pasted-image-1280.jpg 2x">
                        <img src="images/pasted-image-640.jpg" alt="" class="js-3 img-5">
                    </picture>
                </div>
            </div>
        </div>
    </div>
</div>
<script type="text/javascript" src="js/jquery.js"></script>
<script type="text/javascript" src="js/page-1.20190402165402.js"></script>
<script type="text/javascript">
    var ver = RegExp(/Mozilla\/5\.0 \(Linux; .; Android ([\d.]+)/).exec(navigator.userAgent);
    if (ver && parseFloat(ver[1]) < 5) {
        document.getElementsByTagName('body')[0].className += ' whitespacefix';
    }
</script>`,
'pages/login/find-password/find-password' : `<!--
/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */
-->

<div class="find-password-container">

  <!-- form-panel -->
  <div class="form-panel">

    <!-- label-panel -->
    <div class="label-panel">
      <h2>비밀번호 찾기</h2>

      <!-- btn-close -->
      <div class="btn-close"
           (click)="dismiss()">
        <i class="material-icons">close</i>
      </div>
      <!-- // btn-close -->

    </div>
    <!-- // label-panel -->

    <!-- content-panel -->
    <div class="content-panel">

      <!-- find-password-panel -->
      <div class="find-password-panel">

        <form>

          <!-- step1-panel -->
          <div class="step1-panel"
               *ngIf="step == 1">

            <p>가입시 입력한 이메일주소로 인증번호를 전송합니다.</p>

            <div class="input-panel">
              <md-input-container>
                <input mdInput
                       type="email"
                       name="email"
                       placeholder="이메일 주소"
                       maxlength="256"
                       [(ngModel)]="identifier"/>
              </md-input-container>
            </div>

            <div class="btn-panel">
              <button type="submit"
                      (click)="forgotPasswordStart()"
                      [disabled]="!isValid()">
                <span>다음</span>
              </button>
            </div>

          </div>
          <!-- // step1-panel -->

          <!-- step2-panel -->
          <div class="step2-panel"
               *ngIf="step == 2">

            <p>이메일을 확인하여 도착한 인증번호를 입력해주세요.</p>

            <div class="input-panel">
              <md-input-container>
                <input mdInput
                       type="text"
                       name="certificateNumber"
                       placeholder="인증번호"
                       [(ngModel)]="certificateNumber"/>
              </md-input-container>
              <div class="btn-resend"
                   (click)="forgotPasswordStart()">
                인증번호 재전송
              </div>
            </div>

            <div class="btn-panel">
              <button type="submit"
                      (click)="forgotPasswordCheck()"
                      [disabled]="!isValid()">
                <span>다음</span>
              </button>
            </div>

          </div>
          <!-- // step2-panel -->

          <!-- step3-panel -->
          <div class="step3-panel"
               *ngIf="step == 3">

            <p>새로운 비밀번호를 설정해주세요.</p>

            <div class="input-panel">
              <md-input-container>
                <input mdInput
                       type="password"
                       name="password"
                       placeholder="새 비밀번호"
                       [(ngModel)]="password"
                       minlength="8"/>
              </md-input-container>
              <p class="validation-text">
                영문, 숫자, 특수문자 포함 8자 ~ 12자 이내<br />
                (특수문자는 ~!@#$%^&*()_-+=만 가능)
              </p>
            </div>

            <div class="input-panel">
              <md-input-container>
                <input mdInput
                       type="password"
                       name="passwordConfirm"
                       placeholder="새 비밀번호 확인"
                       [(ngModel)]="passwordConfirm"
                       minlength="8"/>
              </md-input-container>
            </div>

            <div class="btn-panel">
              <button type="submit"
                      (click)="forgotPasswordComplete()"
                      [disabled]="!isValid()">
                <span>새 비밀번호 설정</span>
              </button>
            </div>

          </div>
          <!-- // step3-panel -->

        </form>

      </div>
      <!-- // find-password-panel -->

    </div>
    <!-- // content-panel -->

  </div>
  <!-- // form-panel -->

</div>
`,
'pages/login/pre-order-history/pre-order-history' : `<!--
/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */
-->

<div class="find-password-container">

  <!-- form-panel -->
  <div class="form-panel">

    <!-- label-panel -->
    <div class="label-panel">
      <h2>비회원 주문내역 확인</h2>

      <!-- btn-close -->
      <div class="btn-close"
           (click)="dismiss()">
        <i class="material-icons">close</i>
      </div>
      <!-- // btn-close -->

    </div>
    <!-- // label-panel -->

    <!-- content-panel -->
    <div class="content-panel">

      <!-- find-password-panel -->
      <div class="find-password-panel">

        <form>

          <!-- step1-panel -->
          <div class="step1-panel">

            <p>결제한 이메일주소와 결제금액을 입력해주세요.</p>

            <div class="input-panel">
              <md-input-container>
                <input mdInput
                       type="email"
                       name="email"
                       placeholder="이메일 주소"
                       maxlength="256"
                       [(ngModel)]="identifier"/>
              </md-input-container>
            </div>

            <div class="input-panel">
              <md-input-container>
                <input mdInput
                       type="number"
                       name="price"
                       placeholder="결제금액"
                       maxlength="10"
                       [(ngModel)]="price"/>
              </md-input-container>
            </div>

            <div class="btn-panel">
              <button type="submit"
                      (click)="goToOrderHistory()"
                      [disabled]="!isValid()">
                <span>주문내역 보기</span>
              </button>
            </div>

          </div>
          <!-- // step1-panel -->

        </form>

      </div>
      <!-- // find-password-panel -->

    </div>
    <!-- // content-panel -->

  </div>
  <!-- // form-panel -->

</div>
`,
'pages/login/register/register' : `<!--
/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */
-->

<div class="register-container">

  <!-- btn-close -->
  <div class="btn-close"
       (click)="dismiss()">
    <i class="material-icons">close</i>
  </div>
  <!-- // btn-close -->

  <!-- form-panel -->
  <div class="form-panel">

    <!-- label-panel -->
    <div class="label-panel">
      <h2>회원가입</h2>
    </div>
    <!-- // label-panel -->

    <!-- content-panel -->
    <div class="content-panel">

      <!-- register-panel -->
      <div class="register-panel">

        <form>

          <div class="input-panel">
            <md-input-container [ngClass]="{'violate': violateInput == 'identifier'}">
              <input mdInput
                     autofocus
                     type="email"
                     name="identifier"
                     maxlength="256"
                     placeholder="이메일 주소"
                     (keyup)="isValidRegister()"
                     [(ngModel)]="registerInfo.identifier"/>
            </md-input-container>
          </div>

          <div class="input-panel">
            <md-input-container [ngClass]="{'violate': violateInput == 'nickname'}">
              <input mdInput
                     type="text"
                     name="nickname"
                     placeholder="닉네임"
                     minlength="2"
                     maxlength="8"
                     (keyup)="isValidRegister()"
                     [(ngModel)]="registerInfo.nickname"/>
            </md-input-container>
            <p class="validation-text">2자 ~ 8자 이내</p>
          </div>

          <div class="input-panel">
            <md-input-container [ngClass]="{'violate': violateInput == 'password'}">
              <input mdInput
                     type="password"
                     name="password"
                     placeholder="비밀번호"
                     minlength="8"
                     maxlength="12"
                     (keyup)="isValidRegister()"
                     [(ngModel)]="registerInfo.password"/>
            </md-input-container>
            <p class="validation-text">
              영문, 숫자, 특수문자 포함 8자 ~ 12자 이내<br />
              (특수문자는 ~!@#$%^&*()_-+=만 가능)
            </p>
          </div>

          <div class="input-panel">
            <md-input-container [ngClass]="{'violate': violateInput == 'passwordConfirm'}">
              <input mdInput
                     type="password"
                     name="password"
                     placeholder="비밀번호 확인"
                     minlength="8"
                     maxlength="12"
                     (keyup)="isValidRegister()"
                     [(ngModel)]="passwordConfirm"/>
            </md-input-container>
          </div>

          <!-- policy-panel -->
          <div class="policy-panel">

            <!-- check-terms-panel -->
            <div class="check-terms-panel">

              <!-- check-panel -->
              <div class="check-panel"
                   (click)="registerInfo.termsCheck = !registerInfo.termsCheck">
                <img *ngIf="!registerInfo.termsCheck"
                     src="assets/img/ic_checkmark_off.png">
                <img *ngIf="registerInfo.termsCheck"
                     src="assets/img/ic_checkmark_on.png">
                <span>이용약관 동의</span>
              </div>
              <!-- // check-panel -->

              <!-- btn-policy -->
              <div class="btn-policy"
                   (click)="openPolicyForm('terms')">
                상세보기
              </div>
              <!-- // btn-policy -->
            </div>
            <!-- // check-terms-panel -->

            <!-- check-privacy-panel -->
            <div class="check-privacy-panel">

              <!-- check-panel -->
              <div class="check-panel"
                   (click)="registerInfo.privacyCheck = !registerInfo.privacyCheck">
                <img *ngIf="!registerInfo.privacyCheck"
                     src="assets/img/ic_checkmark_off.png">
                <img *ngIf="registerInfo.privacyCheck"
                     src="assets/img/ic_checkmark_on.png">
                <span>개인정보처리방침 동의</span>
              </div>
              <!-- // check-panel -->

              <!-- btn-policy -->
              <div class="btn-policy"
                   (click)="openPolicyForm('privacy')">
                상세보기
              </div>
              <!-- // btn-policy -->

            </div>
            <!-- // check-privacy-panel -->

            <!-- check-privacy-panel -->
            <div class="check-privacy-panel">

              <!-- check-panel -->
              <div class="check-panel"
                   (click)="registerInfo.legalCheck = !registerInfo.legalCheck">
                <img *ngIf="!registerInfo.legalCheck"
                     src="assets/img/ic_checkmark_off.png">
                <img *ngIf="registerInfo.legalCheck"
                     src="assets/img/ic_checkmark_on.png">
                <span>법적고지</span>
              </div>
              <!-- // check-panel -->

              <!-- btn-policy -->
              <div class="btn-policy"
                   (click)="openPolicyForm('legal')">
                상세보기
              </div>
              <!-- // btn-policy -->

            </div>
            <!-- // check-privacy-panel -->

          </div>
          <!-- // policy-panel -->

          <div class="btn-panel">
            <button type="submit"
                    (click)="checkDuplicateUser()"
                    [disabled]="!isValidRegister()">
              <span>가입하기</span>
            </button>
          </div>

        </form>

      </div>
      <!-- // register-panel -->

    </div>
    <!-- // content-panel -->

  </div>
  <!-- // form-panel -->

</div>
`,
'pages/product/product-conneck/product-conneck' : `<!--
/**
 * Created by PHILIP on 27/06/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 27/06/2017
 *
 */
-->

<div class="product-conneck-container">

  <!-- header-panel -->
  <div class="header-panel">

    <!-- menu-panel -->
    <ul class="menu-panel">

      <li class="menu-item"
          [ngClass]="{active: appService.isActiveUrl('/product-conneck/conneck-overview')}"
          (click)="appService.goToMenu('/product-conneck/conneck-overview')">
        {{ 'product.conneck.menu.overview.name' | translate }}
      </li>

      <li class="menu-item"
          [ngClass]="{active: appService.isActiveUrl('/product-conneck/conneck-story')}"
          (click)="appService.goToMenu('/product-conneck/conneck-story')">
        {{ 'product.conneck.menu.story.name' | translate }}
      </li>

      <li class="menu-item"
          [ngClass]="{active: appService.isActiveUrl('/product-conneck/conneck-function-design')}"
          (click)="appService.goToMenu('/product-conneck/conneck-function-design')">
        {{ 'product.conneck.menu.functionDesign.name' | translate }}
      </li>

      <!-- <li class="menu-item buy">
        <div (click)="appService.goToMenu('store/product-detail/', 'CONNECK')">
          {{ 'buttons.buyNow' | translate }}
        </div>
      </li> -->

    </ul>
    <!-- // menu-panel -->

  </div>
  <!-- // header-panel -->

  <router-outlet></router-outlet>

</div>`,
'pages/product/product-noseBall/product-noseBall' : `<!--
/**
 * Created by PHILIP on 27/06/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 27/06/2017
 *
 */
-->

<div class="product-noseBall-container">

  <!-- header-panel -->
  <div class="header-panel">

    <!-- menu-panel -->
    <ul class="menu-panel">

      <li class="menu-item"
          [ngClass]="{active: appService.isActiveUrl('/product-noseBall/noseBall-overview')}"
          (click)="appService.goToMenu('/product-noseBall/noseBall-overview')">
        {{ 'product.noseBall.menu.overview.name' | translate }}
      </li>

      <li class="menu-item"
          [ngClass]="{active: appService.isActiveUrl('/product-noseBall/noseBall-story')}"
          (click)="appService.goToMenu('/product-noseBall/noseBall-story')">
        {{ 'product.noseBall.menu.story.name' | translate }}
      </li>

      <li class="menu-item"
          (click)="appService.openWeb('http://blog.nosework-official.com')">
        {{ 'product.noseBall.menu.aromaTherapy.name' | translate }}
      </li>

      <li class="menu-item"
          [ngClass]="{active: appService.isActiveUrl('/product-noseBall/noseBall-function-design')}"
          (click)="appService.goToMenu('/product-noseBall/noseBall-function-design')">
        {{ 'product.noseBall.menu.functionDesign.name' | translate }}
      </li>

      <li class="menu-item buy">
        <div (click)="appService.openWeb('https://nosework-shop.com/product/detail.html?product_no=14&cate_no=46', 'NOSEball')">
          {{ 'buttons.buyNow' | translate }}
        </div>
      </li>

    </ul>
    <!-- // menu-panel -->

  </div>
  <!-- // header-panel -->

  <router-outlet></router-outlet>

</div>`,
'pages/product/product-noseWorKit/product-noseWorKit' : `<!--
/**
 * Created by PHILIP on 27/06/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 27/06/2017
 *
 */
-->

<div class="product-noseWorKit-container">

  <!-- header-panel -->
  <div class="header-panel">
    <ul class="menu-panel">
      <li class="menu-item"
          [ngClass]="{active: appService.isActiveUrl('/product-noseWorKit/noseWorKit-overview')}"
          (click)="appService.goToMenu('/product-noseWorKit/noseWorKit-overview')">
        {{ 'product.noseworKit.menu.overview.name' | translate }}
      </li>
      <li class="menu-item"
          [ngClass]="{active: appService.isActiveUrl('/product-noseWorKit/noseWorKit-story')}"
          (click)="appService.goToMenu('/product-noseWorKit/noseWorKit-story')">
        {{ 'product.noseworKit.menu.story.name' | translate }}
      </li>
      <li class="menu-item"
          [ngClass]="{active: appService.isActiveUrl('/product-noseWorKit/noseWorKit-function-design')}"
          (click)="appService.goToMenu('/product-noseWorKit/noseWorKit-function-design')">
        {{ 'product.noseworKit.menu.functionDesign.name' | translate }}
      </li>
      <li class="menu-item buy">
        <div (click)="appService.openWeb('https://nosework-shop.com/product/detail.html?product_no=25&cate_no=1', 'NOSEWORKit')">
          {{ 'buttons.buyNow' | translate }}
        </div>
      </li>
    </ul>
  </div>
  <!-- // header-panel -->

  <router-outlet></router-outlet>

</div>`,
'pages/product/product-smartNoseWork/product-smartNoseWork' : `<!--
/**
 * Created by PHILIP on 27/06/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 27/06/2017
 *
 */
-->

<div class="product-smartNoseWork-container">

  <!-- header-panel -->
  <div class="header-panel">

    <!-- menu-panel-->
    <ul class="menu-panel">

      <li class="menu-item"
          [ngClass]="{active: appService.isActiveUrl('/product-smartNoseWork/smartNoseWork-overview')}"
          (click)="appService.goToMenu('/product-smartNoseWork/smartNoseWork-overview')">
        {{ 'product.smartNosework.menu.overview.name' | translate }}
      </li>

      <li class="menu-item"
          [ngClass]="{active: appService.isActiveUrl('/product-smartNoseWork/smartNoseWork-story')}"
          (click)="appService.goToMenu('/product-smartNoseWork/smartNoseWork-story')">
        {{ 'product.smartNosework.menu.story.name' | translate }}
      </li>

      <li class="menu-item"
          [ngClass]="{active: appService.isActiveUrl('/product-smartNoseWork/smartNoseWork-function')}"
          (click)="appService.goToMenu('/product-smartNoseWork/smartNoseWork-function')">
        {{ 'product.smartNosework.menu.function.name' | translate }}
      </li>

      <li class="menu-item"
          [ngClass]="{active: appService.isActiveUrl('/product-smartNoseWork/smartNoseWork-design')}"
          (click)="appService.goToMenu('/product-smartNoseWork/smartNoseWork-design')">
        {{ 'product.smartNosework.menu.design.name' | translate }}
      </li>

      <li class="menu-item buy">
        <div (click)="appService.openWeb('https://www.nosework-shop.com/product/detail.html?product_no=24', 'smart NOSEWORK')">
          {{ 'buttons.buyNow' | translate }}
        </div>
      </li>

    </ul>
    <!-- // menu-panel-->

  </div>
  <!-- // header-panel -->

  <router-outlet></router-outlet>

</div>`,
'pages/store/invoice-list/invoice-list' : `<!--
/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */
-->
 <!-- 네이버 공동유입스크립트 -->
<script type="text/javascript" src="http://wcs.naver.net/wcslog.js"></script>
<script type="text/javascript">
    if (!wcs_add) var wcs_add = {};
    wcs_add["wa"] = "s_52e6d95fb9a0";
    wcs.inflow("nosework-official.com");
    wcs_do();
</script>

<div class="invoice-list-container">
  <h2>주문 내역</h2>

  <!-- empty-panel -->
  <div class="empty-panel"
       *ngIf="invoices.length <= 0">
    <span>주문내역이 없습니다.</span>
  </div>
  <!-- // empty-panel -->

  <!-- not-empty-panel -->
  <div class="not-empty-panel"
       *ngIf="invoices.length > 0">

    <!-- content-panel -->
    <div class="content-panel">

      <!-- list-panel -->
      <div class="list-panel">

        <!-- list-body-panel -->
        <div class="list-body-panel">

          <!-- invoice-list-panel -->
          <div class="invoice-list-panel">

            <!-- invoice-panel -->
            <div class="invoice-panel"
                 *ngFor="let invoice of invoices; let itemIndex = index;">

              <!-- info-panel -->
              <div class="info-panel"
                   (click)="appService.goToMenu('/store/invoice-detail/', invoice._id)">

                <!-- image-panel -->
                <div class="image-panel">
                  <preload-image [imageSource]="invoice.productPhoto"
                                 [ratio]="1"></preload-image>
                </div>
                <!-- // image-panel -->

                <!-- detail-panel -->
                <div class="detail-panel">

                  <!-- name-panel -->
                  <div class="name-panel">
                    <p>
                      {{invoice.itemList[0].product.name}}
                      <span *ngIf="invoice.itemList.length > 1">외 {{invoice.itemList.length-1}}개</span>
                    </p>
                    <p>
                      가격 <span>{{invoice.totalAmount | number}}</span>
                    </p>
                  </div>
                  <!-- // name-panel -->

                  <!-- status-panel -->
                  <div class="status-panel">
                    <p>{{invoice.translateStatus}}</p>
                    <p>{{invoice.translatePaymentType}}</p>
                  </div>
                  <!-- // status-panel -->

                </div>
                <!-- // detail-panel -->

              </div>
              <!-- // info-panel -->

              <!-- cancel-panel -->
              <div class="cancel-panel">
                <p>
                  {{invoice.invoiceDate}}<br />
                  {{invoice.invoiceTime}}
                  <!-- {{invoice.createdAt | datex:"YYYY년 MM월 DD일"}}<br />
                  {{invoice.createdAt | datex:"HH시 mm분"}} -->
                </p>
                <div class="btn-cancelPayment"
                     *ngIf="invoice.status == 'PAYMENT_PENDING' || invoice.status == 'PAYMENT_DONE'"
                     (click)="cancelPaymentForUser(invoice)">
                  <span>주문취소</span>
                </div>
              </div>
              <!-- // cancel-panel -->

            </div>
            <!-- // invoice-panel -->

          </div>
          <!-- // invoice-list-panel -->

        </div>
        <!-- // list-body-panel -->

      </div>
      <!-- // list-panel -->

    </div>
    <!-- // content-panel -->

  </div>
  <!-- // not-empty-panel -->

</div>`,
'pages/store/manage-product/manage-product' : `<!--
/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */
-->
 <!-- 네이버 공동유입스크립트 -->
<script type="text/javascript" src="http://wcs.naver.net/wcslog.js"></script>
<script type="text/javascript">
    if (!wcs_add) var wcs_add = {};
    wcs_add["wa"] = "s_52e6d95fb9a0";
    wcs.inflow("nosework-official.com");
    wcs_do();
</script>

<div class="manage-product-container">
  <h2>제품 관리</h2>

  <!-- empty-panel -->
  <div class="empty-panel"
       *ngIf="registeredSerials.length <= 0">
      <span>등록된 제품이 없습니다.</span>
  </div>
  <!-- // empty-panel -->

  <!-- not-empty-panel -->
  <div class="not-empty-panel"
       *ngIf="registeredSerials.length > 0">

    <!-- content-panel -->
    <div class="content-panel">

      <!-- list-panel -->
      <div class="list-panel">

        <!-- registered-serial-panel -->
        <div class="registered-serial-panel"
             *ngFor="let serial of registeredSerials;let serialIndex = index;">

          <!-- product-name-panel -->
          <div class="product-name-panel">
            <span>{{serial.productName}}</span>
          </div>
          <!-- // product-name-panel -->

          <!-- serial-number-panel -->
          <div class="serial-number-panel">
            <span>{{serial.serialNumber}}</span>
          </div>
          <!-- // serial-number-panel -->

          <!-- registered-at-panel -->
          <div class="registered-at-panel">
            <span>{{serial.registeredAt | datex: "YYYY년 MM월 DD일"}} 등록됨 </span>
            <i class="material-icons">check_circle</i>
            <div class="btn-cancelRegistered"
                 (click)="cancelRegistered(serialIndex)">
              <span>삭제</span>
            </div>
          </div>
          <!-- // registered-at-panel -->

        </div>
        <!-- // registered-serial-panel -->

      </div>
      <!-- // list-panel -->

    </div>
    <!-- // content-panel -->

  </div>
  <!-- // not-empty-panel -->

  <!-- btn-registerSerial -->
  <div class="btn-registerSerial"
       (click)="openRegisterGenuine()">
    <span>정품 등록하기</span>
  </div>
  <!-- // btn-registerSerial -->

</div>`,
'pages/store/cart-list/cart-list' : `<!--
/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */
-->

<!-- naverPayButton.js -->
<script type="text/javascript" src="http://test-pay.naver.com/customer/js/naverPayButton.js" charset="UTF-8"></script>
<script type="text/javascript" src="http://test-pay.naver.com/customer/js/mobile/naverPayButton.js" charset="UTF-8"></script>

 <!-- 네이버 공동유입스크립트 -->
<script type="text/javascript" src="http://wcs.naver.net/wcslog.js"></script>
<script type="text/javascript">
    if (!wcs_add) var wcs_add = {};
    wcs_add["wa"] = "s_52e6d95fb9a0";
    wcs.inflow("nosework-official.com");
    wcs_do();
</script>

<div class="cart-list-container">

  <h2>장바구니</h2>

  <!-- empty-panel -->
  <div class="empty-panel"
       *ngIf="cartList.length <= 0">
    <span>장바구니에 담긴 상품이 없습니다.</span>
  </div>
  <!-- // empty-panel -->

  <!-- not-empty-panel -->
  <div class="not-empty-panel"
       *ngIf="cartList.length > 0">

    <!-- content-panel -->
    <div class="content-panel">

      <!-- list-panel -->
      <div class="list-panel">

        <!-- list-header-panel -->
        <div class="list-header-panel">

          <!-- label -->
          <div class="label">
            <span>내 장바구니</span>
          </div>
          <!-- // label -->

          <!-- btn-panel -->
          <div class="btn-panel">

            <!-- btn-allSelect -->
            <div class="btn-allSelect"
                 (click)="allSelectProduct()">
              <i class="material-icons">check_circle</i>
              <span>전체 선택</span>
            </div>
            <!-- // btn-allSelect -->

            <!-- btn-removeSelect -->
            <div class="btn-removeSelect"
                 (click)="removeSelectProduct()">
              <span>선택 상품 삭제</span>
            </div>
            <!-- // btn-removeSelect -->

          </div>
          <!-- // btn-panel -->

        </div>
        <!-- // list-header-panel -->

        <!-- list-body-panel -->
        <div class="list-body-panel">

          <!-- product-list-panel -->
          <div class="product-list-panel">

            <!-- product-panel -->
            <div class="product-panel"
                 *ngFor="let item of cartList; let itemIndex = index;">

              <!-- image-panel -->
              <div class="image-panel"
                   (click)="appService.goToMenu('store/product-detail/', item.product.name)">
                <preload-image [imageSource]="item.product.productPhoto.secure_url"
                               [ratio]="1"></preload-image>
              </div>
              <!-- // image-panel -->

              <!-- setting-panel -->
              
              <div class="setting-panel">
                <!-- info-panel -->
                <div class="info-panel">
                  <p class="name">{{item.product.name}}</p>
                  <div class="option-panel"
                       *ngIf="item.selectedOption && item.selectedOption.length > 0">
                       <div *ngFor = "let selectedItem of item.selectedOption; let selectedIndex = index;">
                          <div class="pay-option">
                              <div class="pay-first-panel">
                                  <div class="pay-option-name">
                                      <span>┕ <b>{{selectedItem.GroupName}}</b></span> {{selectedItem.name}}
                                  </div>
                              </div>
                              <div class="pay-second-panel">
                                  <div class="pay-option-remove">
                                      <button class="btn-addCart" style="color: #CCCCCC;"
                                      (click)=" reduceButton(itemIndex,selectedIndex)">
                                      <i class="material-icons">remove</i>
                                      </button>
                                      
                                     </div>
                                     <div class="pay-option-number">
                                      <span>{{selectedItem.quantity}}</span>
                                     </div>
                                     <div class="pay-option-add">
                                      <button class="btn-addCart" style="color: #CCCCCC;"
                                        (click)="addButton(itemIndex,selectedIndex)">
                                        <i class="material-icons">add</i>
                                      </button>
                                     </div>
                                     <div class="pay-option-price">
                                      {{selectedItem.price | number}} 원
                                     </div>
                              </div>
                           </div>
                       </div>
                  </div>
                </div>
                <!-- // info-panel -->
                <div class="quantityAndselect-panel" >
                  <div class="quantity-set-panel"
                    *ngIf="!item.product.soldOut && !item.totalAttr">
                    <!-- price -->
                    <p class="price" *ngIf="item.product.salePrice == null">
                      <span>
                        {{item.product.price | number}}
                        <!--{{item.product.salePrice == null ? item.product.price | number : item.product.salePrice | number}}-->
                      </span>
                      원
                    </p>
                    <p class="price" *ngIf="item.product.salePrice != null">
                      <span>
                        {{item.product.salePrice | number}}
                        <!--{{item.product.salePrice == null ? item.product.price | number : item.product.salePrice | number}}-->
                      </span>
                      원
                    </p>
                    <!-- // price -->
                    
                    <!-- quantity-panel -->
                    <div class="quantity-panel">

                      <!-- btn-subtractQuantity -->
                      <div class="btn-subtractQuantity"
                            (click)="subtractQuantity(itemIndex)">
                        <i class="material-icons">remove</i>
                      </div>
                      <!-- // btn-subtractQuantity -->

                      <!-- quantity -->
                      <div class="quantity">
                        <span>{{item.quantity}}</span>
                      </div>
                      <!-- // quantity -->

                      <!-- btn-addQuantity -->
                      <div class="btn-addQuantity"
                            (click)="addQuantity(itemIndex)">
                        <i class="material-icons">add</i>
                      </div>
                      <!-- // btn-addQuantity -->

                    </div>
                    <!-- // quantity-panel -->
                  </div>
                  <div class="selectView-panel">
                    <!-- // product-empty -->
    
                    <!-- select-panel -->
                    <div class="select-panel"
                          [ngClass]="{'selected': item.selected}"
                          (click)="selectProduct(itemIndex)">
                      <i class="material-icons">check_circle</i>
                    </div>
                  </div>

                  <div class="product-empty"
                      *ngIf="item.product.soldOut">
                        <span>품절</span>
                  </div>
                </div>
                <!-- quantity-set-panel -->
                
                <!-- // quantity-set-panel -->

                <!-- product-empty -->
                
                <!-- // select-panel -->

              </div>
              <!-- // setting-panel -->

            </div>
            <!-- // product-panel -->

          </div>
          <!-- // product-list-panel -->

        </div>
        <!-- // list-body-panel -->

      </div>
      <!-- // list-panel -->

      <!-- price-info-panel -->
      <div class="price-info-panel">

        <!-- notice-panel -->
        <div class="notice-panel">
          <p>* 5만원이상 구매 시 무료배송입니다.</p>
          <p>* 일부 도서산간지역은 추가운임 혹은 배송지연이 발생할 수 있습니다.</p>
        </div>
        <!-- // notice-panel -->

        <!-- price-panel -->
        <div class="price-panel">

          <!-- delivery-price-panel -->
          <div class="delivery-price-panel">
            <div class="name">
              <span>배송비</span>
            </div>
            <div class="price">
              <span>+ {{deliveryPrice | number}} 원</span>
            </div>
          </div>
          <!-- // delivery-price-panel -->

          <!-- select-price-panel -->
          <div class="select-price-panel">
            <div class="name">
              <span>제품 구매가</span>
            </div>
            <div class="price">
              <span>+ {{totalPrice | number}} 원</span>
            </div>
          </div>
          <div class="select-price-panel">
              <div class="name">
                <span>추가제품 구매가</span>
              </div>
              <div class="price">
                <span>+ {{totalSelectPrice | number}} 원</span>
              </div>
          </div>
          <!-- // select-price-panel -->

        </div>
        <!-- // price-panel -->

        <!-- total-price-panel -->
        <div class="total-price-panel">
          <div class="name">
            <span>합계</span>
          </div>
          <div class="price">
            <span>{{totalPrice + totalSelectPrice + deliveryPrice | number}} 원</span>
          </div>
        </div>
        <!-- // total-price-panel -->

      </div>
      <!-- // price-info-panel -->

      <!-- btn-panel -->
      <div class="btn-panel">
        <button class="btn-selectedBuy"
                (click)="selectedBuy()">
          <span>선택 상품 주문하기</span>
        </button>
        <button class="btn-allBuy"
                (click)="allBuy()">
          <span>전체 상품 주문하기</span>
        </button>
        <!-- // naverPay button create-->
        <div id="naverPayBtn" style="margin-left : 20px">
      </div>
      <!-- // btn-panel -->

    </div>
    <!-- // content-panel -->

  </div>
  <!-- // not-empty-panel -->

</div>`,
'pages/store/payment/payment' : `<!--
/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */
-->
<!-- jQuery -->
<script type="text/javascript" src="https://code.jquery.com/jquery-1.12.4.min.js" ></script>
<!-- iamport.payment.js -->
<script type="text/javascript" src="https://service.iamport.kr/js/iamport.payment-1.1.5.js"></script>
 <!-- 네이버 공동유입스크립트 -->
<script type="text/javascript" src="http://wcs.naver.net/wcslog.js"></script>
<script type="text/javascript">
    if (!wcs_add) var wcs_add = {};
    wcs_add["wa"] = "s_52e6d95fb9a0";
    wcs.inflow("nosework-official.com");
    wcs_do();
</script>

<div class="payment-container">
  <h2>결제하기</h2>

  <!-- content-panel -->
  <div class="content-panel"
       *ngIf="cartList.length > 0">

    <!-- list-panel -->
    <div class="list-panel">

      <!-- list-header-panel -->
      <div class="list-header-panel">

        <!-- label -->
        <div class="label">
          <span>주문 내역</span>
        </div>
        <!-- // label -->

      </div>
      <!-- // list-header-panel -->

      <!-- list-body-panel -->
      <div class="list-body-panel">

        <!-- product-list-panel -->
        <!--<div class="product-list-panel" *ngIf = "!cartList[0].basket">-->
        <div class="product-list-panel">

          <!-- product-panel -->
          <div class="product-panel"
               *ngFor="let item of cartList;let itemIndex = index;">
            <div class="total-panel">
              <div class="first-panel">
                  <preload-image [imageSource]="item.product.productPhoto.secure_url"
                                 [ratio]="1"></preload-image>
              </div>
              <!-- // image-panel -->
              <!-- info-panel -->
              <div class="info-panel" *ngIf="item.selectedOption">
                <p class="name">{{item.product.name}}</p>
                <div class="option-panel"
                    *ngFor="let selectedItem of item.selectedOption ; let selectedIndex = index;"
                     >
                     <div class="pay-option">
                        <div class="pay-first-panel">
                            <div class="pay-option-name">
                                <span>┕ <b>{{selectedItem.GroupName}}</b></span> {{selectedItem.name}}
                            </div>
                        </div>
                        <div class="pay-second-panel">
                          <div class="pay-option-remove">
                            <button class="btn-addCart" style="color: #CCCCCC;"
                            (click)=" reduceButton(itemIndex,selectedIndex)">
                            <i class="material-icons">remove</i>
                          </button>
                          </div>
                          <div class="pay-option-number">
                            <span>{{selectedItem.quantity}}</span>
                          </div>
                          <div class="pay-option-add" >
                            <button class="btn-addCart"  style="color: #CCCCCC;"
                              (click)="addButton(itemIndex,selectedIndex)">
                              <i class="material-icons">add</i>
                            </button>
                          </div>
                          <div class="pay-option-price">
                            {{selectedItem.price | number}} 원
                          </div>
                        </div>
                     </div>
                </div>
              </div>
            </div>
            <!-- image-panel -->
            <!-- // info-panel -->
            <!-- quantity-set-panel -->
            <!--<div *ngIf="!enableAttrbutes">-->
            <div *ngIf="!item.attr">
              <div class="quantity-set-panel">
                <!-- price -->
                <p class="price">
                      <span *ngIf="!item.product.salePrice">
                        {{item.product.price | number}}
                      </span>
                  <span *ngIf="item.product.salePrice">
                        {{item.product.salePrice | number}}
                      </span>
                  원
                </p>
                <!-- quantity-panel -->
                <div class="quantity-panel">
  
                  <!-- btn-subtractQuantity -->
                  <div class="btn-subtractQuantity"
                       (click)="subtractQuantity(itemIndex)">
                    <i class="material-icons">remove</i>
                  </div>
                  <!-- // btn-subtractQuantity -->
  
                  <!-- quantity -->
                  <div class="quantity">
                    <span>{{item.quantity}}</span>
                  </div>
                  <!-- // quantity -->
  
                  <!-- btn-addQuantity -->
                  <div class="btn-addQuantity"
                       (click)="addQuantity(itemIndex)">
                    <i class="material-icons">add</i>
                  </div>
                  <!-- // btn-addQuantity -->
  
                </div>
                <!-- // quantity-panel -->
              </div>
            </div>
            
            <!-- // quantity-set-panel -->

          </div>
          <!-- // product-panel -->

        </div>
        <!-- // product-list-panel -->

      </div>
      <!-- // list-body-panel -->

    </div>
    <!-- // list-panel -->
    <div class="price-info-panel">
      <div class="notice-panel">
        <p>* 5만원이상 구매 시 무료배송입니다.</p>
        <p>* 일부 도서산간지역은 추가운임 혹은 배송지연이 발생할 수 있습니다.</p>
      </div>
      <div class="price-panel">
        <div class="delivery-price-panel">
          <div class="name">
            <span>배송비</span>
          </div>
          <div class="price">
            <span>+ {{deliveryPrice | number}} 원</span>
          </div>
        </div>
        <div class="select-price-panel">
          <div class="name">
            <span>제품 구매가</span>
          </div>
          <div class="price">
            <span>+ {{totalAmount | number}} 원</span>
          </div>
        </div>
        <div class="select-price-panel">
            <div class="name">
              <span>추가제품 구매가</span>
            </div>
            <div class="price">
              <span>+ {{totalSelectedAmount | number}} 원</span>
            </div>
        </div>
      </div>
      <div class="total-price-panel">
        <div class="name">
          <span>합계</span>
        </div>
        <div class="price">
          <span>{{totalAmount + deliveryPrice + totalSelectedAmount| number}} 원</span>
        </div>
      </div>
    </div>

    <div class="delivery-info-panel">

        <!-- info-header-panel -->
        <div class="info-header-panel">
  
          <!-- label -->
          <div class="label">
            <span>배송 정보</span>
          </div>
          <!-- // label -->
  
        </div>
        <!-- // info-header-panel -->
  
        <!-- info-body-panel -->
        <div class="info-body-panel">
          <div>
  
            <!-- info-panel -->
            <div class="info-panel">
  
              <!-- address-panel -->
              <div class="address-panel">
                <label>주소</label>
                <div>
                  <input type="text"
                         name="address"
                         readonly
                         placeholder="검색 주소"
                         [(ngModel)]="address">
                  <button class="btn-findAddress"
                          type="button"
                          (click)="openDaumMapForm()">
                    <span>주소 찾기</span>
                  </button>
                </div>
                <input type="text"
                       name="detailAddress"
                       placeholder="상세 주소"
                       [(ngModel)]="deliveryInfo.addressInfo.detailAddress">
              </div>
              <!-- // address-panel -->
  
              <!-- phone-panel -->
              <div class="phone-panel">
                <label>전화번호</label>
                <input type="phone"
                       name="phone"
                       placeholder="'-'없이 입력해주세요."
                       [(ngModel)]="deliveryInfo.phone">
              </div>
              <!-- // phone-panel -->
  
              <!-- name-panel -->
              <div class="name-panel">
                <label>이름</label>
                <input type="text"
                       name="name"
                       placeholder="받으시는 분 성함을 입력해주세요."
                       [(ngModel)]="deliveryInfo.name">
              </div>
              <!-- // name-panel -->
  
            </div>
            <!-- // info-panel -->
  
            <!-- note-panel -->
            <div class="note-panel">
              <label>배송 시 요청사항</label>
              <textarea name="note"
                        [(ngModel)]="deliveryInfo.note"></textarea>
            </div>
            <!-- // note-panel -->
  
          </div>
        </div>
        <!-- // info-body-panel -->
    </div>

    <div class="payment-type-panel">

        <!-- type-header-panel -->
        <div class="type-header-panel">
  
          <!-- label -->
          <div class="label">
            <span>결제 방식</span>
          </div>
          <!-- // label -->
  
        </div>
        <!-- // type-header-panel -->
  
        <!-- type-body-panel -->
        <div class="type-body-panel">
          <div>
            <div class="card"
                 [ngClass]="{'selected': paymentType == 'card'}"
                 (click)="paymentType = 'card'">
              <div class="check">
                <i class="material-icons">check_circle</i>
              </div>
              <span>신용카드</span>
            </div>
            <div class="account"
                 [ngClass]="{'selected': paymentType == 'trans'}"
                 (click)="paymentType = 'trans'">
              <div class="check">
                <i class="material-icons">check_circle</i>
              </div>
              <span>실시간 계좌이체</span>
            </div>
            <div class="bank"
                 [ngClass]="{'selected': paymentType == 'bank'}"
                 (click)="paymentType = 'bank'">
              <div class="check">
                <i class="material-icons">check_circle</i>
              </div>
              <span>무통장 입금</span>
            </div>
          </div>
        </div>
        <!-- // type-body-panel -->
  
      </div>
      <!-- // payment-type-panel -->
  
      <!-- btn-panel -->
      <div class="btn-panel">
        <button class="btn-back"
                (click)="appService.goBack()">
          <span>취소하기</span>
        </button>
        <button class="btn-buyProduct"
                [disabled]="!isValid()"
                (click)="buyProduct()">
          <span>결제하기</span>
        </button>
        <!-- <button id="naver-pay" (click)="requestNaverPay()"> </button> -->
      </div>


  </div>
</div>`,
'pages/store/product-detail/product-detail' : `<!--
/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */
-->

<!-- jQuery -->
<script type="text/javascript" src="https://code.jquery.com/jquery-1.12.4.min.js" ></script>
<!-- iamport.payment.js -->
<script type="text/javascript" src="https://service.iamport.kr/js/iamport.payment-1.1.6.js"></script>
<!-- naverPayButton.js -->
<script type="text/javascript" src="http://test-pay.naver.com/customer/js/naverPayButton.js" charset="UTF-8"></script>
<script type="text/javascript" src="http://test-pay.naver.com/customer/js/mobile/naverPayButton.js" charset="UTF-8"></script>

 <!-- 네이버 공동유입스크립트 -->
<script type="text/javascript" src="http://wcs.naver.net/wcslog.js"></script>
<script type="text/javascript">
    if (!wcs_add) var wcs_add = {};
    wcs_add["wa"] = "s_52e6d95fb9a0";
    wcs.inflow("nosework-official.com");
    wcs_do();
</script>

<div class="product-detail-container">

  <!-- content-panel -->
  <div class="content-panel"
       *ngIf="product">

    <!-- buy-panel -->
    <div class="buy-panel">
      <!-- product-image-panel -->
      <div class="product-detail-total-panel">
          <div class="product-image-panel">
              <preload-image *ngIf = "product.productPhoto || product.productPhoto != undefined"
              [imageSource]="product.productPhoto.secure_url"
                             [ratio]="1"></preload-image>
          </div>
      </div>
      
      <!-- detail-panel -->
      <div class="detail-panel">

        <!-- info-panel -->
        <div class="info-panel">
          <h2>{{ product.name }}</h2>
          <p>
            <span [ngClass]="{'sale': product.salePrice}">
              {{ product.price | number}}
            </span>
            <span *ngIf="product.salePrice">
              {{product.salePrice | number}}
            </span>
            원
          </p>
        </div>

        <div class="selected-option1" md-button *ngIf = "enableAttribute == true"
        [mdMenuTriggerFor]="Attribute">
            <span>{{ attributeName == "" ? '- [필수] 상품종류를 선택해 주세요 -' : attributeName}}</span>
            <i class="material-icons">arrow_drop_down</i>
          </div>
          <!-- // selected-option -->

          <!-- option-list-panel -->
        <md-menu class="option-list-panel"
                  #Attribute
                  y-position="below">
          <p style="padding-top:20px;padding-left:10px;cursor: pointer;font-weight: bold;" (click) = "selectDefaultAtt()"> - [필수] 상품종류를 선택해 주세요 -</p>
          <p style="padding-top:20px;padding-left:10px;font-size: 25px;font-weight: bold;"><b> ------------------- </b></p>
          <div *ngFor="let eachAttribute of product.attribute; let attributeIndex = index;"
              (click)="selectAttribute(attributeIndex)">
              <p>{{ eachAttribute.name }} ----------- {{eachAttribute.price | number}} 원</p>
          </div>
        </md-menu>
        <!-- divider -->
        <div class="divider"></div>
        <!-- // divider -->

        <!--  -->
        <div class="selected-option" md-button *ngIf = "enableOption == true"
        [mdMenuTriggerFor]="propertyList">
            
            <span>{{ propertyName == "" ? '- [선택] 추가옵션을 선택해 주세요 -' : propertyName}}</span>
            <i class="material-icons">arrow_drop_down</i>
          </div>
          <!-- // selected-option -->

          <!-- option-list-panel -->
        <md-menu class="option-list-panel"
                  #propertyList
                  y-position="below">
          <p style="padding-top:20px;padding-left:10px;cursor: pointer;font-weight: bold;" (click) = "selectDefault()"> - [선택] 추가옵션을 선택해 주세요 -</p>
          <p style="padding-top:20px;padding-left:10px;font-size: 25px;font-weight: bold;"><b> ------------------- </b></p>
          <div *ngFor = "let eachOption of product.option ; let optionIndex= index;">
            <p style="font-size: 15px;font-weight: bold;">{{ eachOption.name }}</p>
            <div *ngFor="let property of eachOption.properties; let propertyIndex = index;"
                [ngClass]="{'sold-out': property.soldOut}"
                (click)="selectOption(optionIndex,propertyIndex)">
                <p>{{ property.name }} ----------- {{property.price | number}} 원</p>
            </div>
          </div>
        </md-menu>
        <!-- // option-list-panel -->

        <!--  -->
        <div class = "buy-form">
          <div class="buy-list"
            *ngFor = "let item of buyItem; let buyIndex = index;">
            <div class="buy-item">
              <div class="buy-name">
                  <p>{{item.name}}</p>
              </div>
              <div class="buy-price">
                  <p>{{item.price | number}} 원</p>
              </div>
              <div class="buy-delete">
                <p>
                    <i class="material-icons"  (click) = "optionClear(buyIndex)">delete_sweep</i>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-panel">
          <!-- product-note-empty -->
          <div class="product-not-empty"
               *ngIf="!product.soldOut">
            <button class="btn-buy"
                    (click)="buy()">
              <span>BUY NOW</span>
            </button>
            <button class="btn-addCart"
                    (click)="addCart()">
              <i class="material-icons">shopping_cart</i>
            </button>
          </div>
          <!-- // product-note-empty -->

          <div class="product-empty"
               *ngIf="product.soldOut">
            <span>품절</span>
          </div>

          <!-- // naverPay button create modified 0626 -->
          <!--<div id="naverPayBtn" style="margin-top: 20px" align="right" 
          (click)="NaverPayClicked()">-->
          <div id="naverPayBtn" style="margin-top: 20px" align="right">

        </div>

      </div>
      <!-- // detail-panel -->

    </div>

  </div>
    <!-- // buy-panel -->
    <div class="policy-panel">
      <div class="product-other-image" *ngIf = "product.detailFiles.length > 0">
        <div class="product-detail" *ngFor = "let detailItem of product.detailFiles; let detailIndex = index;">
          <div *ngIf="detailItem.onlyType == 'image'" class="image-detail-wrapper">
            <div *ngIf="detailItem.otherType == 'gif'" class="gif">
                <img src="{{detailItem.secure_url}}"/>
            </div>
            <div *ngIf="detailItem.otherType != 'gif'">
                <img src="{{detailItem.secure_url}}"/>
            </div>
          </div>
          <div *ngIf = "detailItem.onlyType == 'video'">
              <iframe type="text/html" 
              width="100%" 
              height="450" 
              [src]="detailItem.url | full_youtube_url" (iframeClick)="clicked()"
              *ngIf="detailItem.url.length > 0 && detailItem.url.indexOf('youtube') !== -1"
              frameborder="0" class="center-aligned">
              </iframe>
          </div>
        </div>
      </div>
    </div>

    <!-- policy-panel -->
    <div class="policy-panel">

      <!-- tab-panel -->
      <div class="tab-panel">
        <div [ngClass]="{'active': selectedTab == 'DELIVERY'}"
             (click)="selectedTab = 'DELIVERY'">
          <span>배송안내</span>
        </div>
        <div [ngClass]="{'active': selectedTab == 'EXCHANGE'}"
             (click)="selectedTab = 'EXCHANGE'">
          <span>교환/반품/환불</span>
        </div>
        <div [ngClass]="{'active': selectedTab == 'AS'}"
             (click)="selectedTab = 'AS'">
          <span>보증 및 AS 신청</span>
        </div>
        <div [ngClass]="{'active': selectedTab == 'CUSTOMER'}"
             (click)="selectedTab = 'CUSTOMER'">
          <span>고객센터</span>
        </div>
      </div>
      <!-- // tab-panel -->

      <!-- desc-panel -->
      <div class="desc-panel">
        <div *ngIf="selectedTab == 'DELIVERY'">
          <h2>배송안내</h2>
          <div>
            <p>오후 3시까지 입금 확인된 건에 한하여 당일 출고되며 예상 도착일은 영업일 기준 약 1~2일 후 입니다.</p>
            <p>제품 및 옵션 별 재고가 상이할 수 있으며 이러한 이유의 출고 지연은 예상 도착일을 벗어날 수 있습니다.</p>
            <p>주말 및 공휴일은 제품 출고가 이루어지지 않습니다.</p>
          </div>
        </div>
        <div *ngIf="selectedTab == 'EXCHANGE'">
          <h2>교환/반품/환불</h2>
          <div>
            <p>관련법상 상품에 하자가 없을 시 상품으로써 가치가 훼손된 경우(라벨지 개봉/제품 장착, 설치/제품구동/훼손 및 파손)에는 반품 및 환불처리가 불가능합니다.</p>
            <p>단순 변심으로 인한 교환 및 반품 시 배송 완료일을 기점으로 7일 이내 신청 가능하며 왕복택배비(5,000원)를 직접 결제 하셔야 합니다.</p>
            <p>환불은 보내주신 제품을 본사 수령 및 확인 후 주문 당시 결제 수단에 따라 약 2~3일 내 처리됩니다.</p>
            <p>교환 및 반품 시 제휴 택배회사인 CJ대한통운을 이용해 주시기 바랍니다.</p>
          </div>
        </div>
        <div *ngIf="selectedTab == 'AS'">
          <h2>보증 및 AS 신청</h2>
          <div>
            <p>㈜노즈워크를 통해 정식 수입 및 판매되는 모든 제품의 품질을 보증합니다. 만약 제품에 초기결함이 있다면 구입하신 판매점 또는 고객지원센터로 문의 해주세요.</p>
            <p>또한 구입하신 제품의 고유 시리얼번호로 제품등록을 해주시면 원활한 A/S를 받으실 수 있습니다. (이는 전자 구동이 탑재된 제품이 한함.)</p>
            <p>제품 별 품질 보증 기간 내 제품상의 하자로 인해 발생한 A/S는 무상 처리 됩니다. 하지만 품질 보증 기간 내 라도 사용자의 취급과실로 인한 파손 및 고장은</p>
            <p>유상 A/S가 진행됩니다. 제품 별 품질 보증 기간이 초과되는 제품에 대해서는 유상 A/S가 진행되며 비용은 제품 접수 후 책정되어 부과됩니다.</p>
            <p>소모품의 경우 품질 보증 기간이 없습니다. A/S 신청은 홈페이지 내 신청서 작성 또는 고객지원센터를 통해 접수 가능합니다. 기간은 고장 및 파손 정도에 따라</p>
            <p>차이가 있으며 수리 완료 후 다시 발송하기까지 약 4~5일 정도 소요됩니다. 부품 파트 별 재고가 상이할 수 있으며 이러한 이유의 출고 지연은 예상 도착일을</p>
            <p>벗어날 수 있습니다. 주말 및 공휴일은 제품 출고가 이루어지지 않습니다. ㈜노즈워크를 통해 정식 수입 및 판매되는 모든 제품(해외 전용 모델 제외)은</p>
            <p>한국 전용 모델입니다. 기타 국가에서의 사용은 고장의 원인이 되며 보증 및 수리 또한 불가합니다.</p>
          </div>
        </div>
        <div class="custom"
             *ngIf="selectedTab == 'CUSTOMER'">
          <h2>고객센터</h2>
          <div>
            <p>㈜노즈워크 고객지원센터: 1600-2190</p>
            <p>상담시간: 평일 09:30~17:30 / 점심시간 12:00~13:00 (주말 및 공휴일 휴무)</p>
            <p>본사_ NOSEWORK INC. 4241 Topsail Ct, Soquel, CA 95073</p>
            <p>한국 지사_ 충청남도 천안시 서북구 직산읍 직산로 136, 616호</p>
          </div>
        </div>
      </div>
      <!-- // desc-panel -->

    </div>
    <!-- // policy-panel -->

  </div>
  <!-- // content-panel -->

</div>`,
'pages/store/setting-account/setting-account' : `<!--
/**
 * Created by PHILIP on 11/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 11/07/2017
 *
 */
-->
 <!-- 네이버 공동유입스크립트 -->
<script type="text/javascript" src="http://wcs.naver.net/wcslog.js"></script>
<script type="text/javascript">
    if (!wcs_add) var wcs_add = {};
    wcs_add["wa"] = "s_52e6d95fb9a0";
    wcs.inflow("nosework-official.com");
    wcs_do();
</script>

<div class="setting-account-container">

  <!-- label-panel -->
  <div class="label-panel">
    <h2>계정 설정</h2>
  </div>
  <!-- // label-panel -->

  <!-- form-panel -->
  <div class="form-panel">

    <div>

      <!-- user-info-panel -->
      <div class="user-info-panel">
        <h2>회원정보 수정</h2>
        <form>

          <div class="input-panel">
            <label>이메일 주소</label>
            <input type="email"
                   readonly
                   name="identifier"
                   maxlength="256"
                   [(ngModel)]="user.identifier">
          </div>

          <div class="input-panel">
            <label>닉네임</label>
            <input type="text"
                   name="nickname"
                   minlength="2"
                   maxlength="8"
                   [(ngModel)]="user.nickname">
            <p class="validation-text">2자 ~ 8자 이내</p>
          </div>

          <div class="btn-panel">
            <button class="btn-changeNickname"
                    type="submit"
                    [disabled]="!isValid('nickname')"
                    (click)="changeNickname()">
              <span>변경하기</span>
            </button>
          </div>

        </form>
      </div>
      <!-- // user-info-panel -->

      <!-- change-password-panel -->
      <div class="change-password-panel">
        <h2>비밀번호 변경</h2>
        <form>

          <div class="input-panel">
            <label>현재 비밀번호</label>
            <input type="password"
                   name="currentPassword"
                   minlength="8"
                   maxlength="12"
                   [(ngModel)]="currentPassword">
          </div>

          <div class="input-panel">
            <label>새 비밀번호</label>
            <input type="password"
                   name="password"
                   minlength="8"
                   maxlength="12"
                   [(ngModel)]="password">
            <p class="validation-text">영문, 숫자, 특수문자 포함 8자 ~ 12자 이내</p>
          </div>

          <div class="input-panel">
            <label>새 비밀번호 확인</label>
            <input type="password"
                   name="passwordConfirm"
                   minlength="8"
                   maxlength="12"
                   [(ngModel)]="passwordConfirm">
          </div>

          <div class="btn-panel">
            <button class="btn-changePassword"
                    type="submit"
                    [disabled]="!isValid('password')"
                    (click)="changePassword()">
              <span>변경하기</span>
            </button>
          </div>

        </form>
      </div>
      <!-- // change-password-panel -->

    </div>

    <div>

      <!-- address-panel -->
      <div class="address-panel">
        <h2>배송지 정보</h2>
        <form>

          <div class="input-panel">
            <label>주소</label>
            <div>
              <input type="text"
                     name="address"
                     readonly
                     placeholder="검색 주소"
                     [(ngModel)]="address">
              <button class="btn-findAddress"
                      type="button"
                      (click)="openDaumMapForm()">
                <span>주소 찾기</span>
              </button>
            </div>
            <input type="text"
                   name="detailAddress"
                   placeholder="상세 주소"
                   [(ngModel)]="deliveryInfo.addressInfo.detailAddress">
          </div>

          <div class="input-panel">
            <label>전화번호</label>
            <input type="phone"
                   name="phone"
                   placeholder="'-'없이 입력해주세요."
                   [(ngModel)]="deliveryInfo.phone">
          </div>

          <div class="input-panel">
            <label>이름</label>
            <input type="text"
                   name="name"
                   placeholder="받으시는 분 성함을 입력해주세요."
                   [(ngModel)]="deliveryInfo.name">
          </div>

          <div class="btn-panel">
            <button class="btn-changeAddress"
                    type="submit"
                    [disabled]="!isValid('address')"
                    (click)="changeAddress()">
              <span>변경하기</span>
            </button>
          </div>

        </form>
      </div>
      <!-- // address-panel -->

    </div>

  </div>
  <!-- // form-panel -->

</div>`,
'pages/store/store-home/store-home' : `<!--
/**
 * Created by PHILIP on 27/06/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 27/06/2017
 *
 */
-->
 <!-- 네이버 공동유입스크립트 -->
<script type="text/javascript" src="http://wcs.naver.net/wcslog.js"></script>
<script type="text/javascript">
    if (!wcs_add) var wcs_add = {};
    wcs_add["wa"] = "s_52e6d95fb9a0";
    wcs.inflow("nosework-official.com");
    wcs_do();
</script>

<div class="store-home-container">
  <!-- banner-panel -->
  <div class="banner-panel"
       *ngIf="bannerModel.banners.length > 0">
    <swiper-container #bannerSlider
                      [options]="swiperOptions">
      <swiper-slide *ngFor="let banner of bannerModel.banners"
                    (click)="appService.openWeb(banner.url)">
        <preload-image [imageSource]="banner.photo.secure_url" class="storehome-preload-image"
                       [ratio]="0.5" *ngIf="banner.fileTypes == 'image'"></preload-image>
        <div class="video-panel" *ngIf="banner.fileTypes == 'video'">
          <youtube-player
            [videoId]="banner.url | youtube_video_id" [width]="700" [height]="300"
            (ready)="savePlayer($event)"
            (change)="onStateChange($event)"></youtube-player>
        </div>
      </swiper-slide>
    </swiper-container>

    <!-- btn-prev -->
    
    <!-- // btn-next -->

  </div>
  <!-- // banner-panel -->

  <!-- product-list-panel -->
  <div class="product-list-panel"
       *ngIf="productModel.products.length > 0">

    <!-- product-panel -->
    <div class="product-panel"
         *ngFor="let product of productModel.products"
         (click)="appService.goToMenu('store/product-detail/', product.name)">

      <!-- image-panel -->
      <div class="image-panel">
        <preload-image
         *ngIf="product.productPhoto"
         [imageSource]="product.productPhoto.url"
                       [ratio]="1"></preload-image>
      </div>
      <!-- // image-panel -->

      <!-- info-panel -->
      <div class="info-panel">
        <h2 class="name">{{ product.name }}</h2>
        <p class="summary">{{ product.summary }}</p>
        <p class="price"
           [ngClass]="{'sale': product.salePrice}">
          {{ product.price | number }}
        </p>
        <p class="salePrice" 
           *ngIf="product.salePrice">
          {{ product.salePrice | number}}
        </p>
        <p class="salePrice" style="visibility : hidden"
           *ngIf="product.salePrice == null">
          {{ "1111111" }}
        </p>
      </div>
      <!-- // info-panel -->

    </div>
    <!-- // product-panel -->

  </div>
  <!-- // product-list-panel -->

</div>`,
'shared/components/daum-map/daum-map' : `<!-- 
/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */
  -->

<div class="daum-map-container">

  <!-- form-panel -->
  <div class="form-panel">

    <!-- label-panel -->
    <div class="label-panel">
      <h2>주소 찾기</h2>

      <!-- btn-close -->
      <div class="btn-close"
           (click)="dismiss()">
        <i class="material-icons">close</i>
      </div>
      <!-- // btn-close -->

    </div>
    <!-- // label-panel -->

    <!-- content-panel -->
    <div class="content-panel">

      <h3>지명 또는 주소로 검색해주세요.</h3>

      <!-- search-panel -->
      <div class="search-panel">
        <form>

          <div class="input-panel">
            <md-input-container>
              <input mdInput
                     type="text"
                     name="searchKeyword"
                     placeholder="지명 또는 주소를 입력"
                     autocomplete="off"
                     [ngModel]="searchKeyword"
                     (ngModelChange)="searchAddress($event)">
            </md-input-container>
          </div>

        </form>

        <!-- search-result-panel -->
        <div class="search-result-panel"
             *ngIf="searchResults.length != 0">

          <!-- item-panel -->
          <div class="item-panel"
               *ngFor="let place of searchResults"
               (click)="setPlace(place)">
            <i class="material-icons">location_on</i>
            <span>{{place.address}} ({{place.title}})</span>
          </div>
          <!-- // item-panel -->

        </div>
        <!-- // search-result-panel -->

      </div>
      <!-- // search-panel -->

    </div>
    <!-- // content-panel -->

    <!-- btn-panel -->
    <div class="btn-panel">
      <button class="btn-confirm"
              (click)="dismiss()">
        <span>확인</span>
      </button>
    </div>
    <!-- // btn-panel -->

  </div>
  <!-- // form-panel -->

</div>`,
'shared/components/dialog-message/dialog-message.component' : `<!--
 * Created by Yoon Yong (Andy) Shin on 23/12/2016
 * As part of Nosework
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & Yoon Yong (Andy) Shin - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Yoon Yong (Andy) Shin <andy.shin@applicat.co.kr>, 23/12/2016
 *
-->

<div class="dialog-message-header">
  <span>{{title}}</span>
</div>
<div class="dialog-message-content">
  {{message}}
</div>
<div class="dialog-message-footer">
  <button class="btn-cancel"
          *ngIf="type === 'confirm'"
          (click)="cancel()">
    취소
  </button>
  <button class="btn-confirm"
          (click)="confirm()">
    확인
  </button>
</div>`,
'shared/components/loading/loading.component' : `<!--
/**
 * Created by PHILIP on 11/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 11/07/2017
 *
 */
-->

<div id="loading"
     *ngIf="loadingState != 'hide'">
  <div class="content">
    <img [src]="loadingImg">
  </div>
</div>`,
'shared/components/login-confirm/login-confirm.component' : `<!--
 * Created by Yoon Yong (Andy) Shin on 23/12/2016
 * As part of Nosework
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & Yoon Yong (Andy) Shin - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Yoon Yong (Andy) Shin <andy.shin@applicat.co.kr>, 23/12/2016
 *
-->

<div class="dialog-message-header">
  <span>{{title}}</span>
</div>
<div class="dialog-message-content">
  <p style="font-size: 18px;">로그인후 이용가능합니다.</p><br>
  <p style="font-size: 18px;">로그인 하시겠습니까?</p>
</div>
<div class="dialog-message-footer">
  <button class="btn-cancel"
          *ngIf="type === 'confirm'"
          (click)="login()">
    로그인
  </button>
  <button class="btn-confirm"
          (click)="go()">
    비회원구매
  </button>
</div>`,
'pages/login/register/policy/policy' : `<!--
/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */
-->

<div class="policy-container">

  <!-- label-panel -->
  <div class="label-panel">
    <h2 *ngIf="type == 'terms'">이용약관</h2>
    <h2 *ngIf="type == 'privacy'">개인정보 처리방침</h2>
    <h2 *ngIf="type == 'legal'">법적고지</h2>

    <!-- btn-close -->
    <div class="btn-close"
         (click)="dismiss()">
      <i class="material-icons">close</i>
    </div>
    <!-- // btn-close -->

  </div>
  <!-- // label-panel -->

  <!-- content-panel -->
  <div class="content-panel">

    <!-- 이용약관 -->
    <div *ngIf="type == 'terms'"><span>제 1조(목적)</span>

      본 약관은 주식회사 노즈워크(이하 "회사"라 한다) 가 운영하는 인터넷 관련 서비스(이하 "서비스"라 한다)를 이용함에 있어 당사 홈페이지와 이용자의 권리·의무 및 책임사항을 규정함을 목적으로 합니다.

      ※ 「PC통신 등을 이용하는 전자거래에 대해서도 그 성질에 반하지 않는한 이 약관을 준용합니다」

      <span>제 2조(정의)</span>

      1 'NOSEWORK(노즈워크) 홈페이지'란 (주)노즈워크가 재화 또는 용역을 이용자에게 제공하기 위하여 컴퓨터등 정보통신설비를 이용하여 재화 또는 용역을 거래할 수 있도록 설정한 가상의 영업장을 말 하며, 아울러 사이버몰을 운영하는 사업자의 의미로도 사용합니다.

      2 '이용자'란 'NOSEWORK(노즈워크) 홈페이지'에 접속하여 이 약관에 따라 'NOSEWORK(노즈워크) 홈페이 지'가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.

      3 '회원'이라 함은 'NOSEWORK(노즈워크) 홈페이지'에 개인정보를 제공하여 회원등록을 한 자로서, 'NOSEWORK(노즈워크) 홈페이지'의 정보를 지속적으로 제공받으며, 'NOSEWORK(노즈워크) 홈페이지'가 제 공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.

      <span>제 3조(약관 등의 명시와 설명 및 개정)</span>

      1 'NOSEWORK(노즈워크) 홈페이지'는 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호·모사전송번호·전자우편주소, 사업자등록번호, 통신판매업신고번호, 개인정보관리책임자 등을 이용자가 쉽게 알 수 있도록 'NOSEWORK(노즈워크) 홈페이지'의 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.

      2 'NOSEWORK(노즈워크) 홈페이지'는 이용자가 약관에 동의하기에 앞서 약관에 정해져 있는 내용 중 청약 철회·배송책임·환불조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다.

      3 'NOSEWORK(노즈워크) 홈페이지'는 전자상거래등에서의소비자보호에관한법률, 약관의규제에관한법률, 전자거래기본법, 전자서명법, 정보통신망이용촉진등에관한법률, 방문판매등에관한법률, 소비자보호법 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.

      4 'NOSEWORK(노즈워크) 홈페이지'가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관 과 함께 홈페이지의 초기화면에 그 적용일자 7일이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우'NOSEWORK(노즈워크) 홈페이지'는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도 록 표시합니다.

      5 'NOSEWORK(노즈워크) 홈페이지'가 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정전의약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에'NOSEWORK(노즈워크) 홈페이지'에 송신하여 'NOSEWORK(노즈워크) 홈페이지'의 동의를 받은 경우에 는 개정약관 조항이 적용됩니다.

      6 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래등에서의소비자보호에 관한 법 률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자보호지침 및 관계법령 또는 상관례에 따릅니다.

      <span>제 4조(서비스의 제공 및 변경)</span>

      1 'NOSEWORK(노즈워크) 홈페이지'는 다음과 같은 업무를 수행합니다.

        1. 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결

        2. 구매계약이 체결된 재화 또는 용역의 배송

        3. 기타 'NOSEWORK(노즈워크) 홈페이지'가 정하는 업무

      2 'NOSEWORK(노즈워크) 홈페이지'는 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및 제공일자를 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.

      3 'NOSEWORK(노즈워크) 홈페이지'가 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 주소로 즉시 통지 합니다.

      4 전항의 경우 'NOSEWORK(노즈워크) 홈페이지'는 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, 'NOSEWORK(노즈워크) 홈페이지'가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.

      <span>제 5조(서비스의 중단)</span>

      1 'NOSEWORK(노즈워크) 홈페이지'는 컴퓨터 등 정보통신설비의보수점검·교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.

      2 'NOSEWORK(노즈워크) 홈페이지'는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용 자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, 'NOSEWORK(노즈워크) 홈페이지'가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.

      3 사업종목의 전환,사업의 포기,업체간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 'NOSEWORK(노즈워크) 홈페이지'는 제8조에 정한 방법으로 이용자에게 통지하고 당초 'NOSEWORK(노즈워 크) 홈페이지'에서 제시한 조건에 따라 소비자에게 보상합니다. 다만, 'NOSEWORK(노즈워크) 홈페이지'가보상 기준 등을 고지하지 아니한 경우에는 이용자들의 마일리지 또는 적립금 등을 'NOSEWORK(노즈워크) 홈페이 지'에서 통용되는 통화가치에 상응하는 현물 또는 현금으로 이용자에게 지급합니다.

      <span>제 6조(회원가입)</span>

      1 이용자는 'NOSEWORK(노즈워크) 홈페이지'가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에동의한다는 의사표시를 함으로써 회원가입을 신청합니다.

      2 'NOSEWORK(노즈워크) 홈페이지'는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각호에해당하지 않는 한 회원으로 등록합니다.

        1. 가입신청자가 이 약관 제7조제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제7조제3항 에 의한 회원자격상실후3년이 경과한 자로서 'NOSEWORK(노즈워크) 홈페이지'의 회원재가입 승낙을 얻은 경 우에는 예외로 한다.

        2. 등록 내용에 허위,기재누락,오기가 있는 경우

        3. 기타 회원으로 등록하는 것이 'NOSEWORK(노즈워크) 홈페이지'의 기술상 현저히 지장이 있다고 판단되는 경우

      3 회원가입계약의성립시기는'NOSEWORK(노즈워크) 홈페이지'의 승낙이 회원에게 도달한 시점으로 합니다.

      4 회원은 제15조제1항에 의한 등록사항에 변경이 있는 경우, 즉시 전자우편 기타 방법으로 'NOSEWORK(노즈워크) 홈페이지'에 대하여 그 변경사항을 알려야 합니다.

      <span>제 7조(회원 탈퇴 및 자격 상실 등)</span>

      1 회원은 'NOSEWORK(노즈워크) 홈페이지'에 언제든지 탈퇴를 요청할 수 있으며 'NOSEWORK(노즈워크) 홈페이지'는 즉시 회원탈퇴를 처리합니다.

      2 회원이 다음 각호의 사유에 해당하는 경우, 'NOSEWORK(노즈워크) 홈페이지'는 회원자격을 제한 및 정지 시킬 수 있습니다.

        1. 가입 신청시에 허위 내용을 등록한 경우

        2. 'NOSEWORK(노즈워크) 홈페이지'를 이용하여 구입한 재화등의 대금, 기타 'NOSEWORK(노즈워크) 홈페 이지' 이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우

        3. 다른 사람의 'NOSEWORK(노즈워크) 홈페이지' 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질 서를 위협하는 경우

        4. 'NOSEWORK(노즈워크) 홈페이지'를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우

      3 'NOSEWORK(노즈워크) 홈페이지'가 회원 자격을 제한·정지 시킨후, 동일한 행위가 2회이상 반복되거나 30 일이내에 그 사유가 시정되지 아니하는 경우 'NOSEWORK(노즈워크) 홈페이지'는회원자격을 상실시킬 수 있 습니다.

      4 'NOSEWORK(노즈워크) 홈페이지'가회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원 에게 이를 통지하고, 회원등록 말소전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.

      <span>제 8조(회원에 대한 통지)</span>

      1 'NOSEWORK(노즈워크) 홈페이지'가 회원에 대한 통지를 하는 경우, 회원이 'NOSEWORK(노즈워크) 홈페이지'와 미리 약정하여 지정한 전자우편 주소로 할 수 있습니다.

      2 'NOSEWORK(노즈워크) 홈페이지'는 불특정다수 회원에 대한 통지의 경우 1주일이상 'NOSEWORK(노즈워크) 홈페이지' 게시판에 게시함으로써 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.

      <span>제 9조(구매신청)</span>

      'NOSEWORK(노즈워크) 홈페이지' 이용자는 'NOSEWORK(노즈워크) 홈페이지' 상에서 다음 또는 이와 유사 한 방법에 의하여 구매를 신청하며, 'NOSEWORK(노즈워크) 홈페이지'는 이용자가 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.단,회원인 경우 제2호 내지 제4호의 적용을 제외할 수 있 습니다.

        1. 재화등의 검색 및 선택

        2. 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력

        3. 약관내용, 청약철회권이 제한되는 서비스, 배송료·설치비 등의 비용부담과 관련한 내용에 대한 확인

        4. 이 약관에 동의하고 위 3.호의 사항을 확인하거나 거부하는 표시(예, 마우스 클릭)

        5. 재화등의 구매신청 및 이에 관한 확인 또는"몰"의 확인에 대한 동의

        6. 결제방법의 선택

      <span>제 10조(계약의 성립)</span>

      1 'NOSEWORK(노즈워크) 홈페이지'는 제9조와 같은 구매신청에 대하여 다음 각호에 해당하면 승낙하지 않 을 수 있습니다. 다만, 미성년자와 계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.

        1. 신청 내용에 허위, 기재누락, 오기가 있는 경우

        2. 미성년자가 담배, 주류 등 청소년보호법에서 금지하는 재화 및 용역을 구매하는 경우

        3. 기타 구매신청에 승낙하는 것이 'NOSEWORK(노즈워크) 홈페이지' 기술상 현저히 지장이 있다고 판단하는 경우

      2 'NOSEWORK(노즈워크) 홈페이지'의 승낙이 제12조제1항의 수신확인통지형태로 이용자에게 도달한 시점 에 계약이 성립한 것으로 봅니다.

      3 'NOSEWORK(노즈워크) 홈페이지'의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및 판매가능 여부, 구매신청의 정정 취소등에 관한 정보 등을 포함하여야 합니다.

      <span>제 11조(지급방법)</span>

      'NOSEWORK(노즈워크) 홈페이지'에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음 각호의 방법 중 가용한 방법으로 할 수 있습니다. 단, 'NOSEWORK(노즈워크) 홈페이지'는 이용자의 지급방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도 추가하여 징수할 수 없습니다.

        1. 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체

        2. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제

        3. 온라인 무통장입금

        4. 전자화폐에의한 결제

        5. 수령시 대금지급

        6. 마일리지 등 'NOSEWORK(노즈워크) 홈페이지'가 지급한 포인트에 의한 결제

        7. 'NOSEWORK(노즈워크) 홈페이지'와 계약을 맺었거나 'NOSEWORK(노즈워크) 홈페이지'가 인정한 상품권 에 의한 결제

        8. 기타 전자적 지급 방법에 의한 대금 지급 등

      <span>제 12조(수신확인통지 · 구매신청 변경 및 취소)</span>

      1 'NOSEWORK(노즈워크) 홈페이지'는 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 합니다.

      2 수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매 신청 변경 및 취소를 요청할 수 있고 'NOSEWORK(노즈워크) 홈페이지'는 배송 전에 이용자의 요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만 이미 대금을 지불한 경우에는 제15조의 청약철 회 등에 관한 규정에 따릅니다.

      <span>제 13조(재화등의 공급)</span>

      1 'NOSEWORK(노즈워크) 홈페이지'는 이용자와 재화 등의 공급시기에 관하여 별도의 약정이 없는 이상, 이 용자가 청약을 한 날부터7일 이내에 재화 등을 배송할 수 있도록 주문제작,포장 등 기타의 필요한 조치를 취합니다. 다만, 'NOSEWORK(노즈워크) 홈페이지'가 이미 재화 등의 대금의 전부 또는 일부를 받은 경우에는 대금의 전부 또는 일부를 받은 날부터 2영업일 이내에 조치를 취합니다. 이때 'NOSEWORK(노즈워크) 홈페 이지'는 이용자가 재화 등의 공급 절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.

      2 'NOSEWORK(노즈워크) 홈페이지'는 이용자가 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자, 수 단별 배송기간 등을 명시합니다. 만약 'NOSEWORK(노즈워크) 홈페이지'가 약정 배송기간을 초과한 경우에는 그로 인한 이용자의 손해를 배상하여야 합니다. 다만 'NOSEWORK(노즈워크) 홈페이지'가 고의 · 과실이 없음 을 입증한 경우에는 그러하지 아니합니다.

      <span>제 14조(환급)</span>

      'NOSEWORK(노즈워크) 홈페이지'는 이용자가 구매신청한 재화 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체 없이 그 사유를 이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터 2영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.

      <span>제 15조(청약철회 등)</span>

      1 'NOSEWORK(노즈워크) 홈페이지'와 재화 등의 구매에 관한 계약을 체결한 이용자는 수신확인의 통지를받은 날부터 7일 이내에는 청약의 철회를 할 수 있습니다.

      2 이용자는 재화 등을 배송받은 경우 다음 각호의 1에 해당하는 경우에는 반품 및 교환을 할 수 없습니다.

        1.이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우(다만,재화 등의 내용을 확인하기 위하여 포장 등을 훼손한 경우에는 청약철회를 할 수 있습니다)

        2.이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한 경우

        3. 시간의 경과에 의하여 재판매가 곤란할 정도로 재화 등의 가치가 현저히 감소한 경우

        4. 같은 성능을 지닌 재화 등으로 복제가 가능한 경우 그 원본인 재화 등의 포장을 훼손한 경우

      3 제2항 제2호 내지 제4호의 경우에 'NOSEWORK(노즈워크) 홈페이지'가 사전에 청약철회 등이 제한되는 사실을 소비자가 쉽게 알 수 있는 곳에 명기하거나 시용상품을 제공하는 등의 조치를 하지 않았다면 이용자 의 청약철회 등이 제한되지 않습니다.

      4 이용자는 제1항 및 제2항의 규정에 불구하고 재화 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해 재화 등을 공급받은 날부터 3월 이내, 그 사실을 안 날 또는 알 수 있었던 날부 터 30일 이내에 청약철회 등을 할 수 있습니다.

      <span>제 16조(청약철회 등의 효과)</span>

      1 'NOSEWORK(노즈워크) 홈페이지'는 이용자로부터 재화 등을 반환받은 경우 3영업일 이내에 이미 지급받 은 재화 등의 대금을 환급합니다. 이 경우 'NOSEWORK(노즈워크) 홈페이지'가 이용자에게 재화 등의 환급을 지연한 때에는 그 지연기간에 대하여 공정거래위원회가 정하여 고시하는 지연이자율을 곱하여 산정한 지연이 자를 지급합니다.

      2 'NOSEWORK(노즈워크) 홈페이지'는 위 대금을 환급함에 있어서 이용자가 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 재화 등 의 대금의 청구를 정지 또는 취소하도록 요청합니다.

      3 청약철회 등의 경우 공급받은 재화 등의 반환에 필요한 비용은 이용자가 부담합니다. 'NOSEWORK(노즈 워크) 홈페이지'는 이용자에게 청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 재화 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 재화 등의 반 환에 필요한 비용은'NOSEWORK(노즈워크) 홈페이지'가 부담합니다.

      4 이용자가 재화 등을 제공받을 때 발송비를 부담한 경우에 'NOSEWORK(노즈워크) 홈페이지'는 청약철회 시 그 비용을 누가 부담하는지를 이용자가 알기 쉽도록 명확하게 표시합니다.

      <span>제 17조 (복수 계정의 참여 제한)</span>

      1 'NOSEWORK(노즈워크) 홈페이지'는 이벤트 등 필요한 경우 원활한 서비스 제공을 위하여 회원이 보유하 는 복수의 'NOSEWORK(노즈워크) 홈페이지' 계정 중 하나만을 이용하여 서비스를 이용하도록 이용범위를 제 한할 수 있습니다.

      2 전항의 경우 'NOSEWORK(노즈워크) 홈페이지'는 회원의 'NOSEWORK(노즈워크) 홈페이지' 계정 의 이용 내역을 확인하기 위하여 회원의 본인확인결과값을 활용할 수 있습니다.

      <span>제 18조(개인정보보호)</span>

      1 'NOSEWORK(노즈워크) 홈페이지'는 이용자의 정보수집 시 구매계약 이행에 필요한 최소한의 정보를 수집합니다. 다음 사항을 필수사항으로 하며 그 외 사항은 선택사항으로 합니다.

        1. 성명

        2. 주소

        3. 전화번호

        4. 희망ID(회원의 경우)

        5. 비밀번호(회원의 경우)

        6. 전자우편주소(또는 이동전화번호)

      2 'NOSEWORK(노즈워크) 홈페이지'가 이용자의 개인식별이 가능한 개인정보를 수집하는 때에는 반드시 당 해 이용자의 동의를 받습니다.

      3 제공된 개인정보는 당해 이용자의 동의 없이 목적외의 이용이나 제3자에게 제공할 수 없으며, 이에 대한 모든 책임은 'NOSEWORK(노즈워크) 홈페이지'가 집니다. 다만, 다음의 경우에는 예외로 합니다.

        1. 배송업무상 배송업체에게 배송에 필요한 최소한의 이용자의 정보(성명, 주소, 전화번호)를 알려주는 경우

        2. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우

        3. 재화 등의 거래에 따른 대금정산을 위하여 필요한 경우

        4. 도용방지를 위하여 본인확인에 필요한 경우

        5.법률의 규정 또는 법률에 의하여 필요한 불가피한 사유가 있는 경우

      4 'NOSEWORK(노즈워크) 홈페이지'가 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인 정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공관련사항(제공받은자, 제공목적 및 제공할 정보의 내용) 등 정보통신망이용촉진등에관한법률 제22조제 2항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.

      5 이용자는 언제든지 'NOSEWORK(노즈워크) 홈페이지'가 가지고 있는 자신의 개인정보에 대해 열람 및 오 류정정을 요구할 수 있으며 'NOSEWORK(노즈워크) 홈페이지'는 이에 대해 지체없이 필요한 조치를 취할 의 무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 'NOSEWORK(노즈워크) 홈페이지'는 그 오류를 정정 할 때까지 당해 개인정보를 이용하지 않습니다.

      6 'NOSEWORK(노즈워크) 홈페이지'는 개인정보 보호를 위하여 관리자를 한정하여 그 수를 최소화하며 신용 카드, 은행계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 변조 등으로 인한 이용자의 손해에 대하 여 모든 책임을 집니다.

      7 'NOSEWORK(노즈워크) 홈페이지' 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.

      <span>제 19조('NOSEWORK(노즈워크) 홈페이지'의 의 무)</span>

      1 'NOSEWORK(노즈워크) 홈페이지'는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화·용역을 제공하는데 최선을 다하여야 합니다.

      2 'NOSEWORK(노즈워크) 홈페이지'는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인 정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.

      3 'NOSEWORK(노즈워크) 홈페이지'가 상품이나 용역에 대하여 「표시 · 광고의공정화에관한법률」 제3조 소정 의 부당한 표시 · 광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.

      4 'NOSEWORK(노즈워크) 홈페이지'는 이용자가 원하지 않는 영리목적의광고성전자우편을 발송하지 않습니다.

      <span>제 20조(회원의 ID 및 비밀번호에 대한 의무)</span>

      1 제9조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.

      2 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.

      3 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 'NOSEWORK(노즈워크) 홈페이지'에 통보하고 'NOSEWORK(노즈워크) 홈페이지'의 안내가 있는 경우에는 그 에 따라야 합니다.

      <span>제21조(이용자의 의무)</span>

      이용자는 다음 행위를 하여 서는 안됩니다.

        1. 신청 또는 변경시 허위 내용의 등록

        2. 타인의 정보 도용

        3. 'NOSEWORK(노즈워크) 홈페이지'에 게시된 정보의 변경

        4. 'NOSEWORK(노즈워크) 홈페이지'가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시

        5. 'NOSEWORK(노즈워크) 홈페이지' 기타 제3자의 저작권 등 지적재산권에 대한 침해

        6. 'NOSEWORK(노즈워크) 홈페이지' 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위

        7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 'NOSEWORK(노즈워크) 홈페이지 '에 공개 또는 게시하는 행위.

      <span>제 22조(연결'NOSEWORK(노즈워크) 홈페이지'와 피연결'NOSEWORK(노즈워크) 홈페이지' 간의 관계)</span>

      1 'NOSEWORK(노즈워크) 홈페이지' 와 하위 웹사이트가 하이퍼 링크(예: 하이퍼 링크의 대상에는 문자, 그 림 및 동영상 등이 포함됨)방식 등으로 연결된 경우, 전자를 연결 웹사이트라고 하고 후자를 웹사이트라고 합 니다.

      2 'NOSEWORK(노즈워크) 홈페이지' 는 피연결 웹사이트가 독자적으로 제공하는 재화 등에 의해 이용자와 행하는 거래에 대해서는 그 거래에 대한 보증책임을 지지 않는다는 내용을 'NOSEWORK(노즈워크) 홈페이지' 의 초기화면 또는 연결되는 시점의 팝업화면으로 명시한 경우에는 그 거래에 대한 보증책임을 지지 않습니다.

      <span>제 23조 (저작권의 귀속 및 이용 제한)</span>

      1 'NOSEWORK(노즈워크) 홈페이지'가 작성한 저작물에 대한 저작권 기타 지식재산권은 'NOSEWORK(노즈 워크) 홈페이지'에 귀속됩니다.

      2 이용자는 'NOSEWORK(노즈워크) 홈페이지'를 이용함으로써 얻는 정보 중 'NOSEWORK(노즈워크) 홈페 이지'에게 지식재산권이 귀속된 정보를 'NOSEWORK(노즈워크) 홈페이지'의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리 목적으로 이용하거나 제 3자에게 이용하게 하여서는 안됩니다.

      3 'NOSEWORK(노즈워크) 홈페이지'는 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자 에게 통보합니다.

      <span>제 24조 (분쟁 해결)</span>

      1 'NOSEWORK(노즈워크) 홈페이지' 는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치 · 운영합니다.

      2 'NOSEWORK(노즈워크) 홈페이지' 는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.

      3 'NOSEWORK(노즈워크) 홈페이지' 와 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시 · 도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.

      <span>제 25조 (재판권 및 준거법)</span>

      1 'NOSEWORK(노즈워크) 홈페이지'와 이용자 간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 이 용자의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당 시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니 다.

      2 'NOSEWORK(노즈워크) 홈페이지'와 이용자 간에 제기된 전자상거래 소송에는 대한민국법을 적용합니다.
    </div>
    <!-- // 이용약관 -->

    <!-- 개인정보 처리방침 -->
    <div *ngIf="type == 'privacy'"><span>총칙</span>

      1 개인정보란 생존하는 개인에 관한 정보로서 당해 정보에 포함되어 있는 성명, 전화번호 등의 사항에 의하 여 당해 개인을 식별할 수 있는 정보(당해 정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와 용이하 게 결합하여 식별할 수 있는 것을 포함합니다)를 말합니다.

      2 (주)노즈워크(이하 "회사")는 귀하의 개인정보보호를 매우 중요시하며, 『정보통신망이용촉진및정보보호에관한 법률』상의 개인정보보호규정 및 정보통신부가 제정한 『개인정보보호지침』을 준수하고 있습니다. 회사는 개인정 보취급방침을 통하여 귀하께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호 를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.

      3 회사는개인정보취급방침을 홈페이지 첫 화면에 공개함으로써 귀하께서 언제나 용이하게 보실 수 있도록 조 치하고 있습니다.

      4 회사는개인정보취급방침의 지속적인 개선을 위하여 개인정보취급방침을 개정하는데 필요한 절차를 정하고 있습니다. 그리고 개인정보취급방침을 개정하는 경우 버전번호 등을 부여하여 개정된 사항을 귀하께서 쉽게 알아볼 수 있도록 하고 있습니다.

      <span>개인정보의 수집범위</span>

      회사는 별도의 회원가입 절차 없이 대부분의 컨텐츠에 자유롭게 접근할 수 있습니다. 회사의 회원제 서비스를 이용하시고자 할 경우 다음의 정보를 입력해주셔야 하며 선택항목을 입력하시지 않았다 하여 서비스 이용에 제한은 없습니다.

      1) 회원 가입시 수집하는 개인정보의 범위

        - 필수항목 : 이메일 주소 형식의ID, 닉네임, 비밀번호

        - 선택항목 : 배송지 주소, 이름, 전화번호 개인정보의 수집에 대한 동의

      회사는 귀하께서 회사의 개인정보보호방침 또는 이용약관의 내용에 대해 「동의한다」버튼 또는 「동의하지 않는 다」버튼을 클릭할 수 있는 절차를 마련하여, 「동의한다」버튼을 클릭하면 개인정보 수집에 대해 동의한 것으로 봅니다.

      <span>개인정보의 수집목적 및 이용목적</span>

      1 회사는 회원님께 최대한으로 최적화되고 맞춤화된 서비스를 제공하기 위하여 다음과 같은 목적으로 개인정 보를 수집하고 있습니다.

        - 닉네임,이메일 형식의 ID,비밀번호:회원제 서비스 이용에 따른 본인 식별 절차에 이용

        - 이메일 형식의 ID,전화번호:고지사항 전달, 본인 의사 확인, 불만 처리 등 원활한 의사소통 경로의 확보

        - 주소,전화번호 : 경품과 쇼핑 물품 배송에 대한 정확한 배송지의 확보

        - 그 외 선택항목 : -

      2 단,이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종 및 민족,사상 및 신조,출신지 및 본 적지, 정치적 성향 및 범죄기록, 건강상태 및 성생활 등)는 수집하지 않습니다.

      <span>개인정보 자동수집의 목적 및 거부에 관한 사항</span>

      1 쿠키(cookie)란?

      회사는 귀하에 대한 정보를 저장하고 수시로 찾아내는 쿠키(cookie)를 사용합니다. 쿠키는 웹사이트가 귀하의 컴퓨터 브라우저(넷스케이프, 인터넷 익스플로러 등)로 전송하는 소량의 정보입니다. 귀하께서 웹사이트에 접속 을 하면 회사의 컴퓨터는 귀하의 브라우저에 있는 쿠키의 내용을 읽고, 귀하의 추가정보를 귀하의 컴퓨터에서 찾아 접속에 따른 성명 등의 추가 입력 없이 서비스를 제공할 수 있습니다.쿠키는 귀하의 컴퓨터는 식별하지 만 귀하를 개인적으로 식별하지는 않습니다.

      또한 귀하는 쿠키에 대한 선택권이 있습니다. 웹브라우저 상단의 도구 >인터넷옵션 탭(option tab)에서 모든 쿠키를 다 받아들이거나,쿠키가 설치될 때 통지를 보내도록 하거나,아니면 모든 쿠키를 거부할 수 있는 선 택권을 가질 수 있습니다.

      2 회사의 쿠키(cookie) 운용

      회사는 이용자의 편의를 위하여 쿠키를 운영합니다. 회사가 쿠키를 통해 수집하는 정보는 회사회원 ID에 한하 며, 그 외의 다른 정보는 수집하지 않습니다. 회사가 쿠키(cookie)를 통해 수집한 회원 ID는 다음의 목적을 위해 사용됩니다.

        - 개인의 관심 분야에 따라 차별화된 정보를 제공

        - 회원과 비회원의 접속빈도 또는 머문시간 등을 분석하여 이용자의 취향과 관심분야를 파악하여 타겟 (target) 마케팅에 활용

        - 쇼핑한 품목들에 대한 정보와 관심있게 둘러본 품목들에 대한 자취를 추적하여 다음번 쇼핑 때 개인 맞춤 서비스를 제공

        - 회원들의 습관을 분석하여 서비스 개편 등의 척도

        - 게시판 글 등록

      쿠키는 브라우저의 종료시나 로그아웃시 만료됩니다.

      <span>목적외 사용 및 제3자에 대한 제공 및 공유</span>

      1 회사는 귀하의 개인정보를 「개인정보의 수집목적 및 이용목적」에서 고지한 범위내에서 사용하며, 동 범위를 초과하여 이용하거나 타인 또는 타기업,기관에 제공하지 않습니다. 특히 다음의 경우는 주의를 기울여 개인정 보를 이용 및 제공할 것입니다.

        - 제휴관계 : 보다 나은 서비스 제공을 위하여 귀하의 개인정보를 제휴사에게 제공하거나 또는 제휴사와 공유 할 수 있습니다. 개인정보를 제공하거나 공유할 경우에는 사전에 귀하께 제휴사가 누구인지, 제공 또는 공유되 는 개인정보항목이 무엇인지, 왜 그러한 개인정보가 제공되거나 공유되어야 하는지, 그리고 언제까지 어떻게 보호, 관리되는지에 대해 개별적으로 전자우편 및 서면을 통해 고지하여 동의를 구하는 절차를 거치게 되며, 귀하께서 동의하지 않는 경우에는 제휴사에게 제공하거나 제휴사와 공유하지 않습니다. 제휴관계에 변화가 있 거나 제휴관계가 종결될 때도 같은 절차에 의하여 고지하거나 동의를 구합니다.

        - 위탁 처리 : 원활한 업무 처리를 위해 이용자의 개인정보를 위탁 처리할 경우 반드시 사전에 위탁처리업체명 과 위탁 처리되는 개인정보의 범위, 업무위탁 목적, 위탁 처리되는 과정, 위탁관계 유지기간 등에 대해 상세하 게 고지합니다.

        - 매각,인수합병 등 : 서비스제공자의 권리와 의무가 완전 승계,이전되는 경우 반드시 사전에 정당한 사유와 절차에 대해 상세하게 고지할 것이며 이용자의 개인정보에 대한 동의 철회의 선택권을 부여합니다.

      2 고지 및 동의방법은 온라인 홈페이지 초기화면의 공지사항을 통해 최소 10일 이전부터 고지함과 동시에 이메일 등을 이용하여 1회 이상 개별적으로 고지합니다.

      3 다음은 예외로 합니다.

        - 관계법령에 의하여 수사상의 목적으로 관계기관으로부터의 요구가 있을 경우

        - 통계작성, 학술연구나 시장조사를 위하여 특정 개인을 식별할 수 없는 형태로 광고주, 협력사나 연구단체 등 에 제공하는 경우

        - 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우

        - 그러나 예외 사항에서도 관계법령에 의하거나 수사기관의 요청에 의해 정보를 제공한 경우에는 이를 당사자 에게 고지하는 것을 원칙으로 운영하고 있습니다. 법률상의 근거에 의해 부득이하게 고지를 하지 못할 수도 있습니다. 본래의 수집목적 및 이용목적에 반하여 무분별하게 정보가 제공되지 않도록 최대한 노력하겠습니다.

      <span>개인정보의 열람, 정정</span>

      1 귀하는 언제든지 등록되어 있는 귀하의 개인정보를 열람하거나 정정하실 수 있습니다. 개인정보 열람 및 정정을 하고자 할 경우에는 회사 홈페이지의 『회원정보관리』항목을 클릭하여 직접 열람 또는 정정하거나, 개인 정보관리책임자 및 담당자에게 서면, 전화 또는 E-mail로 연락하시면 지체없이 조치하겠습니다.

      2 귀하가 개인정보의 오류에 대한 정정을 요청한 경우,정정을 완료하기 전까지 당해 개인 정보를 이용 또는 제공하지 않습니다.

      3 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체없이 통지하여 정정 하도록 조치하겠습니다.

      <span>개인정보 수집, 이용, 제공에 대한 동의철회</span>

      1 회원가입 등을 통해 개인정보의 수집, 이용, 제공에 대해 귀하께서 동의하신 내용을 귀하는 언제든지 철회 하실 수 있습니다. 동의철회는 홈페이지의 『회원정보관리』를 클릭하거나 개인정보관리책임자에게 서면, 전화, E- mail등으로 연락하시면 즉시 개인정보의 삭제 등 필요한 조치를 하겠습니다. 동의 철회를 하고 개인정보를 파 기하는 등의 조치를 취한 경우에는 그 사실을 귀하께 지체없이 통지하도록 하겠습니다.

      2 회사는 개인정보의 수집에 대한 동의철회(회원탈퇴)를 개인정보를 수집하는 방법보다 쉽게 할 수 있도록 필요한 조치를 취하겠습니다.

      <span>개인정보의 보유기간 및 이용기간</span>

      회사는 개인정보 수집 시 이용자에게 고지하고 동의 받은 보유 기간이 도래하면 해당 정보를 지체 없이 파기 합니다. 다만 상법, 국세기본법, 전자상거래 등에서의 소비자 보호에 관한 법률 등 관련 법령의 규정에 의하여 다음과 같이 거래 관련 권리 의무 관계의 확인 등을 이유로 일정기간 보유하여야 할 필요가 있을 경우에는 일정기간 보유합니다. 이 경우 회사는 보관하는 개인정보를 그 보관의 목적으로만 이용하며 보존 기간 및 보 존 항목은 아래와 같습니다

        - 계약 또는 청약철회 등에 관한 기록 : 5 년(전자상거래 등에서의 소비자보호에 관한 법률)

        - 대금 결제 및 재화 등의 공급에 관한 기록 : 5년(전자상거래 등에서의 소비자보호에 관한 법률)

        - 소비자 불만 또는 분쟁 처리에 관한 기록 : 3년(전자상거래 등에서의 소비자보호에 관한 법률)

        - 납세 증거에 관한 기록 : 5년(국세 기본법)

        - 보존 항목 : 이름, 이메일, 유선전화번호, 휴대전화번호, 주소

      <span>개인정보의 파기절차 및 방법</span>

      회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다.

      파기절차 및 방법은 다음과 같습니다.

      1 파기절차

      회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서 류함)내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후 파기되어집니다.

      별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되어지는 이외의 다른 목적으로 이용되지 않습니다.

      2 파기방법

      종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기하고전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.

      <span>개인정보보호를 위한 기술 및 관리적 대책</span>

      1 기술적 대책

      회사는 귀하의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확 보를 위하여 다음과 같은 기술적 대책을 강구하고 있습니다.

        - 귀하의 개인정보는 비밀번호에 의해 보호되며 파일 및 전송데이터를 암호화하거나 파일 잠금기능(Lock)을 사용하여 중요한 데이터는 별도의 보안기능을 통해 보호되고 있습니다.

      회사는 백신프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다. 백신 프로그램은 주기적으로 업데이트되며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를 제공함으로 써 개인정보가 침해되는 것을 방지하고 있습니다.

      회사는암호알고리즘을 이용하여 네트워크 상의 개인정보를 안전하게 전송할 수 있는 보안장치(SSL 또는 SET) 를 채택하고 있습니다.

        - 해킹 등 외부침입에 대비하여 침입차단시스템 및 취약점 분석시스템 등을 이용하여 보안에 만전을 기하고 있습니다.

      2 관리적 대책

      회사는 귀하의 개인정보에 대한 접근권한을 최소한의 인원으로 제한하고 있습니다. 그 최소한의 인원에 해당 하는 자는 다음과 같습니다.?이용자를 직접 상대로 하여 마케팅 업무를 수행하는 자? 개인정보관리책임자 및 담당자 등 개인정보관리업무를 수행하는 자? 기타 업무상 개인정보의 취급이 불가피한 자

        - 개인정보를 취급하는 직원을 대상으로 새로운 보안 기술 습득 및 개인정보 보호 의무 등에 관해 정기적인 사내 교육 및 외부 위탁교육을 실시하고 있습니다.

        - 입사 시 개인정보 관련 취급자의 보안서약서를 통하여 사람에 의한 정보유출을 사전에 방지하고 개인정보취 급방침에 대한 이행사항 및 직원의 준수여부를 감사하기 위한 내부절차를 마련하고 있습니다.

        - 개인정보 관련 취급자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이뤄지고 있으며 입사 및 퇴사 후 개인정보 사고에 대한 책임을 명확화하고 있습니다.

        - 개인정보와 일반 데이터를 혼합하여 보관하지 않고 별도로 분리하여 보관하고 있습니다.

        - 전산실 및 자료 보관실 등을 특별 보호구역으로 설정하여 출입을 통제하고 있습니다.

        - 회사는 이용자 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니 다. 회원 개개인이 본인의 개인정보를 보호하기 위해서 자신의 ID 와 비밀번호를 적절하게 관리하고 여기에 대한 책임을 져야 합니다.

        - 그 외 내부 관리자의 실수나 기술관리 상의 사고로 인해 개인정보의 상실,유출,변조,훼손이 유발될 경우 회사는 즉각 귀하께 사실을 알리고 적절한 대책과 보상을 강구할 것입니다.

      <span>링크사이트</span>

      회사는 귀하께 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다. 이 경우 회사는외부사이 트 및 자료에 대한 아무런 통제권이 없으므로 그로부터 제공받는 서비스나 자료의 유용성에 대해 책임질 수 없으며 보증할 수 없습니다. 회사가 포함하고 있는 링크를 클릭(click)하여 타 사이트(site)의 페이지로 옮겨갈 경우 해당 사이트의 개인정보취급방침은 회사와 무관하므로 새로 방문한 사이트의 정책을 검토해 보시기 바랍니다.

      <span>게시글</span>

      1 회사는 귀하의 게시물을 소중하게 생각하며 변조, 훼손, 삭제되지 않도록 최선을 다하여 보호합니다. 그러나 다음의 경우는 그렇지 아니합니다.

        - 스팸(spam)성 게시물 (예 :행운의 편지, 8억 메일, 특정사이트 광고 등)

        - 타인을 비방할 목적으로 허위 사실을 유포하여 타인의 명예를 훼손하는 글

        - 동의 없는 타인의 신상공개 회사의 저작권,제3자의 저작권 등 권리를 침해하는 내용,기타 게시판 주제와 다른 내용의 게시물

        - 회사는 바람직한 게시판 문화를 활성화하기 위하여 동의 없는 타인의 신상 공개시특정부분을 삭제하거나 기 호 등으로 수정하여 게시할 수 있습니다.

        - 다른 주제의 게시판으로 이동 가능한 내용일 경우 해당 게시물에 이동 경로를 밝혀 오해가 없도록 하고 있 습니다.

        - 그 외의 경우 명시적 또는 개별적인 경고 후 삭제 조치할 수 있습니다.

      2 근본적으로 게시물에 관련된 제반 권리와 책임은 작성자 개인에게 있습니다. 또 게시물을 통해 자발적으로 공개된 정보는 보호받기 어려우므로 정보 공개 전에 심사숙고하시기 바랍니다.

      수집한 개인정보의 위탁

      회사는 고객님의 동의없이 고객님의 정보를 외부 업체에 위탁하지 않습니다.

      향후 그러한 필요가 생길 경우,위탁 대상자와 위탁 업무 내용에 대해 고객님에게 통지하고 필요한 경우 사전 동의를 받도록 하겠습니다.

      <span>이용자의 권리와 의무</span>

      1 귀하의 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방해 주시기 바랍니다. 이용자가 입 력한 부정확한 정보로 인해 발생하는 사고의 책임은 이용자 자신에게 있으며 타인 정보의 도용 등 허위정보 를 입력할 경우 회원자격이 상실될 수 있습니다.

      2 귀하는 개인정보를 보호받을 권리와 함께 스스로를 보호하고 타인의 정보를 침해하지 않을 의무도 가지고 있습니다. 비밀번호를 포함한 귀하의 개인정보가 유출되지 않도록 조심하시고 게시물을 포함한 타인의 개인정 보를 훼손하지 않도록 유의해 주십시오.만약 이 같은 책임을 다하지 못하고 타인의 정보 및 존엄성을 훼손할 시에는 『정보통신망이용촉진및정보보호등에관한법률』등에 의해 처벌받을 수 있습니다.

      <span>의견수렴 및 불만처리</span>

      1 회사는 귀하의 의견을 소중하게 생각하며, 귀하는 의문사항으로부터 언제나 성실한 답변을 받을 권리가 있습니다.

      2 회사는 귀하와의 원활환 의사소통을 위해 민원처리센터를 운영하고 있으며 연락처는 다음과 같습니다.

        민원처리센터

        - 전자우편 : support@nosework-official.com

        - 전화번호 : 1600-2190

        - 주소 : 본사_ 4241 Topsail Ct, Soquel, CA 95073, 한국 지사_ 충청남도 천안시 서북구 직산읍 직산로 136, 616호

      3 전화상담은 평일 09:30 ~ 17:30 / 점심시간 12:00~13:00 (주말 및 공휴일 휴무)

      4 전자우편이나 팩스 및 우편을 이용한 상담은 접수 후 24시간 내에 성실하게 답변 드리겠습니다.  다만, 근무시간 이후 또는 주말 및 공휴일에는 익일 처리하는 것을 원칙으로 합니다.

      5 기타 개인정보에 관한 상담이 필요한 경우에는 개인정보침해신고센터, 정보보호마크 인증위원회, 대검찰청 인터넷범죄수사센터, 경찰청 사이버범죄수사대 등으로 문의하실 수 있습니다.

        * 전자거래분쟁조정위원회

        - URL : http://www.ecmc.or.kr

        * 개인정보침해신고센터

        - 전화 : 1336

        - URL : http://www.cyberprivacy.or.kr   * 정보보호마크 인증위원회

        - 전화 : 02-580-0533

        - URL : http://www.privacymark.or.kr

        * 대검찰청 인터넷범죄수사센터

        - 전화 : 02-3480-3600

        - URL : http://icic.sppo.go.kr

        * 경찰청 사이버범죄수사대

        - URL : http://www.police.go.kr/cybercenter

      <span>개인정보관리책임자 및 담당자</span>

      회사는 귀하가 유용한 정보를 안전하게 이용할 수 있도록 최선을 다하고 있습니다. 개인정보를 보호하는데 있 어 귀하께 고지한 사항들에 반하는 사고가 발생할 시에 개인정보관리책임자가 모든 책임을 집니다. 그러나 기 술적인 보완조치를 했음에도 불구하고, 해킹 등 기본적인 네트워크상의 위험성에 의해 발생하는 예기치 못한 사고로 인한 정보의 훼손 및 방문자가 작성한 게시물에 의한 각종 분쟁에 관해서는 책임이 없습니다. 귀하의 개인정보를 취급하는 책임자 및 담당자는 다음과 같으며 개인정보 관련 문의사항에 신속하고 성실하게 답변해 드리고 있습니다.

      개인정보관리담당자

      성 명 : 장현덕

      소속부서 : Nosework Corp. Admin  전자우편: support@nosework-official.com

      전화번호 : 1600-2190

      <span>고객 및 법정 대리인의 권리와 그 행사방법</span>

      고객님 또는 법정대리인 (만 14세 미만의 아동인 경우)은 회사에 대하여 언제든지 개인정보 수집, 이용, 제공 등의 동의를 철회(가입해지)할 수 있습니다. 또 에 대하여, 고객님은 자신의 개인정보를, 법정대리인은 만 14 세 미만의 아동의 개인정보를 각 열람, 제공, 및 오류를 정정하도록 요구할 수 있습니다. 고객님이 개인정보 오류 정정을 요구하신 경우, 회사는 오류정정을 완료하기 전까지 해당 개인정보를 이용, 제공하지 않습니다. 고객님 또는 법정 대리인이 동의철회(가입해지)한 경우 회사는 지체없이 파기하는 것을 원칙으로 하나 관계법 령에서 의무적으로 보유하도록 한 경우에는 개인정보처리방침'개인정보의 보유 및 이용기간'에 따라 처리하고, 반드시 필요한 경우에만 열람 또는 이용이 가능하도록 조치하고 있습니다.

      광고성 정보 전송

      1 회사는 귀하의 명시적인 수신거부의사에 반하여 영리목적의광고성 정보를 전송하지 않습니다.

      2 회사는 귀하가 뉴스레터 등 전자우편 전송에 대한 동의를 한 경우 전자우편의 제목란 및 본문란에 다음 사항과 같이 귀하가 쉽게 알아 볼 수 있도록 조치합니다.

        - 전자우편의 제목란 : (광고)라는 문구를 제목란에 표시하지 않을 수 있으며 전자우편본문란의 주요 내용을 표시합니다.

        - 전자우편의 본문란:

        > 귀하가 수신거부의 의사표시를 할 수 있는 전송자의 명칭, 전자우편주소, 전화번호 및 주소를 명시합니다.   >귀하가 수신 거부의 의사를 쉽게 표시할 수 있는 방법을 한글 및 영문으로 각 각 명시합니다.

        > 귀하가 동의를 한 시기 및 내용을 명시합니다.

      3 회사는 상품정보 안내 등 온라인 마케팅을 위해 광고성 정보를 전자우편 등으로 전송하는 경우 전자우편의 제목란 및 본문란에 다음 사항과 같이 귀하가 쉽게 알아 볼 수 있도록 조치합니다.

        - 전자우편의 제목란 : (광고)또는(성인광고)라는 문구를 제목란의 처음에 빈칸없이 한글로 표시하고 이어서전자우편본문란의 주요 내용을 표시합니다.

        - 전자우편의 본문란 :

        > 귀하가 수신거부의 의사표시를 할 수 있는 전송자의 명칭, 전자우편주소, 전화번호 및 주소를 명시합니다.

        > 귀하가 수신 거부의 의사를 쉽게 표시할 수 있는 방법을 한글 및 영문으로 각 각 명시합니다.

      4 다음과 같이 청소년에게 유해한 정보를 전송하는 경우 "(성인광고)" 문구를 표시합니다.

        - 본문란에 다음 각 항목에 해당하는 것이 부호,문자,영상 또는 음향의 형태로 표현된 경우(해당 전자우편의본 문란에는 직접 표현되어있지 아니하더라도 수신자가 내용을 쉽게 확인할 수 있도록 기술적 조치가 되어 있는 경우를 포함한다)에는 해당 전자우편의 제목란에 "(성인광고)" 문구를 표시합니다.

      Ø 청소년(19세미만의 자를 말한다. 이하 동일)에게 성적인 욕구를 자극하는 선정적인 것이거나 음란한 것

      Ø 청소년에게 포악성이나 범죄의 충동을 일으킬 수 있는 것

      Ø 성폭력을 포함한 각종 형태의 폭력행사와 약물의 남용을 자극하거나 미화하는 것

      Ø 청소년보호법에 의하여 청소년 유해매체물로 결정,고시된 것

        - 영리목적의 광고성전자우편본문란에서 제4항 각항목에 해당하는 내용을 다룬 인터넷 홈페이지를 알리는 경 우에는 해당 전자우편의 제목란에 "(성인광고)" 문구를 표시합니다.

      5 팩스,휴대폰문자전송 등 전자우편이외의문자전송을 통해 영리목적의광고성 정보를 전송하는 경우에는 전송 내용 처음에 "(광고)"라는 문구를 표기하고 전송내용 중에 전송자의 연락처를 명시하도록 조치합니다.

      <span>고지의 의무</span>

      현 개인정보처리방침은2007년 7월 27일에 제정되었으며 정부의 정책 또는 보안기술의 변경에 따라 내용의 추가,삭제 및 수정이 있을 시에는 개정 최소 10일 전부터 홈페이지의 공지란을 통해 고지할 것입니다.

      개인정보처리방침 시행일자 : 2017-09-01

      개인정보처리방침 변경일자 : 2017-09-01
    </div>
    <!-- // 개인정보 처리방침 -->

    <!-- 법적고지 -->
    <div *ngIf="type == 'legal'"><span>노즈워크 브랜드 도용 금지</span>

      노즈워크 브랜드(상호, 상표)는 원칙적으로 ㈜노즈워크가 경영권을 소유한 계열사만이 사용할 수 있습니다. 현재 노즈워크 브랜드를 도용하는 업체에 대해서는 ① 상법 제23조(주체를 오인시킬 상호의 사용금지), ② 상표법 제65조(권리침해에 대한 금지청구권), ③ 부정경쟁방지법 제4조 (부정경쟁행위의 금지청구권) 등을 근거로 제재할 수 있으며, 어떠한 경우라도 외부업체가 노즈워크 상표(심볼, 로고 포함)를 쓰는 경우는 제재 대상이 되므로, 온/오프라인 상에서의 노즈워크 브랜드 오도용을 엄격히 규제합니다.

      <span>노즈워크 저작권</span>

      노즈워크 홈페이지는 ㈜노즈워크가 일체의 비용을 투입하여 독점적으로 소유하는 소중한 자산이며, 이들 모두에 관한 저작권 또한 ㈜노즈워크에 귀속됩니다. 노즈워크 홈페이지에서 제공하는 모든 서비스, 자료, 정보들은 비상업적이고 개인적인 용도의 사용을 위하여만 제공되므로, 문자, 그림, 음성, 화상, 내려받기 파일 링크 및 소스코드들 등 사이트에서 제공되는 서비스, 자료, 정보들 (이하 "정보"라 칭함)을 상업적이거나 공공의 목적으로 내려 받거나 수정, 유통, 기타 어떠한 방식으로도 사용할 수 없습니다.

      <span>법적부인</span>

      노즈워크 홈페이지는 본 홈페이지 및 다른 관련 사이트들의 서비스와 정보들에 대한 정확도, 신뢰도, 완성도에 대해 보장하지 않으며 노즈워크 홈페이지에서 제공하는 자료나 표현에는 오류가 있을 수 있음을 유념하시기 바랍니다. 따라서 고객 여러분은 저희가 서면으로 보장해 드리지 않는 한 이정보나 자료들을 적적으로 의지하지는 마시기 바랍니다. 특히 노즈워크 홈페이지의 웹사이트 서비스를 활용해 개인이나 기업의 이익에 관여되는 판매, 무역, 구매 등의 거래에 직접적인 활용은 삼가해 주시기를 바랍니다. 또한, 어떠한 경우에도 노즈워크 홈페이지는 이들 정보나 자료 등으로 인하여 발생하는 직접, 간접, 부수적 특별 또는 확대 손해에 대해 책임지지 않음을 알려드립니다.

      <span>고지사항의 변경</span>

      본 내용이 고지된 후에 노즈워크 홈페이지에서 제공하는 서비스를 이용하시는 모든 이용자는 본 고지내용에 동의하는 것으로 간주됩니다. 노즈워크 홈페이지는 본 고지사항에 명시된 세부항목과 사용조건의 변경이유를 홈페이지에 적절한 시간 여유를 가지고 고지하거나, 인터넷 산업의 상관례에 맞추어 적절한 방법으로 사전 통지없이 수시로 변경할 권리와 권한을 가지고 있습니다. 그러므로 이용자 스스로 법적인 고지 및 이용약관에 대한 세부항목과 조건을 수시로 검열하여 새로이 변경된 사항이나 조건을 알고계셔야 합니다.

      이상의 법적고지는 2017년 9월 15일 부터 시행합니다.
    </div>
    <!-- // 법적고지 -->

  </div>
  <!-- // content-panel -->

</div>`,
'pages/product/product-conneck/conneck-function-design/conneck-function-design' : `<!--
/**
 * Created by PHILIP on 27/06/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 27/06/2017
 *
 */
-->

<div class="conneck-function-design-container">

  <!-- title-panel -->
  <div class="title-panel">
    <div class="summary-panel">
      <p class="summary">{{ 'product.conneck.menu.functionDesign.sections.titlePanel.summary' | translate }}</p>
      <div class="divider"></div>
      <h2 class="name">{{ 'product.conneck.menu.functionDesign.name' | translate }}</h2>
      <div class="desc">{{ 'product.conneck.menu.functionDesign.sections.titlePanel.description' | translate }}</div>
    </div>
    <div class="img-panel">
      <img src="assets/img/04_function_top.png">
    </div>
  </div>
  <!-- // title-panel -->

  <!-- info-panel -->
  <div class="info-panel">

    <!-- info-panel-1 -->
    <div class="info-panel-1">
      <div class="content-panel">
        <div class="summary-panel">
          <h2 class="summary">{{ 'product.conneck.menu.functionDesign.sections.firstPanel.summary' | translate }}</h2>
          <p class="desc">{{ 'product.conneck.menu.functionDesign.sections.firstPanel.description' | translate }}</p>
        </div>
        <img src="assets/img/04_function_section01.png">
      </div>
    </div>
    <!-- // info-panel-1 -->

    <!-- info-panel-2 -->
    <div class="info-panel-2">
      <div class="content-panel">
        <div class="summary-panel">
          <h2 class="summary">{{ 'product.conneck.menu.functionDesign.sections.secondPanel.summary' | translate }}</h2>
          <p class="desc">{{ 'product.conneck.menu.functionDesign.sections.secondPanel.description' | translate }}</p>
          <p class="gram-panel"
             [innerHtml]="'product.conneck.menu.functionDesign.sections.secondPanel.bandSize' | translate"></p>
        </div>
        <img src="assets/img/04_function_section02.png">
      </div>
    </div>
    <!-- // info-panel-2 -->

    <!-- info-panel-3 -->
    <div class="info-panel-3">
      <div class="content-panel">
        <div class="summary-panel">
          <h2 class="summary">{{ 'product.conneck.menu.functionDesign.sections.thirdPanel.summary' | translate }}</h2>
          <p class="desc">{{ 'product.conneck.menu.functionDesign.sections.thirdPanel.description' | translate }}</p>
        </div>
      </div>
    </div>
    <!-- // info-panel-3 -->

    <!-- info-panel-4 -->
    <div class="info-panel-4">
      <div class="content-panel">
        <div class="summary-panel">
          <h2 class="summary">{{ 'product.conneck.menu.functionDesign.sections.fourthPanel.summary' | translate }}</h2>
          <p class="desc">{{ 'product.conneck.menu.functionDesign.sections.fourthPanel.description' | translate }}</p>
        </div>
      </div>
    </div>
    <!-- // info-panel-4 -->

  </div>
  <!-- // info-panel -->

  <!-- spec-panel -->
  <div class="spec-panel">
    <div>
      <h2>{{ 'product.conneck.menu.functionDesign.sections.specPanel.name' | translate }}</h2>
      <div class="desc-panel">
        <p>
          {{ 'product.conneck.menu.functionDesign.sections.specPanel.bodySize.name' | translate }}
          :
          {{ 'product.conneck.menu.functionDesign.sections.specPanel.bodySize.description' | translate }}
        </p>
        <p>
          {{ 'product.conneck.menu.functionDesign.sections.specPanel.bandSize.name' | translate }}
          :
          {{ 'product.conneck.menu.functionDesign.sections.specPanel.bandSize.description' | translate }}
        </p>
        <p>
          {{ 'product.conneck.menu.functionDesign.sections.specPanel.bodyWeight.name' | translate }}
          :
          {{ 'product.conneck.menu.functionDesign.sections.specPanel.bodyWeight.description' | translate }}
        </p>
        <p>
          {{ 'product.conneck.menu.functionDesign.sections.specPanel.qualityGuaranteePeriod.name' | translate }}
          :
          {{ 'product.conneck.menu.functionDesign.sections.specPanel.qualityGuaranteePeriod.description' | translate }}
        </p>
        <p>
          {{ 'product.conneck.menu.functionDesign.sections.specPanel.packageContents.name' | translate }}
          :
          {{ 'product.conneck.menu.functionDesign.sections.specPanel.packageContents.description' | translate }}
        </p>
        <p>
          {{ 'product.conneck.menu.functionDesign.sections.specPanel.material.name' | translate }}
          :
          {{ 'product.conneck.menu.functionDesign.sections.specPanel.material.description' | translate }}
        </p>
        <p>
          {{ 'product.conneck.menu.functionDesign.sections.specPanel.color.name' | translate }}
          :
          {{ 'product.conneck.menu.functionDesign.sections.specPanel.color.description' | translate }}
        </p>
        <p>
          {{ 'product.conneck.menu.functionDesign.sections.specPanel.productionArea.name' | translate }}
          :
          {{ 'product.conneck.menu.functionDesign.sections.specPanel.productionArea.description' | translate }}
        </p>
        <p>{{ 'product.conneck.menu.functionDesign.sections.specPanel.notice' | translate }}</p>
      </div>
    </div>
  </div>
  <!-- // spec-panel -->

</div>`,
'pages/product/product-conneck/conneck-overview/conneck-overview' : `<!--
/**
 * Created by PHILIP on 27/06/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 27/06/2017
 *
 */
-->

<div class="conneck-overview-container">

  <!-- content-panel -->
  <div class="content-panel">

    <!-- content-sticky-panel -->
    <div class="content-sticky-panel">
      <div>
        <div class="name-panel">
          <p class="korean"
             *ngIf="appService.currentLocal.local == 'kr'">
            {{ 'product.conneck.koreanName' | translate }}
          </p>
          <p class="english">
            {{ 'product.conneck.englishName' | translate }}
          </p>
        </div>
        <div class="img-panel">
          <img src="assets/img/04_overview_top.png">
        </div>
      </div>
    </div>
    <!-- // content-sticky-panel -->

    <!-- content-body-panel -->
    <div class="content-body-panel">

      <!-- overview-panel -->
      <div class="overview-panel">
        <div class="image-panel">
          <img src="assets/img/04_overview_section01.png">
        </div>
        <div class="info-panel">
          <div class="title-panel">
            <h2>{{ 'product.conneck.menu.overview.sections.overview.name' | translate }}</h2>
          </div>
          <div class="desc-panel">
            <p>{{ 'product.conneck.menu.overview.sections.overview.description' | translate }}</p>
          </div>
        </div>
      </div>
      <!-- // overview-panel -->

      <!-- story-panel -->
      <div class="story-panel">
        <img src="assets/img/04_overview_section02.png">
        <div class="info-panel">
          <div class="title-panel">
            <h2>{{ 'product.conneck.menu.overview.sections.story.name' | translate }}</h2>
          </div>
          <div class="desc-panel"
               [innerHtml]="'product.conneck.menu.overview.sections.story.description' | translate">
          </div>
          <div class="btn-link">
            <span (click)="appService.goToMenu('/product-conneck/conneck-story')">
              {{ 'product.conneck.menu.overview.sections.story.moreButton' | translate }} >
            </span>
          </div>
        </div>
      </div>
      <!-- // story-panel -->

      <!-- function-design-panel -->
      <div class="function-design-panel">
        <div class="info-panel">
          <div class="title-panel">
            <h2>{{ 'product.conneck.menu.overview.sections.functionDesign.name' | translate }}</h2>
          </div>
          <div class="desc-panel"
               [innerHtml]="'product.conneck.menu.overview.sections.functionDesign.description' | translate"></div>
          <div class="btn-link">
            <span (click)="appService.goToMenu('/product-conneck/conneck-function-design')">
              {{ 'product.conneck.menu.overview.sections.functionDesign.moreButton' | translate }} >
            </span>
          </div>
        </div>
        <img src="assets/img/04_overview_section03.png">
      </div>
      <!-- // function-design-panel -->

    </div>
    <!-- // content-body-panel -->

  </div>
  <!-- // content-panel -->

</div>`,
'pages/product/product-noseBall/noseBall-function-design/noseBall-function-design' : `<!--
/**
 * Created by PHILIP on 27/06/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 27/06/2017
 *
 */
-->

<div class="noseBall-function-design-container">

  <!-- title-panel -->
  <div class="title-panel">
    <img src="assets/img/03_function_top.png">
    <div class="summary-panel">
      <p class="summary">{{ 'product.noseBall.menu.functionDesign.sections.titlePanel.summary' | translate }}</p>
      <div class="divider"></div>
      <h2 class="name">{{ 'product.noseBall.menu.functionDesign.name' | translate }}</h2>
      <div class="desc-panel">{{ 'product.noseBall.menu.functionDesign.sections.titlePanel.description' | translate }}</div>
    </div>
  </div>
  <!-- // title-panel -->

  <!-- info-panel -->
  <div class="info-panel">

    <!-- info-panel-1 -->
    <div class="info-panel-1">
      <div class="content-panel">
        <img src="assets/img/03_function_section01.png">
        <div class="summary-panel">
          <h2 class="summary">{{ 'product.noseBall.menu.functionDesign.sections.firstPanel.summary' | translate }}</h2>
          <p class="desc">{{ 'product.noseBall.menu.functionDesign.sections.firstPanel.description' | translate }}</p>
        </div>
      </div>
    </div>
    <!-- // info-panel-1 -->

    <!-- info-panel-2 -->
    <div class="info-panel-2">
      <div class="content-panel">
        <div class="summary-panel">
          <h2 class="summary">{{ 'product.noseBall.menu.functionDesign.sections.secondPanel.summary' | translate }}</h2>
          <p class="desc">{{ 'product.noseBall.menu.functionDesign.sections.secondPanel.description' | translate }}</p>
        </div>
        <img src="assets/img/03_function_section02.png">
      </div>
    </div>
    <!-- // info-panel-2 -->

  </div>
  <!-- // info-panel -->

  <!-- spec-panel -->
  <div class="spec-panel">
    <div>
      <h2>{{ 'product.noseBall.menu.functionDesign.sections.specPanel.name' | translate }}</h2>
      <div class="desc-panel">
        <p>
          {{ 'product.noseBall.menu.functionDesign.sections.specPanel.bodySize.name' | translate }}
          :
          {{ 'product.noseBall.menu.functionDesign.sections.specPanel.bodySize.description' | translate }}
        </p>
        <p>
          {{ 'product.noseBall.menu.functionDesign.sections.specPanel.bodyWeight.name' | translate }}
          :
          {{ 'product.noseBall.menu.functionDesign.sections.specPanel.bodyWeight.description' | translate }}
        </p>
        <p>
          {{ 'product.noseBall.menu.functionDesign.sections.specPanel.qualityGuaranteePeriod.name' | translate }}
          :
          {{ 'product.noseBall.menu.functionDesign.sections.specPanel.qualityGuaranteePeriod.description' | translate }}
        </p>
        <p>
          {{ 'product.noseBall.menu.functionDesign.sections.specPanel.packageContents.name' | translate }}
          :
          {{ 'product.noseBall.menu.functionDesign.sections.specPanel.packageContents.description' | translate }}
        </p>
        <p>
          {{ 'product.noseBall.menu.functionDesign.sections.specPanel.material.name' | translate }}
          :
          {{ 'product.noseBall.menu.functionDesign.sections.specPanel.material.description' | translate }}
        </p>
        <p>
          {{ 'product.noseBall.menu.functionDesign.sections.specPanel.color.name' | translate }}
          :
          {{ 'product.noseBall.menu.functionDesign.sections.specPanel.color.description' | translate }}
        </p>
        <p>
          {{ 'product.noseBall.menu.functionDesign.sections.specPanel.productionArea.name' | translate }}
          :
          {{ 'product.noseBall.menu.functionDesign.sections.specPanel.productionArea.description' | translate }}
        </p>
      </div>
    </div>
  </div>
  <!-- // spec-panel -->

</div>`,
'pages/product/product-conneck/conneck-story/conneck-story' : `<!--
/**
 * Created by PHILIP on 27/06/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 27/06/2017
 *
 */
-->

<div class="conneck-story-container">

  <!-- title-panel -->
  <div class="title-panel">
    <div class="img-panel">
      <img src="assets/img/04_story_top.png">
    </div>
    <div class="summary-panel">
      <p class="summary">{{ 'product.conneck.menu.story.sections.titlePanel.summary' | translate }}</p>
      <div class="divider"></div>
      <h2 class="name">{{ 'product.conneck.menu.story.name' | translate }}</h2>
      <div class="desc">{{ 'product.conneck.menu.story.sections.titlePanel.description' | translate }}</div>
    </div>
  </div>
  <!-- // title-panel -->

  <!-- info-panel -->
  <div class="info-panel">

    <!-- info-panel-1 -->
    <div class="info-panel-1">
      <div class="content-panel">
        <div class="summary-panel">
          <h2 class="summary">{{ 'product.conneck.menu.story.sections.firstPanel.summary' | translate }}</h2>
          <p class="desc">{{ 'product.conneck.menu.story.sections.firstPanel.description' | translate }}</p>
        </div>
      </div>
    </div>
    <!-- // info-panel-1 -->

  </div>
  <!-- // info-panel -->

</div>`,
'pages/product/product-noseBall/noseBall-overview/noseBall-overview' : `<!--
/**
 * Created by PHILIP on 27/06/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 27/06/2017
 *
 */
-->

<div class="noseBall-overview-container">

  <!-- content-panel -->
  <div class="content-panel">

    <!-- content-sticky-panel -->
    <div class="content-sticky-panel">
      <div>
        <div class="name-panel">
          <p class="korean"
             *ngIf="appService.currentLocal.local == 'kr'">
            {{ 'product.noseBall.koreanName' | translate }}
          </p>
          <p class="english">
            {{ 'product.noseBall.englishName' | translate }}
          </p>
        </div>
        <img src="assets/img/03_overview_top.png">
      </div>
    </div>
    <!-- // content-sticky-panel -->

    <!-- content-body-panel -->
    <div class="content-body-panel">

      <!-- overview-panel -->
      <div class="overview-panel">
        <div class="image-panel">
          <img src="assets/img/03_overview_section01.png">
        </div>
        <div class="info-panel">
          <div class="title-panel">
            <h2>{{ 'product.noseBall.menu.overview.sections.overview.name' | translate }}</h2>
          </div>
          <div class="desc-panel">
            <p>{{ 'product.noseBall.menu.overview.sections.overview.description' | translate }}</p>
          </div>
        </div>
      </div>
      <!-- // overview-panel -->

      <!-- story-panel -->
      <div class="story-panel">
        <img src="assets/img/03_overview_section02.png">
        <div class="info-panel">
          <div class="title-panel">
            <h2>{{ 'product.noseBall.menu.overview.sections.story.name' | translate }}</h2>
          </div>
          <div class="desc-panel"
               [innerHtml]="'product.noseBall.menu.overview.sections.story.description' | translate">
          </div>
          <div class="btn-link">
            <span (click)="appService.goToMenu('/product-noseBall/noseBall-story')">
              {{ 'product.noseBall.menu.overview.sections.story.moreButton' | translate }} >
            </span>
          </div>
        </div>
      </div>
      <!-- // story-panel -->

      <!-- blog-panel -->
      <div class="blog-panel">
        <div>
          <div class="info-panel">
            <div class="title-panel">
              <h2>{{ 'product.noseBall.menu.overview.sections.aromaTherapy.name' | translate }}</h2>
            </div>
            <div class="desc-panel"
                 [innerHtml]="'product.noseBall.menu.overview.sections.aromaTherapy.description' | translate">
            </div>
            <div class="btn-link">
              <span (click)="appService.openWeb('http://blog.nosework-official.com')">
                {{ 'product.noseBall.menu.overview.sections.aromaTherapy.moreButton' | translate }} >
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- // blog-panel -->

      <!-- function-design-panel -->
      <div class="function-design-panel">
        <img src="assets/img/03_overview_section04.png">
        <div class="info-panel">
          <div class="title-panel">
            <h2>{{ 'product.noseBall.menu.overview.sections.functionDesign.name' | translate }}</h2>
          </div>
          <div class="desc-panel"
               [innerHtml]="'product.noseBall.menu.overview.sections.functionDesign.description' | translate">
          </div>
          <div class="btn-link">
            <span (click)="appService.goToMenu('/product-noseBall/noseBall-function-design')">
              {{ 'product.noseBall.menu.overview.sections.functionDesign.moreButton' | translate }} >
            </span>
          </div>
        </div>
      </div>
      <!-- // function-design-panel -->

    </div>
    <!-- // content-body-panel -->

  </div>
  <!-- // content-panel -->

</div>`,
'pages/product/product-noseBall/noseBall-story/noseBall-story' : `<!--
/**
 * Created by PHILIP on 27/06/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 27/06/2017
 *
 */
-->

<div class="noseBall-story-container">

  <!-- title-panel -->
  <div class="title-panel">
    <div class="summary-panel">
      <p class="summary">{{ 'product.noseBall.menu.story.sections.titlePanel.summary' | translate }}</p>
      <div class="divider"></div>
      <h2 class="name">{{ 'product.noseBall.menu.story.name' | translate }}</h2>
      <p class="desc">{{ 'product.noseBall.menu.story.sections.titlePanel.description' | translate }}</p>
    </div>
    <img src="assets/img/03_story_top.png">
  </div>
  <!-- // title-panel -->

  <!-- info-panel -->
  <div class="info-panel">

    <!-- info-panel-1 -->
    <div class="info-panel-1">
      <div class="content-panel">
        <div class="summary-panel">
          <h2 class="summary">{{ 'product.noseBall.menu.story.sections.firstPanel.summary' | translate }}</h2>
          <p class="desc">{{ 'product.noseBall.menu.story.sections.firstPanel.description' | translate }}</p>
        </div>
      </div>
    </div>
    <!-- // info-panel-1 -->

    <!-- info-panel-2 -->
    <div class="info-panel-2">
      <div class="content-panel">
        <div class="summary-panel">
          <h2 class="summary">{{ 'product.noseBall.menu.story.sections.secondPanel.summary' | translate }}</h2>
          <p class="desc">{{ 'product.noseBall.menu.story.sections.secondPanel.description' | translate }}</p>
          <div class="btn-panel">
            <div class="btn-detail"
                 (click)="appService.openWeb('http://blog.nosework-official.com')">
              <span>{{ 'product.noseBall.menu.story.sections.secondPanel.moreButton' | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- // info-panel-2 -->

  </div>
  <!-- // info-panel -->

</div>`,
'pages/product/product-noseWorKit/noseWorKit-function-design/noseWorKit-function-design' : `<!--
/**
 * Created by PHILIP on 27/06/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 27/06/2017
 *
 */
-->

<div class="noseWorKit-function-design-container">

  <!-- title-panel -->
  <div class="title-panel">
    <div class="summary-panel">
      <p class="summary">{{ 'product.noseworKit.menu.functionDesign.sections.titlePanel.summary' | translate }}</p>
      <div class="divider"></div>
      <h2 class="name">{{ 'product.noseworKit.menu.functionDesign.name' | translate }}</h2>
      <div class="desc-panel"
           [innerHtml]="'product.noseworKit.menu.functionDesign.sections.titlePanel.description' | translate"></div>
    </div>
    <div class="img-panel">
      <img src="assets/img/02_function_top.png" style="width: 100%;">
    </div>
  </div>
  <!-- // title-panel -->

  <!-- info-panel -->
  <div class="info-panel">

    <!-- info-panel-1 -->
    <div class="info-panel-1">
      <div class="content-panel">
        <div class="summary-panel">
          <h2 class="summary">{{ 'product.noseworKit.menu.functionDesign.sections.firstPanel.summary' | translate }}</h2>
          <p class="desc">{{ 'product.noseworKit.menu.functionDesign.sections.firstPanel.description' | translate }}</p>
        </div>
      </div>
    </div>
    <!-- // info-panel-1 -->

    <!-- info-panel-2 -->
    <div class="info-panel-2">
      <div class="content-panel">
        <div class="summary-panel">
          <h2 class="summary">{{ 'product.noseworKit.menu.functionDesign.sections.secondPanel.summary' | translate }}</h2>
          <p class="desc">{{ 'product.noseworKit.menu.functionDesign.sections.secondPanel.description' | translate }}</p>
        </div>
      </div>
    </div>
    <!-- // info-panel-2 -->

    <!-- info-panel-3 -->
    <div class="info-panel-3">
      <div class="content-panel">
        <img src="assets/img/02_function_section03.png">
        <div class="summary-panel">
          <h2 class="summary"
              [innerHtml]="'product.noseworKit.menu.functionDesign.sections.thirdPanel.summary' | translate"></h2>
          <p class="desc">{{ 'product.noseworKit.menu.functionDesign.sections.thirdPanel.description' | translate }}</p>
        </div>
      </div>
    </div>
    <!-- // info-panel-3 -->

  </div>
  <!-- // info-panel -->

  <!-- spec-panel -->
  <div class="spec-panel">
    <div>
      <h2>{{ 'product.noseworKit.menu.functionDesign.sections.specPanel.name' | translate }}</h2>
      <div class="desc-panel">
        <p>
          {{ 'product.noseworKit.menu.functionDesign.sections.specPanel.bodySize.name' | translate }}
          :
          {{ 'product.noseworKit.menu.functionDesign.sections.specPanel.bodySize.description' | translate }}
        </p>
        <p>
          {{ 'product.noseworKit.menu.functionDesign.sections.specPanel.bodyWeight.name' | translate }}
          :
          {{ 'product.noseworKit.menu.functionDesign.sections.specPanel.bodyWeight.description' | translate }}
        </p>
        <p>
          {{ 'product.noseworKit.menu.functionDesign.sections.specPanel.qualityGuaranteePeriod.name' | translate }}
          :
          {{ 'product.noseworKit.menu.functionDesign.sections.specPanel.qualityGuaranteePeriod.description' | translate }}
        </p>
        <p>
          {{ 'product.noseworKit.menu.functionDesign.sections.specPanel.packageContents.name' | translate }}
          :
          {{ 'product.noseworKit.menu.functionDesign.sections.specPanel.packageContents.description' | translate }}
        </p>
        <p>
          {{ 'product.noseworKit.menu.functionDesign.sections.specPanel.material.name' | translate }}
          :
          {{ 'product.noseworKit.menu.functionDesign.sections.specPanel.material.description' | translate }}
        </p>
        <p>
          {{ 'product.noseworKit.menu.functionDesign.sections.specPanel.color.name' | translate }}
          :
          {{ 'product.noseworKit.menu.functionDesign.sections.specPanel.color.description' | translate }}
        </p>
        <p>
          {{ 'product.noseworKit.menu.functionDesign.sections.specPanel.productionArea.name' | translate }}
          :
          {{ 'product.noseworKit.menu.functionDesign.sections.specPanel.productionArea.description' | translate }}
        </p>
      </div>
    </div>
  </div>
  <!-- // spec-panel -->

</div>`,
'pages/product/product-noseWorKit/noseWorKit-overview/noseWorKit-overview' : `<!--
/**
 * Created by PHILIP on 27/06/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 27/06/2017
 *
 */
-->

<div class="noseWorKit-overview-container">

  <!-- content-panel -->
  <div class="content-panel">

    <!-- content-sticky-panel -->
    <div class="content-sticky-panel">
      <div>
        <div class="name-panel">
          <p class="korean"
             *ngIf="appService.currentLocal.local == 'kr'">
            {{ 'product.noseworKit.koreanName' | translate }}
          </p>
          <p class="english">
            {{ 'product.noseworKit.englishName' | translate }}
          </p>
        </div>
      </div>
    </div>
    <!-- // content-sticky-panel -->

    <!-- content-body-panel -->
    <div class="content-body-panel">

      <!-- overview-panel -->
      <div class="overview-panel">
        <div class="image-panel">
          <img src="assets/img/02_overview_section01.png">
        </div>
        <div class="info-panel">
          <div class="title-panel">
            <h2>{{ 'product.noseworKit.menu.overview.sections.overview.name' | translate }}</h2>
          </div>
          <div class="desc-panel">
            <p>{{ 'product.noseworKit.menu.overview.sections.overview.description' | translate }}</p>
          </div>
        </div>
      </div>
      <!-- // overview-panel -->

      <!-- story-panel -->
      <div class="story-panel">
        <img src="assets/img/02_overview_section02.png">
        <div class="info-panel">
          <div class="title-panel">
            <h2>{{ 'product.noseworKit.menu.overview.sections.story.name' | translate }}</h2>
          </div>
          <div class="desc-panel"
               [innerHtml]="'product.noseworKit.menu.overview.sections.story.description' | translate">
          </div>
          <div class="btn-link">
            <span (click)="appService.goToMenu('/product-noseWorKit/noseWorKit-story')">
              {{ 'product.noseworKit.menu.overview.sections.story.moreButton' | translate }} >
            </span>
          </div>
        </div>
      </div>
      <!-- // story-panel -->

      <!-- function-design-panel -->
      <div class="function-design-panel">
        <div class="info-panel">
          <div class="title-panel">
            <h2>{{ 'product.noseworKit.menu.overview.sections.functionDesign.name' | translate }}</h2>
          </div>
          <div class="desc-panel"
               [innerHtml]="'product.noseworKit.menu.overview.sections.functionDesign.description' | translate"></div>
          <div class="btn-link">
            <span (click)="appService.goToMenu('/product-noseWorKit/noseWorKit-function-design')">
              {{ 'product.noseworKit.menu.overview.sections.functionDesign.moreButton' | translate }} >
            </span>
          </div>
        </div>
        <img src="assets/img/02_overview_section03.png">
      </div>
      <!-- // function-design-panel -->

    </div>
    <!-- // content-body-panel -->

  </div>
  <!-- // content-panel -->

</div>`,
'pages/product/product-noseWorKit/noseWorKit-story/noseWorKit-story' : `<!--
/**
 * Created by PHILIP on 27/06/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 27/06/2017
 *
 */
-->

<div class="noseWorKit-story-container">

  <!-- title-panel -->
  <div class="title-panel">
    <div class="summary-panel">
      <p class="summary">{{ 'product.noseworKit.menu.story.sections.titlePanel.summary' | translate }}</p>
      <div class="divider"></div>
      <h2 class="name">{{ 'product.noseworKit.menu.story.name' | translate }}</h2>
      <div class="desc-panel">{{ 'product.noseworKit.menu.story.sections.titlePanel.description' | translate }}</div>
    </div>
    <div class="img-panel">
      <img src="assets/img/02_story_top.png">
    </div>
  </div>
  <!-- // title-panel -->

  <!-- info-panel -->
  <div class="info-panel">

    <!-- info-panel-1 -->
    <div class="info-panel-1">
      <div class="content-panel">
        <div class="summary-panel">
          <h2 class="summary">{{ 'product.noseworKit.menu.story.sections.firstPanel.summary' | translate }}</h2>
          <p class="desc">{{ 'product.noseworKit.menu.story.sections.firstPanel.description' | translate }}</p>
        </div>
        <img src="assets/img/02_story_section01.png">
      </div>
    </div>
    <!-- // info-panel-1 -->

  </div>
  <!-- // info-panel -->

</div>`,
'pages/product/product-smartNoseWork/smartNoseWork-design/smartNoseWork-design' : `<!--
/**
 * Created by PHILIP on 27/06/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 27/06/2017
 *
 */
-->

<div class="smartNoseWork-design-container">

  <!-- title-panel -->
  <div class="title-panel">
    <img src="assets/img/01_design_top.png">
    <div class="summary-panel">
      <p class="summary">{{ 'product.smartNosework.menu.design.sections.titlePanel.summary' | translate }}</p>
      <div class="divider"></div>
      <h2 class="name">{{ 'product.smartNosework.menu.design.name' | translate }}</h2>
      <p class="desc">{{ 'product.smartNosework.menu.design.sections.titlePanel.description' | translate }}</p>
    </div>
  </div>
  <!-- // title-panel -->

  <!-- info-panel -->
  <div class="info-panel">

    <!-- info-panel-1 -->
    <div class="info-panel-1">
      <div class="content-panel">
        <div class="summary-panel">
          <h2 class="summary">{{ 'product.smartNosework.menu.design.sections.firstPanel.summary' | translate }}</h2>
          <p class="desc">{{ 'product.smartNosework.menu.design.sections.firstPanel.description' | translate }}</p>
        </div>
        <img src="assets/img/01_design_section02.png">
      </div>
    </div>
    <!-- // info-panel-1 -->

    <!-- info-panel-2 -->
    <div class="info-panel-2">
      <div class="content-panel">
        <img src="assets/img/01_design_section03.png">
        <div class="summary-panel">
          <h2 class="summary">{{ 'product.smartNosework.menu.design.sections.secondPanel.summary' | translate }}</h2>
          <p class="desc">{{ 'product.smartNosework.menu.design.sections.secondPanel.description' | translate }}</p>
        </div>
      </div>
    </div>
    <!-- // info-panel-2 -->

  </div>
  <!-- // info-panel -->

  <!-- spec-panel -->
  <div class="spec-panel">
    <div>
      <h2>{{ 'product.smartNosework.menu.design.sections.specPanel.name' | translate }}</h2>
      <div class="desc-panel">
        <p>
          {{ 'product.smartNosework.menu.design.sections.specPanel.bodySize.name' | translate }}
          :
          {{ 'product.smartNosework.menu.design.sections.specPanel.bodySize.description' | translate }}
        </p>
        <p>
          {{ 'product.smartNosework.menu.design.sections.specPanel.bodyWeight.name' | translate }}
          :
          {{ 'product.smartNosework.menu.design.sections.specPanel.bodyWeight.description' | translate }}
        </p>
        <p>
          {{ 'product.smartNosework.menu.design.sections.specPanel.power.name' | translate }}
          :
          {{ 'product.smartNosework.menu.design.sections.specPanel.power.description' | translate }}
        </p>
        <p>
          {{ 'product.smartNosework.menu.design.sections.specPanel.ratedPowerConsumption.name' | translate }}
          :
          {{ 'product.smartNosework.menu.design.sections.specPanel.ratedPowerConsumption.description' | translate }}
        </p>
        <p>
          {{ 'product.smartNosework.menu.design.sections.specPanel.powerCordLength.name' | translate }}
          :
          {{ 'product.smartNosework.menu.design.sections.specPanel.powerCordLength.description' | translate }}
        </p>
        <p>
          {{ 'product.smartNosework.menu.design.sections.specPanel.qualityGuaranteePeriod.name' | translate }}
          :
          {{ 'product.smartNosework.menu.design.sections.specPanel.qualityGuaranteePeriod.description' | translate }}
        </p>
        <p>
          {{ 'product.smartNosework.menu.design.sections.specPanel.packageContents.name' | translate }}
          :
          {{ 'product.smartNosework.menu.design.sections.specPanel.packageContents.description' | translate }}
        </p>
        <p>
          {{ 'product.smartNosework.menu.design.sections.specPanel.material.name' | translate }}
          :
          {{ 'product.smartNosework.menu.design.sections.specPanel.material.description' | translate }}
        </p>
        <p>
          {{ 'product.smartNosework.menu.design.sections.specPanel.color.name' | translate }}
          :
          {{ 'product.smartNosework.menu.design.sections.specPanel.color.description' | translate }}
        </p>
        <p>
          {{ 'product.smartNosework.menu.design.sections.specPanel.productionArea.name' | translate }}
          :
          {{ 'product.smartNosework.menu.design.sections.specPanel.productionArea.description' | translate }}
        </p>
      </div>
    </div>
  </div>
  <!-- // spec-panel -->

</div>`,
'pages/product/product-smartNoseWork/smartNoseWork-function/smartNoseWork-function' : `<!--
/**
 * Created by PHILIP on 27/06/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 27/06/2017
 *
 */
-->

<div class="smartNoseWork-function-container">

  <!-- title-panel -->
  <div class="title-panel">
    <img src="assets/img/01_function_top.png">
    <div class="summary-panel">
      <p class="summary">{{ 'product.smartNosework.menu.function.sections.titlePanel.summary' | translate }}</p>
      <div class="divider"></div>
      <h2 class="name">{{ 'product.smartNosework.menu.function.name' | translate }}</h2>
      <div class="desc-panel"
           [innerHtml]="'product.smartNosework.menu.function.sections.titlePanel.description' | translate"></div>
    </div>
  </div>
  <!-- // title-panel -->

  <!-- info-panel -->
  <div class="info-panel">

    <!-- info-panel-1 -->
    <div class="info-panel-1">
      <div class="content-panel">
        <div class="summary-panel">
          <h2 class="summary">{{ 'product.smartNosework.menu.function.sections.firstPanel.summary' | translate }}</h2>
          <p class="desc">{{ 'product.smartNosework.menu.function.sections.firstPanel.description' | translate }}</p>
        </div>
      </div>
    </div>
    <!-- // info-panel-1 -->

    <!-- info-panel-2 -->
    <div class="info-panel-2">

      <!-- divider -->
      <div class="divider"></div>
      <!-- // divider -->

      <div class="content-panel">
        <div class="summary-panel">
          <h2 class="summary"
              [innerHtml]="'product.smartNosework.menu.function.sections.secondPanel.summary' | translate"></h2>
          <p class="desc">{{ 'product.smartNosework.menu.function.sections.secondPanel.description' | translate }}</p>
          <div class="btn-panel">
            <div class="btn-aroma"
                 (click)="appService.openWeb('http://blog.nosework-official.com')">
              <span>{{ 'product.smartNosework.menu.function.sections.secondPanel.moreButton' | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- // info-panel-2 -->

    <!-- info-panel-3 -->
    <div class="info-panel-3">
      <div class="content-panel">
        <div class="summary-panel">
          <h2 class="summary">{{ 'product.smartNosework.menu.function.sections.thirdPanel.summary' | translate }}</h2>
          <div class="desc"
               [innerHtml]="'product.smartNosework.menu.function.sections.thirdPanel.description' | translate"></div>
        </div>
      </div>
    </div>
    <!-- // info-panel-3 -->

    <!-- info-panel-4 -->
    <div class="info-panel-4"
         [ngStyle]="{'background-image': appService.currentLocal.local == 'en' ? 'url(../assets/img/01_function_section04_en.png)' : 'url(../assets/img/01_function_section04.png)'}">
      <div class="content-panel">
        <div class="summary-panel">
          <h2 class="summary">{{ 'product.smartNosework.menu.function.sections.fourthPanel.summary' | translate }}</h2>
          <p class="desc">{{ 'product.smartNosework.menu.function.sections.fourthPanel.description' | translate }}</p>
        </div>
      </div>
    </div>
    <!-- // info-panel-4 -->

    <!-- info-panel-5 -->
    <div class="info-panel-5">
      <div class="content-panel">
        <img src="assets/img/01_function_section05.png">
        <div class="summary-panel">
          <p class="summary"
              [innerHtml]="'product.smartNosework.menu.function.sections.fifthPanel.summary' | translate"></p>
          <p class="desc">{{ 'product.smartNosework.menu.function.sections.fifthPanel.description' | translate }}</p>
        </div>
      </div>
    </div>
    <!-- // info-panel-5 -->

  </div>
  <!-- // info-panel -->

</div>`,
'pages/product/product-smartNoseWork/smartNoseWork-overview/smartNoseWork-overview' : `<!--
/**
 * Created by PHILIP on 27/06/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 27/06/2017
 *
 */
-->

<div class="smartNoseWork-overview-container">

  <!-- content-panel -->
  <div class="content-panel">

    <!-- content-sticky-panel -->
    <div class="content-sticky-panel">
      <div>
        <div class="name-panel">
          <p class="korean"
             *ngIf="appService.currentLocal.local == 'kr'">
            {{ 'product.smartNosework.koreanName' | translate }}
          </p>
          <p class="english">
            {{ 'product.smartNosework.englishName' | translate }}
          </p>
        </div>
        <img src="assets/img/01_overview_top.png">
      </div>
    </div>
    <!-- // content-sticky-panel -->

    <!-- content-body-panel -->
    <div class="content-body-panel">

      <!-- overview-panel -->
      <div class="overview-panel">
        <div class="image-panel">
          <img src="assets/img/01_overview_section01.png">
        </div>
        <div class="info-panel">
          <div class="title-panel">
            <h2>{{ 'product.smartNosework.menu.overview.sections.overview.name' | translate }}</h2>
          </div>
          <div class="desc-panel">
            <p>{{ 'product.smartNosework.menu.overview.sections.overview.description' | translate }}</p>
          </div>
        </div>
      </div>
      <!-- // overview-panel -->

      <!-- story-panel -->
      <div class="story-panel">
        <img src="assets/img/01_overview_section02.png">
        <div class="info-panel">
          <div class="title-panel">
            <h2>{{ 'product.smartNosework.menu.overview.sections.story.name' | translate }}</h2>
          </div>
          <div class="desc-panel"
               [innerHtml]="'product.smartNosework.menu.overview.sections.story.description' | translate">
          </div>
          <div class="btn-link">
            <span (click)="appService.goToMenu('/product-smartNoseWork/smartNoseWork-story')">
              {{ 'product.smartNosework.menu.overview.sections.story.moreButton' | translate }} >
            </span>
          </div>
        </div>
      </div>
      <!-- // story-panel -->

      <!-- function-panel -->
      <div class="function-panel">
        <div class="info-panel">
          <div class="title-panel">
            <h2>{{ 'product.smartNosework.menu.overview.sections.function.name' | translate }}</h2>
          </div>
          <div class="desc-panel"
               [innerHtml]="'product.smartNosework.menu.overview.sections.function.description' | translate"></div>
          <div class="btn-link">
            <span (click)="appService.goToMenu('/product-smartNoseWork/smartNoseWork-function')">
              {{ 'product.smartNosework.menu.overview.sections.function.moreButton' | translate }} >
            </span>
          </div>
        </div>
        <img src="assets/img/01_overview_section03.png">
      </div>
      <!-- // function-panel -->

      <!-- design-panel -->
      <div class="design-panel">
        <img src="assets/img/01_overview_section04.png">
        <div class="info-panel">
          <div class="title-panel">
            <h2>{{ 'product.smartNosework.menu.overview.sections.design.name' | translate }}</h2>
          </div>
          <div class="desc-panel"
               [innerHtml]="'product.smartNosework.menu.overview.sections.design.description' | translate"></div>
          <div class="btn-link">
            <span (click)="appService.goToMenu('/product-smartNoseWork/smartNoseWork-design')">
              {{ 'product.smartNosework.menu.overview.sections.design.moreButton' | translate }} >
            </span>
          </div>
        </div>
      </div>
      <!-- // design-panel -->

    </div>
    <!-- // content-body-panel -->

  </div>
  <!-- // content-panel -->

</div>`,
'pages/product/product-smartNoseWork/smartNoseWork-story/smartNoseWork-story' : `<!--
/**
 * Created by PHILIP on 27/06/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 27/06/2017
 *
 */
-->

<div class="smartNoseWork-story-container">

  <!-- title-panel -->
  <div class="title-panel">
    <img src="assets/img/01_story_top.png">
    <div class="summary-panel">
      <p class="summary">{{ 'product.smartNosework.menu.story.sections.titlePanel.summary' | translate }}</p>
      <div class="divider"></div>
      <h2 class="name">{{ 'product.smartNosework.menu.story.name' | translate }}</h2>
      <div class="desc-panel"
           [innerHtml]="'product.smartNosework.menu.story.sections.titlePanel.description' | translate"></div>
    </div>
  </div>
  <!-- // title-panel -->

  <!-- info-panel -->
  <div class="info-panel">

    <!-- info-panel-1 -->
    <div class="info-panel-1">
      <div class="content-panel">
        <div class="summary-panel">
          <h2 class="summary">{{ 'product.smartNosework.menu.story.sections.firstPanel.summary' | translate }}</h2>
          <p class="desc">{{ 'product.smartNosework.menu.story.sections.firstPanel.description' | translate }}</p>
        </div>
        <img src="assets/img/01_story_section01.png">
      </div>
    </div>
    <!-- // info-panel-1 -->

    <!-- info-panel-2 -->
    <div class="info-panel-2">

      <!-- divider -->
      <div class="divider"></div>
      <!-- // divider -->

      <!-- content-panel -->
      <div class="content-panel">
        <div class="summary-panel"
             [innerHtml]="'product.smartNosework.menu.story.sections.secondPanel.summary' | translate"></div>
        <div class="desc-panel"
             [innerHtml]="'product.smartNosework.menu.story.sections.secondPanel.description' | translate"></div>
      </div>
      <!-- // content-panel -->

    </div>
    <!-- // info-panel-2 -->

    <!-- info-panel-3 -->
    <div class="info-panel-3">
      <div class="content-panel">
        <div class="summary-panel">
          <h2 class="summary">{{ 'product.smartNosework.menu.story.sections.thirdPanel.summary' | translate }}</h2>
          <p class="desc">{{ 'product.smartNosework.menu.story.sections.thirdPanel.description' | translate }}</p>
        </div>
      </div>
    </div>
    <!-- // info-panel-3 -->

    <!-- info-panel-4 -->
    <div class="info-panel-4">
      <div class="content-panel">
        <div class="summary-panel">
          <h2 class="summary">{{ 'product.smartNosework.menu.story.sections.fourthPanel.summary' | translate }}</h2>
          <p class="desc">{{ 'product.smartNosework.menu.story.sections.fourthPanel.description' | translate }}</p>
        </div>
        <img src="assets/img/01_story_section04.png">
      </div>
    </div>
    <!-- // info-panel-4 -->

  </div>
  <!-- // info-panel -->

</div>`,
'pages/store/invoice-list/invoice-detail/invoice-detail' : `<!--
/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */
-->
 <!-- 네이버 공동유입스크립트 -->
<script type="text/javascript" src="http://wcs.naver.net/wcslog.js"></script>
<script type="text/javascript">
    if (!wcs_add) var wcs_add = {};
    wcs_add["wa"] = "s_52e6d95fb9a0";
    wcs.inflow("nosework-official.com");
    wcs_do();
</script>

<div class="invoice-detail-container"
     *ngIf="invoice">
  <h2>{{invoice.createdAt | datex: "YYYY년 MM월 DD일 HH시 mm분"}}</h2>

  <div class="invoice-info-panel">
    <p>주문 내역</p>

    <div class="payment-info-panel">
      <p>결제 정보</p>
      <p>{{invoice.translatePaymentType}} <span>({{invoice.translateStatus}})</span></p>
    </div>
    
    <div class="item-list-panel">

      <!-- <div class="delivery-price-panel">
        <div class="name">
          <span>배송비</span>
        </div>
        <div class="price">
          <span>+ {{ invoice.deliveryPrice | number }} 원</span>
        </div>
      </div> -->
      
      <div>
        
        <div class="item-list-panel m-t-0">

          <div class="info-panel" *ngFor="let item of invoice.itemList; let itemIndex = index;">

            <p>
              <span>상품명</span>{{ item.product.name }}
            </p>
            <p>
              <span>수량</span>{{ item.quantity }}
            </p>
            <p>
              <span>원가</span>{{ item.product.price * item.quantity | number }} 원
            </p>
            <p>
              <span>할인가</span>{{ item.product.salePrice * item.quantity | number }} 원
            </p>

            <div *ngFor="let option of optionList; let optionIndex = index">

              <div *ngIf="itemIndex == optionIndex">
                <div *ngFor="let one of option" style="padding: 10px;">
                    <span>{{ one.name }}&nbsp;&nbsp;{{ one.quantity }}개&nbsp;&nbsp;&nbsp;{{ one.price * one.quantity }} 원</span>
                </div>
              </div>

            </div>
            <p style="margin-top: 15px">
              <span>결제금액</span>{{ item.totalAmount | number }} 원
            </p>

          </div>

        </div>

        <div class="info-panel">
          <p>
            <span>총 수량</span>{{ invoice.totalQuantity }}
          </p>
          <p>
            <span>배송비</span>{{ invoice.deliveryPrice | number }} 원
          </p>
          <p>
            <span>상품 총액</span>{{ invoice.totalAmount - invoice.deliveryPrice | number }} 원
          </p>
          <p>
            <span>합계</span>{{ invoice.totalAmount | number }} 원
          </p>
        </div>
        
      </div>
      
    </div>
    
    <div class="total-price-panel">
      <p>합계</p>
      <p><span>{{invoice.totalAmount | number}}</span> 원</p>
    </div>
    
  </div>
  
  <div class="delivery-info-panel" *ngIf = "invoice.transInfo.companyName != '' && invoice.transInfo.transNumber != 0">
      <p>운송 정보</p>
      <div class="info-panel">
          <p>
            <span>운송회사명</span>{{invoice.transInfo.companyName}}
          </p>
          <p>
            <span>운송번호</span>{{invoice.transInfo.transNumber}}
          </p>
      </div>
    </div>
  <!-- delivery-info-panel -->
  <div class="delivery-info-panel">
    <p>배송지 정보</p>

    <!-- info-panel -->
    <div class="info-panel">
      <p>
        <span>이름</span>{{invoice.deliveryInfo.name}}
      </p>
      <p>
        <span>전화번호</span>{{invoice.deliveryInfo.phone}}
      </p>
      <p>
        <span>주소</span>{{invoice.deliveryInfo.addressInfo.address}} {{invoice.deliveryInfo.addressInfo.detailAddress}}
      </p>
      <!-- <p>
          <span>주소</span>{{invoice.deliveryInfo.addressInfo.address}} {{invoice.deliveryInfo.addressInfo.detailAddress}}
        </p> -->
      <p>
        <span>요청사항</span>{{invoice.note}}
      </p>
    </div>
    <!-- // info-panel -->

  </div>
  <!-- // delivery-info-panel -->

</div>`,
'pages/store/manage-product/register-genuine/register-genuine' : `<!--
/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */
-->
 <!-- 네이버 공동유입스크립트 -->
<script type="text/javascript" src="http://wcs.naver.net/wcslog.js"></script>
<script type="text/javascript">
    if (!wcs_add) var wcs_add = {};
    wcs_add["wa"] = "s_52e6d95fb9a0";
    wcs.inflow("nosework-official.com");
    wcs_do();
</script>

<div class="register-genuine-container">

  <!-- form-panel -->
  <div class="form-panel">

    <!-- label-panel -->
    <div class="label-panel">
      <h2>정품 등록</h2>

      <!-- btn-close -->
      <div class="btn-close"
           (click)="dismiss()">
        <i class="material-icons">close</i>
      </div>
      <!-- // btn-close -->

    </div>
    <!-- // label-panel -->

    <!-- content-panel -->
    <div class="content-panel">

      <p>구매하신 제품을 등록해주세요.</p>

      <div class="product-panel">
        <div class="product-item smart"
             [ngClass]="{active: productName == 'Smart NOSEWORK'}"
             (click)="productName = 'Smart NOSEWORK'">
          <span>Smart NOSEWORK</span>
        </div>
        <div class="product-item conneck"
             [ngClass]="{active: productName == 'CONNECK'}"
             (click)="productName = 'CONNECK'">
          <span>CONNECK</span>
        </div>
      </div>

      <form>

        <div class="input-panel">
          <md-input-container>
            <input mdInput
                   type="text"
                   name="serialNumber"
                   [placeholder]="placeholder"
                   (focus)="inputFocused()"
                   (blur)="inputBlurred()"
                   [(ngModel)]="serialNumber"/>
          </md-input-container>
        </div>

        <div class="btn-panel">
          <button type="submit"
                  (click)="registered()"
                  [disabled]="!isValid()">
            <span>등록하기</span>
          </button>
        </div>

      </form>

    </div>
    <!-- // content-panel -->

  </div>
  <!-- // form-panel -->

</div>`,
'pages/store/payment/pre-payment/pre-payment' : `<!--
/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */
-->
 <!-- 네이버 공동유입스크립트 -->
<script type="text/javascript" src="http://wcs.naver.net/wcslog.js"></script>
<script type="text/javascript">
    if (!wcs_add) var wcs_add = {};
    wcs_add["wa"] = "s_52e6d95fb9a0";
    wcs.inflow("nosework-official.com");
    wcs_do();
</script>

<div class="find-password-container">

  <!-- form-panel -->
  <div class="form-panel">

    <!-- label-panel -->
    <div class="label-panel">
      <h2>비회원 구매</h2>

      <!-- btn-close -->
      <div class="btn-close"
           (click)="dismiss()">
        <i class="material-icons">close</i>
      </div>
      <!-- // btn-close -->

    </div>
    <!-- // label-panel -->

    <!-- content-panel -->
    <div class="content-panel">

      <!-- find-password-panel -->
      <div class="find-password-panel">

        <form>

          <!-- step1-panel -->
          <div class="step1-panel">

            <p>이메일주소를 입력해주세요.</p>

            <div class="input-panel">
              <md-input-container>
                <input mdInput
                       type="email"
                       name="email"
                       placeholder="이메일 주소"
                       maxlength="256"
                       [(ngModel)]="identifier"/>
              </md-input-container>
            </div>

            <div class="btn-panel">
              <button type="submit"
                      (click)="goToOrder()"
                      [disabled]="!isValid()">
                <span>주문하기</span>
              </button>
            </div>

          </div>
          <!-- // step1-panel -->

        </form>

      </div>
      <!-- // find-password-panel -->

    </div>
    <!-- // content-panel -->

  </div>
  <!-- // form-panel -->

</div>
`,
'pages/store/payment/success-payment/success-payment' : `<!--
/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */
-->
 <!-- 네이버 공동유입스크립트 -->
<script type="text/javascript" src="http://wcs.naver.net/wcslog.js"></script>
<script type="text/javascript">
    if (!wcs_add) var wcs_add = {};
    wcs_add["wa"] = "s_52e6d95fb9a0";
    wcs.inflow("nosework-official.com");
    wcs_do();
</script>

<div class="success-payment-container">
  <h2>주문이 완료되었습니다.</h2>
  <h3 *ngIf = "invoice.paymentType == 'bank'">
    test </h3>
  <!-- content-panel -->
  <div class="content-panel">

    <!-- info-panel -->
    <div class="info-panel"
         *ngIf="invoice"
         (click)="appService.goToMenu('/store/invoice-detail/', invoice._id)">

      <!-- image-panel -->
      <div class="image-panel">
        <preload-image [imageSource]="invoice.productPhoto"
                       [ratio]="1"></preload-image>
      </div>
      <!-- // image-panel -->

      <!-- detail-panel -->
      <div class="detail-panel">

        <!-- name-panel -->
        <div class="name-panel">
          <p>
            {{invoice.itemList[0].product.name}}
            <span *ngIf="invoice.itemList.length > 1">외 {{invoice.itemList.length-1}}개</span>
          </p>
          <p>
            가격 <span>{{invoice.totalAmount | number}}</span>
          </p>
        </div>
        <!-- // name-panel -->

        <p class="bank-notice"
           *ngIf="invoice.paymentType == 'bank'">
           무통장입금의 경우 3일내에 입금하지 않은 주문건은 자동으로 주문 취소가 됩니다.
        </p>
      </div>
      <!-- // detail-panel -->

    </div>
    <!-- // info-panel -->

  </div>
  <!-- // content-panel -->

  <!-- btn-panel -->
  <div class="btn-panel">
    <div class="btn-invoiceList"
         (click)="appService.goToMenu('/store/invoice-list')">
      <span>주문 내역</span>
    </div>
    <div class="btn-store"
         (click)="appService.goToMenu('/store/store-home')">
      <span>스토어</span>
    </div>
  </div>
  <!-- // btn-panel -->

</div>`,
'pages/store/product-detail/product-detail-dialog/product-detail-dialog' : `<!--
/**
 * Created by PHILIP on 11/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 11/07/2017
 *
 */
-->
 <!-- 네이버 공동유입스크립트 -->
<script type="text/javascript" src="http://wcs.naver.net/wcslog.js"></script>
<script type="text/javascript">
    if (!wcs_add) var wcs_add = {};
    wcs_add["wa"] = "s_52e6d95fb9a0";
    wcs.inflow("nosework-official.com");
    wcs_do();
</script>

<div class="product-detail-dialog-container"
     [style.width]="contentSize.width"
     [style.height]="contentSize.height">
    <div class="form-panel">
        <div *ngIf="fileTypeName == 'video'">
            <div class="input-panel">
                <iframe type="text/html" 
                        width="800" 
                        height="800"
                        [src]="fileData.url | full_youtube_url"
                        *ngIf="fileData.url.length > 0 && fileData.url.indexOf('youtube') !== -1"
                        frameborder="0" class="center-aligned">
                </iframe>
            </div>
        </div>
        <div *ngIf="fileTypeName == 'image'">
                <img src="{{fileData.url}}" width="800px" height="800px"/>
        </div>
    </div>
    <div class="sidebar-panel">
        <div class="option-form">
            <div class="image-view">
                <img src="{{fileData.secure_url}}" width="80px" height="80px">
            </div>
            <div class="name-view">
                <div class="option-name">
                    <span>상품명</span>
                </div>
                <div class="option-rating-stars">
                    <span class="star"></span>
                    <span class="star"></span>
                    <span class="star"></span>
                    <span class="star"></span>
                    <span class="star"></span>
                </div>
                <div class="option-owner">
                    <span>흰족제비</span>
                </div>
            </div>
        </div>
        <div class="review-view">
            <span style="color:#989494">
                    이 리뷰에 대해 
                    1명 중 1명이 도움이 된다 선택 했습니다
            </span>
        </div>
        <div class="option-view">
            <div class="comma-line">
                <p>--------------------------------------------------------</p>
            </div>
            <div class="option-viewName">
                <p>선택한 옵션</p>
            </div>
            <div class="comma-line">
                <p>--------------------------------------------------------</p>
            </div>
        </div>
        <div class="option-detail">
            <p style="color:#5a5a5a">
                    아이들이 잘때도 끼고자고 넘나좋은가봐용ㅎㅎㅎ
                    항상 강아지들위해서 뭔가 해주고싶었는데 아르르 킁킁볼로 시작을 했어요!나이가 많은 아이들임에도 즐겁게 잘놀구 우울해하지않아 너무좋네요! 재구입의사있습니다!!
            </p>
        </div>
        <div class="option-review-button">
            <span>이 리뷰가 도움이 되었나요?</span>
            <div class="okbtn">
                <button style="width:60px;height:40px;">네</button>
            </div>
            <div class="nobtn">
                <button style="width:60px;height:40px;">아니오</button>
            </div>
        </div>
        <div class="option-admintext">
            <div class="option-review-text-and-button">
                <div class="option-review-text">
                    <p>nosework 관리자</p>
                    <p style="margin-top:30px;">
                        안녕하세요 고객님! 
                        소중한 후기 감사드립니다.
                        상품에 만족을 하지 못하실 경우, 
                        고객센터 또는 게시판이나 카카오톡 플러스친구(@nosework)로 알려주시면 확인 후 도움드리겠습니다.
                        저희 아르르는 앞으로도 고객만족을 위해 항상 노력하겠습니다. 
                        오늘도 좋은하루 되세요 :-)
                    </p>
                </div>
                <div class="option-review-button">
                    <div class="option-text">
                        <input type="text" placeholder="댓글을 입력해주세요:)">
                    </div>
                    <div class="option-button">
                        <button style="width:60px;height:40px;">등록</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>`,
}
export function getTemplate(id){return templates[id];}