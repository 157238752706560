/**
 * Created by Yoon Yong (Andy) Shin on 24/02/2017
 * As part of Nosework
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & Yoon Yong (Andy) Shin - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Yoon Yong (Andy) Shin <andy.shin@applicat.co.kr>, 24/02/2017
 *
 */

// Angular
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[InputAllow]'
})
export class InputAllow {

  constructor(private el: ElementRef) { }

  @Input() InputAllow: string;

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    let e = <KeyboardEvent> event;
    if (this.InputAllow) {
      if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode == 65 && e.ctrlKey === true) ||
        // Allow: Ctrl+C
        (e.keyCode == 67 && e.ctrlKey === true) ||
        // Allow: Ctrl+X
        (e.keyCode == 88 && e.ctrlKey === true) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)) {
        // let it happen, don't do anything
        return;
      }

      let ch = String.fromCharCode(e.keyCode);
      let regEx =  new RegExp(this.InputAllow);
      if(regEx.test(ch))
        return;
      else
        e.preventDefault();
    }
  }

}