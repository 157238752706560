// Angular
import {ElementRef, Component, Input, Renderer, ViewChild, SimpleChange, OnChanges, DoCheck, OnInit} from "@angular/core";


@Component({
  selector: 'preload-video',
  template: `
  <div #videoPanel class="preload-video-panel">
    <div #loadingPanel class="preload-video-loading">
      <spinner></spinner>
    </div>
    <ng-content></ng-content>
  </div>
`,
})
export class PreloadVideo implements OnChanges, DoCheck, OnInit {

  element;
  previouseWidth;

  @ViewChild("videoPanel") videoPanel: ElementRef;
  @ViewChild("loadingPanel") loadingPanel: ElementRef;

  @Input() videoSource: string;
  @Input() ratio?: number;
  @Input() resize?: string;
  @Input() videoStyle: any;

  constructor(private renderer: Renderer,
              private elementRef: ElementRef) {
    this.element = elementRef;
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() { }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
      this.element.nativeElement.outerHTML = ` <!-- VIDEO -->
            <video class="slider-video" width="100%" preload="auto" style="visibility: visible; width: 100%;" poster="//">
              <source src="${changes['videoSource'].currentValue}" type="video/mp4">
                <source src="${changes['videoSource'].currentValue}" type="video/webm">
                  <source src="${changes['videoSource'].currentValue}" type="video/ogg">
            </video>
            <!-- END VIDEO -->`;
  }

  ngDoCheck(){
      this.renderer.setElementClass(this.loadingPanel.nativeElement, 'done', true);
  }

}