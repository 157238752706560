/**
 * Created by Yoon Yong (Andy) Shin on 23/12/2016
 * As part of Nosework
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & Yoon Yong (Andy) Shin - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Yoon Yong (Andy) Shin <andy.shin@applicat.co.kr>, 23/12/2016
 *
 */

// Angular
import { Component } from "@angular/core";
import { MdDialog, MdDialogRef, MdDialogConfig } from "@angular/material";

// Project Sources
import { getTemplate } from "../../../shared/templates";

import {Login} from "../../../pages/login/login";
import {PrePayment} from "../../../pages/store/payment/pre-payment/pre-payment";

@Component({
  selector: 'dialog-message',
  template: getTemplate('shared/components/login-confirm/login-confirm.component')
})
export class LoginConfirm {

  type;

  title;
  message;

  dialogLoginRef: MdDialogRef<Login>;
  dialogBuyRef: MdDialogRef<PrePayment>;
  dialogLoginConfig: MdDialogConfig = new MdDialogConfig();
  dialogBuyConfig: MdDialogConfig = new MdDialogConfig();

  constructor(private loginDialog: MdDialog,
              private buyDialog: MdDialog,
              public dialogRef: MdDialogRef<LoginConfirm>) {
    }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {}

  /*****************************
   *        util functions
   *****************************/

  go() {
    this.dialogRef.close(true);

    this.dialogBuyConfig.disableClose = true;
    this.dialogBuyConfig.width = '100%';
    this.dialogBuyConfig.height = '100%';

    this.dialogBuyRef = this.buyDialog.open(PrePayment, this.dialogBuyConfig);

    this.dialogBuyRef.afterClosed()
        .subscribe((result) => {});
  }

  login() {
    this.dialogRef.close(true);

    this.dialogLoginConfig.disableClose = true;
    this.dialogLoginConfig.width = '100%';
    this.dialogLoginConfig.height = '100%';

    this.dialogLoginRef = this.loginDialog.open(Login, this.dialogLoginConfig);

    this.dialogLoginRef.afterClosed()
        .subscribe((result) => {});
  }
}
