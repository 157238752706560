/**
 * Created by PHILIP on 27/06/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 27/06/2017
 *
 */

// Export Library
declare var Swiper;
declare var wcs;
declare var wcs_do;
declare var _;

// Angular
import {Component, OnInit, ViewChild} from "@angular/core";
import {MdDialog, MdDialogConfig, MdDialogRef} from "@angular/material";
import {AppService} from "../../shared/services/app.service";
// Project Sources
import {getTemplate} from "../../shared/templates";
import {BannerService} from "../../shared/services/banner.service";
import {DialogService} from "../../shared/components/dialog-message/dialog-message.service";
import {SupportForm} from "../support-form/support-form";
import {PopUpDialog} from "../popup-dialog/popup-dialog";
import {PopUpDialogService} from "../../shared/services/popup-dialog.service";

@Component({
  selector: 'home',
  template: getTemplate('pages/home/home')
})
export class Home implements OnInit {

  @ViewChild("bannerSlider") bannerSlider;

  player = new Array();
  ytEvent;
  
  currentSwiperIndex;
  swiperOptions;
  sliderAutoPlayTime = 3000;

  firstVideoPlaying = false;
  videoPlaying = false;

  model;

  dialogSupportRef: MdDialogRef<SupportForm>;
  config: MdDialogConfig = new MdDialogConfig();  
  dialogPopUpDialogRef: MdDialogRef<PopUpDialog>;
  constructor(public appService: AppService,
              private mdDialog: MdDialog,
              private bannerService: BannerService,
              private popupDialogService:PopUpDialogService,
              private dialogService: DialogService) {}

  /*****************************
   *         life cycle
   *****************************/
  compareDay(date, serverTime) {
    let dateString = date.toString();
    let serverTimeString = serverTime.toString();
    
    let dateStringDay = dateString.substring(0, dateString.indexOf('T'));
    let serverTimeStringDay = serverTimeString.substring(0, serverTimeString.indexOf('T'));
    
    if (dateStringDay != serverTimeStringDay)
      return true;
    else
      return false;
  }

  loadPopups(myData) {
    let params: any = {
    };
    
    this.popupDialogService.find(params)
      .subscribe(
        (PopUpsWrapper) => {
            this.appService.popupTotalData = "";
            var isShow = false;
            for(var i = 0 ; i < PopUpsWrapper.total ; i++) {
                var count = 0;
                var index = -1;
                for(var j = 0 ; j < myData.length; j++) {
                    if(myData[j]._id == PopUpsWrapper['PopUps'][i]._id) {
                        count = 1;
                        index = j;
                        let date = myData[j].checkedTime; 
                        let serverCurrentTime = PopUpsWrapper.serverCurrentTime;
                        if (this.compareDay(date, serverCurrentTime)) {
                            isShow = true;
                            break;
                        }
                    }
                }

                if (isShow || count == 0) {
                    if (PopUpsWrapper['PopUps'][i].show) {
                        this.dialogPopUpDialogRef = this.mdDialog.open(PopUpDialog, this.config);
                        this.dialogPopUpDialogRef.componentInstance.popupDialog_ID = PopUpsWrapper['PopUps'][i]._id;
                    }
                } else {
                    this.appService.popupTotalData += JSON.stringify(myData[index]) + "&&&";
                }
            }
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      )
  }

  loadShowPopup() {
    let params: any = {
    };

    this.popupDialogService.find(params)
      .subscribe(
        (PopUpsWrapper) => {
          var dialogArr = [];
            for (var i = 0 ; i < PopUpsWrapper.total; i++) {
                if (PopUpsWrapper['PopUps'][i].show) {
                    this.dialogPopUpDialogRef = this.mdDialog.open(PopUpDialog, this.config);
                    this.dialogPopUpDialogRef.componentInstance.popupDialog_ID = PopUpsWrapper['PopUps'][i]._id;
                }
            }
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      )
  }

  openPopup() {
    let popupData = localStorage.getItem('PopupData');
    this.appService.popupTotalData = "";
    if (!this.appService.showFlag) {
        if (popupData == null || popupData == "") {
            this.appService.showFlag = true;
            this.loadShowPopup();
        } else {
            this.appService.showFlag = true;
            let totalPopup = popupData.split('&&&');
            let popup = [];
            for(var i =0 ; i < totalPopup.length -1;i++){
                popup.push(JSON.parse(totalPopup[i]));
            }
            this.loadPopups(popup);
        }
    }
  }
  
  ngOnInit() {
    this.appService.showFlag = false;
    this.openPopup();
    if (!wcs_add) var wcs_add={};

    wcs_add["wa"] = "s_52e6d95fb9a0";
    if (!_nasa) var _nasa = {};
    wcs.inflow();
    wcs_do(_nasa);

    this.model = {
        banners: [],
        more: false,
        total: 0
      };
    this.loadBanners();

    this.currentSwiperIndex = 0;
    this.swiperOptions = {
      autoplay: this.sliderAutoPlayTime,
      onSlideChangeStart: () => {
        this.onSlideChangeStart();
      },
      onInit: () => {
        this.playVideoSlide(0);
      }
    };

    // 무효한 호출 같아 임ㅅ 막아놓는다. 2018.06.02 
    // setTimeout(function(thisObj) {
    //   if (thisObj.bannerSlider.swiper) {
    //     thisObj.bannerSlider.swiper.stopAutoplay();
    //   }
    // }, 100, this);
  }

  /*****************************
   *        util functions
   *****************************/
  onSlideChangeStart() {
      this.currentSwiperIndex = this.bannerSlider.swiper.activeIndex;
      this.playVideoSlide(this.currentSwiperIndex);

      setTimeout(function(thisObj) {
        if (!thisObj.videoPlaying) {
          // startAutoplay 는 초기에 한번만 해주면 되므로 매번 해줄 필요가 없음 
          // thisObj.bannerSlider.swiper.startAutoplay();
        } else {
        }
      }, 7000, this);
  }

  recursivePlayVideo(thisObj, activeIndex): void {
    //이 부분은 setTimeout() 에 들어가 았을 필요가 없어 꺼내놓는다. 
    // InternetExploer 에서 동영상인 경우 재생도중 바로 넘어가는 현상이 있어 수정. 20180602
    if (thisObj.bannerSlider.swiper) {
      thisObj.videoPlaying = true;
      thisObj.bannerSlider.swiper.stopAutoplay();
    }

    setTimeout(function(thisObj) {

      if (thisObj.player.length > 0) {
        let currentVideoIndex = -1;
        for (let i = 0; i < thisObj.model['banners'].length; i++) {
          if (thisObj.model['banners'][i].fileTypes == 'video') {
            if (i > activeIndex) break;

            currentVideoIndex++;
          }
        } 

        if (currentVideoIndex > -1) {
          thisObj.player[currentVideoIndex].playVideo();
          // 우에서 stopAutoplay() 를 진행하므로 필요가 없음
          // if (thisObj.bannerSlider.swiper) {
          //   thisObj.bannerSlider.swiper.stopAutoplay();
          // }
        }
      } else {
        thisObj.recursivePlayVideo(thisObj, activeIndex);
      }
    }, 500, this);
  }

  playVideoSlide(activeIndex): void {
    if (this.model['banners']) {
      if (this.model['banners'][activeIndex]) {
        if (this.model['banners'][activeIndex].fileTypes) {
          if (this.model['banners'][activeIndex].fileTypes == 'video') {
            this.firstVideoPlaying = true;
            this.recursivePlayVideo(this, activeIndex);
          } else {
            this.videoPlaying = false;
          }
        }
      }
    }
  }

  openSupportForm(){
    this.dialogService.resizeModal(this.config);
    this.config.disableClose = true;

    this.dialogSupportRef = this.mdDialog.open(SupportForm, this.config);

    this.dialogSupportRef.afterClosed()
      .subscribe((result) => {});
  };
  
  /*****************************
   *       helper functions
   *****************************/

  loadBanners() {
    this.bannerService.find(
      {
        query: {
          category: 'WEB',
          isDeleted: false
        },
        sort: { displayOrder: 1},
        populate: ['photo']
      }
    )
      .subscribe(
        (bannersWrapper) => {
          // 필요가 없을것 같아 막아 놓는다. 20180602
          // if (this.bannerSlider && this.bannerSlider.swiper) {
          //   this.bannerSlider.swiper.stopAutoplay();
          // }

          for (let i = 0; i < bannersWrapper['banners'].length; i++) {
            let banner = bannersWrapper['banners'][i];
            if (!banner['photo'] && banner['imageVideo'] === 'video') {
              banner['photo'] = {};
              banner['photo'].secure_url = banner.url;
            }
          }

          for (var i = 0 ; i < bannersWrapper.total; i++) {
            var fileTypes = bannersWrapper['banners'][i].photo.fileType.split('/')[0];
            bannersWrapper['banners'][i].fileTypes = fileTypes;
          }

          this.model['banners'] = bannersWrapper['banners'];
          this.model['more'] = bannersWrapper['more'];
          this.model['total'] = bannersWrapper['total'];
          
          setTimeout(function(thisObj) { 
            if (thisObj.model['total'] > 0) {
              thisObj.playVideoSlide(0);
            }
          }, 500, this);

          setTimeout(function(thisObj) { 
            if (thisObj.bannerSlider.swiper && !thisObj.firstVideoPlaying) {
              thisObj.bannerSlider.swiper.startAutoplay();
            }
          }, 2500, this);
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      );
  }

  onStateChange(event) {
    this.ytEvent = event.data;
    if (this.ytEvent == 0) {
      if (this.player.length > 0) {
        var bannerSlider = this.bannerSlider;
        setTimeout(function() {
          bannerSlider.swiper.startAutoplay();
        }, 3000);
      }
    } 
  }

  savePlayer(player) {
    this.player.push(player);
  }

}