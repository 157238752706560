/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */

// Export Lib
declare var _;
declare var wcs;
declare var wcs_do;
declare var window;

// Browser Lib
import {Observable} from "rxjs";
import "rxjs/add/operator/toPromise";
import 'rxjs/Rx';

// Angular
import {Component, OnInit,ViewChild,QueryList,ViewChildren } from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {MdDialogRef, MdDialog, MdDialogConfig} from "@angular/material";
import {FormControl} from '@angular/forms';

// Angular
import {Injectable} from "@angular/core";
import {RequestOptions, URLSearchParams, Headers} from "@angular/http";
import { Response } from '@angular/http';

// Angular Third Party Lib
import {InterceptableHttp} from "../../../lib/ng-http-interceptor";

// Project Sources
import {getTemplate} from "../../../shared/templates";
import {AppService} from "../../../shared/services/app.service";
import {ProductService} from "../../../shared/services/product.service";
import {TransactionService} from "../../../shared/services/transaction.service";
import {DialogService} from "../../../shared/components/dialog-message/dialog-message.service";
import {LoginConfirmService} from "../../../shared/components/login-confirm/login-confirm.service";
import {FaqService } from "../../../shared/services/faq.service";
import {ProductDetailDialog} from "./product-detail-dialog/product-detail-dialog";
import { MdSelectModule } from '@angular/material';
// Project Sources
import {config} from "../../../app/app.config";

@Component({
  selector: 'product-detail',
  template: getTemplate('pages/store/product-detail/product-detail')
})

export class ProductDetailIndex implements OnInit {
  constructor(private appService: AppService,
              private mdDialog: MdDialog,
              private activatedRoute: ActivatedRoute,
              private productService: ProductService,
              private transactionService: TransactionService,
              private dialogService: DialogService,
              private loginConfirmService: LoginConfirmService,
              private  http: InterceptableHttp) {
  }

  id;

  ngOnInit() {
    this.activatedRoute.queryParams
      .subscribe((params) => {
        console.log(params);
        this.id = params['product[0][id]'];

        this.loadProduct();
      });
  }

  loadProduct() {
    this.dialogService.loadingSubject.next('spinner');

    this.productService.findOne(
      {
        query: {
          _id: this.id
        },
        populate: ['photos', 'detailFiles']
      }
    )
      .finally(() => {
        this.dialogService.loadingSubject.next('hide');
      })
      .subscribe(
        (productWrapper) => {
          if (productWrapper['product']) {
            this.appService.goToMenu('store/product-detail/'+ productWrapper['product']['name']);
          } else {
            this.dialogService.message("알림", "찾을 수 없는 상품입니다.");
            this.appService.goToMenu('/store');
          }
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';  
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
          this.appService.goToMenu('/store');
        }
      )
  }

}
