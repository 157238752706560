import {ActivatedRoute} from "@angular/router";
/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */

// Export Lib
declare var _;
declare var wcs;
declare var wcs_do;

// Angular
import {Component, OnInit} from "@angular/core";
import { CookieService } from 'angular2-cookie/core';
import {MdDialog, MdDialogConfig, MdDialogRef, LIVE_ANNOUNCER_ELEMENT_TOKEN} from "@angular/material";
import {config} from "../../../app/app.config";

// Project Sources
import {getTemplate} from "../../../shared/templates";
import {AppService} from "../../../shared/services/app.service";
import {AuthService} from "../../../shared/services/auth.service";
import {ProductService} from "../../../shared/services/product.service";
import {TransactionService} from "../../../shared/services/transaction.service";
import {DialogService} from "../../../shared/components/dialog-message/dialog-message.service";
import {DaumMap} from "../../../shared/components/daum-map/daum-map";

@Component({
  selector: 'payment',
  template: getTemplate('pages/store/payment/payment')
})
export class Payment implements OnInit {

  config: MdDialogConfig = new MdDialogConfig();
  dialogDaumMapRef: MdDialogRef<DaumMap>;

  cartList;
  enableAttrbutes;
  deliveryInfo;
  address = '';

  paymentType;

  totalAmount;

  //made by mangus
  totalSelectedAmount;

  originalAmount;
  discountAmount;
  deliveryPrice;
  totalQuantity;

  nonMemberEmail = "";

  constructor(public appService: AppService,
              private activatedRoute: ActivatedRoute,
              private mdDialog: MdDialog,
              private authService: AuthService,
              private productService: ProductService,
              private transactionService: TransactionService,
              private dialogService: DialogService,
              private _cookieService: CookieService) {
  }

  /*****************************
   *         life cycle
   *****************************/
  
  ngOnInit() {
    // this.activatedRoute.params
    //   .subscribe((params => {
    //     if (params['isFailed']) {
    //       this.dialogService.message("알림", "결제가 취소되었습니다.");
    //     }
    //   }));

    if (!wcs_add) var wcs_add = {};
    wcs_add["wa"] = "s_52e6d95fb9a0";
    if (!_nasa) var _nasa = {};
    wcs.inflow();
    wcs_do(_nasa);

    if (this.transactionService.products.length <= 0) {
      this.dialogService.message("알림", "잘못된 요청입니다.");
      this.appService.goBack();
    }

    this.cartList = [];
    this.enableAttrbutes = false;
    this.deliveryInfo = {
      addressInfo: {
        zipCode: '',
        address: '',
        detailAddress: ''
      },
      phone: null,
      name: '',
      note: ''
    };
    this.address = '';

    this.paymentType = 'card';

    this.totalAmount = 0;
    this.originalAmount = 0;
    this.discountAmount = 0;
    this.deliveryPrice = 2500;
    this.totalQuantity = 0;
    this.totalSelectedAmount = 0;

    this.initLoadSequence();
    // setTimeout(()=>{
    //   this.transactionService.NAVER.NaverPayButton.apply({
    //     BUTTON_KEY: "FE7A3593-C4FD-401C-A1CC-4310B876A33E",
    //     TYPE: "B", //버튼 스타일
    //     COLOR: 1, //버튼 색상타입
    //     COUNT: 2, // 네이버페이버튼 + 찜하기버튼 모두 출력 여부
    //     ENABLE: "Y", //네이버페이 활성여부(재고부족 등에는 N으로 비활성처리)
    //     EMBED_ID: "naver-pay", //네이버페이 버튼 UI가 부착될 HTML element의 ID
    //     BUY_BUTTON_HANDLER : function() {
    //       //중략
    //       //IMP.request_pay(param);
          
    //     },
    //     WISHLIST_BUTTON_HANDLER : function() {
    //       //중략
    //       //핸들러 내에서 아임포트 네이버페이 찜하기 함수 호출(iamport.payment-1.1.6.js부터 지원됨)
    //       //IMP.naver_zzim(param);
    //     }
    //   });
    // }, 500);
  }
  requestNaverPay(){
    let param = {
      pg : 'naverco',
      pay_method : 'card', //연동되지 않습니다. 네이버페이 결제창 내에서 결제수단을 구매자가 직접 선택하게 됩니다.
      merchant_uid : 'merchant_' + new Date().getTime(), //상점에서 관리하시는 고유 주문번호를 전달
      name : '주문명:  ',
      amount : 14000,
      buyer_email : 'iamport@siot.do',
      buyer_name : '구매자이름',
      buyer_tel : '010-1234-5678', //누락되면 이니시스 결제창에서 오류
      buyer_addr : '서울특별시 강남구 삼성동',
      buyer_postcode : '123-456',
      naverProducts : [
        {
          id : "singleProductId",
          name : "네이버페이 상품1",
          basePrice : 1000,
          taxType : 'TAX', //TAX or TAX_FREE
          quantity : 2,
          infoUrl : "http://www.iamport.kr/product/detail",
          imageUrl : "http://www.iamport.kr/product/detail/thumbnail",
          shipping : {
            groupId : "shipping-a",
            method : "DELIVERY", //DELIVERY(택배·소포·등기), QUICK_SVC(퀵 서비스), DIRECT_DELIVERY(직접 전달), VISIT_RECEIPT(방문 수령), NOTHING(배송 없음)
            baseFee : 2500,
            feeRule : {
              freeByThreshold : 20000
            },
            feePayType : "PREPAYED" //PREPAYED(선불), CASH_ON_DELIVERY(착불)
          }
        },
        {
          id : "optionProductId",
          name : "네이버페이 상품2",
          basePrice : 1000,
          taxType : 'TAX', //TAX or TAX_FREE
          infoUrl : "http://www.iamport.kr/product/detail",
          imageUrl : "http://www.iamport.kr/product/detail/thumbnail",
          options : [ //네이버페이 상품2에 대해서 빨강-170mm사이즈 3개와 빨강-180mm사이즈 2개: 총 5개 구매
            {
              optionQuantity : 3,
              optionPrice : 100,
              selectionCode : "R_M",
              selections : [
                {
                    code : "RED",
                  label : "색상",
                  value : "빨강"
                },
                {
                  code : "170",
                  label : "사이즈",
                  value : "170"
                }
            ]
          },
            {
              optionQuantity : 2,
              optionPrice : 200,
              selectionCode : "R_L",
              selections : [
                {
                    code : "RED",
                  label : "색상",
                  value : "빨강"
                },
                {
                  code : "180",
                  label : "사이즈",
                  value : "180"
                }
            ]
          }
        ],
        shipping : {
          groupId : "shipping-a",
          method : "DELIVERY", //DELIVERY(택배·소포·등기), QUICK_SVC(퀵 서비스), DIRECT_DELIVERY(직접 전달), VISIT_RECEIPT(방문 수령), NOTHING(배송 없음)
          baseFee : 2500,
          feeRule : {
              freeByThreshold : 20000
            },
          feePayType : "PREPAYED" //PREPAYED(선불), CASH_ON_DELIVERY(착불)
        }
      }
      ]
    }
    this.transactionService.NAVER.request_pay(param,function(result){
      if (result.success) {
        //this.dialogService.message("알림", "결제가 성공하였습니다.");
      } else {
        //this.dialogService.message("알림", "등록된 사용자가 아니거나, 아임포트 관리자 페이지에 설정된 PG정보가 없습니다.");
      }
    });
  }
  ngOnDestory() {
    // this.transactionService['products'] = [];
  }

  /*****************************
   *        util functions
   *****************************/

  initLoadSequence() {
    //var IMP = window.IMP; // 생략가능
    
    //IMP.init('iamport'); // 'iamport' 대신 부여받은 "가맹점 식별코드"를 사용
    this.getMyUserInfo();
  }

  isValid() {
    let regExpPhone = /^[0-9]*$/;
    if (this.cartList.length <= 0) return false;
    else if (!this.paymentType) return false;
    else if (!this.deliveryInfo['addressInfo'].address || this.deliveryInfo['addressInfo'].address == '') return false;
    else if (!this.deliveryInfo['phone'] || this.deliveryInfo['phone'] == '' || !regExpPhone.test(this.deliveryInfo['phone'])) return false;
    else if (!this.deliveryInfo['name'] || this.deliveryInfo['name'] == '') return false;
    else return true;
  }

  addQuantity(itemIndex) {
    this.cartList[itemIndex].quantity++;
    this.totalQuantity++;
    this.getLowPrice(itemIndex, 'add');
    this.setDeliveryPrice();
  }

  subtractQuantity(itemIndex) {
    if (this.cartList[itemIndex].quantity > 1) {
      this.cartList[itemIndex].quantity--;
      this.totalQuantity--;
      this.getLowPrice(itemIndex, 'subtract');
      this.setDeliveryPrice();
    } else {
      this.dialogService.message("알림", "최소 1개 이상 선택할 수 있습니다.");
    }
  }

  getLowPrice(itemIndex, type) {
    let item = this.cartList[itemIndex];

    switch (type) {
      case 'add':
        this.originalAmount += item.product.price;
        if (!item.product.salePrice) {
          this.totalAmount += item.product.price;
        } else {
          this.discountAmount += item.product.salePrice;
          this.totalAmount += item.product.salePrice;
        }
        break;
      case 'subtract':
        this.originalAmount -= item.product.price;
        if (!item.product.salePrice) {
          this.totalAmount -= item.product.price;
        } else {
          this.discountAmount -= item.product.salePrice;
          this.totalAmount -= item.product.salePrice;
        }
        break;
    }
  }

  setDeliveryPrice() {
    if (this.totalAmount + this.totalSelectedAmount >= 50000) this.deliveryPrice = 0;
    else this.deliveryPrice = 2500;
  }

  openDaumMapForm() {
    this.dialogService.resizeModal(this.config);
    this.config.disableClose = true;
    this.config.width = "600px";
    
    this.dialogDaumMapRef = this.mdDialog.open(DaumMap, this.config);

    this.dialogDaumMapRef.afterClosed()
      .subscribe((result) => {
        if (result && result.place) {
          this.address = '';

          if (result.place.zipcode) {
            this.address = '(' + result.place.zipcode + ') ';
            this.deliveryInfo['addressInfo'].zipCode = result.place.zipcode;
          } else {
            this.deliveryInfo['addressInfo'].zipCode = '확인불가';
          }
          this.address += result.place.address;
          this.deliveryInfo['addressInfo'].address = result.place.address;
        }
      });
  }

  removeItemInCart() {
    let copyCartList = this.appService.cartList.slice(0);
    let copyProducts = this.transactionService.products;


    for(var i = 0 ; i < copyProducts.length ;i++){
      let findConfig = {product: {name: copyProducts[i].product.name}};
      if (copyProducts[i].selectedOption) findConfig['selectedOption'] = copyProducts[i].selectedOption;
      let findItemIndex = _.findIndex(copyCartList, findConfig);
      copyCartList.splice(findItemIndex, 1);
    }
    // _.forEach(copyProducts, (item) => {
    //   let findConfig = {product: {name: item.product.name}};
    //   if (item.selectedOption) findConfig['selectedOption'] = item.selectedOption;
    //   let findItemIndex = _.findIndex(copyCartList, findConfig);
    //   copyCartList.splice(findItemIndex, 1);
    // });

    this.appService.cartList = copyCartList;
  }

  /*****************************
   *       helper functions
   *****************************/

  getMyUserInfo() {
    this.dialogService.loadingSubject.next('spinner');
    
    this.nonMemberEmail = this._cookieService.get("nonMemberMail");
    this._cookieService.remove("nonMemberMail");
    if(this.appService.user == null)
      return;
    this.authService.getMyUserInfo({
      query: {
        _id: this.appService.user._id,
      },
    })
      .subscribe(
        (userWrapper) => {
          if (userWrapper['user']) { 
            this.appService.user = userWrapper['user'];

            if (userWrapper['user'].deliveryInfo) {
              this.deliveryInfo = userWrapper['user'].deliveryInfo;

              if (this.deliveryInfo.zipCode)
                this.address = '(' + this.deliveryInfo['addressInfo'].zipCode + ') ';
              this.address += this.deliveryInfo['addressInfo'].address;
            }
            this.loadProduct();
          } else if (!userWrapper['user']) {
            this.dialogService.message("알림", "사용자 정보를 찾을 수 없습니다. 다시 로그인 해주세요.");
            this.dialogService.loadingSubject.next('hide');
            this.appService.sendEvent('logout');
          }
        },
        (err) => {
          let subTitle = '';

          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '사용자 정보를 찾을 수 없습니다. 다시 로그인 해주세요.';
              break;
          }
          this.dialogService.message("알림", subTitle);
          this.dialogService.loadingSubject.next('hide');
        }
      )
  }

  loadProduct() {
    let productIds = _.uniq(_.map(this.transactionService.products, 'product._id'));
    this.productService.find({
      query: {
        _id: {
          $in: productIds
        }
      },
      populate: ['photos']
    })
      .finally(() => {
        this.dialogService.loadingSubject.next('hide');
      })
      .subscribe(
        (productsWrapper) => {
          
          let copyCartList = _.clone(this.transactionService.products);
          for(var k =0 ; k < copyCartList.length;k++){
            var kk = -1;
            for(var i =0 ; i < copyCartList[k].selectedOption.length;i++){
              if(copyCartList[k].selectedOption[i].attr){
                this.enableAttrbutes = true;
                copyCartList[k]['attr'] = true;//필수 옵션이 있는 경우
                kk = 1;
                //break;
              }
            }
            if(kk == -1){
              copyCartList[k]['attr'] = false;// 선택옵션만 있는 경우
            }
          }

          // 삭제된 상품 찾아내는 로직
          let findProductIds = _.uniq(_.map(productsWrapper.products, '_id'));
          let differenceIds = _.difference(productIds, findProductIds);

          // 변경된 상품 찾아내는 로직
          let changeProduct = false;
          _.forEach(copyCartList, (item) => {
            _.forEach(productsWrapper['products'], (product) => {
              if (product['_id'] == item['product']._id) {
                if (product['lastUpdatedAt'] > item['product'].lastUpdatedAt) {
                  changeProduct = true;
                } else {
                  item['product'] = product;
                  item['product'].productPhoto = _.find(item['product'].photos, {tag: 'THUMBNAIL'});
                }
              }
            })
          });

          if (differenceIds.length > 0 || changeProduct) {
            this.transactionService.products = [];
            this.dialogService.message("알림", "변경 또는 품절된 상품이 있습니다. 다시 시도해주세요.");
            this.dialogService.loadingSubject.next('hide');
            this.appService.goBack();
          } else {
            this.cartList = copyCartList;
            this.initSelectedItem();
            // 가격 및 수량 구하는 로직
            _.forEach(this.cartList, (item) => {
              // 가격
              if(item.attr){
                if(item.selectedOption.length > 0){
                  for(var i =0; i < item.selectedOption.length;i++){
                    if(item.selectedOption[i].attr){
                      this.originalAmount += (item.selectedOption[i].price * item.selectedOption[i].quantity);
                      this.totalAmount+= (item.selectedOption[i].price * item.selectedOption[i].quantity);  
                    }
                  }
                }
                if(item.selectedOption.length > 0){
                  for(var k =0 ; k < item.selectedOption.length;k++){
                    if(!item.selectedOption[k].attr){
                      this.totalSelectedAmount += item.selectedOption[k].price * item.selectedOption[k].quantity;
                    }
                  }
                }
              } else {
                this.originalAmount += (item.product.price * item.quantity);
                if(item.selectedOption.length > 0){
                  for(var i =0 ; i < item.selectedOption.length;i++){
                    if(!item.selectedOption[i].attr){
                      this.totalSelectedAmount += item.selectedOption[i].price * item.selectedOption[i].quantity;
                    }
                  }
                }
                if (item.product.salePrice) {
                  this.discountAmount += (item.product.salePrice * item.quantity);
                  this.totalAmount += (item.product.salePrice * item.quantity);
                } else {
                  this.totalAmount += (item.product.price * item.quantity);
                }
                // 수량
                this.totalQuantity += item.quantity;
              }
            });
            this.setDeliveryPrice();
          }
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      )
  }
  
  initSelectedItem() {
    for (var i = 0; i < this.cartList.length; i++) {
      for (var j = 0; j < this.cartList[i]['selectedOption'].length; j++) {
        if (this.cartList[i]['selectedOption'][j].quantity == NaN  || this.cartList[i]['selectedOption'][j].quantity == undefined) {
          this.cartList[i]['selectedOption'][j].quantity = 1;
          if(this.cartList[i]['selectedOption'][j].attr == "false"){
            this.totalSelectedAmount += this.cartList[i]['selectedOption'][j].price;
          }
        }
      }
    }
  }

  buyProduct() {
    this.dialogService.loadingSubject.next('spinner');
    
    let itemList = [];

    let defaultName = '';
    // let optionList = [];

    this.totalQuantity = 0;
    // this.totalAmount = 0; //선택옵션만 있는 경우 0으로 하면 이미 계산되었던 totalAmount가 초기화된다. 
    let w_ntotalAmount = 0;// 필수제품 + 추가제품
    let w_bNece = false;

    _.forEach(this.cartList, (item) => {
      let w_bNece = false;
      let optionList = [];

      _.forEach(item.selectedOption, (property)=> {

        if(property.GroupName){// 그룹명이 있는 경우
          if (property.GroupName != defaultName){
            optionList.push({
              name:property.GroupName,
              property:[{
                name:property.name,
                price:property.price,
                quantity:property.quantity,
                soldOut:property.soldOut
              }]
            });
            defaultName = property.GroupName;
          } else {
            _.forEach(optionList,(optionItem)=> {
              if(optionItem.name == property.GroupName){
                optionItem['property'].push({
                  name:property.name,
                  price:property.price,
                  quantity:property.quantity,
                  soldOut:property.soldOut
                })
              }
            });
          }
        } else {// 그룹명이 없는 경우
            optionList.push({
              name:"",
              property:[{
                name:property.name,
                price:property.price,
                quantity:property.quantity,
                soldOut:property.soldOut
              }]
            });
            defaultName = "";
        }

        if(property.attr)// 필수 옵션이 있는 경우
            w_bNece = true;

        this.totalQuantity += property.quantity;
        // this.totalAmount += property.price * property.quantity;
        w_ntotalAmount += property.price * property.quantity;// 필수제품 + 추가제품
      });

      // 선택옵션이 없는 경우는 상품 아이템의 개수를 더해준다. 20180613
      // if(item.selectedOption == null || item.selectedOption.length == 0) {
      if(!w_bNece) {
          this.totalQuantity += item.quantity; 
      } 

      // 장바구니는 여러 상품을 주문할 수 있으므로 이 옵션은 상품별로 추가 되어야 함 
      console.log(optionList);

      let product = _.assign({}, item.product);
      delete product.productPhoto;
      itemList.push({
        product: product,
        quantity: item.quantity,
        selectedOption: optionList
      });

    });

    // 장바구니에서는 여러 상품을 주문 할수 있으므로 이 부분은 위로
    // _.forEach(this.cartList, (item) => {
    //   let product = _.assign({}, item.product);
    //   delete product.productPhoto;
    //   itemList.push({
    //     product: product,
    //     quantity: item.quantity,
    //     selectedOption: optionList
    //   });
    // });

    let totalPrice = 0;

    // if(w_bNece){// 필수옵션이 있는 경우
    //     totalPrice = w_ntotalAmount + this.deliveryPrice;
    // } else {
    //     totalPrice = this.totalAmount + w_ntotalAmount + this.deliveryPrice;
    // }

    totalPrice = this.totalAmount + this.totalSelectedAmount + this.deliveryPrice;

    // if (this.totalAmount >= 50000) this.deliveryPrice = 0;
    // else this.deliveryPrice = 2500;
    if (totalPrice >= 50000) this.deliveryPrice = 0;
    else this.deliveryPrice = 2500;

    totalPrice = this.totalAmount + this.totalSelectedAmount + this.deliveryPrice;    

    let invoice = {
      // totalAmount: this.totalAmount + this.deliveryPrice + this.totalSelectedAmount,
      totalAmount: totalPrice,
      originalAmount: this.originalAmount,
      discountAmount: this.discountAmount,
      deliveryPrice: this.deliveryPrice,
      totalQuantity: this.totalQuantity,
      itemList: itemList,
      paymentType: this.paymentType,
      deliveryInfo: this.deliveryInfo,
      nonMemberEmail: this.nonMemberEmail,
      userId :  this.appService.user._id
    };

    this.transactionService.pendingPayment(invoice)
      .finally(() => {
        this.dialogService.loadingSubject.next('hide');
      })
      .subscribe(
        (paymentWrapper) => {
          if (this.paymentType != 'bank') {
            this.requestPay(paymentWrapper['payment']);
          } else {
            this.removeItemInCart();
            this.appService.goToMenu('/store/success-payment/', paymentWrapper['payment'].invoice);
            this.dialogService.loadingSubject.next('hide');
          }
        },
        (err) => {
          let subTitle = '';
          switch (err.status) { 
            case 400:
              subTitle = '상품을 찾을 수 없습니다.';
              break;
            case 406:
              subTitle = '재고를 확인해주세요.';
              break;
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
          this.dialogService.loadingSubject.next('hide');
          this.appService.goBack();
        }
      )
  }

  requestPay(payment) {
    this.dialogService.loadingSubject.next('spinner');

    let address = this.deliveryInfo.addressInfo.address;
    let postCode = '';
    if (this.deliveryInfo.addressInfo.detailAddress)
      address += this.deliveryInfo.addressInfo.detailAddress;
    if (this.deliveryInfo.addressInfo.zipCode)
      postCode = this.deliveryInfo.addressInfo.zipCode;

    let param_info = {
        pg: 'html5_inicis',
        pay_method: payment.paymentType,
        merchant_uid: payment._id,
        name: payment.name,
        amount: payment.
        paid_amount,
        buyer_email: this.appService.user.identifier,
        buyer_name: this.deliveryInfo.name,
        buyer_tel: this.deliveryInfo.phone,
        buyer_addr: address,
        buyer_postcode: postCode,
        m_redirect_url: config.webUrl + '/store/success-payment/' + payment.invoice
    };

    console.log(param_info);

    this.transactionService.IMP.request_pay(
      {
        pg: 'html5_inicis',
        pay_method: payment.paymentType,
        merchant_uid: payment._id,
        name: payment.name,
        amount: payment.
        paid_amount,
        buyer_email: this.appService.user.identifier,
        buyer_name: this.deliveryInfo.name,
        buyer_tel: this.deliveryInfo.phone,
        buyer_addr: address,
        buyer_postcode: postCode,
        m_redirect_url: config.webUrl + '/store/success-payment/' + payment.invoice
      },
      (response) => {
        if (response.success) {

          this.removeItemInCart();
          this.appService.goToMenu('/store/success-payment/', payment.invoice);

          // this.transactionService.confirmPayment(response.merchant_uid, response.amount)
          //   .finally(() => {
          //     this.dialogService.loadingSubject.next('hide');
          //   })
          //   .subscribe(
          //     (paymentWrapper) => {
          //       this.removeItemInCart();
          //       this.appService.goToMenu('/store/success-payment/', paymentWrapper['payment'].invoice);
          //     },
          //     (err) => {
          //       let subTitle = '';
          //       switch (err.status) {
          //         case 400:
          //           subTitle = '결제내역을 찾을 수 없습니다.';
          //           break;
          //         case 406:
          //           subTitle = '결제에 실패하였습니다.';
          //           break;
          //         case 500:
          //           subTitle = '서버에러';
          //           break;
          //         default:
          //           subTitle = '잘못된 요청입니다.';
          //           break;
          //       }
          //       this.dialogService.message("알림", subTitle).subscribe();
          //     }
          //   )
        } else {
          let paymentInfo = {
            merchant_uid: response.merchant_uid,
            message: response.error_msg
          };

          this.transactionService.failPayment(paymentInfo)
            .finally(() => {
              this.dialogService.loadingSubject.next('hide');
            })
            .subscribe(
              (res) => {
                if (response.error_msg)
                  this.dialogService.message("알림", response.error_msg).subscribe();
                else
                  this.dialogService.message("알림", '결제가 취소되었습니다. 다시 시도해주세요.').subscribe();
              },
              (err) => {
                let subTitle = '';
                switch (err.status) {
                  case 400:
                    subTitle = '결제내역을 찾을 수 없습니다.';
                    break;
                  case 500:
                    subTitle = '서버에러';
                    break;
                  default:
                    subTitle = '잘못된 요청입니다.';
                    break;
                }
                this.dialogService.message("알림", subTitle).subscribe();
              }
            );
        }
      });
  }
  reduceButton(itemIndex,selectedIndex){
    if(this.cartList[itemIndex]['selectedOption'][selectedIndex].quantity == 1){
      this.dialogService.message("알림", "최소 1개 이상 선택할 수 있습니다.");
    } else {
      this.cartList[itemIndex]['selectedOption'][selectedIndex].quantity -= 1;
      let attr = this.cartList[itemIndex]['selectedOption'][selectedIndex].attr;
      this.calculatingPrice(itemIndex,selectedIndex,attr, 'subtract');
      this.setDeliveryPrice();
    }
  }
  addButton(itemIndex,selectedIndex){
    this.cartList[itemIndex]['selectedOption'][selectedIndex].quantity += 1;
    let attr = this.cartList[itemIndex]['selectedOption'][selectedIndex].attr;
    this.calculatingPrice(itemIndex,selectedIndex,attr, 'add');
    this.setDeliveryPrice();
  }
  calculatingPrice(itemIndex,selectedIndex,attr,type){
    let item = this.cartList[itemIndex];
    if(!attr){
      switch (type) {
        case 'add':
          this.totalSelectedAmount += this.cartList[itemIndex]['selectedOption'][selectedIndex].price;
          break;
        case 'subtract':
          this.totalSelectedAmount -= this.cartList[itemIndex]['selectedOption'][selectedIndex].price;
          break;
      }
    }
    else {
      switch (type) {
        case 'add':
          this.totalAmount += this.cartList[itemIndex]['selectedOption'][selectedIndex].price;
          break;
        case 'subtract':
          this.totalAmount -= this.cartList[itemIndex]['selectedOption'][selectedIndex].price;
          break;
      }
    }
  }
}