/**
 * Created by PHILIP on 27/06/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 27/06/2017
 *
 */

// Export Lib
declare var _;

// Angular
import {Component, OnInit, ViewChild} from "@angular/core";
import {ActivatedRoute} from "@angular/router";

// Project Sources
import {getTemplate} from "../../../shared/templates";
import {AppService} from "../../../shared/services/app.service";
import {BannerService} from "../../../shared/services/banner.service";
import {ProductService} from "../../../shared/services/product.service";
import {DialogService} from "../../../shared/components/dialog-message/dialog-message.service";

@Component({
  selector: 'store-home',
  template: getTemplate('pages/store/store-home/store-home')
})
export class StoreHome implements OnInit {

  @ViewChild("bannerSlider") bannerSlider;

  player = new Array();
  ytEvent;

  currentSwiperIndex;
  swiperOptions;
  sliderAutoPlayTime = 3000;
  productType;
  bannerModel;
  productModel;

  firstVideoPlaying = false;
  videoPlaying = false;

  constructor(private router: ActivatedRoute,
              public appService: AppService,
              private bannerService: BannerService,
              private productService: ProductService,
              private dialogService: DialogService) {
  }

  /*****************************
   *         life cycle
   *****************************/
  private static pauseFlag = false;
  private static videoFlag = false;
  otherValue = 0;
  
  ngOnInit() {
    this.loadBanners();

    this.bannerModel = {
      banners: [],
      total: 0
    };
    this.productModel = {
      products: [],
      more: false
    };

    this.router.params
      .subscribe((params) => {
        this.productType = params['type'];
        this.loadProduct(this.productType);
      });

    this.currentSwiperIndex = 0;
    this.swiperOptions = {
      autoplay: this.sliderAutoPlayTime,
      onSlideChangeStart: () => {
        this.onSlideChangeStart();
      },
      onInit: () => {
        this.playVideoSlide(this.currentSwiperIndex);
      }
    };
  }

  ngOnDestroy() {
    /*for (let i = 0; i < this.player.length; i++) {
      if (this.player[i])
        this.player[i].pauseVideo();
    }
    this.player = [];*/
  }

  /*****************************
   *        util functions
   *****************************/
  onSlideChangeStart() {
    this.currentSwiperIndex = this.bannerSlider.swiper.activeIndex;
    this.playVideoSlide(this.currentSwiperIndex);

    setTimeout(function(thisObj) {
      if (!thisObj.videoPlaying) {
        thisObj.bannerSlider.swiper.startAutoplay();
      } else {
      }
    }, 7000, this);
  }

  recursivePlayVideo(thisObj, activeIndex): void {
    setTimeout(function(thisObj) {
      if (thisObj.bannerSlider.swiper) {
        thisObj.videoPlaying = true;
        thisObj.bannerSlider.swiper.stopAutoplay();
      }
      
      if (thisObj.player.length > 0) {
        let currentVideoIndex = -1;
        for (let i = 0; i < thisObj.bannerModel['banners'].length; i++) {
          if (thisObj.bannerModel['banners'][i].fileTypes == 'video') {
            if (i > activeIndex) break;

            currentVideoIndex++;
          }
        } 

        if (currentVideoIndex > -1) {
          thisObj.player[currentVideoIndex].playVideo();

          if (thisObj.bannerSlider.swiper) {
            thisObj.bannerSlider.swiper.stopAutoplay();
          }
        }
      } else {
        thisObj.recursivePlayVideo(thisObj, activeIndex);
      }
    }, 500, this);
  }

  playVideoSlide(activeIndex): void {
    if (this.bannerModel['banners']) {
      if (this.bannerModel['banners'][activeIndex]) {
        if (this.bannerModel['banners'][activeIndex].fileTypes) {
          if (this.bannerModel['banners'][activeIndex].fileTypes == 'video') {
            this.firstVideoPlaying = true;
            this.recursivePlayVideo(this, activeIndex);
          } else {
            this.videoPlaying = false;
          }
        }
      }
    }
  }

  /*****************************
   *       helper functions
   *****************************/
  loadBanners() {
    this.bannerService.find(
      {
        query: {
          category: 'STORE'
        },
        sort: { displayOrder : 1 },
        limit: 30,
        populate: ['photo']
      }
    )
      .subscribe(
        (bannersWrapper) => {
          if (this.bannerSlider && this.bannerSlider.swiper)
            this.bannerSlider.swiper.stopAutoplay();

          for (let i = 0; i < bannersWrapper['banners'].length; i++) {
            let banner = bannersWrapper['banners'][i];
            if (!banner['photo'] && banner['imageVideo'] === 'video') {
              banner['photo'] = {};
              banner['photo'].secure_url = banner.url;
            }
          }

          for (var i = 0 ; i < bannersWrapper.total; i++) {
            var fileTypes = bannersWrapper['banners'][i].photo.fileType.split('/')[0];
            bannersWrapper['banners'][i].fileTypes = fileTypes;
          }

          this.bannerModel['banners'] = bannersWrapper['banners'];
          this.bannerModel['total'] = bannersWrapper['total'];

          setTimeout(function(thisObj) { 
            if (thisObj.bannerModel['total'] > 0) {
              thisObj.playVideoSlide(0);
            }
          }, 500, this);

          setTimeout(function(thisObj) { 
            if (thisObj.bannerSlider.swiper && !thisObj.firstVideoPlaying) {
              thisObj.bannerSlider.swiper.startAutoplay();
            }
          }, 2500, this);
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      );
  }

  loadProduct(type) {   // type: 'all', 'dog', 'cat'
    var findParam = {
        sort: { displayOrder: 1 },
        limit: 20,
        populate: ['photos']
    };

    let productType = 0;
    if (type === 'dog') {
      productType = 1;
    } else if (type === 'cat') {
      productType = 2;
    }

    if (productType != 0) {
      findParam['query'] = {
        '$or': [
          { category: 3 },
          { category: productType }
        ],
        'isDeleted': false
      };
    }

    this.productService.find(findParam)
      .subscribe(
        (productsWrapper) => {            
          this.productModel['products'] = productsWrapper['products'];
          
          _.forEach(this.productModel['products'], (product) => {
            product['productPhoto'] = _.find(product.photos, {tag: 'THUMBNAIL'});
          })
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      )
  }

  onStateChange(event) {
    this.ytEvent = event.data;
    if (this.ytEvent === 0) {
      if (this.player.length > 0) {
        var bannerSlider = this.bannerSlider;
        setTimeout(function() {
          bannerSlider.swiper.startAutoplay();
        }, 3000);
      }
    }
  }

  savePlayer(player) {
    this.player.push(player);
  }

}