/**
 * Created by PHILIP on 27/06/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 27/06/2017
 *
 */

// Angular
import {Component, OnInit} from "@angular/core";

// Project Sources
import {getTemplate} from "../../../shared/templates";
import {AppService} from "../../../shared/services/app.service";
import {ProductService} from "../../../shared/services/product.service";
import {DialogService} from "../../../shared/components/dialog-message/dialog-message.service";


import {MdDialog, MdDialogConfig, MdDialogRef} from "@angular/material";
import {SupportForm} from "../../support-form/support-form";
import {PopUpDialog} from "../../popup-dialog/popup-dialog";
import {PopUpDialogService} from "../../../shared/services/popup-dialog.service";

@Component({
  selector: 'product-smartNoseWork',
  template: getTemplate('pages/product/product-smartNoseWork/product-smartNoseWork')
})
export class ProductSmartNoseWork implements OnInit {

  dialogSupportRef: MdDialogRef<SupportForm>;
  config: MdDialogConfig = new MdDialogConfig();  
  dialogPopUpDialogRef: MdDialogRef<PopUpDialog>;

  constructor(public appService: AppService,
              private mdDialog: MdDialog,
              private popupDialogService:PopUpDialogService,
              private dialogService: DialogService) {}

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {
    this.openPopup();
  }
  compareDay(date, serverTime) {
    let dateString = date.toString();
    let serverTimeString = serverTime.toString();

    let dateStringDay = dateString.substring(0, dateString.indexOf('T'));
    let serverTimeStringDay = serverTimeString.substring(0, serverTimeString.indexOf('T'));
    
    if (dateStringDay != serverTimeStringDay)
      return true;
    else
      return false;
  }
  loadPopups(myData) {
    let params: any = {
    };
    
    this.popupDialogService.find(params)
      .subscribe(
        (PopUpsWrapper) => {
            this.appService.popupTotalData = "";
            var isShow = false;
            for(var i = 0 ; i < PopUpsWrapper.total ; i++) {
                var count = 0;
                var index = -1;
                for(var j = 0 ; j < myData.length; j++) {
                    if(myData[j]._id == PopUpsWrapper['PopUps'][i]._id) {
                        count = 1;
                        index = j;
                        let date = myData[j].checkedTime; 
                        let serverCurrentTime = PopUpsWrapper.serverCurrentTime;
                        if (this.compareDay(date, serverCurrentTime)) {
                            isShow = true;
                            break;
                        }
                    }
                }
                if(isShow || count == 0){
                    if (PopUpsWrapper['PopUps'][i].show) {
                        this.dialogPopUpDialogRef = this.mdDialog.open(PopUpDialog, this.config);
                        this.dialogPopUpDialogRef.componentInstance.popupDialog_ID = PopUpsWrapper['PopUps'][i]._id;
                    }
                } else {
                    this.appService.popupTotalData += JSON.stringify(myData[index]) + "&&&";
                }
                
            }
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      )
  }
  loadShowPopup(){
    let params: any = {
    };
    this.popupDialogService.find(params)
      .subscribe(
        (PopUpsWrapper) => {
          var dialogArr = [];
            for (var i = 0 ; i < PopUpsWrapper.total; i++) {
                if (PopUpsWrapper['PopUps'][i].show) {
                    this.dialogPopUpDialogRef = this.mdDialog.open(PopUpDialog, this.config);
                    this.dialogPopUpDialogRef.componentInstance.popupDialog_ID = PopUpsWrapper['PopUps'][i]._id;
                }
            }
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      )
  }
  openPopup(){
    let popupData = localStorage.getItem('PopupData');
    this.appService.popupTotalData = "";
    if(!this.appService.showFlag){
        if (popupData == null || popupData == "") {
            this.appService.showFlag = true;
            this.loadShowPopup();
        } else {
            this.appService.showFlag = true;
            let totalPopup = popupData.split('&&&');
            let popup = [];
            for(var i =0 ; i < totalPopup.length -1;i++){
                popup.push(JSON.parse(totalPopup[i]));
            }
            this.loadPopups(popup);
        }
    }
  }

  /*****************************
   *       helper functions
   *****************************/

}