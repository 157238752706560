/**
 * Created by andy on 2/21/17
 * As part of web
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & andy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by andy <>, 2/21/17
 *
 */

// Angular
import {Pipe} from "@angular/core";

@Pipe({
    name: 'phone'
})
export class PhonePipe {
  transform(val, args?) {
    //TODO: get args as type e.g. "lane-line" or "mobile"
    // for now we only do mobile

    let result;

    if (val[2] && val[3]) result = val.substring(0, 3) + "-" + val.substring(3, 7);

    if (val[6] && val[7]) result = result + "-" + val.substring(7);
    else result = result + val.substring(7);

    return result;
  }
}