/**
 * Created by Yoon Yong (Andy) Shin on 23/12/2016
 * As part of Nosework
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & Yoon Yong (Andy) Shin - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Yoon Yong (Andy) Shin <andy.shin@applicat.co.kr>, 23/12/2016
 *
 */

// Angular
import {Directive, ElementRef, Output, EventEmitter, OnInit, OnDestroy, ChangeDetectionStrategy} from '@angular/core';

@Directive({
  selector: 'scroller'
})
export class Scroller implements OnInit, OnDestroy {

  @Output() scroll: EventEmitter<any> = new EventEmitter();

  private scrollYPos: number = 0;
  private scrollXPos: number = 0;
  private element: any;
  private parentElement: any;

  constructor(element: ElementRef) {
    this.element = element.nativeElement;
    this.element.classList.add('datatable-scroll');
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {
    this.parentElement = this.element.parentElement.parentElement;
    this.parentElement.addEventListener('scroll', this.onScrolled.bind(this));
  }

  ngOnDestroy() {
    this.parentElement.removeEventListener('scroll');
  }

  /*****************************
   *        util functions
   *****************************/

  setOffset(offsetY: number) {
    if (this.parentElement) {
      this.parentElement.scrollTop = offsetY;
    }
  }

  onScrolled(event) {
    const dom = event.currentTarget;
    this.scrollYPos = dom.scrollTop;
    this.scrollXPos = dom.scrollLeft;
  }
}
