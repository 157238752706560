/**
 * Created by PHILIP on 11/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 11/07/2017
 *
 */

// Angular
import {Component, HostListener, OnInit, Renderer, ViewChild} from "@angular/core";
import {MdDialogRef} from "@angular/material";

// Project Sources
import {getTemplate} from "../../shared/templates";
import {throttleable} from "../../shared/services/util";
import {AuthService} from "../../shared/services/auth.service";
import {DialogService} from "../../shared/components/dialog-message/dialog-message.service";

@Component({
  selector: 'support-form',
  template: getTemplate('pages/support-form/support-form')
})
export class SupportForm implements OnInit {

  @ViewChild("textarea") textarea;

  supportInfo;

  contentSize;

  constructor(private renderer: Renderer,
              private dialogRef: MdDialogRef<SupportForm>,
              private authService: AuthService,
              private dialogService: DialogService){}

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit(){
    this.recalcSize();

    this.supportInfo = {
      email: '',
      content: ''
    }
  }

  /*****************************
   *        util functions
   *****************************/

  @HostListener('window:resize')
  @throttleable(5)
  recalcSize() {
    this.contentSize = this.dialogService.calcContentSize();
  }

  isValid(){
    if(!this.supportInfo['email'] || this.supportInfo['email'].length <= 0) return false;
    else if(!this.supportInfo['content'] || this.supportInfo['content'].length <= 0) return false;
    else return true;
  }

  inputChange(){
    if(!this.supportInfo.content || this.supportInfo.content.length <= 0)
      this.renderer.setElementClass(this.textarea.nativeElement, "focus", false);
    else
      this.renderer.setElementClass(this.textarea.nativeElement, "focus", true);
  }

  dismiss(){
    this.dialogRef.close();
  }

  /*****************************
   *       helper functions
   *****************************/

  send() {
    this.dialogService.loadingSubject.next('spinner');

    this.authService.support(this.supportInfo)
      .finally(() => {
        this.dialogService.loadingSubject.next('hide');
      })
      .subscribe(
        (result) => {
          this.dialogService.message("알림", "문의 해주셔서 감사합니다.")
            .subscribe(() => {
              this.dismiss();
            });
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 413:
            subTitle = "첨부파일 제한 사이즈가 초과 하였습니다.     (";
              break;
            case 500:
            subTitle = "MAIL 서버에러.     (";
              break;
            case 400:
            subTitle = "잘못된 요청입니다. E-mail 주소를 확인해주세요.     (";
              break;
            case 401:
            subTitle = "요청권한이 없습니다.     (";
              break;
            case 403:
            subTitle = "금지된 요청입니다.     (";
              break;
            case 0:  
            subTitle = "서버 상태를 확인해주세요.     (";
              break;
            default:
            subTitle = "잘못된 요청입니다.     (";
              break;
          }
          this.dialogService.message("알림", subTitle+"\n"+"ERR-CODE :"+err.status+" )");
        });
  }

}