/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */

declare var wcs;
declare var wcs_do;

// Export Lib
declare var _;

// Angular
import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";

// Project Sources
import {getTemplate} from "../../../../shared/templates";
import {AppService} from "../../../../shared/services/app.service";
import {TransactionService} from "../../../../shared/services/transaction.service";
import {DialogService} from "../../../../shared/components/dialog-message/dialog-message.service";

@Component({
  selector: 'invoice-detail',
  template: getTemplate('pages/store/invoice-list/invoice-detail/invoice-detail')
})
export class InvoiceDetail implements OnInit {

  invoiceId;
  invoice;
  option;

  optionList = [];

  constructor(private activatedRoute: ActivatedRoute,
              private appService: AppService,
              private transactionService: TransactionService,
              private dialogService: DialogService) {
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {
    if (!wcs_add) var wcs_add = {};

    wcs_add["wa"] = "s_52e6d95fb9a0";
    if (!_nasa) var _nasa = {};

    wcs.inflow();
    wcs_do(_nasa);
    this.option = [];
    this.activatedRoute.params
      .subscribe((params) => {
        this.invoiceId = params['invoiceId'];
        if (!this.invoiceId || this.invoiceId === '') {
          this.dialogService.message('알림', '잘못된 요청입니다.');
          this.appService.goToMenu('/store');
        } else {
          this.initLoadSequence();
        }
      });

      this.optionList = [];
  }

  /*****************************
   *        util functions
   *****************************/

  initLoadSequence() {
    this.loadInvoice();
  }

  /*****************************
   *       helper functions
   *****************************/

  loadInvoice() {
    this.transactionService.myInvoice({
      query: {
        _id: this.invoiceId
      },
      populate: ['products']
    })
      .subscribe(
        (invoiceWrapper) => {
          if (invoiceWrapper['invoice']) {
            /*this.invoice = invoiceWrapper['invoice'];
            _.forEach(this.invoice.itemList, (item) => {
              if (item.product.salePrice) {
                item['totalAmount'] = item.product.salePrice * item.quantity;
              } else {
                item['totalAmount'] = item.product.price * item.quantity;
              }
            });

            for (let i = 0; i < this.invoice.itemList.length; i++) {
              if (this.invoice.itemList[i].product.option.length > 0) {
                for (let j = 0; j < this.invoice.itemList[i].product.option.length; j++) {
                  for (let k = 0; k < this.invoice.itemList[i].product.option[j].properties.length; k++) {
                    const optionValue = {
                      optionName: this.invoice.itemList[i].product.option[j].name,
                      propertyName: '',
                      propertyPrice: ''
                    };
                    optionValue['propertyName'] = this.invoice.itemList[i].product.option[j].properties[k].name;
                    optionValue['propertyPrice'] = this.invoice.itemList[i].product.option[j].properties[k].price;
                    this.invoice.itemList[i].selectedOption.push(optionValue);
                  }
                }
              }
            }*/
            
            this.invoice = invoiceWrapper['invoice'];

            if (this.invoice.deliveryInfo) {
              if (!this.invoice.deliveryInfo.name) this.invoice.deliveryInfo.name = "";
            }
            
            _.forEach(this.invoice.itemList, (item) => {
              item.optionList = [];

              if (item.product.salePrice)
                item['totalAmount'] = item.product.salePrice * item.quantity;
              else
                item['totalAmount'] = item.product.price * item.quantity;
            });
            
            // this.invoice.totalQuantity  는 invoice.totalQuantity 를 그대로 이용 
            // this.invoice.totalQuantity = 0;
            // for (var i =0 ; i < this.invoice.itemList.length; i++) {
            //   this.invoice.totalQuantity += this.invoice.itemList[i].quantity;
            // }

            for (var i = 0; i < this.invoice.itemList.length; i++) {
              var item = this.invoice.itemList[i];
              var optionOne = [];
              if (item.selectedOption != null && item.selectedOption.length > 0) {
                for (var j = 0; j < item.selectedOption.length; j++) {
                  if (item.selectedOption[j].property.length > 0) {
                    for (var k = 0; k < item.selectedOption[j].property.length; k++) {
                      var optionName = "";
                      if (item.selectedOption[j].name && item.selectedOption[j].name != 'undefined')
                        optionName = item.selectedOption[j].name;

                      if (item.selectedOption[j].property[k].name && item.selectedOption[j].property[k].name != 'undefined') {
                        if (optionName == "") {
                          optionName = item.selectedOption[j].property[k].name;
                        } else {
                          optionName = optionName + "-" + item.selectedOption[j].property[k].name;
                        }
                      }

                      var optionObj = {
                        name: optionName,
                        price: item.selectedOption[j].property[k].price,
                        quantity: item.selectedOption[j].property[k].quantity
                      };
                      optionOne.push(optionObj);
                    }
                  }
                }

                if (optionOne.length > 0) {
                  if(item.product.attribute && item.product.attribute.length > 0)// 필수 옵션이 있는 경우에만 totalAmount 다시 계산 
                    item['totalAmount'] = 0;
                  // this.invoice.totalQuantity = 0;
                  for (var k = 0; k < optionOne.length; k++) {
                    item['totalAmount'] += optionOne[k].price * optionOne[k].quantity;
                    // this.invoice.totalQuantity += optionOne[k].quantity;
                  }
                }

                item.optionList = optionOne;
                
                this.optionList.push(optionOne);
              } else {
                // 옵션이 없는 경우에는 빈옵션을 추가해준다. 20180615
                item.optionList = optionOne;
                this.optionList.push(optionOne);
              }
            }

            switch (this.invoice.status) {
              case 'PAYMENT_PENDING':
                this.invoice['translateStatus'] = '결제대기';
                break;
              case 'PAYMENT_FAILED':
                this.invoice['translateStatus'] = '결제실패';
                break;
              case 'PAYMENT_DONE':
                this.invoice['translateStatus'] = '결제완료';
                break;
              case 'ORDER_CANCELLED':
                this.invoice['translateStatus'] = '주문취소';
                break;
              case 'DELIVERY_PREPARE':
                this.invoice['translateStatus'] = '배송준비';
                break;
              case 'DELIVERING':
                this.invoice['translateStatus'] = '배송중';
                break;
              case 'DELIVERED':
                this.invoice['translateStatus'] = '배송완료';
                break;
              case 'EXCHANGE_REQUESTED':
                this.invoice['translateStatus'] = '교환요청';
                break;
              case 'EXCHANGE_DONE':
                this.invoice['translateStatus'] = '교환완료';
                break;
              case 'REFUND_REQUESTED':
                this.invoice['translateStatus'] = '환불요청';
                break;
              case 'REFUND_DONE':
                this.invoice['translateStatus'] = '환불완료';
                break;
              case 'ORDER_DONE':
                this.invoice['translateStatus'] = '주문완료';
                break;
            }
            switch(this.invoice.paymentType){
              case 'card':
                this.invoice['translatePaymentType'] = '신용카드';
                break;
              case 'trans':
                this.invoice['translatePaymentType'] = '실시간 계좌이체';
                break;
              case 'bank':
                this.invoice['translatePaymentType'] = '무통장 입금';
                break;
            }
          } else {
            this.dialogService.message('알림', '찾을 수 없는 상품입니다.');
            this.appService.goToMenu('/store');
          }
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message('알림', subTitle);
          this.appService.goToMenu('/store');
        }
      );
  }

}