/**
 * Created by Yoon Yong (Andy) Shin on 23/12/2016
 * As part of Nosework
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & Yoon Yong (Andy) Shin - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Yoon Yong (Andy) Shin <andy.shin@applicat.co.kr>, 23/12/2016
 *
 */

// Angular
import { Directive, forwardRef, Attribute } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[validateEqual][formControlName],[validateEqual][formControl],[validateEqual][ngModel]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => EqualValidator), multi: true}
  ]
})
export class EqualValidator implements Validator {


  constructor(@Attribute('validateEqual') public validateEqual: string) {
  }

  validate(c: FormControl): { [key: string]: any } {
    // self value (e.g. retype password)
    let v = c.value;
    // control value (e.g. password)
    let e = c.root.get(this.validateEqual).value;
    // value not equal
    if (e && v !== e.value) return {
      validateEqual: false
    };
    return null;
  }

  static isPasswordMatch(ref: string): ValidatorFn {
    return (c: FormControl)=> {
      // self value (e.g. retype password)
      let hostValue = c.value;

      // control value (e.g. password)
      let refControl = c.root.get(ref);

      // value not equal
      if (refControl && hostValue !== refControl.value) return {
        validateEqual: false
      };
      return null;
    }
  }

  static notify(ref: string): ValidatorFn {
    return (c: FormControl)=> {
      // control value (e.g. password)
      let refControl = c.root.get(ref);

      // value not equal
      if (refControl) {
        refControl.updateValueAndValidity();
      }

      return null;
    }
  }
}