/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */

// Export Lib
declare var _;
declare var wcs;
declare var wcs_do;

// Angular
import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";

// Project Sources
import {getTemplate} from "../../../../shared/templates";
import {AppService} from "../../../../shared/services/app.service";
import {AuthService} from "../../../../shared/services/auth.service";
import {TransactionService} from "../../../../shared/services/transaction.service";
import {DialogService} from "../../../../shared/components/dialog-message/dialog-message.service";

@Component({
  selector: 'success-payment',
  template: getTemplate('pages/store/payment/success-payment/success-payment')
})
export class SuccessPayment implements OnInit {

  invoiceId;
  invoice;

  constructor(private activatedRoute: ActivatedRoute,
              public appService: AppService,
              private authService: AuthService,
              private transactionService: TransactionService,
              private dialogService: DialogService) {
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {
    this.activatedRoute.params
      .subscribe((params => {
        this.invoiceId = params['invoiceId'];
        if (!this.invoiceId || this.invoiceId == '') {
          this.dialogService.message("알림", "잘못된 요청입니다.");
          this.appService.goToMenu('/store');
        } else {
          this.initLoadSequence();
        }
      }));
  }

  /*****************************
   *        util functions
   *****************************/

  initLoadSequence() {
    this.loadInvoice();
  }

  /*****************************
   *       helper functions
   *****************************/

  changeAddress() {
    this.dialogService.loadingSubject.next('spinner');

    let user = this.appService.user;
    user['deliveryInfo'] = this.invoice.deliveryInfo;

    this.authService.updateMyInfo(user)
      .finally(() => {
        this.dialogService.loadingSubject.next('hide');
      })
      .subscribe(
        (userWrapper) => {
          this.appService.user = userWrapper['user'];
          this.dialogService.message("알림", "배송지 정보가 변경되었습니다.");
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      )
  }

  loadInvoice() {
    this.transactionService.myInvoice({
      query: {
        _id: this.invoiceId
      },
      populate: ['products']
    })
      .subscribe(
        (invoiceWrapper) => {
          if (invoiceWrapper['invoice']) {
            this.invoice = invoiceWrapper['invoice'];

            if (this.invoice.itemList.length > 0 && this.invoice.itemList[0].product.photos.length > 0)
              this.invoice['productPhoto'] = this.invoice.itemList[0].product.photos[0].secure_url;
            else
              this.invoice['productPhoto'] = 'assets/img/img_default_product.png';

            switch (this.invoice.status) {
              case 'PAYMENT_PENDING':
                this.invoice['translateStatus'] = '결제대기';
                break;
              case 'PAYMENT_FAILED':
                this.invoice['translateStatus'] = '결제실패';
                break;
              case 'PAYMENT_DONE':
                this.invoice['translateStatus'] = '결제완료';
                break;
              case 'ORDER_CANCELLED':
                this.invoice['translateStatus'] = '주문취소';
                break;
              case 'DELIVERY_PREPARE':
                this.invoice['translateStatus'] = '배송준비';
                break;
              case 'DELIVERING':
                this.invoice['translateStatus'] = '배송중';
                break;
              case 'DELIVERED':
                this.invoice['translateStatus'] = '배송완료';
                break;
              case 'EXCHANGE_REQUESTED':
                this.invoice['translateStatus'] = '교환요청';
                break;
              case 'EXCHANGE_DONE':
                this.invoice['translateStatus'] = '교환완료';
                break;
              case 'REFUND_REQUESTED':
                this.invoice['translateStatus'] = '환불요청';
                break;
              case 'REFUND_DONE':
                this.invoice['translateStatus'] = '환불완료';
                break;
              case 'ORDER_DONE':
                this.invoice['translateStatus'] = '주문완료';
                break;
            }
            switch (this.invoice.paymentType) {
              case 'card':
                this.invoice['translatePaymentType'] = '신용카드';
                break;
              case 'trans':
                this.invoice['translatePaymentType'] = '실시간 계좌이체';
                break;
              case 'bank':
                this.invoice['translatePaymentType'] = '무통장 입금';
                break;
            }


            if(this.invoice.paymentType != 'bank'){// 무통장입금인 경우에는 무시  2018.05.25
              if (invoiceWrapper.result && invoiceWrapper.result != 'paid') {
                this.dialogService.message("알림", "결제가 취소되었습니다.");
                return this.appService.goToMenu('store/payment');
              }
            }


            this.removeItemInCart();

            var _nasa = {};
            _nasa["cnv"] = wcs.cnv("1", this.invoice.totalPrice); // 전환유형, 전환가치 설정해야함. 설치매뉴얼 참고
            if (!wcs_add) var wcs_add = {};
            wcs_add["wa"] = "s_52e6d95fb9a0";

            wcs.inflow();
            wcs_do(_nasa);


            let user = this.appService.user;
            if (
              !user.deliveryInfo ||
              this.invoice.deliveryInfo.name != user.deliveryInfo.name ||
              this.invoice.deliveryInfo.phone != user.deliveryInfo.phone ||
              this.invoice.deliveryInfo.addressInfo.address != user.deliveryInfo.addressInfo.address ||
              this.invoice.deliveryInfo.addressInfo.detailAddress != user.deliveryInfo.addressInfo.detailAddress ||
              this.invoice.deliveryInfo.addressInfo.zipCode != user.deliveryInfo.addressInfo.zipCode
            ) {
              this.dialogService.confirm("알림", "기본배송지로 등록하시겠습니까?")
                .filter(function (x) {
                  return x;
                })
                .subscribe(() => {
                  this.changeAddress();
                });
            }

          } else {
            this.dialogService.message("알림", "찾을 수 없는 상품입니다.");
            this.appService.goToMenu('/store');
          }
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
          this.appService.goToMenu('/store');
        }
      )
  }

  removeItemInCart() {
    let copyCartList = this.appService.cartList.slice(0);
    let copyProducts = this.transactionService.products;

    _.forEach(copyProducts, (item) => {
      let findConfig = {product: {name: item.product.name}};
      if (item.selectedOption) findConfig['selectedOption'] = item.selectedOption;
      let findItemIndex = _.findIndex(copyCartList, findConfig);
      copyCartList.splice(findItemIndex, 1);
    });

    this.appService.cartList = copyCartList;
  }

}