/**
 * Created by Yoon Yong (Andy) Shin on 10/12/2016
 * As part of Nosework
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & Yoon Yong (Andy) Shin - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Yoon Yong (Andy) Shin <andy.shin@applicat.co.kr>, 10/12/2016
 *
 */

// Angular
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'photo'})
export class PhotoPipe implements PipeTransform {

  constructor() {
  }

  transform(value: any, operation: string, argument?: any): string {
    switch (operation) {
      case 'resize':
        return PhotoPipe.resize(value, argument);
      default:
        return value;
    }
  }

  static resize(value: string, argument: string): string {
    let lastIndex = value.lastIndexOf('/');
    if (lastIndex === -1)
      return value;

    let hostName = value.substring(0, lastIndex + 1);
    let photoName = value.substring(lastIndex);

    return hostName + argument + photoName;
  }
}