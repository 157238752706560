/**
 * Created by Yoon Yong (Andy) Shin on 23/12/2016
 * As part of Nosework
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & Yoon Yong (Andy) Shin - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Yoon Yong (Andy) Shin <andy.shin@applicat.co.kr>, 23/12/2016
 *
 */

// Browser Lib
import 'core-js/client/shim';
import 'es6-promise';
import 'zone.js/dist/zone';

// Angular
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// Angular Third Party Lib

// Project Sources
import { AppModule } from './app.module';
// import {enableProdMode} from "@angular/core";
//
// enableProdMode();

platformBrowserDynamic().bootstrapModule(AppModule);
