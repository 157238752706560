/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */

// Export Lib
declare var _;
declare var wcs;
declare var wcs_do;
declare var window;

// Browser Lib
import {Observable} from "rxjs";
import "rxjs/add/operator/toPromise";
import 'rxjs/Rx';

// Angular
import {Component, OnInit} from "@angular/core";

// Angular
import {Injectable} from "@angular/core";
import {RequestOptions, URLSearchParams, Headers} from "@angular/http";
import { Response } from '@angular/http';

// Angular Third Party Lib
import {InterceptableHttp} from "../../../lib/ng-http-interceptor";

// Project Sources
import {getTemplate} from "../../../shared/templates";
import {AppService} from "../../../shared/services/app.service";
import {ProductService} from "../../../shared/services/product.service";
import {TransactionService} from "../../../shared/services/transaction.service";
import {DialogService} from "../../../shared/components/dialog-message/dialog-message.service";
import {LoginConfirmService} from "../../../shared/components/login-confirm/login-confirm.service";

// Project Sources
import {config} from "../../../app/app.config";

@Component({
  selector: 'cart-list',
  template: getTemplate('pages/store/cart-list/cart-list')
})
export class CartList implements OnInit{
  
  private productUrl = config.webUrl+"/store/product-detail/";
  private cartUrl = config.webUrl+"/store/cart-list"; // 장바구니
  private shopId = "np_xfeav780531";
  private certikey = "A6730EFC-7050-4E39-B9EC-085EB20C4FBC";

  cartList;

  allSelected;

  deliveryPrice;
  totalPrice;
  totalSelectPrice;

  // 찜정보 등록후 네이버로부터 받은 상품아이디
  itemIds;

  constructor(public appService: AppService,
              private productService: ProductService,
              private transactionService: TransactionService,
              private dialogService: DialogService,
              private loginConfirmService: LoginConfirmService,
              private  http: InterceptableHttp) {
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit(){

    // if (!wcs_add) var wcs_add = {};
    // wcs_add["wa"] = "s_52e6d95fb9a0";
    // if (!_nasa) var _nasa = {};
    // wcs.inflow();
    // wcs_do(_nasa);

    this.cartList = [];

    this.allSelected = false;

    this.deliveryPrice = 2500;
    this.totalPrice = 0;
    this.totalSelectPrice = 0;
    this.initLoadSequence();

  }

  /*****************************
   *        util functions
   *****************************/

  initLoadSequence(){
    this.loadProduct();
  }

  loadNaverPayButton(){
    var this2 = this;
    var naver = window.naver;
    
    if(this.appService.user != null && this.appService.user.identifier == "test@test.co.kr") {
      setTimeout(function() {
        naver.NaverPayButton.apply({
          BUTTON_KEY: "FE7A3593-C4FD-401C-A1CC-4310B876A33E",
          TYPE: "B", //버튼 스타일
          COLOR: 1, //버튼 색상타입
          COUNT: 2, // 네이버페이버튼 + 찜하기버튼 모두 출력 여부
          ENABLE: "Y", //네이버페이 활성여부(재고부족 등에는 N으로 비활성처리)
          EMBED_ID: "naverPayBtn", //네이버페이 버튼 UI가 부착될 HTML element의 ID
          BUY_BUTTON_HANDLER : function(url){
            this2.buy_nc();
          },
          WISHLIST_BUTTON_HANDLER : function(url) {
            this2.wishlist_nc();
          },
          "":""
        });
      }, 1000);
    }    
  }

  // 네이버 구매하기 버튼
  buy_nc() {

    let cartList = [];
    _.forEach(this.cartList, (item) => {
      if(item.selected)
        cartList.push({
          product: item.product,
          selectedOption: item.selectedOption,
           quantity: item.quantity,
           basket:true,
        });
    });
    if(cartList.length <= 0){
      this.dialogService.message("알림", "선택된 상품이 없습니다.");
      return false;      
    }

    let url = "https://test-api.pay.naver.com/o/customer/api/order/v20/register";

    // 주문서 작성
    let quantity = 1;
    let xml_request = "<?xml version=\"1.0\" encoding=\"utf-8\" ?>\n";
    xml_request += "<order>\n"; 
    xml_request += "<merchantId>" + this.shopId + "</merchantId>\n"; // 가맹점 아이디
    xml_request += "<certiKey>" + this.certikey + "</certiKey>\n";// 가맹점 인증키

    for(var i = 0; i < this.cartList.length; i++){
      let item = this.cartList[i];
      var item_price = 0;
      item_price = this.calculateLowPrice(i);

      if(item.selected){
        let imageUrl = item.product.productPhoto['url'];
        imageUrl = imageUrl.replace('http://images.nosework-official.com', 'http://s3.ap-northeast-2.amazonaws.com/images.nosework-official.com');

        xml_request += "<product>\n";
        xml_request += "<id>" + item.product['_id'] + "</id>\n";
        xml_request += "<name>" + item.product['name'] + "</name>\n";
        xml_request += "<basePrice>" + item.product['price'] + "</basePrice>\n";// 실지 네이버페이에 현시되는 총 가격은 item.quantity * item.product['price']
        // xml_request += "<basePrice>" + item_price + "</basePrice>\n";
        xml_request += "<infoUrl>" + this.productUrl + item.product['name'] + "</infoUrl>\n";
        xml_request += "<imageUrl>" + item.product.productPhoto['url'] + "</imageUrl>\n";

        if(item.selectedOption.length == 0){
          // 단독형인 경우
          xml_request += "<single>\n";
          xml_request += "<quantity>" + item.quantity + "</quantity>\n";
          xml_request += "</single>\n";
        } else {
        // 옵션 및 추가 상품이 있는 경우
        for(var j = 0; j < item.selectedOption.length; j++){
            let selectOptions = item.selectedOption[j];
            // 선택옵션이 있는 경우
            if(selectOptions.attr == true){
              xml_request += "<option>\n";
              xml_request += "<quantity>" + selectOptions.quantity + "</quantity>\n";
              xml_request += "<selectedItem>\n";
              xml_request += "<type>SELECT</type>\n";
              xml_request += "<name>상품종류</name>\n";
              xml_request += "<value>\n";
              xml_request += "<id>" + selectOptions._id + "</id>\n";
              xml_request += "<text>" + selectOptions.name + "</text>\n";
              xml_request += "</value>\n";
              xml_request += "</selectedItem>\n";
              xml_request += "</option>\n";
            }
          }

          for(var j = 0; j < item.selectedOption.length; j++){
            let selectOptions = item.selectedOption[j];
            if(selectOptions.attr == false){
              // 추가 상품이 있는 경우
              xml_request += "<supplement>\n";
              xml_request += "<id>" + selectOptions._id + "</id>\n";
              xml_request += "<name>" + selectOptions.name + "</name>\n";
              xml_request += "<quantity>" + selectOptions.quantity + "</quantity>\n";
              xml_request += "<price>" + selectOptions.price + "</price>\n";
              xml_request += "</supplement>\n";
            }
          }
        }

        // 배송정책
        xml_request += "<shippingPolicy>\n";

        if(this.deliveryPrice == 0 ){
          xml_request += "<feePayType>FREE</feePayType>\n";
          xml_request += "<feeType>FREE</feeType>\n";
          xml_request += "<feePrice>0</feePrice>\n";
        } else {
          xml_request += "<feePayType>PREPAYED</feePayType>\n";
          xml_request += "<feeType>CHARGE</feeType>\n";
          xml_request += "<feePrice>2500</feePrice>\n";
        }

        xml_request += "</shippingPolicy>\n";
        xml_request += "</product>\n";
      }
    }

    xml_request += "<backUrl>" + this.cartUrl + "</backUrl>\n";
    xml_request += "</order>\n";

    // 주문서 요청
    let formData = xml_request;

    let headers = new Headers({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
      'Access-Control-Allow-Credentials': 'true',
      'Content-type': 'text/xml',
    });

    let options = new RequestOptions({headers : headers});

    console.log(headers);
    console.log(formData);

    let requestParam = {
      data : encodeURI(formData)
    }

    // 서버에서 요청 진행
    this.transactionService.requestNaverPay(requestParam)
      .finally(() => {
        this.dialogService.loadingSubject.next('hide');
      })
      .subscribe(
        (requestWrapper) => {
          // requestWrapper['param1'] : 인증키
          // requestWrapper['param2'] : 가맹점번호
          if(requestWrapper['param0'] == 'SUCCESS'){
              // let redirectUrl = 'https://order.pay.naver.com/customer/buy/' + requestWrapper['param1'] + '/' + requestWrapper['param2']; // 서비스
              let redirectUrl = 'https://test-order.pay.naver.com/customer/buy/' + requestWrapper['param1'] + '/' + requestWrapper['param2'];// 테스트
              console.log(redirectUrl);
              window.location.href = redirectUrl;
          }
        },
        (err) => {
          console.log(err);
          alert("주문서 요청 실패 " + err);
        }
      )

    return false;
  }

  wishlist_nc(){
    let querString = "SHOP_ID=" + this.shopId;
    querString += "&CERTI_KEY=" + this.certikey;
    querString += "&RESERVE1=&RESERVE2=&RESERVE3=&RESERVE4=&RESERVE5=";

    for(var i = 0; i < this.cartList.length; i++){
      let item = this.cartList[i];

      if(item.selected){
        querString += "&ITEM_ID=" + item.product['_id'];
        querString += "&ITEM_NAME=" + item.product['name'];
        querString += "&ITEM_UPRICE=" + item.product['price'];
        querString += "&ITEM_IMAGE=" + item.product.productPhoto['url'];
        querString += "&ITEM_URL=" + this.productUrl + item.product['name'];
      }
    }

    let en_data = encodeURI(querString);
    let requestParam = {
      data : en_data
    }

        // 서버에서 요청 진행
    this.transactionService.requestNaverPayWish(requestParam)
      .finally(() => {
        this.dialogService.loadingSubject.next('hide');
      })
      .subscribe(
        (requestWrapper) => {
          console.log(requestWrapper);
          let bodys = requestWrapper['body'];
          bodys = bodys.trim();
          this.itemIds = bodys.split(","); //찜정보 등록후 네이버에서 받은 상품 아이디

          console.log(this.itemIds);

          let wishlistPopupUrl = "https://test-pay.naver.com/customer/wishlistPopup.nhn?";
          wishlistPopupUrl += "SHOP_ID=" + this.shopId;
          for(var i = 0; i < this.itemIds.length; i++){
            wishlistPopupUrl += "&ITEM_ID=" + this.itemIds[i];
          }

          window.location.href = wishlistPopupUrl;
        },
        (err) => {
          console.log(err);
          alert("찜 정보 등록 실패 " + err);
        }
      )
    return false;
  }

  allSelectProduct(){
    let findSelected = _.find(this.cartList, {selected: true});

    if(!findSelected){
      let price = 0;
      _.forEach(this.cartList, (item, index) => {
        item.selected = true;
        if(item.selectedOption.length > 0){
          this.totalSelectPrice += this.calculateSelectedPrice(index);
        }
        price += this.calculateLowPrice(index);
      });
      
      this.totalPrice = price;
      this.setDeliveryPrice();
    } else {
      _.forEach(this.cartList, (item) => {
        item.selected = false;
      });
      this.totalSelectPrice = 0;
      this.totalPrice = 0;
      this.setDeliveryPrice();
    }
  }

  removeSelectProduct(){
    for(let i = this.cartList.length-1; i >= 0; i--){
      if(this.cartList[i].selected)
        this.cartList.splice(i, 1);
    }

    this.appService.cartList = this.cartList;
    this.totalPrice = 0;
    this.setDeliveryPrice();
  }

  selectProduct(itemIndex){
    if(this.cartList[itemIndex].selected){
      this.cartList[itemIndex].selected = false;
      if(this.cartList[itemIndex].selectedOption.length > 0){
        this.totalSelectPrice -= this.calculateSelectedPrice(itemIndex);
      }
      this.totalPrice -= this.calculateLowPrice(itemIndex);
      this.setDeliveryPrice();
    } else {
      this.cartList[itemIndex].selected = true;
      if(this.cartList[itemIndex].selectedOption.length > 0){
        this.totalSelectPrice += this.calculateSelectedPrice(itemIndex);
      }
      this.totalPrice += this.calculateLowPrice(itemIndex);
      this.setDeliveryPrice();
    }
  }

  addQuantity(itemIndex){
    this.cartList[itemIndex].quantity++;
    if (this.cartList[itemIndex].selected){
      this.totalPrice += this.getLowPrice(itemIndex);
      this.setDeliveryPrice();
    }
  }

  subtractQuantity(itemIndex){
    if(this.cartList[itemIndex].quantity > 1) {
      this.cartList[itemIndex].quantity--;
      if (this.cartList[itemIndex].selected){
        this.totalPrice -= this.getLowPrice(itemIndex);
        this.setDeliveryPrice();
      }
    } else {
      this.dialogService.message("알림", "최소 1개 이상 선택할 수 있습니다.");
    }
  }

  getLowPrice(itemIndex){
    let item = this.cartList[itemIndex];

    if(!item.product.salePrice) return item.product.price;
    else return item.product.salePrice;
  }
  calculateSelectedPrice(itemIndex){
    let price = 0;
    for(var i = 0 ; i < this.cartList[itemIndex].selectedOption.length;i++){
      let item = this.cartList[itemIndex].selectedOption[i];
      if(!item.attr){
        price += item.price * item.quantity;
      }
    }
    return price;
  }
  calculateLowPrice(itemIndex){
    let item = this.cartList[itemIndex];
    let price = 0;
    if(item.totalAttr){
      if(item.selectedOption.length > 0 ){
        var attrPrice = 0;
        for(var i =0 ; i < item.selectedOption.length;i++){
          if(item.selectedOption[i].attr){
            attrPrice += item.selectedOption[i].price * item.selectedOption[i].quantity;          
          }
        }
        return attrPrice;
      }
    } else {
      if(!item.product.salePrice) price = item.product.price;
      else price = item.product.salePrice;

      return price * item.quantity;
    }
  }

  selectedBuy(){
    // if(this.appService.user && this.appService.token){
    //   let cartList = [];
    //   _.forEach(this.cartList, (item) => {
    //     if(item.selected)
    //       cartList.push({
    //         product: item.product,
    //         selectedOption: item.selectedOption,
    //         quantity: item.quantity,
    //         basket:true,
    //       });
    //   });
    //   cartList['basket'] = true;
    //   if(cartList.length <= 0){
    //     this.dialogService.message("알림", "선택된 상품이 없습니다.");
    //   } else {
    //     this.transactionService.products = [];
    //     this.transactionService.products = cartList;
    //     this.appService.goToMenu('store/payment');
    //   }
    // } else {
    //   this.openLoginConfirm();
    // }

    // 비회원인 경우도 장바구니에서 상품을 주문할 수 있으므로 아래와 같이 수정 20180608
    let cartList = [];
    _.forEach(this.cartList, (item) => {
      if(item.selected)
        cartList.push({
          product: item.product,
          selectedOption: item.selectedOption,
           quantity: item.quantity,
           basket:true,
        });
    });
    cartList['basket'] = true;
    if(cartList.length <= 0){
      this.dialogService.message("알림", "선택된 상품이 없습니다.");
    } else {
      this.transactionService.products = [];
      this.transactionService.products = cartList;
      if(this.appService.user && this.appService.token){
        this.appService.goToMenu('store/payment');
      } else {
        this.openLoginConfirm();
      }
    }
  }

  allBuy(){
      this.transactionService.products = [];
      this.cartList[0]['basket'] = true;
      this.transactionService.products = this.cartList;

    if(this.appService.user && this.appService.token){
      // 비회원인 경우도 장바구니에서 상품을 주문할수 있으므로 위로 보냄  20180608
      // this.transactionService.products = [];
      // this.cartList[0]['basket'] = true;
      // this.transactionService.products = this.cartList;
      this.appService.goToMenu('store/payment');
    } else {
      this.openLoginConfirm();
    }
  }

  openLoginConfirm() {
    this.loginConfirmService.confirm("확인", "로그인 후 이용가능합니다.<br>로그인 하시겠습니까?");
  }
  addButton(itemIndex,selectedIndex){
    this.cartList[itemIndex]['selectedOption'][selectedIndex].quantity += 1;
    let attr = this.cartList[itemIndex]['selectedOption'][selectedIndex].attr;
    if(this.cartList[itemIndex].selected){
      this.calculatingPrice(itemIndex,selectedIndex,attr, 'add');
      this.setDeliveryPrice();
    }
  }
  reduceButton(itemIndex,selectedIndex){
    if(this.cartList[itemIndex]['selectedOption'][selectedIndex].quantity == 1){
      this.dialogService.message("알림", "최소 1개 이상 선택할 수 있습니다.");
    } else {
      this.cartList[itemIndex]['selectedOption'][selectedIndex].quantity -= 1;
      let attr = this.cartList[itemIndex]['selectedOption'][selectedIndex].attr;
      if(this.cartList[itemIndex].selected){
        this.calculatingPrice(itemIndex,selectedIndex,attr, 'subtract');
        this.setDeliveryPrice();
      }
    }
  }
  // calculatingPrice(itemIndex,selectedIndex,attr,type){
  //   let item = this.cartList[itemIndex];
  //   if(!item.selected)
  //     return;
  //   if(!attr){
  //     switch (type) {
  //       case 'add':
  //         this.totalSelectPrice += this.cartList[itemIndex]['selectedOption'][selectedIndex].price;
  //         break;
  //       case 'subtract':
  //         this.totalSelectPrice -= this.cartList[itemIndex]['selectedOption'][selectedIndex].price;
  //         break;
  //     }
  //   }
  //   else {
  //     switch (type) {
  //       case 'add':
  //         this.totalPrice += this.cartList[itemIndex]['selectedOption'][selectedIndex].price;
  //         break;
  //       case 'subtract':
  //         this.totalPrice -= this.cartList[itemIndex]['selectedOption'][selectedIndex].price;
  //         break;
  //     }
  //   }
  //}

  setDeliveryPrice(){
    if(this.totalPrice + this.totalSelectPrice >= 50000) this.deliveryPrice = 0;
    else this.deliveryPrice = 2500;
  }
  calculatingPrice(itemIndex,selectedIndex,attr,type){
    let item = this.cartList[itemIndex];
    if(!attr){
      switch (type) {
        case 'add':
          this.totalSelectPrice += this.cartList[itemIndex]['selectedOption'][selectedIndex].price;
          break;
        case 'subtract':
          this.totalSelectPrice -= this.cartList[itemIndex]['selectedOption'][selectedIndex].price;
          break;
      }
    }
    else {
      switch (type) {
        case 'add':
          this.totalPrice += this.cartList[itemIndex]['selectedOption'][selectedIndex].price;
          break;
        case 'subtract':
          this.totalPrice -= this.cartList[itemIndex]['selectedOption'][selectedIndex].price;
      }
    }
  }
  /*****************************
   *       helper functions
   *****************************/

  loadProduct() {
    this.dialogService.loadingSubject.next('spinner');

    let productIds = _.uniq(_.map(this.appService.cartList, 'product._id'));

    this.productService.find({
      query: {
        _id: {
          $in: productIds
        }
      },
      sort: { displayOrder: 1 },
      populate: ['photos']
    })
      .finally(() => {
        this.dialogService.loadingSubject.next('hide');
      })
      .subscribe(
        (productsWrapper) => {
          let copyCartList = this.appService.cartList.slice(0);
          for(var i =0 ;i < copyCartList.length ;i++){

            console.log(copyCartList[i].product);

            if(copyCartList[i].selectedOption.length > 0){
              for(var j = 0; j < copyCartList[i].selectedOption.length;j++){
                copyCartList[i].selectedOption[j]['quantity'] = 1;
                if(copyCartList[i].selectedOption[j].attr){
                  copyCartList[i]['totalAttr'] = true;
                }
              }
            }
          }
          // 삭제된 상품 찾아내어 장바구니에서 삭제하는 로직
          let findProductIds = _.uniq(_.map(productsWrapper['products'], '_id'));
          let differenceIds = _.difference(productIds, findProductIds);
          if(differenceIds.length > 0){
            for(let i = copyCartList.length-1; i >= 0; i--){
              _.forEach(differenceIds, (differenceId) => {
                if(copyCartList[i]['product']._id == differenceId)
                  copyCartList.splice(i, 1);
              });
            }
          }

          // 변경된 상품 찾아서 장바구니에서 삭제
          _.forEach(copyCartList, (item, index) => {
            _.forEach(productsWrapper['products'], (product) => {
              if(product['_id'] == item['product']._id){
                if(product['lastUpdatedAt'] > item['product'].lastUpdatedAt) {
                  copyCartList.splice(index, 1);
                } else {
                  item['product'] = product;
                  item['product'].productPhoto = _.find(item['product'].photos, {tag: 'THUMBNAIL'});
                }
              }
            })
          });

          this.cartList = copyCartList;

          this.appService.cartList = copyCartList;

          this.loadNaverPayButton();
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      )
  }

}