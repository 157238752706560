/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */

// Angular
import {Component, OnInit} from "@angular/core";
import {MdDialogRef} from "@angular/material";

// Project Sources
import {getTemplate} from "../../../../shared/templates";
import {AppService} from "../../../../shared/services/app.service";
import {SerialService} from "../../../../shared/services/serial.service";
import {DialogService} from "../../../../shared/components/dialog-message/dialog-message.service";

@Component({
  selector: 'register-genuine',
  template: getTemplate('pages/store/manage-product/register-genuine/register-genuine')
})
export class RegisterGenuine implements OnInit{

  productName;
  serialNumber;

  placeholder;

  constructor(public dialogRef: MdDialogRef<RegisterGenuine>,
              private appService: AppService,
              private serialService: SerialService,
              private dialogService: DialogService){}

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit(){
    this.productName = '';
    this.serialNumber = '';

    this.placeholder = '시리얼 넘버를 입력해주세요.';
  }

  /*****************************
   *        util functions
   *****************************/

  isValid(){
    if(!this.productName || this.productName == '') return false;
    else if(!this.serialNumber || this.serialNumber == '') return false;
    else return true;
  }

  inputFocused(){
    this.placeholder = '시리얼 넘버';
  }

  inputBlurred(){
    if(this.placeholder != '') this.placeholder = '시리얼 넘버';
    else this.placeholder = '시리얼 넘버를 입력해주세요.';
  }

  dismiss(){
    this.dialogRef.close();
  }

  /*****************************
   *       helper functions
   *****************************/

  registered(){

    if(this.isValid()) {
      this.dialogService.loadingSubject.next('spinner');
      this.serialService.registered({
        serialNumber: this.serialNumber,
        productName: this.productName
      })
        .finally(() => {
          this.dialogService.loadingSubject.next('hide');
        })
        .subscribe(
          (serialWrapper) => {
            let serial = serialWrapper['serial'];
            this.dialogService.message("알림", "정품인증을 완료하였습니다.");
            this.appService.sendEvent('reload');
            this.dismiss();
          },
          (err) => {
            let subTitle = '';
            switch (err.status) {
              case 400:
                subTitle = '등록되지 않은 시리얼넘버입니다.';
                break;
              case 409:
                subTitle = '등록할 수 없는 시리얼넘버입니다.';
                break;
              case 500:
                subTitle = '서버에러';
                break;
              default:
                subTitle = '잘못된 요청입니다.';
                break;
            }
            this.dialogService.message("알림", subTitle);
          }
        )
    }
  }

}