/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */

// Browser Lib
declare var _;

// Angular
import {Component, OnInit} from "@angular/core";
import {MdDialogRef} from "@angular/material";
import { CookieService } from 'angular2-cookie/core';

// Project Sources
import {getTemplate} from "../../../shared/templates";
import {AppService} from "../../../shared/services/app.service";
import {AuthService} from "../../../shared/services/auth.service";
import {UserService} from "../../../shared/services/user.service";
import {DialogService} from "../../../shared/components/dialog-message/dialog-message.service";

@Component({
  selector: 'pre-order-history',
  template: getTemplate('pages/login/pre-order-history/pre-order-history')
})
export class PreOrderHistory implements OnInit {

  validationText;

  identifier;
  price;

  constructor(public dialogRef: MdDialogRef<PreOrderHistory>,
              private appService: AppService,
              private authService: AuthService,
              private userService: UserService,
              private dialogService: DialogService,
              private _cookieService: CookieService) {}

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {
    this.identifier = '';
    this.price = 0;
    this.validationText = '';
    //this.goToOrderHistory();
  }

  /*****************************
   *        util functions
   *****************************/

  isValid() {
    let regExpMail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;

    if (!this.identifier || this.identifier === '' || this.identifier.length > 32 || !regExpMail.test(this.identifier)) {
      this.validationText = '이메일을 확인해주세요.';
      return false;
    }

    if (!this.price || this.price === 0 || this.price === '') {
      this.validationText = '결제금액을 확인해주세요.';
      return false;
    }

    return true;
  }

  dismiss() {
    this.dialogRef.close();
  }

  /******************************
   *       helper functions
   *****************************/

  goToOrderHistory() {
    if (this.isValid()) {
      let registerInfo = {
        identifier: this.identifier,
        nickname: '',
        password: '',
        termsCheck: false,
        privacyCheck: false,
        legalCheck: false
      };

      this._cookieService.put("nonMemberMail", this.identifier);
      this._cookieService.put("invoicePrice", this.price);

      this.userService.findOne({
        query: {
          identifier: this.identifier,
          isDeleted: false
        }
      })
        .subscribe(
          (userWrapper) => {
            if (!userWrapper['user']) {
              this.userService.create(registerInfo)
                .finally(() => {
                  this.dialogService.loadingSubject.next('hide');
                })
                .subscribe(
                  (userWrapper) => {
                    if (userWrapper['user']) {
                      this.appService.user = userWrapper['user'];

                      this.appService.goToMenu('/store/invoice-list');

                      this.dialogRef.close();
                    } else if (!userWrapper['user']) {
                      this.dialogService.message("알림", "사용자 정보를 찾을 수 없습니다. 다시 로그인 해주세요.");
                      this.dialogService.loadingSubject.next('hide');
                    }
                    // this.dialogService.message("알림", '회원가입이 완료되었습니다.');
                    // this.dismiss();
                  },
                  (err) => {
                    let subTitle = '';
                    switch (err.status) {
                      case 500:
                        subTitle = '서버에러';
                        break;
                      default:
                        subTitle = '잘못된 요청입니다.';
                        break;
                    }
                    this.dialogService.message("알림", subTitle);
                  });
            } else {
              this.appService.user = userWrapper['user'];
              this.appService.goToMenu('/store/invoice-list');
              this.dialogRef.close();
            }
          },
          (err) => {
            let subTitle = '';
            let needCreate = 0;

            if (err.status) {
              // server error handler
              switch (err.status) { 
                case 400:
                  subTitle = '사용자 정보를 찾을 수 없습니다. 다시 로그인 해주세요.';
                  needCreate = 1;
                  break;
                case 500:
                  subTitle = '서버에러(ErrorName)' + err.name + ': ' + err.message;
                  break;
                default:
                  subTitle = '잘못된 요청입니다.';
                  break;
              }
            } else {
              // client error handler
              if (err.message == 'already used mail') {
                this.authService.getMyUserInfo({identifier: this.identifier})
                  .subscribe(
                    (userWrapper) => {
                      if (userWrapper['user']) {
                        this.appService.user = userWrapper['user'];

                        this.appService.goToMenu('/store/invoice-list');
                      } else if (!userWrapper['user']) {
                        this.dialogService.message("알림", "사용자 정보를 찾을 수 없습니다. 다시 로그인 해주세요.");
                        this.dialogService.loadingSubject.next('hide');
                      }
                    },
                    (err) => {
                      let subTitle = '';

                      switch (err.status) {
                        case 500:
                          subTitle = '서버에러';
                          break;
                        default:
                          subTitle = '사용자 정보를 찾을 수 없습니다. 다시 로그인 해주세요.';
                          break;
                      }
                      this.dialogService.message("알림", subTitle);
                      this.dialogService.loadingSubject.next('hide');
                    }
                  )
              }
            }

            if (needCreate === 1) {
              this.userService.create(registerInfo)
                .finally(() => {
                  this.dialogService.loadingSubject.next('hide');
                })
                .subscribe(
                  (userWrapper) => {
                    if (userWrapper['user']) {
                      this.appService.user = userWrapper['user'];

                      this.appService.goToMenu('/store/invoice-list');

                      this.dialogRef.close();
                    } else if (!userWrapper['user']) {
                      this.dialogService.message("알림", "사용자 정보를 찾을 수 없습니다. 다시 로그인 해주세요.");
                      this.dialogService.loadingSubject.next('hide');
                    }
                    // this.dialogService.message("알림", '회원가입이 완료되었습니다.');
                    // this.dismiss();
                  },
                  (err) => {
                    let subTitle = '';
                    switch (err.status) {
                      case 500:
                        subTitle = '서버에러';
                        break;
                      default:
                        subTitle = '잘못된 요청입니다.';
                        break;
                    }
                    this.dialogService.message("알림", subTitle);
                  });
            } else {
              this.dialogService.message("알림", subTitle);
              this.dialogService.loadingSubject.next('hide');
            }
          }
        )
    }
  }

}