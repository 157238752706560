/**
 * Created by Yoon Yong (Andy) Shin on 23/12/2016
 * As part of Nosework
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & Yoon Yong (Andy) Shin - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Yoon Yong (Andy) Shin <andy.shin@applicat.co.kr>, 23/12/2016
 *
 */

// Export Lib
declare var _;

// Angular
import {EventEmitter, Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {Location} from "@angular/common";

@Injectable()
export class AppService {

  locals = [
    {local: 'en', name: 'ENGLISH'},
    {local: 'kr', name: '한국어'}
  ];
  currentLocal;

  currentMenuPosition = '';
  showFlag = false;
  popupTotalData = "";
  appEvent: EventEmitter<any> = new EventEmitter();

  //made by mangus

  set popup_dialog(_dialog){
    if (_dialog)
      localStorage.setItem('nosework_dialog', JSON.stringify(_dialog));
    else
      localStorage.removeItem('nosework_dialog');
  }

  get popup_dialog():any{
    let dialog = localStorage.getItem('nosework_dialog');
    try{
      dialog =JSON.parse(dialog);
      return dialog;
    } catch(e) {
      return null;
    }
  }
  //
  get user(): any {
    let user = localStorage.getItem('nosework_user');

    try {
      user = JSON.parse(user);
      return user;
    } catch (e) {
      return null;
    }
  };

  
  set user(_user) {
    if (_user)
      localStorage.setItem('nosework_user', JSON.stringify(_user));
    else
      localStorage.removeItem('nosework_user');
  };

  get token(): any {
    let token = localStorage.getItem('nosework_token');

    try {
      token = JSON.parse(token);
      return token;
    } catch (e) {
      return null;
    }
  };

  set token(_token: any) {
    if (_token)
      localStorage.setItem('nosework_token', JSON.stringify(_token));
    else
      localStorage.removeItem('nosework_token');
  };

  get cartList(): any {
    let cartList = localStorage.getItem('nosework_cartList');

    try {
      cartList = JSON.parse(cartList);
      return cartList;
    } catch (e) {
      return null;
    }
  };

  set cartList(_cartList: any) {
    if (_cartList)
      localStorage.setItem('nosework_cartList', JSON.stringify(_cartList));
    else
      localStorage.removeItem('nosework_cartList');
  };

  constructor(private router: Router,
              private location: Location) {}

  /*****************************
   *        util functions
   *****************************/

  sendEvent(eventName: string, data?: any) {
    let eventData: {name: string, data?: any} = {name: eventName};
    if (data) eventData.data = data;
    this.appEvent.emit(eventData);
  }

  isActiveUrl(url) {
    return this.router.url === url;
  }

  goToMenu(url, params?) {
    if(params && params != ''){
      url = url + params;
    }
    console.log(url);
    this.router.navigate([url]);
    window.scrollTo(0,0);
  }

  openWeb(url){
    try {
      if(url == "")
        return;
      window.open(url, '_self');
    }
    catch(Error) {

    }
  }

  goBack(){
    this.location.back();
  }

}