/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */

// Browser Lib
declare var _;

// Angular
import {Component, OnInit} from "@angular/core";
import {MdDialogRef} from "@angular/material";

// Project Sources
import {getTemplate} from "../../../shared/templates";
import {AuthService} from "../../../shared/services/auth.service";
import {DialogService} from "../../../shared/components/dialog-message/dialog-message.service";

@Component({
  selector: 'find-password',
  template: getTemplate('pages/login/find-password/find-password')
})
export class FindPassword implements OnInit {

  step;
  validationText;

  identifier;
  certificateNumber;
  password;
  passwordConfirm;

  constructor(public dialogRef: MdDialogRef<FindPassword>,
              private authService: AuthService,
              private dialogService: DialogService) {}

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {
    this.step = 1;

    this.identifier = '';
    this.certificateNumber = '';
    this.password = '';
    this.passwordConfirm = '';
    this.validationText = '';
  }

  /*****************************
   *        util functions
   *****************************/

  isValid() {
    let regExpMail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
    let regExpPassword = /^.*(?=^.{8,12}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[*~`!^\-_+@\#$%&\=\(\)]).*$/;

    switch(this.step){
      case 1:
        if (!this.identifier || this.identifier == '' || this.identifier.length > 32 || !regExpMail.test(this.identifier)) {
          this.validationText = '이메일을 확인해주세요.';
          return false;
        } else {
          return true;
        }
      case 2:
        if(!this.certificateNumber || this.certificateNumber == ''){
          this.validationText = '인증번호를 확인해주세요.';
          return false;
        } else {
          return true;
        }
      case 3:
        if(!this.password || this.password == '' || !regExpPassword.test(this.password)) {
          this.validationText = '비밀번호를 확인해주세요.';
          return false;
        } else if(!this.passwordConfirm || this.passwordConfirm == '' || this.password != this.passwordConfirm) {
          this.validationText = '확인을 위하여 입력한 비밀번호를 확인해주세요.';
          return false;
        } else {
          return true;
        }

    }
  }

  dismiss(){
    this.dialogRef.close();
  }

  /******************************
   *       helper functions
   *****************************/

  forgotPasswordStart() {
    if(this.isValid() && this.step == 1){
      this.dialogService.loadingSubject.next('spinner');

      this.authService.forgotPasswordStart(this.identifier)
        .finally(() => {
          this.dialogService.loadingSubject.next('hide');
        })
        .subscribe(
          () => {
            this.step = 2;
          },
          (err) => {
            let subTitle = '';
            switch(err.status){
              case 400:
                subTitle = '등록된 회원이 아닙니다.';
                break;
              case 402:
                subTitle = '하루 요청 횟수를 초과했습니다. 하루 요청 횟수는 최대 5번입니다.';
                break;
              case 500:
                subTitle = '서버에러';
                break;
              default:
                subTitle = '잘못된 요청입니다.';
                break;
            }
            this.dialogService.message("알림", subTitle);
          }
        );
    } else {
      this.dialogService.message("알림", this.validationText);
    }
  }

  forgotPasswordCheck(){
    if(this.isValid()){
      this.dialogService.loadingSubject.next('spinner');

      this.authService.forgotPasswordCheck(this.identifier, this.certificateNumber)
        .finally(() => {
          this.dialogService.loadingSubject.next('hide');
        })
        .subscribe(
          () => {
            this.step = 3;
          },
          (err) => {
            let subTitle = '';

            switch(err.status){
              case 400:
                subTitle = '입력한 정보를 확인해주세요.';
                break;
              case 412:
                subTitle = '인증번호를 요청해주세요.';
                break;
              case 422:
                subTitle = '인증번호 또는 요청시간이 만료되었습니다.';
                break;
              case 500:
                subTitle = '서버에러';
                break;
              default:
                subTitle = '잘못된 요청입니다.';
                break;
            }
            this.dialogService.message("알림", subTitle);
          }
        );
    } else {
      this.dialogService.message("알림", this.validationText);
    }
  }

  forgotPasswordComplete(){
    if(this.isValid()){
      this.dialogService.loadingSubject.next('spinner');

      this.authService.forgotPasswordComplete(this.password)
        .finally(() => {
          this.dialogService.loadingSubject.next('hide');
        })
        .subscribe(
          () => {
            this.dialogService.message("알림", "새로운 비밀번호가 설정되었습니다.");
            this.dismiss();
          },
          (err) => {
            let subTitle = '';
            switch(err.status){
              case 400:
                subTitle = '입력한 정보를 확인해주세요.';
                break;
              case 412:
                subTitle = '패스워드 재설정을 요청하지 않은 사용자입니다.';
                break;
              case 500:
                subTitle = '서버에러';
                break;
              default:
                subTitle = '잘못된 요청입니다.';
                break;
            }
            this.dialogService.message("알림", subTitle);
          }
        );
    } else {
      this.dialogService.message("알림", this.validationText);
    }
  }

}