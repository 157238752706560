/**
 * Created by Yoon Yong (Andy) Shin on 23/12/2016
 * As part of Nosework
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & Yoon Yong (Andy) Shin - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Yoon Yong (Andy) Shin <andy.shin@applicat.co.kr>, 23/12/2016
 *
 */

// Angular
import {NgModule} from "@angular/core";
import {BrowserModule, Title} from "@angular/platform-browser";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {Http, HttpModule} from "@angular/http";
import {MaterialRootModule, MdIconRegistry, MdUniqueSelectionDispatcher} from "@angular/material";
import { RouteReuseStrategy } from '@angular/router';
import { MyRouteReuseStrategy } from './my_route_reuse_strategy';
// Angular Third Party Library
import {PerfectScrollbarModule, PerfectScrollbarConfigInterface} from 'angular2-perfect-scrollbar';
import {AgmCoreModule} from 'angular2-google-maps/core';
import {TranslateLoader, TranslateModule, TranslateStaticLoader} from "ng2-translate";
import { CookieService } from 'angular2-cookie/services/cookies.service';
import { YoutubePlayerModule } from 'ng2-youtube-player';
import {FileUploadModule} from "../lib/ng2-file-upload/components/file-upload/file-upload.module";
import {Ng2PageScrollModule} from "../lib/ng2-page-scroll/ng2-page-scroll.module";
import {HttpInterceptorModule} from "../lib/ng-http-interceptor";

// Project Sources
import {routing} from "./app.routes";
import {AppComponent} from "./app.component";
// src/pages
import {Brand} from "../pages/brand/brand";
import {Home} from "../pages/home/home";

import {ProductConneck} from "../pages/product/product-conneck/product-conneck";
import {ConneckOverview} from "../pages/product/product-conneck/conneck-overview/conneck-overview";
import {ConneckStory} from "../pages/product/product-conneck/conneck-story/conneck-story";
import {ConneckFunctionDesign} from "../pages/product/product-conneck/conneck-function-design/conneck-function-design";
import {ProductNoseBall} from "../pages/product/product-noseBall/product-noseBall";
import {NoseBallOverview} from "../pages/product/product-noseBall/noseBall-overview/noseBall-overview";
import {NoseBallStory} from "../pages/product/product-noseBall/noseBall-story/noseBall-story";
import {NoseBallFunctionDesign} from "../pages/product/product-noseBall/noseBall-function-design/noseBall-function-design";
import {ProductNoseWorKit} from "../pages/product/product-noseWorKit/product-noseWorKit";
import {NoseWorKitOverview} from "../pages/product/product-noseWorKit/noseWorKit-overview/noseWorKit-overview";
import {NoseWorKitStory} from "../pages/product/product-noseWorKit/noseWorKit-story/noseWorKit-story";
import {NoseWorKitFunctionDesign} from "../pages/product/product-noseWorKit/noseWorKit-function-design/noseWorKit-function-design";
import {ProductSmartNoseWork} from "../pages/product/product-smartNoseWork/product-smartNoseWork";
import {SmartNoseWorkOverview} from "../pages/product/product-smartNoseWork/smartNoseWork-overview/smartNoseWork-overview";
import {SmartNoseWorkStory} from "../pages/product/product-smartNoseWork/smartNoseWork-story/smartNoseWork-story";
import {SmartNoseWorkFunction} from "../pages/product/product-smartNoseWork/smartNoseWork-function/smartNoseWork-function";
import {SmartNoseWorkDesign} from "../pages/product/product-smartNoseWork/smartNoseWork-design/smartNoseWork-design";
import {Service} from "../pages/service/service";
import {SupportForm} from "../pages/support-form/support-form";
import {PopUpDialog} from "../pages/popup-dialog/popup-dialog";

import {Supporters} from "../pages/supporters/supporters";

import {Store} from "../pages/store/store";
import {StoreHome} from "../pages/store/store-home/store-home";
import {ProductDetail} from "../pages/store/product-detail/product-detail";
import {ProductDetailIndex} from "../pages/store/product-detail/product-detail-index";
import {ProductDetailDialog} from "../pages/store/product-detail/product-detail-dialog/product-detail-dialog";

import {CartList} from "../pages/store/cart-list/cart-list";
import {Payment} from "../pages/store/payment/payment";
import {SuccessPayment} from "../pages/store/payment/success-payment/success-payment";
import {InvoiceList} from "../pages/store/invoice-list/invoice-list";
import {InvoiceDetail} from "../pages/store/invoice-list/invoice-detail/invoice-detail";
import {SettingAccount} from "../pages/store/setting-account/setting-account";
import {ManageProduct} from "../pages/store/manage-product/manage-product";
import {RegisterGenuine} from "../pages/store/manage-product/register-genuine/register-genuine";
import {Login} from "../pages/login/login";
import {Register} from "../pages/login/register/register";
import {Policy} from "../pages/login/register/policy/policy";
import {FindPassword} from "../pages/login/find-password/find-password";
import {PreOrderHistory} from "../pages/login/pre-order-history/pre-order-history"
import {PrePayment} from "../pages/store/payment/pre-payment/pre-payment"
// src/shared/components
import {DialogMessage} from "../shared/components/dialog-message/dialog-message.component";
import {LoginConfirm} from "../shared/components/login-confirm/login-confirm.component";
import {DaumMap} from "../shared/components/daum-map/daum-map";
import {LoadingComponent} from "../shared/components/loading/loading.component";
import {PreloadImage} from "../shared/components/preload-image/preload-image";
import {PreloadVideo} from "../shared/components/preload-video/preload-video";
import {SwiperContainer, SwiperSlide} from "../shared/components/swiper-wrapper";
// src/shared/services
import {AppService} from "../shared/services/app.service";
import {AuthService} from "../shared/services/auth.service";
import {UserService} from "../shared/services/user.service";
import {BannerService} from "../shared/services/banner.service";
import {FaqService} from "../shared/services/faq.service";
import {ProductService} from "../shared/services/product.service";
import {SerialService} from "../shared/services/serial.service";
import {TransactionService} from "../shared/services/transaction.service";
import {PopUpDialogService} from "../shared/services/popup-dialog.service";

import {PagerComponent} from "../shared/components/pager/pager.component";
import {VirtualFileInput} from "../shared/directive/virtual-file-input";

import {PhonePipe} from "../shared/pipes/phone.pipe";
import {DatexPipe} from "../shared/pipes/datex.pipe";
import {EqualValidator} from "../shared/validators/equal.validator";
import {PSDValidator} from "../shared/validators/password-format.validator";
import {EmailValidator} from "../shared/validators/email-format.validator";
import {PhotoPipe} from "../shared/pipes/photo.pipe";
import {Scroller} from "../shared/directive/scroller";
import {InputAllow} from "../shared/directive/input-allow";
import {DialogService} from "../shared/components/dialog-message/dialog-message.service";
import {LoginConfirmService} from "../shared/components/login-confirm/login-confirm.service";
import {RouterGuardService} from "../shared/services/router-guard.service";
import {SanitizeStyle} from "../shared/directive/sanitize-style";
import {Spinner} from "../shared/components/spinner";
import {CardNumber} from "../shared/components/card-number/card-number";
import {PhoneNumber} from "../shared/components/phone-number/phone-number";
import {ExpiryNumber} from "../shared/components/expiry/expiry";
import {NumberInput} from "../shared/components/number-input/number-input";

import {YoutubeVideoIdPipe} from "../shared/pipes/youtube_video_id.pipe";
import {FullYoutubeUrlPipe} from "../shared/pipes/full_youtube_url.pipe";

import {SanitizeAttribute} from "../shared/directive/sanitize-attribute";

export function createTranslateLoader(http: Http) {
  return new TranslateStaticLoader(http, './assets/i18n', '.json');
}

const PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    routing,
    HttpModule,
    MaterialRootModule,
    FileUploadModule,
    Ng2PageScrollModule.forRoot(),
    PerfectScrollbarModule.forRoot(PERFECT_SCROLLBAR_CONFIG),
    TranslateModule.forRoot({
      provide: TranslateLoader,
      useFactory: (createTranslateLoader),
      deps: [Http]
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDaj_MkTSU6S6aR7FMjrnd8fKgYeI3NmYI'
    }),
    HttpInterceptorModule.noOverrideHttp(),
    YoutubePlayerModule,
  ],
  declarations: [
    AppComponent,
    // pages
    Brand,
    Home,
    ProductConneck,
    ConneckOverview,
    ConneckStory,
    ConneckFunctionDesign,
    ProductNoseBall,
    NoseBallOverview,
    NoseBallStory,
    NoseBallFunctionDesign,
    ProductNoseWorKit,
    ProductSmartNoseWork,
    NoseWorKitOverview,
    NoseWorKitStory,
    NoseWorKitFunctionDesign,
    SmartNoseWorkOverview,
    SmartNoseWorkStory,
    SmartNoseWorkFunction,
    SmartNoseWorkDesign,
    Service,
    SupportForm,
    PopUpDialog,
    Supporters,
    Store,
    StoreHome,
    ProductDetail,
    ProductDetailIndex,
    ProductDetailDialog,
    CartList,
    Payment,
    SuccessPayment,
    InvoiceList,
    InvoiceDetail,
    SettingAccount,
    ManageProduct,
    RegisterGenuine,
    Login,
    Register,
    Policy,
    FindPassword,
    PreOrderHistory,
    PrePayment,
    FullYoutubeUrlPipe,
    YoutubeVideoIdPipe,
    // src/shared/components
    DaumMap,
    LoadingComponent,
    PreloadImage,
    PreloadVideo,
    SwiperContainer,
    SwiperSlide,

    PagerComponent,
    EqualValidator,
    PSDValidator,
    EmailValidator,
    DatexPipe,
    PhotoPipe,
    PhonePipe,
    Scroller,
    VirtualFileInput,
    InputAllow,
    DialogMessage,
    LoginConfirm,
    SanitizeStyle,
    Spinner,
    CardNumber,
    PhoneNumber,
    ExpiryNumber,
    NumberInput,
    SanitizeAttribute
  ],
  providers: [
    CookieService,
    // src/shared/components
    DialogService,
    LoginConfirmService,

    // src/shared/services
    AppService,
    AuthService,
    UserService,
    BannerService,
    FaqService,
    ProductService,
    SerialService,
    PopUpDialogService,

    Title,
    MdIconRegistry,
    MdUniqueSelectionDispatcher,
    RouterGuardService,
    TransactionService,

    // {provide: RouteReuseStrategy, useClass: MyRouteReuseStrategy}
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    // src/pages
    SupportForm,
    PopUpDialog,
    ProductDetailDialog,
    RegisterGenuine,
    Login,
    Register,
    Policy,
    FindPassword,
    PreOrderHistory,
    PrePayment,

    // src/shared/components
    DialogMessage,
    LoginConfirm,
    DaumMap
  ]
})

export class AppModule {
}
