/**
 * Created by PHILIP on 11/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 11/07/2017
 *
 */

// Angular

declare var _;

import {Component, HostListener, OnInit, Renderer, ViewChild} from "@angular/core";
import {MdDialogRef} from "@angular/material";

// Project Sources
import {getTemplate} from "../../shared/templates";
import {throttleable} from "../../shared/services/util";
import {PopUpDialogService} from "../../shared/services/popup-dialog.service";

import {DialogService} from "../../shared/components/dialog-message/dialog-message.service";

import {Login} from "../../pages/login/login";
import { AppService } from "../../shared/services/app.service";
import { Jsonp } from "@angular/http";


@Component({
  selector: 'popup-dialog',
  template: getTemplate('pages/popup-dialog/popup-dialog')
})
export class PopUpDialog implements OnInit {

  @ViewChild("textarea") textarea;

  supportInfo;

  contentSize;
  popupDialogInfo;
  popupDialog_ID;
  checked;

  constructor(private renderer: Renderer,
              private dialogRef: MdDialogRef<PopUpDialog>,
              private appService:AppService,
              private popupDialogService: PopUpDialogService,
              private dialogService: DialogService){}

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit(){
    this.recalcSize();
    this.popupDialogInfo = {
        photo : [],
        checked : false,
        title : '',
        text : '',
        _id : ''
    };
    this.checked = this.popupDialogInfo['checked'];
    this.popupDialogService
      .findOne({
        query: {_id: this.popupDialog_ID},
        populate: ['photo']
      })
      .subscribe(
        (popupWrapper) => {
          this.popupDialogInfo.title = popupWrapper['PopUp'].title;
          this.popupDialogInfo.text = popupWrapper['PopUp'].message;
          if(popupWrapper['PopUp'].imageShow)
          {
            this.popupDialogInfo.photo.push(popupWrapper['PopUp'].photo[0]);  
          } else {
            this.popupDialogInfo.photo = [];
          }
          
          this.popupDialogInfo._id = popupWrapper['PopUp']._id;
          this.popupDialogInfo.url = popupWrapper['PopUp'].url;

        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 400:
              subTitle = '웹배너를 찾을 수 없습니다.';
              break;
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      );
  }
  /*****************************
   *        util functions
   *****************************/

  @HostListener('window:resize')
  @throttleable(5)
  recalcSize() {
    this.contentSize = this.dialogService.calcPopupDialogSize();
  }

  dismiss(){
    this.dialogRef.close();
  }
  /*****************************
   *       helper functions
   *****************************/
  getTime(){
    let utcTime = new Date().getTime();
    let utcTimeOffset = new Date().getTimezoneOffset() * 60000;
    let utc = utcTime + utcTimeOffset;
    let krOffset = 9;
    let korTime = utc + (3600000 * krOffset);

    let reallyTime = new Date(korTime);
    let time = new Date();

    let str = reallyTime.getFullYear() + "-" + (reallyTime.getMonth() + 1) +"-" + reallyTime.getDate();
    return str;
  }

  clickedEnd(){
    this.checked = !this.checked;
    
    var reallyTime = this.getTime();
    if(this.checked){
      let template = {
        checked:this.popupDialogInfo['checked'],
        checkedTime:reallyTime,
        _id:this.popupDialogInfo._id
      }
      let str = JSON.stringify(template);
      if(this.appService.popupTotalData == undefined)
        this.appService.popupTotalData = "";
      this.appService.popupTotalData += str + "&&&";
      localStorage.setItem('PopupData',this.appService.popupTotalData);
    }
    else {
      var data = localStorage.getItem('PopupData').split("&&&");
      
      localStorage.clear();
      this.appService.popupTotalData = "";
      
      for(var i =0 ; i < data.length - 1; i ++){
        var jsons = JSON.parse(data[i]);
        if(this.popupDialogInfo._id != jsons._id){
          let str = JSON.stringify(jsons);
          this.appService.popupTotalData += str + "&&&";
        }
      }
      localStorage.setItem('PopupData',this.appService.popupTotalData);
    }
    
    
    
    // if(template.checked)
    //   this.dialogService.message("알림", "오늘 하루동안 선택한 팝업이 현시되지 않습니다.");
  }
  closePopup() {
    this.dialogService.loadingSubject.next('spinner');
  }
}