/**
 * Created by PHILIP on 27/06/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 27/06/2017
 *
 */

// Angular
import {Component} from "@angular/core";

// Project Sources
import {getTemplate} from "../../../../shared/templates";

@Component({
  selector: 'smartNoseWork-story',
  template: getTemplate('pages/product/product-smartNoseWork/smartNoseWork-story/smartNoseWork-story')
})
export class SmartNoseWorkStory {

  constructor() {}

}