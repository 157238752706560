/**
 * Created by PHILIP on 27/06/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 27/06/2017
 *
 */

// Export lib
declare var _;

// Browser lib
import {Observable} from "rxjs";

// Angular
import {Injectable} from "@angular/core";
import {URLSearchParams} from "@angular/http";

// Angular Third Party lib
import {InterceptableHttp} from "../../lib/ng-http-interceptor";

// Project Sources
import {config} from "../../app/app.config";

@Injectable()
export class SerialService {
  private serverUrl = config.serverUrl + '/serial';

  constructor(private http: InterceptableHttp) {}

  find(queryParams: any): Observable<any> {
    let url = this.serverUrl + '/find';

    let params: URLSearchParams = new URLSearchParams();
    _.forEach(queryParams, (value, key) => {
      params.set(key, JSON.stringify(value));
    });

    return this.http
      .get(url, {search: params});
  }

  registered(serial): Observable<any>{
    let url = this.serverUrl + '/registered';

    return this.http
      .put(url, serial);
  }

  cancelRegistered(serial): Observable<any>{
    let url = this.serverUrl + '/cancelRegistered';

    return this.http
      .put(url, serial);
  }

}
