/**
 * Created by Yoon Yong (Andy) Shin on 23/12/2016
 * As part of Nosework
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & Yoon Yong (Andy) Shin - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Yoon Yong (Andy) Shin <andy.shin@applicat.co.kr>, 23/12/2016
 *
 */

export const config = {
  // Dev
  //  serverUrl: 'https://api.nosework-official.com',
  //  webUrl: 'http://192.168.219.117:8002'

  // Prod
  serverUrl: 'https://api.nosework-official.com',
  webUrl: 'https://www.nosework-official.com'
};
