/**
 * Created by Yoon Yong (Andy) Shin on 11/04/2017
 * As part of Nosework
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & Yoon Yong (Andy) Shin - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Yoon Yong (Andy) Shin <andy.shin@applicat.co.kr>, 11/04/2017
 *
 */

// Angular
import {Directive, Input, ElementRef, Renderer, OnInit, SimpleChange, OnChanges} from "@angular/core";

@Directive({
  selector: '[sanitize-attribute]'
})
export class SanitizeAttribute implements OnInit, OnChanges {
  public element;

  @Input('sanitize-attribute') attributes: any;

  constructor(private renderer: Renderer,
              private elementRef: ElementRef) {
    this.element = elementRef;
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {
    if (this.attributes)
      this.updateAttributes();
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (changes['style']) {
      this.updateAttributes();
    }
  }

  /*****************************
   *        util functions
   *****************************/

  updateAttributes() {
    for (let key in this.attributes) {
      this.renderer.setElementAttribute(this.element.nativeElement, key, this.attributes[key]);
    }
  }

}