/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */

// Browser Lib
declare var _;

// Angular
import {Component, OnInit} from "@angular/core";
import {MdDialog, MdDialogConfig, MdDialogRef} from "@angular/material";

// Project Sources
import {getTemplate} from "../../../shared/templates";
import {AuthService} from "../../../shared/services/auth.service";
import {DialogService} from "../../../shared/components/dialog-message/dialog-message.service";
import {Policy} from "./policy/policy";

@Component({
  selector: 'register',
  template: getTemplate('pages/login/register/register')
})
export class Register implements OnInit {

  config: MdDialogConfig = new MdDialogConfig();
  dialogPolicyRef: MdDialogRef<Policy>;

  registerInfo;
  passwordConfirm;
  violateInput;

  constructor(public dialogRef: MdDialogRef<Register>,
              private mdDialog: MdDialog,
              private authService: AuthService,
              private dialogService: DialogService) {}

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {
    this.registerInfo = {
      identifier: '',
      nickname: '',
      password: '',
      termsCheck: false,
      privacyCheck: false,
      legalCheck: false
    };
    this.passwordConfirm = '';
  }

  /*****************************
   *        util functions
   *****************************/

  isValidRegister() {
    let regExpMail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
    let regExpPassword = /^.*(?=^.{8,12}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[*~`!^\-_+@\#$%&\=\(\)]).*$/;

    if(!this.registerInfo['identifier'] || this.registerInfo['identifier'] == '' || this.registerInfo['identifier'].length > 32 || !regExpMail.test(this.registerInfo['identifier'])){
      this.violateInput = 'identifier';
      return false;
    }
    if(!this.registerInfo['nickname'] || this.registerInfo['nickname'] == '' || (this.registerInfo['nickname'].length < 2 || this.registerInfo['nickname'] > 8)) {
      this.violateInput = 'nickname';
      return false;
    }
    if(!this.registerInfo['password'] || this.registerInfo['password'] == '' || !regExpPassword.test(this.registerInfo['password'])) {
      this.violateInput = 'password';
      return false;
    }
    if(!this.passwordConfirm || this.passwordConfirm == '' || this.registerInfo['password'] != this.passwordConfirm) {
      this.violateInput = 'passwordConfirm';
      return false;
    }

    this.violateInput = null;

    if(!this.registerInfo['termsCheck']){
      return false;
    } else if(!this.registerInfo['privacyCheck']){
      return false;
    } else if(!this.registerInfo['legalCheck']){
      return false;
    } else {
      return true;
    }
  }

  openPolicyForm(type){
    this.dialogService.resizeModal(this.config);
    this.config.disableClose = true;

    this.dialogPolicyRef = this.mdDialog.open(Policy, this.config);
    this.dialogPolicyRef.componentInstance.type = type;

    this.dialogPolicyRef.afterClosed()
      .subscribe((result) => {});
  }

  dismiss(){
    this.dialogRef.close();
  }

  /*****************************
   *       helper functions
   *****************************/

  checkDuplicateUser() {
    if (this.isValidRegister()) {
      this.dialogService.loadingSubject.next('hide');

      this.authService.checkIdentifier(this.registerInfo.identifier)
        .flatMap(
          (result) => {
            if(!result['isAvailable']){
              throw new Error('already used mail');
            } else {
              return this.authService.checkNickname(this.registerInfo.nickname);
            }
          }
        )
        .flatMap(
          (result) => {
            if(!result['isAvailable']){
              throw new Error('already used nickname');
            } else {
              return this.authService.checkNickname(this.registerInfo.nickname);
            }
          }
        )
        .subscribe(
          () => {
            this.registerUser();
          },
          (err) => {
            let subTitle = '';

            if(err.status){
              // server error handler
              switch (err.status) {
                case 400:
                  subTitle = '입력한 정보를 확인해주세요.';
                  break;
                case 500:
                  subTitle = '서버에러(ErrorName)' + err.name + ': ' + err.message;
                  break;
                default:
                  subTitle = '잘못된 요청입니다.';
                  break;
              }
            } else {
              // client error handler
              if(err.message == 'already used mail')
                subTitle = '이미 사용중인 이메일 입니다.';
              else if(err.message == 'already used nickname')
                subTitle = '이미 사용중인 닉네임 입니다.';
            }
            this.dialogService.message("알림", subTitle);
            this.dialogService.loadingSubject.next('hide');
          }
        )
    }
  }

  registerUser() {
    this.authService.register(this.registerInfo)
      .finally(() => {
        this.dialogService.loadingSubject.next('hide');
      })
      .subscribe(
        (userWrapper) => {
          this.dialogService.message("알림", '회원가입이 완료되었습니다.');
          this.dismiss();
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        });
  }

}