/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */

// Angular
import {ElementRef, Component, Input, Renderer, ViewChild, SimpleChange, OnChanges, DoCheck, OnInit} from "@angular/core";

// Project Sources
import { PhotoPipe } from "../../pipes/photo.pipe";

@Component({
  selector: 'preload-image',
  template: `
  <div #imagePanel class="preload-image-panel">
    <div #loadingPanel class="preload-image-loading">
      <spinner></spinner>
    </div>
    <ng-content></ng-content>
  </div>
`,
})
export class PreloadImage implements OnChanges, DoCheck, OnInit {

  element;
  previouseWidth;

  @ViewChild("imagePanel") imagePanel: ElementRef;
  @ViewChild("loadingPanel") loadingPanel: ElementRef;

  @Input() imageSource: string;
  @Input() ratio: number;
  @Input() resize?: string;
  @Input() imageStyle: any;

  constructor(private renderer: Renderer,
              private elementRef: ElementRef) {
    this.element = elementRef;
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {}

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (changes['ratio']) this.setImageStyle();

    if (changes['imageSource']) this.setImageStyle();

    if (changes['resize']) this.setImageStyle();
  }

  ngDoCheck(){
    if (this.previouseWidth != this.element.nativeElement.offsetWidth)
      this.setImageStyle();
  }

  /*****************************
   *        util functions
   *****************************/

  setImageStyle() {
    if (this.ratio && this.element) {
      let height = this.element.nativeElement.offsetWidth * this.ratio;
      this.renderer.setElementStyle(this.element.nativeElement, 'height', height + 'px');
      this.previouseWidth = this.element.nativeElement.offsetWidth;
    }

    if (this.imageStyle)
      if (this.imageStyle)
        for (var key in this.imageStyle) {
          this.renderer.setElementStyle(this.imagePanel.nativeElement, key, this.imageStyle[key]);
        }

    if (this.imageSource && this.resize) {
      let resizedUrl = PhotoPipe.resize(this.imageSource, this.resize);
      this.renderer.setElementStyle(this.imagePanel.nativeElement, 'background-image', "url('" + resizedUrl + "')");
    } else if (this.imageSource) {
      this.renderer.setElementStyle(this.imagePanel.nativeElement, 'background-image', "url('" + this.imageSource + "')");
    }
    this.renderer.setElementClass(this.loadingPanel.nativeElement, 'done', true);
  }
}
