/**
 * Created by Yoon Yong (Andy) Shin on 18/04/2017
 * As part of Nosework
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & Yoon Yong (Andy) Shin - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Yoon Yong (Andy) Shin <andy.shin@applicat.co.kr>, 18/04/2017
 *
 */

// Angular
import {AfterViewInit, Component, EventEmitter, forwardRef, OnInit, Output, ViewChild} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ExpiryNumber),
  multi: true
};

@Component({
  selector: 'expiry',
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  template: `
    <div class="form-input">
      <input #month
             type="number"
             (keydown)="onKeyPress($event)"
             (keyup)="setToMaxChar($event)"
             (change)="setToMaxChar($event)"
             (blur)="setToMaxChar($event)"
             (focus)="checkAllowed($event)"
             min="1"
             max="12"/>
    </div>
    <div class="splitter">/</div>
    <div class="form-input">
      <input #year
             type="number"
             min="1"
             max="99"
             (keydown)="onKeyPress($event)"
             (keyup)="setToMaxChar($event)"
             (change)="setToMaxChar($event)"
             (blur)="setToMaxChar($event)"
             (focus)="checkAllowed($event)"/>
    </div>
  `
})
export class ExpiryNumber implements OnInit, AfterViewInit, ControlValueAccessor {

  private specialKeys: Array<string> = ['Tab', 'End', 'Home'];

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  @Output() blur: EventEmitter<any> = new EventEmitter();

  @ViewChild("month") monthInput;
  @ViewChild("year") yearInput;

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {}


  ngAfterViewInit() {}

  /*****************************
   *        util functions
   *****************************/

  checkAllowed(event) {}

  isValidInput() {
    if (!this.monthInput.nativeElement.value)
      return false;

    if (!this.yearInput.nativeElement.value)
      return false;

    if (this.monthInput.nativeElement.value.length != 2)
      return false;

    if (this.yearInput.nativeElement.value.length != 2)
      return false;

    return true;
  }

  setToMaxChar(event) {
    if (event.target.value.length > 2)
      event.target.value = event.target.value.substring(0, 2);

    if (this.isValidInput())
      this.onChangeCallback("20" + this.yearInput.nativeElement.value +
        "-" + this.monthInput.nativeElement.value);
    else
      this.onChangeCallback("");
  }

  onKeyPress(event) {
    if ([46, 9, 27, 13, 110, 190].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode == 65 && event.ctrlKey === true) ||
      // Allow: Ctrl+C
      (event.keyCode == 67 && event.ctrlKey === true) ||
      // Allow: Ctrl+V
      (event.keyCode == 86 && event.ctrlKey === true) ||
      // Allow: Ctrl+X
      (event.keyCode == 88 && event.ctrlKey === true) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39) ||

      // specialKeys
      this.specialKeys.indexOf(event.key) !== -1) {
      // let it happen, don't do anything
      return;
    }

    // Backspace keyCode == 8
    if (event.key == 'Backspace') {
      if (event.target == this.monthInput.nativeElement
        && this.monthInput.nativeElement.value.length == 0) {
        return;
      }

      if (event.target == this.yearInput.nativeElement
        && this.yearInput.nativeElement.value.length == 0) {
        return this.monthInput.nativeElement.focus();
      }
    } else {
      if (event.target == this.monthInput.nativeElement
        && this.monthInput.nativeElement.value.length > 1) {
        this.yearInput.nativeElement.focus();
      }

      if (event.target == this.yearInput.nativeElement
        && this.yearInput.nativeElement.value.length > 1) {
        this.blur.emit();
      }
    }
  }

  //From ControlValueAccessor interface
  writeValue(value: any) {
    if (!value)return;

    value = value.toString();

    value = value.split("-");

    if (value[0]) {
      this.yearInput.nativeElement.value = value[0].substring(2, 4);
    }

    if (value[1]) {
      this.monthInput.nativeElement.value = value[1].substring(0, 2);
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

}