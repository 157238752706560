/**
 * Created by Yoon Yong (Andy) Shin on 18/04/2017
 * As part of Nosework
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & Yoon Yong (Andy) Shin - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Yoon Yong (Andy) Shin <andy.shin@applicat.co.kr>, 18/04/2017
 *
 */

// Angular
import {AfterViewInit, Component, EventEmitter, forwardRef, OnInit, Output, ViewChild} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CardNumber),
  multi: true
};

@Component({
  selector: 'card-number',
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  template: `
    <input #number1 type="number"
           (keydown)="onKeyDown($event)"
           (keyup)="setToMaxChar($event)"
           (change)="setToMaxChar($event)"
           (blur)="setToMaxChar($event)"
           (focus)="checkAllowed($event)"/>
    <span>-</span>
    <input #number2 type="number"
           (keydown)="onKeyDown($event)"
           (keyup)="setToMaxChar($event)"
           (change)="setToMaxChar($event)"
           (blur)="setToMaxChar($event)"
           (focus)="checkAllowed($event)"/>
    <span>-</span>
    <input #number3 type="number"
           (keydown)="onKeyDown($event)"
           (keyup)="setToMaxChar($event)"
           (change)="setToMaxChar($event)"
           (blur)="setToMaxChar($event)"
           (focus)="checkAllowed($event)"/>
    <span>-</span>
    <input #number4 type="number"
           (keydown)="onKeyDown($event)"
           (keyup)="setToMaxChar($event)"
           (change)="setToMaxChar($event)"
           (blur)="setToMaxChar($event)"
           (focus)="checkAllowed($event)"/>
  `
})
export class CardNumber implements OnInit, AfterViewInit, ControlValueAccessor {

  private specialKeys: Array<string> = ['Tab', 'End', 'Home'];

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  @Output() blur: EventEmitter<any> = new EventEmitter();

  @ViewChild("number1") cardNumber1;
  @ViewChild("number2") cardNumber2;
  @ViewChild("number3") cardNumber3;
  @ViewChild("number4") cardNumber4;

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {}

  ngAfterViewInit() {}

  /*****************************
   *        util functions
   *****************************/

  checkAllowed(event) {
    // if (event.target == this.cardNumber2.nativeElement
    //   && this.cardNumber1.nativeElement.value.length < 4) {
    //   return this.cardNumber1.nativeElement.focus();
    // }
    //
    // if (event.target == this.cardNumber3.nativeElement
    //   && this.cardNumber2.nativeElement.value.length < 4) {
    //   return this.cardNumber2.nativeElement.focus();
    // }
    //
    // if (event.target == this.cardNumber4.nativeElement
    //   && this.cardNumber3.nativeElement.value.length < 4) {
    //   return this.cardNumber3.nativeElement.focus();
    // }
  }

  isValidInput() {
    if (!this.cardNumber1.nativeElement.value)return false;
    if (!this.cardNumber2.nativeElement.value)return false;
    if (!this.cardNumber3.nativeElement.value)return false;
    if (!this.cardNumber4.nativeElement.value)return false;

    if (this.cardNumber1.nativeElement.value.length != 4)return false;
    if (this.cardNumber2.nativeElement.value.length != 4)return false;
    if (this.cardNumber3.nativeElement.value.length != 4)return false;
    if (this.cardNumber4.nativeElement.value.length != 4)return false;

    return true;
  }

  setToMaxChar(event) {
    if (event.target.value.length > 4)
      event.target.value = event.target.value.substring(0, 4);

    this.onChangeCallback(this.cardNumber1.nativeElement.value + this.cardNumber2.nativeElement.value + this.cardNumber3.nativeElement.value + this.cardNumber4.nativeElement.value);
  }


  onKeyDown(event) {
    if ([46, 9, 27, 13, 110, 190].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode == 65 && event.ctrlKey === true) ||
      // Allow: Ctrl+C
      (event.keyCode == 67 && event.ctrlKey === true) ||
      // Allow: Ctrl+V
      (event.keyCode == 86 && event.ctrlKey === true) ||
      // Allow: Ctrl+X
      (event.keyCode == 88 && event.ctrlKey === true) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39) ||

      // specialKeys
      this.specialKeys.indexOf(event.key) !== -1) {
      // let it happen, don't do anything
      return;
    }

    // Backspace keyCode == 8
    if (event.key == 'Backspace') {
      if (event.target == this.cardNumber1.nativeElement
        && this.cardNumber1.nativeElement.value.length == 0) {
        return;
      }

      if (event.target == this.cardNumber2.nativeElement
        && this.cardNumber2.nativeElement.value.length == 0) {
        return this.cardNumber1.nativeElement.focus();
      }

      if (event.target == this.cardNumber3.nativeElement
        && this.cardNumber3.nativeElement.value.length == 0) {
        return this.cardNumber2.nativeElement.focus();
      }

      if (event.target == this.cardNumber4.nativeElement
        && this.cardNumber4.nativeElement.value.length == 0) {
        return this.cardNumber3.nativeElement.focus();
      }
    } else {
      if (event.target == this.cardNumber1.nativeElement
        && this.cardNumber1.nativeElement.value.length > 3) {
        this.cardNumber2.nativeElement.focus();
      }

      if (event.target == this.cardNumber2.nativeElement
        && this.cardNumber2.nativeElement.value.length > 3) {
        this.cardNumber3.nativeElement.focus();
      }

      if (event.target == this.cardNumber3.nativeElement
        && this.cardNumber3.nativeElement.value.length > 3) {
        this.cardNumber4.nativeElement.focus();
      }

      if (event.target == this.cardNumber4.nativeElement
        && this.cardNumber4.nativeElement.value.length > 3) {
        this.blur.emit();
      }
    }
  }

  //From ControlValueAccessor interface
  writeValue(value: any) {
    if (!value)return;

    value = value.toString();

    this.cardNumber1.nativeElement.value = value.substring(0, 4);
    value = value.substring(4);

    if (value) {
      this.cardNumber2.nativeElement.value = value.substring(0, 4);
      value = value.substring(4);
    }

    if (value) {
      this.cardNumber3.nativeElement.value = value.substring(0, 4);
      value = value.substring(4);
    }

    if (value) {
      this.cardNumber4.nativeElement.value = value.substring(0, 4);
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

}