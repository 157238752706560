/**
 * Created by PHILIP on 09/03/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 09/03/2017
 *
 * Updater    수정자 - PHILIP 21/03/2017
 */

/**
 *  swiper component that wraps http://idangero.us/swiper
 */

// Export lib
declare var Swiper;

// Angular
import {Injectable, Inject, Component, ElementRef, Host, Input} from '@angular/core';

@Injectable()
@Component({
  selector: 'swiper-container',
  template: `<div class="swiper-container">
    <div class="swiper-wrapper">
      <ng-content></ng-content>
    </div>
    <div class="swiper-pagination"></div>
  </div>`
})
export class SwiperContainer {
  @Input() options;
  swiper;

  constructor(@Inject(ElementRef) private elementRef: ElementRef) {}

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {
    let options = this.defaults({
      pagination: '.pagination',
      autoplay:3000,
      loop:true,
      paginationClickable: true
    }, this.options);

    const nativeElement = this.elementRef.nativeElement;
    setTimeout(() => {
      this.swiper = new Swiper(nativeElement.children[0], this.options);
    });
  }

  /*****************************
   *        util functions
   *****************************/

  defaults(dest, args: any[]) {
    for (let i = args.length - 1; i >= 1; i--) {
      let source = args[i] || {};
      for (let key in source) {
        if (source.hasOwnProperty(key) && !dest.hasOwnProperty(key)) {
          dest[key] = source[key];
        }
      }
    }
    return dest;
  }

  update() {
    setTimeout(() => { 
      this.swiper.update();
    });
  }

}

@Injectable()
@Component({
  selector: 'swiper-slide',
  template: '<ng-content></ng-content>'
})
export class SwiperSlide {

  ele: HTMLElement;

  constructor(@Inject(ElementRef) elementRef: ElementRef,
              @Host() @Inject(SwiperContainer) swiper: SwiperContainer) {
    this.ele = elementRef.nativeElement;
    this.ele.classList.add('swiper-slide');

    swiper.update();
  }
}
