/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */

// Angular
import {Component} from "@angular/core";

// Project Sources
import {getTemplate} from "../../../../shared/templates";
import {MdDialogRef} from "@angular/material";

@Component({
  selector: 'policy',
  template: getTemplate('pages/login/register/policy/policy')
})
export class Policy {

  type;

  constructor(public dialogRef: MdDialogRef<Policy>) {}

  /*****************************
   *        util functions
   *****************************/

  dismiss(){
    this.dialogRef.close();
  }

}