/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */

// Export Lib
declare var _;
declare var wcs;
declare var wcs_do;
declare var window;

// Browser Lib
import {Observable} from "rxjs";
import "rxjs/add/operator/toPromise";
import 'rxjs/Rx';

// Angular
import {Component, OnInit,ViewChild,QueryList,ViewChildren } from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {MdDialogRef, MdDialog, MdDialogConfig} from "@angular/material";
import {FormControl} from '@angular/forms';

// Angular
import {Injectable} from "@angular/core";
import {RequestOptions, URLSearchParams, Headers} from "@angular/http";
import { Response } from '@angular/http';

// Angular Third Party Lib
import {InterceptableHttp} from "../../../lib/ng-http-interceptor";

// Project Sources
import {getTemplate} from "../../../shared/templates";
import {AppService} from "../../../shared/services/app.service";
import {ProductService} from "../../../shared/services/product.service";
import {TransactionService} from "../../../shared/services/transaction.service";
import {DialogService} from "../../../shared/components/dialog-message/dialog-message.service";
import {LoginConfirmService} from "../../../shared/components/login-confirm/login-confirm.service";
import {FaqService} from "../../../shared/services/faq.service";
import {ProductDetailDialog} from "./product-detail-dialog/product-detail-dialog";
import {MdSelectModule} from '@angular/material';
// Project Sources
import {config} from "../../../app/app.config";

@Component({
  selector: 'product-detail',
  template: getTemplate('pages/store/product-detail/product-detail')
})

export class ProductDetail implements OnInit {

  private productUrl = config.webUrl+"/store/product-detail/";
  private shopId = "np_xfeav780531";
  private certikey = "A6730EFC-7050-4E39-B9EC-085EB20C4FBC";
  
  name;
  selectedTab;

  //made by mangus
  enableOption;
  enableAttribute;
  propertyName;
  attributeName;
  //

  product;
  selectedOption;

  // 찜정보 등록후 네이버로부터 받은 상품아이디
  itemId;


  buyItem = [];
  config: MdDialogConfig = new MdDialogConfig();
  dialogDetailProduct: MdDialogRef<ProductDetailDialog>;
  constructor(private appService: AppService,
              private mdDialog: MdDialog,
              private activatedRoute: ActivatedRoute,
              private productService: ProductService,
              private transactionService: TransactionService,
              private dialogService: DialogService,
              private loginConfirmService: LoginConfirmService,
              private  http: InterceptableHttp) {
  }
  
  /*****************************
   *         life cycle
   *****************************/
  ngOnInit() {
    this.propertyName = "";
    this.attributeName = "";

    // if (!wcs_add) var wcs_add = {};
    // wcs_add["wa"] = "s_52e6d95fb9a0";
    // if (!_nasa) var _nasa = {};
    // wcs.inflow();
    // wcs_do(_nasa);

    this.enableOption = false;
    this.activatedRoute.params
      .subscribe((params) => {
        this.name = params['name'];
        if (!this.name || this.name == '') {
          this.dialogService.message("알림", "잘못된 요청입니다.");
          this.appService.goToMenu('/store');
        } else {
          this.selectedTab = 'DELIVERY';
          this.selectedOption = {
            name:[],
            property:[]
          };
          this.loadProduct();
          this.loadNaverPayButton();
        }
      });
  }


  loadNaverPayButton(){
    var this2 = this;
    var naver = window.naver;
    var enable = "";

    if(this.appService.user != null && this.appService.user.identifier == "test@test.co.kr"){
      setTimeout(function() {
        if(this2.product['soldOut']) {
          enable = "N";
        } else {
          enable = "Y";
        }

        naver.NaverPayButton.apply({
          BUTTON_KEY: "FE7A3593-C4FD-401C-A1CC-4310B876A33E",
          TYPE: "B", //버튼 스타일
          COLOR: 1, //버튼 색상타입
          COUNT: 2, // 네이버페이버튼 + 찜하기버튼 모두 출력 여부
          ENABLE: enable, //네이버페이 활성여부(재고부족 등에는 N으로 비활성처리)
          EMBED_ID: "naverPayBtn", //네이버페이 버튼 UI가 부착될 HTML element의 ID
          BUY_BUTTON_HANDLER : function(url){
            this2.buy_nc();
          },
          // BUY_BUTTON_LINK_URL:"https://test-api.pay.naver.com/o/customer/api/order/v20/register",
          WISHLIST_BUTTON_HANDLER : function() {
            this2.wishlist_nc();
          },
          // WISHLIST_BUTTON_LINK_URL:"http://mydomain.com/wishlist/popup/url/", // 찜하기 팝업링크 주소
          "":""
        });
      }, 1000);
    }
  }

  buy_nc() {
    // let url = "https://api.pay.naver.com/o/customer/api/order/v20/register";// 서비스
    let url = "https://test-api.pay.naver.com/o/customer/api/order/v20/register";// 테스트

    if(this.product['soldOut']){
        this.dialogService.message("알림", "품절된 상품입니다.");
        return false;
    }

    if (!this.isValid()) {// 필수 선택옵션 체크
        this.dialogService.message("알림", "필수옵션을 확인해주세요.");
        return false;
    } 

    // 전체 가격 계산
    var total_price = 0;
    total_price = this.product['price'];      
   if(this.buyItem.length == 0){
      // 단독형인 경우
   } else {
     // 옵션 및 추가 상품이 있는 경우
     var attr_price = 0;
      for (var i = 0; i < this.buyItem.length; i++) {
          let item = this.buyItem[i];
          // 선택옵션이 있는 경우
          if(item.attr == true){
            attr_price += this.product['price'];
          }
      }

      if(attr_price > 0)
        total_price = 0;

      for (var i = 0; i < this.buyItem.length; i++) {
        let item = this.buyItem[i];
        if(item.attr == false){
          // 추가 상품이 있는 경우
          attr_price += item.price;

        }
      }
      total_price += attr_price;
   } 

    // 주문서 작성
    let quantity = 1;
    let imageUrl = this.product.productPhoto['url'];
    imageUrl = imageUrl.replace('http://images.nosework-official.com', 'http://s3.ap-northeast-2.amazonaws.com/images.nosework-official.com');

    let xml_request = "<?xml version=\"1.0\" encoding=\"utf-8\" ?>\n";
    xml_request += "<order>\n"; 
    xml_request += "<merchantId>" + this.shopId + "</merchantId>\n"; // 가맹점 아이디
    xml_request += "<certiKey>" + this.certikey + "</certiKey>\n";// 가맹점 인증키
    xml_request += "<product>\n";
    xml_request += "<id>" + this.product['_id'] + "</id>\n";
    xml_request += "<name>" + this.product['name'] + "</name>\n";
    xml_request += "<basePrice>" + this.product['price'] + "</basePrice>\n";
    xml_request += "<infoUrl>" + this.productUrl + this.product['name'] + "</infoUrl>\n";
    xml_request += "<imageUrl>" + imageUrl + "</imageUrl>\n";

   if(this.buyItem.length == 0){
      // 단독형인 경우
      xml_request += "<single>\n";
      xml_request += "<quantity>" + quantity + "</quantity>\n";
      xml_request += "</single>\n";
   } else {
     // 옵션 및 추가 상품이 있는 경우
      for (var i = 0; i < this.buyItem.length; i++) {
          let item = this.buyItem[i];
          if(item.attr == true){
          // 선택옵션이 있는 경우
            xml_request += "<option>\n";
            xml_request += "<quantity>1</quantity>\n";
            xml_request += "<selectedItem>\n";
            xml_request += "<type>SELECT</type>\n";
            xml_request += "<name>상품종류</name>\n";
            xml_request += "<value>\n";
            xml_request += "<id>" + item._id + "</id>\n";
            xml_request += "<text>" + item.name + "</text>\n";
            xml_request += "</value>\n";
            xml_request += "</selectedItem>\n";
            xml_request += "</option>\n";
          }
      }

      for (var i = 0; i < this.buyItem.length; i++) {
        let item = this.buyItem[i];
        if(item.attr == false){
          // 추가 상품이 있는 경우
          xml_request += "<supplement>\n";
          xml_request += "<id>" + item._id + "</id>\n";
          xml_request += "<name>" + item.name + "</name>\n";
          xml_request += "<quantity>1</quantity>\n";
          xml_request += "<price>" + item.price + "</price>\n";
          xml_request += "</supplement>\n";
        }
      }
   } 

    // 배송정책
    xml_request += "<shippingPolicy>\n";

    if(total_price >= 50000) {
      xml_request += "<feePayType>FREE</feePayType>\n";
      xml_request += "<feeType>FREE</feeType>\n";
      xml_request += "<feePrice>0</feePrice>\n";
    } else {
      xml_request += "<feePayType>PREPAYED</feePayType>\n";
      xml_request += "<feeType>CHARGE</feeType>\n";
      xml_request += "<feePrice>2500</feePrice>\n";
    }

    xml_request += "</shippingPolicy>\n";
    xml_request += "</product>\n";
    xml_request += "<backUrl>" + this.productUrl + this.product['name'] + "</backUrl>\n";
    xml_request += "</order>\n";

    // 주문서 요청
    let formData = xml_request;

    let headers = new Headers({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization, Secrete_Token',
      'Access-Control-Allow-Credentials': 'true',
      'Content-type' : 'application/xml; charset=utf-8',
      'X-PINGOTHER' : 'pingpong',
    });

    let options = new RequestOptions({headers : headers});

    console.log(headers);
    console.log(formData);

    let en_data = encodeURI(formData);
    // en_data = en_data.replace(/\&/g, "%26");
    en_data = en_data.replace(/\&/g, " ");
    console.log(en_data);

    let requestParam = {
      data : en_data
    }

    // 서버에서 요청 진행
    this.transactionService.requestNaverPay(requestParam)
      .finally(() => {
        this.dialogService.loadingSubject.next('hide');
      })
      .subscribe(
        (requestWrapper) => {
          // requestWrapper['param1'] : 인증키
          // requestWrapper['param2'] : 가맹점번호
          if(requestWrapper['param0'] == 'SUCCESS'){
              // let redirectUrl = 'https://order.pay.naver.com/customer/buy/' + requestWrapper['param1'] + '/' + requestWrapper['param2']; //실 서비스
              let redirectUrl = 'https://test-order.pay.naver.com/customer/buy/' + requestWrapper['param1'] + '/' + requestWrapper['param2'];// 테스트
              console.log(redirectUrl);
              window.location.href = redirectUrl;
          }
        },
        (err) => {
          console.log(err);
          alert("주문서 요청 실패 " + err);
        }
      )
    return false;
  }

  wishlist_nc() {
    // let url = "https://api.pay.naver.com/o/customer/api/order/v20/register";// 서비스
    let url = "https://test-api.pay.naver.com/o/customer/api/order/v20/register";// 테스트

    if(this.product['soldOut']){
        this.dialogService.message("알림", "품절된 상품입니다.");
        return false;
    }

    // if (!this.isValid()) {// 필수 선택옵션 체크
    //     this.dialogService.message("알림", "필수옵션을 확인해주세요.");
    //     return false;
    // } 

    let querString = "SHOP_ID=" + this.shopId;
    querString += "&CERTI_KEY=" + this.certikey;
    querString += "&RESERVE1=&RESERVE2=&RESERVE3=&RESERVE4=&RESERVE5=";
    querString += "&ITEM_ID=" + this.product['_id'];
    querString += "&ITEM_NAME=" + this.product['name'];
    querString += "&ITEM_UPRICE=" + this.product['price'];
    querString += "&ITEM_IMAGE=" + this.product.productPhoto['url'];
    querString += "&ITEM_URL=" + this.productUrl + this.product['name'];

    let en_data = encodeURI(querString);
    let requestParam = {
      data : en_data
    }

    // 서버에서 요청 진행
    this.transactionService.requestNaverPayWish(requestParam)
      .finally(() => {
        this.dialogService.loadingSubject.next('hide');
      })
      .subscribe(
        (requestWrapper) => {
          console.log(requestWrapper);
          let bodys = requestWrapper['body'];
          this.itemId = bodys; //찜정보 등록후 네이버에서 받은 상품 아이디

          let wishlistPopupUrl = "https://test-pay.naver.com/customer/wishlistPopup.nhn?";
          wishlistPopupUrl += "SHOP_ID=" + this.shopId;
          wishlistPopupUrl += "&ITEM_ID=" + this.itemId;
          
          window.location.href = wishlistPopupUrl;
        },
        (err) => {
          console.log(err);
          alert("찜 정보 등록 실패 " + err);
        }
      )
    return false;
  }

  isValid() {
    if(!this.enableAttribute){
      return true;
    } else {
      if(this.buyItem.length == 0){
        return false;
      } else {
          for(var i =0 ; i < this.buyItem.length; i++){
            if(this.buyItem[i].attr){
              return true;
            }
          }
      }
      return false;
    }
  }

  selectAttribute(attributeIndex) {
    var attributeObj = this.product['attribute'][attributeIndex];
    attributeObj['attr'] = true;
    this.attributeName = this.product['attribute'][attributeIndex].name;
    
    if (attributeObj['soldOut']) {
      this.dialogService.message("알림", "품절된 상품입니다.");
    } else {
      if (!this.checkedAverrideItem(attributeObj)){
        this.buyItem.push(attributeObj);
      } else {
        this.dialogService.message("알림", "이미 선택되어있는 상품입니다.");
      }      
    }
  }
  selectDefaultAtt(){
    this.attributeName = "- [필수] 상품종류를 선택해 주세요 -";
  }
  selectDefault(){
    this.propertyName = "- [선택] 추가옵션을 선택해 주세요 -";
  }
  selectOption(optionIndex,propertyIndex) {
    let option = this.product['option'][optionIndex];
    var property = option['properties'][propertyIndex];
    property['attr'] = false;
    this.propertyName = property.name;
    if (property['soldOut']) {
      this.dialogService.message("알림", "품절된 상품입니다.");
    } else {
      if(!this.checkedAverrideItem(property)){
        this.buyItem.push(property);
        option.selectedProperty = property.name;
      } else {
        this.dialogService.message("알림", "이미 선택되어있는 옵션입니다.");
      }
    }
  }

  checkedAverrideItem(property) {
    for (var i = 0; i < this.buyItem.length; i++) {
      if (property.name == this.buyItem[i].name) {
        return true;
      }
    }
    return false;
  }

  addCart() {
    if (this.isValid()) {
      let copyCartList = this.appService.cartList.slice(0);

      if (copyCartList.length > 0) {
        let findConfig = {product: {name: this.product.name}};
        if (this.buyItem) findConfig['selectedOption'] = this.buyItem;
        let findItemIndex = _.findIndex(copyCartList, findConfig);

        if (findItemIndex >= 0) {
          this.dialogService.confirm("알림", "기존에 장바구니에 등록된 상품입니다. 추가하시겠습니까?")
            .filter(function (x) {
              return x;
            })
            .subscribe(() => {
              copyCartList[findItemIndex].quantity++;
              this.appService.cartList = copyCartList;
            });
        } else {
          copyCartList.push({
            product: this.product,
            selectedOption: this.buyItem,
            quantity: 1,
            selected: false
          });
          this.appService.cartList = copyCartList;
        }
      } else {
        copyCartList.push({
          product: this.product,
          selectedOption: this.buyItem,
          quantity: 1,
          selected: false
        });
        this.appService.cartList = copyCartList;
      }
    } else {
      this.dialogService.message("알림", "옵션을 확인해주세요.");
    }
  }
  
  optionClear(buyIndex){
    for(var i= 0 ;i < this.buyItem.length;i++){
      if(i == buyIndex){
        this.buyItem.splice(i,1);
      }
    }
  }

  buy() {
    if (this.isValid()) {
      this.transactionService.products = [];
      this.transactionService.products = [{
        product: this.product,
        selectedOption: this.buyItem,
        quantity: 1
      }];
    } else {
      this.dialogService.message("알림", "필수옵션을 확인해주세요.");
      return;
    }
    
    if (this.appService.user && this.appService.token) {
      this.appService.goToMenu('store/payment');
    } else {
      this.openLoginConfirm();
    }
  }

  openLoginConfirm() {
    this.loginConfirmService.confirm("확인", "로그인 후 이용가능합니다.<br>로그인 하시겠습니까?");
  }

  /*****************************
   *       helper functions
   *****************************/
  selectedArr = [];
  loadProduct() {
    this.dialogService.loadingSubject.next('spinner');

    this.productService.findOne(
      {
        query: {
          name: this.name
        },
        populate: ['photos', 'detailFiles']
      }
    )
      .finally(() => {
        this.dialogService.loadingSubject.next('hide');
      })
      .subscribe(
        (productWrapper) => {
          if (productWrapper['product']) {
            this.product = productWrapper['product'];
            this.product['productPhoto'] = _.find(this.product['photos'], {tag: 'THUMBNAIL'});
            this.product['detailPhoto'] = _.find(this.product['photos'], {tag: 'DETAIL'});
            //made by mangus
            for(var i = 0 ; i < this.product['option'].length; i++){
                for(var j = 0 ; j < this.product['option'][i]['properties'].length; j++){
                  this.product['option'][i]['properties'][j].GroupName = this.product['option'][i].name;
                }
            }
            
            let flag = false;
            
            for(var i =0 ; i < this.product['detailFiles'].length ;i++){
              var fileType = '';
              var fileType1 = this.product['detailFiles'][i]['fileType'].split("/");
              this.product['detailFiles'][i]['onlyType'] = fileType1[0];
              this.product['detailFiles'][i]['otherType'] = fileType1[1];
            }
            for(var i = 0 ; i < this.product['option'].length;i++){
              if(this.product['option'][i]['properties'].length > 0){
                this.enableOption = true;
                break;
              }
            }
            if(this.product['attribute'].length > 0){
              this.enableAttribute = true;
            }

          } else {
            this.dialogService.message("알림", "찾을 수 없는 상품입니다.");
            this.appService.goToMenu('/store');
          }
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';  
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
          this.appService.goToMenu('/store');
        }
      )
  }

 // by Nosework 0626
NaverPayClicked(){
  if (this.isValid()) {// 필수 옵션 있을 시 테스트문구 현시
    this.dialogService.message("알림","네이버페이 테스트 진행중입니다. 빠른 시일 내에 서비스를 제공하도록 하겠습니다.");
  }
  
}

  // made by mangus
  /*openImageDialog(imageIndex) {
    this.dialogDetailProduct = this.mdDialog.open(ProductDetailDialog, this.config);
    this.dialogDetailProduct.componentInstance.productDialog_ID =  this.product['detailFiles'][imageIndex]._id;
  }
  openVideoDialog(videoIndex) {
    this.dialogDetailProduct = this.mdDialog.open(ProductDetailDialog, this.config);
    this.dialogDetailProduct.componentInstance.productDialog_ID =  this.product['detailFiles'][videoIndex]._id;
  }*/
  
}