/**
 * Created by Yoon Yong (Andy) Shin on 23/12/2016
 * As part of Nosework
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & Yoon Yong (Andy) Shin - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Yoon Yong (Andy) Shin <andy.shin@applicat.co.kr>, 23/12/2016
 *
 */

// Angular
import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, FormControl, Validator } from '@angular/forms';

let EMAIL_REGEXP: RegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@Directive({
  selector: '[invalidEmail][formControlName],[invalidEmail][ngModel],[invalidEmail][formControl]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => EmailValidator), multi: true}
  ]
})
export class EmailValidator implements Validator {

  constructor() {
  }

  validate(c: FormControl) {
    return EmailValidator.isValidFormat(c);
  }

  static isValidFormat(c: FormControl) {
    return EMAIL_REGEXP.test(c.value) ? null : {
      invalidEmail: true
    };
  }
}