/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */

// Export Lib
declare var _;
declare var wcs;
declare var wcs_do;

// Angular
import {Component, OnInit} from "@angular/core";
import { CookieService } from 'angular2-cookie/core';

// Project Sources
import {getTemplate} from "../../../shared/templates";
import {AppService} from "../../../shared/services/app.service";
import {TransactionService} from "../../../shared/services/transaction.service";
import {DialogService} from "../../../shared/components/dialog-message/dialog-message.service";

@Component({
  selector: 'invoice-list',
  template: getTemplate('pages/store/invoice-list/invoice-list')
})
export class InvoiceList implements OnInit{

  invoices;

  constructor(public appService: AppService,
              private transactionService: TransactionService,
              private dialogService: DialogService,
              private _cookieService: CookieService) {
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {
    if (!wcs_add) var wcs_add = {};
    wcs_add["wa"] = "s_52e6d95fb9a0";
    if (!_nasa) var _nasa = {};
    wcs.inflow();
    wcs_do(_nasa);

    this.invoices = [];

    this.initLoadSequence();
  }

  /*****************************
   *        util functions
   *****************************/

  initLoadSequence() {
    this.loadInvoices();
  }

  /*****************************
   *       helper functions
   *****************************/

  loadInvoices() {
    let nonMemberMail = '';
    let invoicePrice = '';
    if (this._cookieService.get('nonMemberMail') !== undefined) {
      nonMemberMail = this._cookieService.get('nonMemberMail');
      invoicePrice = this._cookieService.get('invoicePrice');

      this._cookieService.remove('nonMemberMail');
      this._cookieService.remove('invoicePrice');
    } else {
      nonMemberMail = this.appService.user.identifier;
    }

    // made by mangus
    let params: any = {
      query: {},
      sort: {lastUpdatedAt: -1},
      populate: ['owner'],
      $or : { 'owner': this.appService.user._id }
    };

    if (this.appService.token != null) {
      params.query['owner'] = this.appService.user._id;
    } else {
      params.query['createdByEmail'] = nonMemberMail;
    }

    if (invoicePrice !== '') {
      params.query['totalAmount'] = parseInt(invoicePrice);
    }
    
    this.transactionService.myInvoices(params)
      .subscribe(
        (invoicesWrapper) => {
          this.invoices = invoicesWrapper['invoices'];
          _.forEach(this.invoices, (invoice) => {
            if (invoice.itemList.length > 0 && invoice.itemList[0].product.photos.length > 0) {
              invoice['productPhoto'] = invoice.itemList[0].product.photos[0].secure_url;
            } else {
              invoice['productPhoto'] = 'assets/img/img_default_product.png';
            }

            const time = new Date(invoice.createdAt);
            const utc = time.getTime() + (time.getTimezoneOffset() *60000);
            const offset = 9;
            const seoulTime = new Date(utc + (3600000 * offset));

            const invoiceDate = seoulTime.getFullYear() + '년' + (seoulTime.getMonth() + 1) + '월' + seoulTime.getDate() + '일';
            const invoiceTime = seoulTime.getHours() + '시' + seoulTime.getMinutes() + '분';
            invoice.invoiceDate = invoiceDate;
            invoice.invoiceTime = invoiceTime;

            switch(invoice.status){
              case 'PAYMENT_PENDING':
                invoice['translateStatus'] = '결제대기';
                break;
              case 'PAYMENT_FAILED':
                invoice['translateStatus'] = '결제실패';
                break;
              case 'PAYMENT_DONE':
                invoice['translateStatus'] = '결제완료';
                break;
              case 'ORDER_CANCELLED':
                invoice['translateStatus'] = '주문취소';
                break;
              case 'DELIVERY_PREPARE':
                invoice['translateStatus'] = '배송준비';
                break;
              case 'DELIVERING':
                invoice['translateStatus'] = '배송중';
                break;
              case 'DELIVERED':
                invoice['translateStatus'] = '배송완료';
                break;
              case 'EXCHANGE_REQUESTED':
                invoice['translateStatus'] = '교환요청';
                break;
              case 'EXCHANGE_DONE':
                invoice['translateStatus'] = '교환완료';
                break;
              case 'REFUND_REQUESTED':
                invoice['translateStatus'] = '환불요청';
                break;
              case 'REFUND_DONE':
                invoice['translateStatus'] = '환불완료';
                break;
              case 'ORDER_DONE':
                invoice['translateStatus'] = '주문완료';
                break;
            }
            switch(invoice.paymentType){
              case 'card':
                invoice['translatePaymentType'] = '신용카드';
                break;
              case 'trans':
                invoice['translatePaymentType'] = '실시간 계좌이체';
                break;
              case 'bank':
                invoice['translatePaymentType'] = '무통장 입금';
                break;
            }
          });
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 400:
              subTitle = '주문 내역을 찾을 수 없습니다.';
              break;
            case 406:
              subTitle = '상품을 구매할 수 없습니다. 다시 시도해주세요.';
              break;
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message('알림', subTitle);
          this.appService.goBack();
        }
      )
  }

  cancelPaymentForUser(invoice) {
    if (
      invoice.status === 'PAYMENT_PENDING' ||
      invoice.status === 'PAYMENT_DONE'
    ) {
      this.dialogService.confirm('알림', '정말 취소하시겠습니까?')
        .filter(function(x){
          return x;
        })
        .subscribe(() => {
          this.dialogService.loadingSubject.next('spinner');

          this.transactionService.cancelPaymentForUser(invoice.payments[0]._id)
            .finally(() => {
              this.dialogService.loadingSubject.next('hide');
            })
            .subscribe(
              () => {
                this.initLoadSequence();
              },
              (err) => {
                let subTitle = '';
                switch (err.status) {
                  case 400:
                    subTitle = '주문을 취소할 수 없습니다. 주문상태를 확인해주세요.';
                    break;
                  case 422:
                    subTitle = '고객센터 또는 PG사로 문의가 필요합니다.';
                    break;
                  case 500:
                    subTitle = '서버에러';
                    break;
                  default:
                    subTitle = '잘못된 요청입니다.';
                    break;
                }
                this.dialogService.message("알림", subTitle);
              }
            );
        });
    } else {
      this.dialogService.message('알림', '고객센터(1600-2190)로 문의하시기 바랍니다.');
    }
  }

}